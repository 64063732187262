import { Button, IconButton, Stack, Toolbar, Typography, useTheme } from "@mui/material";
import { Activ8CoursesContentLearningCourseContentChapter, Activ8CoursesContentLearningCourseContentChapterSession } from "src/api/redux/app/appApi";
import Iconify from "src/components/Iconify";
import { CloseIcon } from 'src/theme/overrides/CustomIcons';

type Props = {
    isQuizInProgress: boolean;
    onClose: () => void;
    onPreviousClick: () => Promise<void>;
    onNextClick: () => Promise<void>;
    programName: string;
    contentChapter: Activ8CoursesContentLearningCourseContentChapter;
    contentSession: Activ8CoursesContentLearningCourseContentChapterSession;
    isLoading: boolean;
    currentlyOnFirstStep: boolean;
    currentlyOnLastStep: boolean;
    isNextProgressionBlocked: boolean;
    compact?: boolean;
    isAllQuizzesOnPageComplete: boolean;
    isQuizQuestionAnswered: boolean | undefined;
    lastQuestionInQuizBlockInProgress: boolean | undefined | null;
}

export const ContentWizardToolbar = ({ isQuizInProgress, onClose, programName, compact, contentChapter, contentSession, onNextClick, isLoading, onPreviousClick, lastQuestionInQuizBlockInProgress, isAllQuizzesOnPageComplete, isQuizQuestionAnswered, isNextProgressionBlocked, currentlyOnFirstStep, currentlyOnLastStep }: Props) => {
    const theme = useTheme();

    return (
        <Toolbar>
            <Stack direction='row' justifyContent={compact ? 'flex-end' : 'space-between'} sx={{ width: '100%', p: 1 }} alignItems={'center'}>
                {!compact && <IconButton
                    edge="start"
                    color="default"
                    onClick={onClose}
                    // hidden={isQuizInProgress}
                    sx={isQuizInProgress ? { visibility: 'hidden' } : {}}
                    aria-label="close"
                >
                    <CloseIcon />
                </IconButton>}
                {!compact && <Stack direction={'column'} spacing={{ xs: 1, md: 2 }} alignItems={'center'}>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="body2" component="div">
                        {programName}
                    </Typography>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="body2" component="div">
                        {contentChapter.name}
                    </Typography>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="body1" component="div">
                        {contentSession.name}
                    </Typography>
                </Stack>}
                <div>
                    <Button color={"inherit"} variant='text' disabled={isLoading || currentlyOnFirstStep || currentlyOnLastStep || isQuizInProgress} onClick={onPreviousClick} startIcon={<Iconify icon='eva:arrow-left-fill' />} sx={{ mr: theme.spacing(2) }}>
                        Back
                    </Button>
                    <Button autoFocus color={isNextProgressionBlocked ? "info" : "primary"} variant='contained' disabled={isQuizInProgress || isNextProgressionBlocked || isLoading || (!isQuizInProgress && !isAllQuizzesOnPageComplete && !isNextProgressionBlocked) || (isQuizInProgress && !isQuizQuestionAnswered)} onClick={currentlyOnLastStep ? onClose : onNextClick} endIcon={currentlyOnLastStep ? <Iconify icon='eva:checkmark-circle-2-outline' /> : <Iconify icon='eva:arrow-right-fill' />}>
                        {currentlyOnLastStep ? 'Finish' : 'Next'}
                    </Button>
                </div>
            </Stack>
        </Toolbar>
    )
}