import { difference, get, uniq } from 'lodash-es';
import { DateTime } from 'luxon';
import { forwardRef, ReactElement, Ref, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
    Activ8CoursesContentLearningCourseContentBlock,
    Activ8CoursesContentLearningCourseContentChapter,
    Activ8CoursesContentLearningCourseContentChapterSession,
    Activ8CoursesContentLearningCourseContentResource,
    Activ8CoursesContentQuizResultsLearningCourseContentQuizResult, appApi
} from 'src/api/redux/app/appApi';
import Iconify from 'src/components/Iconify';
import LoadingScreen from 'src/components/LoadingScreen';
import useApiResponseHandler from 'src/hooks/useApiResponseHandler';

import {
    AppBar,
    Container, Dialog,
    LinearProgress, Slide, Stack,
    Typography, useTheme
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';

import {
    LearningCourseProgramSessionContentViewWizardFormData
} from '../LearningCourseProgramOverviewDashboard';
import { ContentWizardToolbar } from './ContentWizardToolbar';
import {
    LearningCourseProgramSessionContentBlockView
} from './LearningCourseProgramSessionContentBlockView';

// import 'react-quill/dist/quill.snow.css';

type LearningCourseProgramSessionContentViewWizardProps = {
    chapterIndex: number;
    sessionIndex: number;
    programName: string;
    courseProgramId: string;
    contentSession: Activ8CoursesContentLearningCourseContentChapterSession;
    contentChapter: Activ8CoursesContentLearningCourseContentChapter;
    onClose: () => void;
    initialQuizResults: Activ8CoursesContentQuizResultsLearningCourseContentQuizResult[];
}

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: ReactElement;
    },
    ref: Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export function LearningCourseProgramSessionContentViewWizard({ contentChapter, programName, chapterIndex, sessionIndex, contentSession, courseProgramId, onClose, initialQuizResults }: LearningCourseProgramSessionContentViewWizardProps) {
    const [currentStepIndex, setCurrentStepIndex] = useState(0);
    const [quizContentBlockIdInProgress, setQuizContentBlockIdInProgress] = useState<string | undefined>(undefined);
    const [currentQuizQuestionIndex, setCurrentQuizQuestionIndex] = useState<number | undefined>(undefined);
    const [completedQuizIds, setCompletedQuizIds] = useState<string[]>(initialQuizResults ? initialQuizResults.filter(e => e.isCompleted).map(e => e.learningCourseContentQuizId as string) : []);
    const [contentEntryTime, setContentEntryTime] = useState(DateTime.utc());
    const [isNextProgressionBlocked, setIsNextProgressionBlocked] = useState<boolean>((contentSession.content?.filter(e => Boolean(e.quiz))?.length || 0) != initialQuizResults.filter(e => e.isCompleted).length);
    const preparedContentForParticipantQuery = appApi.endpoints.learningCourseProgramSessionPrepareSessionContent.useQuery({ courseProgramId: courseProgramId, courseProgramChapterId: contentChapter.id as string, courseProgramChapterSessionId: contentSession.id as string }, { refetchOnFocus: true, refetchOnMountOrArgChange: true, refetchOnReconnect: true })
    const methods = useFormContext<LearningCourseProgramSessionContentViewWizardFormData>();
    const theme = useTheme();
    const { handleError } = useApiResponseHandler();
    const [logProgressionThroughBlocksPost, logProgressionThroughBlocksPostStatus] = appApi.endpoints.learningCourseProgramSessionProgressThroughSessionContentBatch.useMutation();

    const [contentPages, setContentPages] = useState<Array<Array<Activ8CoursesContentLearningCourseContentBlock>>>([]);
    const [quizResults, setQuizResults] = useState<Array<Activ8CoursesContentQuizResultsLearningCourseContentQuizResult>>(initialQuizResults);

    useEffect(() => {
        const pages: Array<Array<Activ8CoursesContentLearningCourseContentBlock>> = [];
        if (!preparedContentForParticipantQuery.isLoading && !preparedContentForParticipantQuery.isFetching && preparedContentForParticipantQuery.data && preparedContentForParticipantQuery.data.session && preparedContentForParticipantQuery.data.session.content && preparedContentForParticipantQuery.data.session.content.length > 0) {
            const currentSessionContent = preparedContentForParticipantQuery.data.session.content;
            let currentPage: Array<Activ8CoursesContentLearningCourseContentBlock> = [];
            for (let contentBlockIndex = 0; contentBlockIndex < currentSessionContent.length; contentBlockIndex++) {
                const contentBlock = currentSessionContent[contentBlockIndex];

                if (contentBlock.type !== 'PageBreak') {
                    currentPage.push(contentBlock);
                }

                if (contentBlock.type === 'PageBreak') {
                    // Start new page
                    pages.push([...currentPage]);
                    currentPage = [];
                }
                else if (contentBlockIndex === currentSessionContent.length - 1) {
                    // Last content block, finalise the page
                    pages.push([...currentPage]);
                }
            }
        }

        setContentPages(pages);
    }, [preparedContentForParticipantQuery.isLoading])

    const totalProgressions = contentPages.length;

    const prepareContentResourceReadUrl = async (file: Activ8CoursesContentLearningCourseContentResource, isDownload: boolean): Promise<string> => {
        const preparedUrl = get(preparedContentForParticipantQuery.data?.resourceContentUrls, file.id as string);

        return preparedUrl as string;
    };

    const isLoading = preparedContentForParticipantQuery.isLoading || preparedContentForParticipantQuery.isFetching;
    const currentlyOnLastStep = !isLoading && (currentStepIndex) === totalProgressions;
    const currentlyOnFirstStep = (currentStepIndex) === 0;
    const percentComplete = currentStepIndex === 0 ? 0 : ((currentStepIndex) / totalProgressions) * 100;

    const onQuizOptionSelectToggle = (optionId: string, isSelected: boolean) => {
        let selected = methods.getValues('quizQuestionSelectedOptions') as string[];
        if (isSelected) {
            if (selected.indexOf(optionId) === -1) {
                // add
                selected.push(optionId);
            }
        }
        else {
            if (selected.indexOf(optionId) > -1) {
                // remove
                selected = selected.filter(e => e !== optionId);
            }
        }
        methods.setValue('quizQuestionSelectedOptions', selected);
    }

    const onQuizStart = (quizContentBlock: Activ8CoursesContentLearningCourseContentBlock) => {
        // Always set the quiz in progress to track current question
        setQuizContentBlockIdInProgress(quizContentBlock.id as string);
        setCurrentQuizQuestionIndex(0);
        setContentEntryTime(DateTime.utc());
        methods.setValue('quizQuestionSelectedOptions', []);
        methods.setValue('quizQuestionFreeTextAnswerText', '');


    }

    const getQuizInfo = () => {
        // Find the active quiz block and its details
        const quizContentBlockInProgress = contentPages[currentStepIndex]?.find(c => c.id === quizContentBlockIdInProgress);
        const quizBlockInProgress = quizContentBlockInProgress?.quiz;
        const lastQuestionInQuizBlockInProgress = quizBlockInProgress && quizBlockInProgress.questions && quizBlockInProgress.questions.length - 1 === currentQuizQuestionIndex;
        const isActiveQuizInline = quizBlockInProgress?.isQuizShownInLine === true;

        return {
            quizContentBlockInProgress,
            quizBlockInProgress,
            lastQuestionInQuizBlockInProgress: Boolean(lastQuestionInQuizBlockInProgress),
            isActiveQuizInline
        }
    }

    useEffect(() => {
        const { quizContentBlockInProgress } = getQuizInfo();

        if (currentQuizQuestionIndex != undefined && quizContentBlockInProgress?.quiz?.isAnswerFeedbackShown) {
            const currentQuestion = quizContentBlockInProgress.quiz.questions ? quizContentBlockInProgress.quiz.questions[currentQuizQuestionIndex] : null;
            if (currentQuestion && currentQuestion.questionType !== 'FreeTextMultiLine') {
                setIsNextProgressionBlocked(true);
                return;
            }
        }

        setIsNextProgressionBlocked(false);
    }, [currentQuizQuestionIndex])

    const onQuizComplete = () => {
        const { quizBlockInProgress, isActiveQuizInline, quizContentBlockInProgress } = getQuizInfo();

        const currentQuizId = quizBlockInProgress?.id as string

        // Mark quiz as complete
        const newCompletedQuizIds = uniq([...completedQuizIds, currentQuizId]).filter(Boolean)
        const quizContentBlockInProgresss = contentPages[currentStepIndex]?.find(c => c.id === quizContentBlockIdInProgress);
        console.log('Quiz completed', { currentStepIndex, currentQuizId, quizContentBlockIdInProgress, quizContentBlockInProgresss, completedQuizIds, newCompletedQuizIds, quizBlockInProgress, isActiveQuizInline })

        setCompletedQuizIds(newCompletedQuizIds);

        // Reset quiz state
        methods.setValue('quizQuestionSelectedOptions', []);
        methods.setValue('quizQuestionFreeTextAnswerText', '');
        setContentEntryTime(DateTime.utc());
        setCurrentQuizQuestionIndex(undefined);
        setQuizContentBlockIdInProgress(undefined);

        // For inline quizzes, find and scroll to next incomplete quiz
        // if (isActiveQuizInline) {
        //     setTimeout(() => {

        //         contentPages[currentStepIndex].findIndex((e) => e.)

        //         const nextBlock = contentPages[currentStepIndex].find(b =>
        //             b.type === 'Quiz' &&
        //             b.quiz?.isQuizShownInLine &&
        //             b.id !== currentQuizId &&
        //             !newCompletedQuizIds.includes(b.quiz?.id as string)
        //         );

        //         if (nextQuizBlock) {
        //             const quizDiv = document.getElementById(`contentblock-${nextQuizBlock.id}`);
        //             if (quizDiv) {
        //                 quizDiv.scrollIntoView({ behavior: 'smooth' });
        //                 // Auto-start next quiz
        //                 onQuizStart(nextQuizBlock);
        //             }
        //         }
        //     }, 600);
        // }
    }

    const quizQuestionSelectedOptionsWatch = methods.watch('quizQuestionSelectedOptions') as any[] | undefined;
    const quizQuestionFreeTextAnswerTextWatch = methods.watch('quizQuestionFreeTextAnswerText') as string | undefined;

    const onPreviousClick = async () => {
        setCurrentStepIndex(currentStepIndex - 1);
    }

    const onFreeTextAnswerTextChange = (text: string) => {
        methods.setValue('quizQuestionFreeTextAnswerText', text);
    }

    // Check completion status of non-inline quizzes
    const allNonInlineQuizzesOnPage = (contentPages[currentStepIndex] || []).filter(c => c.type === 'Quiz' && c.quiz?.isQuizShownInLine !== true);
    const isAllQuizzesOnPageComplete = allNonInlineQuizzesOnPage.length === 0 || difference(allNonInlineQuizzesOnPage.filter(c => c && c.quiz).map(q => q.quiz?.id), completedQuizIds).length === 0;

    // Find next incomplete inline quiz on the page
    const nextIncompleteInlineQuiz = contentPages[currentStepIndex]?.find(b =>
        b.type === 'Quiz' &&
        b.quiz?.isQuizShownInLine === true &&
        b.id !== quizContentBlockIdInProgress &&
        !completedQuizIds.includes(b.quiz?.id as string)
    );

    const onNextClick = async () => {
        try {
            await logProgressThroughBlocks();
        }
        catch (err) {
            handleError(err);
            return;
        }

        let willProgressToNextStep = true;

        const { lastQuestionInQuizBlockInProgress, quizBlockInProgress, isActiveQuizInline } = getQuizInfo();
        console.log('Nextprogress - lastQuestionInQuizBlockInProgress - !isNextProgressionBlocked', { lastQuestionInQuizBlockInProgress, quizBlockInProgress, isActiveQuizInline })

        if (quizBlockInProgress) {
            console.log('Quiz in progress')
            if (!lastQuestionInQuizBlockInProgress) {
                console.log('Quiz in progress - !lastQuestionInQuizBlockInProgress')
                // Not on last question - move to next question
                if (!isNextProgressionBlocked) {
                    methods.setValue('quizQuestionSelectedOptions', []);
                    methods.setValue('quizQuestionFreeTextAnswerText', '');
                    setCurrentQuizQuestionIndex((currentQuizQuestionIndex || 0) + 1);

                    if (quizBlockInProgress?.isAnswerFeedbackShown) {
                        setIsNextProgressionBlocked(true);
                    }
                }
                else {
                    willProgressToNextStep = false;
                    setIsNextProgressionBlocked(false);
                }
            }
            else {
                console.log('Quiz in progress - lastQuestionInQuizBlockInProgress')
                // On last question
                if (!isNextProgressionBlocked) {
                    console.log('Quiz in progress - lastQuestionInQuizBlockInProgress - !isNextProgressionBlocked')
                    // Complete current quiz
                    onQuizComplete();
                    setIsNextProgressionBlocked(false);

                    if (isActiveQuizInline) {
                        // // For inline quizzes, check if there's another incomplete inline quiz
                        // if (nextIncompleteInlineQuiz) {
                        //     // Start next inline quiz
                        //     setTimeout(() => {
                        //         const quizDiv = document.getElementById(`contentblock-${nextIncompleteInlineQuiz.id}`);
                        //         if (quizDiv) {
                        //             quizDiv.scrollIntoView({ behavior: 'smooth' });
                        //             onQuizStart(nextIncompleteInlineQuiz);
                        //         }
                        //     }, 600);
                        //     willProgressToNextStep = false;
                        // }
                        // else {
                        //     // No more inline quizzes, check if we can progress
                        //     willProgressToNextStep = isAllQuizzesOnPageComplete;
                        // }
                    }
                    else {
                        // For non-inline quizzes, scroll back to completed quiz
                        setTimeout(() => {
                            const quizDiv = document.getElementById(`contentblock-${quizContentBlockIdInProgress}`);
                            if (quizDiv) {
                                quizDiv.scrollIntoView({ behavior: 'smooth' });
                            }
                        }, 600);
                        willProgressToNextStep = isAllQuizzesOnPageComplete;
                    }
                }
                else {
                    console.log('Quiz in progress - lastQuestionInQuizBlockInProgress - isNextProgressionBlocked')
                    willProgressToNextStep = false;
                    setIsNextProgressionBlocked(false);
                }
            }
        }
        else {
            // No quiz in progress, check if we can move to next step
            if (isAllQuizzesOnPageComplete) {
                setCurrentStepIndex(currentStepIndex + 1);
            }
            else if (nextIncompleteInlineQuiz) {
                // Start the next incomplete inline quiz
                setTimeout(() => {
                    const quizDiv = document.getElementById(`contentblock-${nextIncompleteInlineQuiz.id}`);
                    if (quizDiv) {
                        quizDiv.scrollIntoView({ behavior: 'smooth' });
                        onQuizStart(nextIncompleteInlineQuiz);
                    }
                }, 600);
                willProgressToNextStep = false;
            }
        }

        if (willProgressToNextStep) {
            setContentEntryTime(DateTime.utc());
        }
    }

    const logProgressThroughBlocks = async (): Promise<void> => {
        const exitTime = DateTime.utc();

        // Prepare requests array
        const requests = [];

        // Add quiz progress if a quiz is in progress
        const { quizBlockInProgress, quizContentBlockInProgress } = getQuizInfo();
        if (quizBlockInProgress) {
            requests.push({
                entryTime: contentEntryTime.toISO() || undefined,
                exitTime: exitTime.toISO() || undefined,
                learningCourseProgramId: courseProgramId,
                learningCourseTemplateContentChapterId: contentChapter.id as string,
                learningCourseTemplateContentSessionId: contentSession.id as string,
                learningCourseContentBlockId: quizContentBlockInProgress?.id as string,
                learningCourseContentQuizId: quizBlockInProgress.id,
                learningCourseContentQuizQuestionType: currentQuizQuestionIndex !== undefined ?
                    (quizContentBlockInProgress?.quiz?.questions || [])[currentQuizQuestionIndex].questionType :
                    undefined,
                learningCourseContentQuizQuestionId: currentQuizQuestionIndex !== undefined ?
                    (quizContentBlockInProgress?.quiz?.questions || [])[currentQuizQuestionIndex].id :
                    undefined,
                learningCourseContentQuizQuestionMultipleChoiceOptionIdsSelected: currentQuizQuestionIndex !== undefined ?
                    methods.getValues('quizQuestionSelectedOptions') :
                    undefined,
                learningCourseContentQuizQuestionFreeTextAnswerText: currentQuizQuestionIndex !== undefined ?
                    methods.getValues('quizQuestionFreeTextAnswerText') :
                    undefined,
            });
        }

        // Add non-quiz blocks progress
        const nonQuizBlocks = contentPages[currentStepIndex].filter(e =>
            e.type !== 'Quiz' ||
            (e.type === 'Quiz' && e.quiz?.isQuizShownInLine && !isQuizInProgress)
        );

        if (nonQuizBlocks.length > 0) {
            requests.push(...nonQuizBlocks.map(contentBlock => ({
                entryTime: contentEntryTime.toISO() || undefined,
                exitTime: exitTime.toISO() || undefined,
                learningCourseProgramId: courseProgramId,
                learningCourseTemplateContentChapterId: contentChapter.id as string,
                learningCourseTemplateContentSessionId: contentSession.id as string,
                learningCourseContentBlockId: contentBlock?.id as string
            })));
        }

        // Send requests if there are any
        if (requests.length > 0) {
            const result = await logProgressionThroughBlocksPost({
                activ8CoursesProgramsSessionsProgressThroughSessionContentBatchRequest: {
                    requests
                }
            }).unwrap();

            // Update quiz results if any were returned
            if (result?.responses && result.responses.length > 0) {
                const newResults = [...quizResults];

                for (const response of result.responses) {
                    if (response.quizResults) {
                        for (const quizResult of response.quizResults) {
                            const existingResultIndex = newResults.findIndex(r =>
                                r.learningCourseContentQuizId === quizResult.learningCourseContentQuizId
                            );
                            if (existingResultIndex > -1) {
                                newResults[existingResultIndex] = quizResult;
                            } else {
                                newResults.push(quizResult);
                            }
                        }
                    }
                }

                setQuizResults(newResults);
                setIsNextProgressionBlocked(false);
            }
        }
    }

    const isQuizInProgress = Boolean(quizContentBlockIdInProgress && currentQuizQuestionIndex !== undefined && currentQuizQuestionIndex >= 0);
    const isQuizQuestionAnswered = isQuizInProgress ? (Boolean(quizQuestionFreeTextAnswerTextWatch) || Boolean(quizQuestionSelectedOptionsWatch && quizQuestionSelectedOptionsWatch.length > 0)) : false;

    const contentToShow = useMemo(() => {

        if (!contentPages[currentStepIndex]) return [];

        const quizInProgress = isQuizInProgress ? contentPages[currentStepIndex].find(e => e.id === quizContentBlockIdInProgress)?.quiz : undefined;
        const inProgressQuizIsInline = Boolean(quizInProgress?.isQuizShownInLine)

        return contentPages[currentStepIndex].filter((e, i) => quizInProgress ? (inProgressQuizIsInline ? true : e.id === quizContentBlockIdInProgress) : true)
    }, [contentPages, currentStepIndex, currentQuizQuestionIndex, quizContentBlockIdInProgress])

    return (
        <Dialog
            fullScreen
            open={true}
            onClose={onClose}
            TransitionComponent={Transition}
            sx={{ paddingBottom: '64px' }}
        >
            <AppBar sx={{ position: 'relative' }} color='secondary'>
                <ContentWizardToolbar isQuizInProgress={isQuizInProgress} onClose={onClose} onPreviousClick={onPreviousClick} onNextClick={onNextClick} programName={programName} contentChapter={contentChapter} contentSession={contentSession} isLoading={isLoading} currentlyOnFirstStep={currentlyOnFirstStep} currentlyOnLastStep={currentlyOnLastStep} isNextProgressionBlocked={isNextProgressionBlocked} isAllQuizzesOnPageComplete={isAllQuizzesOnPageComplete} isQuizQuestionAnswered={isQuizQuestionAnswered} lastQuestionInQuizBlockInProgress={getQuizInfo().lastQuestionInQuizBlockInProgress} />
                <LinearProgress variant="determinate" value={percentComplete} color={currentlyOnLastStep ? "success" : "info"} sx={{ height: '10px', borderRadius: 0, width: '100%' }} />
            </AppBar>

            {/* <code>{JSON.stringify(completedQuizIds)}</code> */}

            {isLoading ? <LoadingScreen preventFullScreen /> :
                <Container sx={{ mt: 5, pb: 5 }}>
                    {/* <code>{JSON.stringify(contentPages)}</code> */}
                    {currentlyOnLastStep ?

                        <Stack direction='column' spacing={2} sx={{ width: '100%' }} alignItems='center' justifyContent='center'>
                            <Typography variant='h3' sx={{ color: theme.palette.success.main }}>All done here!</Typography>
                            <Iconify sx={{ m: 0, p: 0, color: theme.palette.success.main }} icon='ic:baseline-tag-faces' fontSize={90} />
                        </Stack>
                        :

                        <Stack gap={2}>
                            {/* {contentPages[currentStepIndex].filter((block) => !block.quiz || block.quiz?.isQuizShownInLine === true || block.id === quizContentBlockIdInProgress).map((block) => ( */}
                            {contentToShow.map((block) => (
                                <LearningCourseProgramSessionContentBlockView
                                    onFreeTextAnswerTextChange={onFreeTextAnswerTextChange}
                                    quizResults={quizResults}
                                    key={`${block.id}_${block.quiz ? completedQuizIds.length : ''}`}
                                    loading={logProgressionThroughBlocksPostStatus.isLoading || isLoading}
                                    contentBlock={block}
                                    prepareContentResourceReadUrl={prepareContentResourceReadUrl}
                                    onQuizStart={onQuizStart}
                                    completedQuizIds={completedQuizIds}
                                    onNextClick={onNextClick}
                                    currentQuizQuestionIndex={block.id === quizContentBlockIdInProgress ? currentQuizQuestionIndex : undefined}
                                    onQuizOptionSelectToggle={onQuizOptionSelectToggle}
                                    isQuizQuestionAnswered={isQuizQuestionAnswered}
                                />
                            ))}
                        </Stack>
                    }
                </Container>
            }

            <AppBar position="fixed" color="secondary" sx={{ top: 'auto', bottom: 0 }}>
                <ContentWizardToolbar compact isQuizInProgress={isQuizInProgress} onClose={onClose} onPreviousClick={onPreviousClick} onNextClick={onNextClick} programName={programName} contentChapter={contentChapter} contentSession={contentSession} isLoading={isLoading} currentlyOnFirstStep={currentlyOnFirstStep} currentlyOnLastStep={currentlyOnLastStep} isNextProgressionBlocked={isNextProgressionBlocked} isAllQuizzesOnPageComplete={isAllQuizzesOnPageComplete} isQuizQuestionAnswered={isQuizQuestionAnswered} lastQuestionInQuizBlockInProgress={getQuizInfo().lastQuestionInQuizBlockInProgress} />
            </AppBar>
        </Dialog>
    )
}