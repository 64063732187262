import { orderBy } from 'lodash-es';
import { DateTime } from 'luxon';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo, useState } from 'react';
import { FieldErrors, useForm } from 'react-hook-form';
import {
  Activ8CoursesContentLearningCourseContent, Activ8CoursesProgramsLearningCourseProgramStatus,
  Activ8CoursesTemplatesLearningCourseTemplateDto, appApi
} from 'src/api/redux/app/appApi';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import Iconify from 'src/components/Iconify';
import LoadingScreen from 'src/components/LoadingScreen';
import uuidv4 from 'src/utils/uuidv4';
import * as Yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import {
  Box, Button, Divider, Stack, Step, StepButton, StepLabel, Stepper, useTheme
} from '@mui/material';

import { FormProvider } from '../../../../components/hook-form';
import { ColorSchema } from '../../../../theme/palette';
import LearningCourseProgramWizardFormAccreditationStep from './Steps/LearningCourseProgramWizardFormAccreditationStep';
import LearningCourseProgramWizardFormCourseTypeStep from './Steps/LearningCourseProgramWizardFormCourseTypeStep';
import LearningCourseProgramWizardFormDeliveryStep from './Steps/LearningCourseProgramWizardFormDeliveryStep';
import LearningCourseProgramWizardFormDetailsStep from './Steps/LearningCourseProgramWizardFormDetailStep';
import LearningCourseProgramWizardFormPreRequisitesStep from './Steps/LearningCourseProgramWizardFormPreRequisitesStep';
import LearningCourseProgramWizardFormPublishingStep from './Steps/LearningCourseProgramWizardFormPublishingStep';

// ----------------------------------------------------------------------

const Activ8CoursesLearningCourseProgramSessionSchema = Yup.object({
  id: Yup.string().required('Required'),
  learningCourseTemplateContentChapterId: Yup.string().required('Required'),
  learningCourseTemplateContentSessionId: Yup.string().required('Required'),
  sessionDate: Yup.string().nullable(),
  sessionStartTime: Yup.string().nullable(),
  sessionFinishTime: Yup.string().nullable()
}).test('session-startBeforeFinish', 'Start time must be before finish time', s => {
  if (!s.sessionStartTime || !s.sessionFinishTime) return true;

  const start = DateTime.fromISO('2000-01-01T' + s.sessionStartTime).toJSDate();
  const finish = DateTime.fromISO('2000-01-01T' + s.sessionFinishTime).toJSDate();

  const valid = start.getTime() < finish.getTime();
  // console.log('heree session-startBeforeFinish', valid, s, start, finish);
  return valid;
}).test('session-sessionDateAndTimesPresent', 'Session should have both date and time set or none set', s => {
  if (!s.sessionDate && !s.sessionStartTime && !s.sessionFinishTime) return true;

  if (s.sessionDate && (!s.sessionStartTime || !s.sessionFinishTime)) return false;
  if (s.sessionStartTime && (!s.sessionDate || !s.sessionFinishTime)) return false;
  if (s.sessionFinishTime && (!s.sessionDate || !s.sessionStartTime)) return false;

  return true;
}).required();

const Activ8CoursesLearningCourseProgramChapterSchema = Yup.object({
  learningCourseTemplateContentChapterId: Yup.string().required('Required'),
  sessions: Yup.array().required('Required').min(1, 'Program must contain at least one session').of(Activ8CoursesLearningCourseProgramSessionSchema),
}).required();

const LearningProgramSchema = Yup.object({
  learningCourseTemplateId: Yup.string().required('Required'),
  // assignFacilitatorIdentityUserIds: Yup.array<UserOption>().required('Required'),
  internalName: Yup.string().max(50, 'Maximum 50 characters').required('Required'),
  publicName: Yup.string().max(50, 'Maximum 50 characters').required('Required'),
  publicShortDescription: Yup.string().required('Required'),
  publicLongRichTextDescription: Yup.string().required('Required'),
  status: Yup.mixed<Activ8CoursesProgramsLearningCourseProgramStatus>().oneOf<Activ8CoursesProgramsLearningCourseProgramStatus>(['Draft', 'Live', 'Complete', 'Cancelled']).required('Required'),
  isListedOnPartnerPublicWebsite: Yup.boolean().required('Required'),
  conductionAddress: Yup.string().required('Required'),
  conductionIanaTimezone: Yup.string().required('Required'),
  supportContactEmail: Yup.string().email('Must be a valid email').required('Required'),
  supportContactPhone: Yup.string().required('Required').nullable(),
  //bannerImage
  chapters: Yup.array().required('Required').min(1, 'Program must contain at least one chapter').of(Activ8CoursesLearningCourseProgramChapterSchema),
}).required();

export type LearningProgramFormValuesSchema = Yup.InferType<typeof LearningProgramSchema>;
export type LearningProgramChapterFormValuesSchema = Yup.InferType<typeof Activ8CoursesLearningCourseProgramChapterSchema>;
export type LearningProgramChapterSessionFormValuesSchema = Yup.InferType<typeof Activ8CoursesLearningCourseProgramSessionSchema>;

type ExtraFormValueProps = {

}

type Props = {
  courseProgramId: string;
  isEdit: boolean;
  defaultValues: LearningProgramFormValuesSchema;
  onSubmit: (program: LearningProgramFormValuesSchema) => Promise<void>;
  links: { name: string, href: string }[]
};

export default function LearningCourseProgramWizardForm({ courseProgramId, defaultValues, onSubmit, links, isEdit }: Props) {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const [openedSteps, setOpenedSteps] = useState<string[]>(['details']);
  const [courseTemplate, setCourseTemplate] = useState<Activ8CoursesTemplatesLearningCourseTemplateDto>({});
  const [courseTemplateContent, setCourseTemplateContent] = useState<Activ8CoursesContentLearningCourseContent>({});
  const { enqueueSnackbar } = useSnackbar();
  const [getCourseTemplateLazyQuery, getCourseTemplateLazyQueryState] = appApi.endpoints.learningCourseTemplateGet.useLazyQuery();
  const [getCourseTemplateContentLazyQuery, getCourseTemplateContentLazyQueryState] = appApi.endpoints.learningCourseTemplateGetTemplateContent.useLazyQuery();
  const accreditationQuery = appApi.endpoints.personAccreditationGetList.useQuery({ maxResultCount: 100 }); // preload state
  const attributeQuery = appApi.endpoints.personAttributeGetList.useQuery({ maxResultCount: 100 }); // preload state
  const getPartnerProfileQuery = appApi.endpoints.partnerProfileGet.useQuery();

  const steps = [
    { key: 'type', label: 'Course' },
    { key: 'details', label: 'Details' },
    { key: 'delivery', label: 'Delivery' },
    { key: 'accreditations', label: 'Accreditations' },
    { key: 'prerequisites', label: 'Prerequisites' },
    { key: 'publishing', label: 'Publishing' },
  ];

  // useEffect(() => {
  //   if(!isEdit){

  //   }
  // }, []);

  const initialValues = useMemo<LearningProgramFormValuesSchema>(
    () => ({
      ...defaultValues
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [defaultValues, courseProgramId, isEdit]
  );

  const methods = useForm<LearningProgramFormValuesSchema>({
    resolver: yupResolver(LearningProgramSchema),
    reValidateMode: 'onBlur',
    shouldUnregister: false,
    mode: 'all',
    criteriaMode: 'all',
    defaultValues: initialValues,
  });

  const submitFormAllowed = (): boolean => {
    // console.log('heree submitFormAllowed');

    if (formState.isValid) return true;

    const formErrors: string[] = [];

    if (formState.errors.chapters) {
      for (let chapterIndex = 0; chapterIndex < (formState.errors.chapters as unknown as any[]).length; chapterIndex++) {
        const chapterError = (formState.errors.chapters as unknown as any[])[chapterIndex];
        if (chapterError && chapterError.message) {
          formErrors.push(`Ch ${(chapterIndex + 1)}: ${chapterError.message}`);
        }

        if (chapterError && chapterError.sessions) {
          for (let sessionIndex = 0; sessionIndex < chapterError.sessions.length; sessionIndex++) {
            const sessionError = chapterError.sessions[sessionIndex];
            if (sessionError && sessionError.message) {
              formErrors.push(`Ch ${(chapterIndex + 1)} Ses ${(sessionIndex + 1)}: ${sessionError.message}`);
            }

            if (sessionError && sessionError.content && sessionError.content.length > 0) {
              for (let contentIndex = 0; contentIndex < sessionError.content.length; contentIndex++) {
                const contentError = sessionError.content[contentIndex];
                if (contentError && contentError.message) {
                  formErrors.push(`Ch ${(chapterIndex + 1)} Ses ${(sessionIndex + 1)} block ${contentIndex + 1}: ${contentError.message}`);
                }
                if (contentError && contentError.quiz) {
                  formErrors.push(`Ch ${(chapterIndex + 1)} Ses ${(sessionIndex + 1)} block ${contentIndex + 1}: Quiz invalid`);
                }
              }
            }
          }
        }
      }
    }

    // console.log('heree formErrors', { chaps: formState.errors.chapters, formErrors })

    if (formErrors.length > 0) {
      for (let index = 0; index < formErrors.length; index++) {
        const element = formErrors[index];
        enqueueSnackbar(element, {
          variant: 'error',
          autoHideDuration: 10000,
          anchorOrigin: { vertical: 'top', horizontal: 'left' }
        })
      }
    }

    return false;
  }

  const {
    reset,
    handleSubmit,
    formState,
    watch,
    trigger,
    setFocus,
    control,
    getValues,
    setValue
  } = methods;

  const onSelectCourseTemplateId = (templateId: string) => {
    // console.log('heree onSelectCourseTemplateId', templateId);
    setValue('learningCourseTemplateId', templateId, { shouldDirty: false });
    onNextStepClick();
  }

  const internalNameWatch = watch('internalName');
  const courseTemplateIdWatch = watch('learningCourseTemplateId');

  const stepFieldNames = {
    'type': ['learningCourseTemplateId'] as Array<keyof LearningProgramFormValuesSchema>,
    'details': ['internalName', 'supportContactEmail', 'supportContactPhone', 'conductionAddress', 'conductionIanaTimezone'] as Array<keyof LearningProgramFormValuesSchema>,
    'delivery': ['chapters'] as Array<keyof LearningProgramFormValuesSchema>,
    'accreditations': [] as Array<keyof LearningProgramFormValuesSchema>,
    'prerequisites': [] as Array<keyof LearningProgramFormValuesSchema>,
    'publishing': ['publicName', 'publicShortDescription', 'isListedOnPartnerPublicWebsite', 'status', 'publicLongRichTextDescription'] as Array<keyof LearningProgramFormValuesSchema>,
  };

  useEffect(() => {
    const setTemplateData = async () => {
      // console.log('heree onSelectCourseTemplateId2', courseTemplateIdWatch);
      const courseTemplateResult = getCourseTemplateLazyQuery({ id: courseTemplateIdWatch });
      const courseTemplateContentResult = getCourseTemplateContentLazyQuery({ courseTemplateId: courseTemplateIdWatch });

      await Promise.all([courseTemplateResult, courseTemplateContentResult]);

      const courseTemplateData = (await courseTemplateResult).data;
      const courseTemplateContentData = (await courseTemplateContentResult).data;
      setCourseTemplateContent(courseTemplateContentData || {});
      setCourseTemplate(courseTemplateData || {});

      // Sort chapters and sessions by the order they appear in the template content
      const sortedData = orderBy(getValues('chapters'), e => (courseTemplateContentData?.chapters || []).findIndex(a => a.id === e.learningCourseTemplateContentChapterId));
      sortedData.forEach(chapter => {
        const contentChapter = (courseTemplateContentData?.chapters || []).find(a => a.id === chapter.learningCourseTemplateContentChapterId);

        chapter.sessions = orderBy(chapter.sessions, e => (contentChapter?.sessions || []).findIndex(a => a.id === e.learningCourseTemplateContentSessionId));
      });

      setValue('chapters', sortedData)
    }

    if (courseTemplateIdWatch) {
      setTemplateData();
    }
  }, [courseTemplateIdWatch]);

  useEffect(() => {
    if (isEdit) return;

    setValue('internalName', courseTemplate?.publicName || '', { shouldDirty: true, shouldTouch: true });
    setValue('publicName', courseTemplate?.publicName || '', { shouldDirty: true, shouldTouch: true });
    setValue('publicShortDescription', courseTemplate?.publicShortDescription || '', { shouldDirty: true, shouldTouch: true });
    setValue('publicLongRichTextDescription', courseTemplate?.publicLongRichTextDescription || '', { shouldDirty: true, shouldTouch: true });
    setValue('isListedOnPartnerPublicWebsite', courseTemplate?.isRecommendedForPartnerPublicWebsite || false, { shouldDirty: true, shouldTouch: true });
  }, [courseTemplate])

  useEffect(() => {
    if (isEdit) return;

    setValue('chapters', (courseTemplateContent?.chapters || []).map(ch => {
      return {
        learningCourseTemplateContentChapterId: ch.id as string,
        sessions: (ch?.sessions || []).map(se => {
          return {
            id: uuidv4(),
            sessionDate: '',
            sessionStartTime: '',
            sessionFinishTime: '',
            learningCourseTemplateContentChapterId: ch.id as string,
            learningCourseTemplateContentSessionId: se.id as string,
          }
        })
      }
    }
    ), { shouldDirty: true, shouldTouch: true })
  }, [courseTemplateContent])

  useEffect(() => {
    if (!isEdit) {
      if (!getValues('supportContactEmail')) {
        setValue('supportContactEmail', getPartnerProfileQuery.data?.participantSupportContactEmail || '');
      }
      if (!getValues('conductionAddress')) {
        setValue('conductionAddress', getPartnerProfileQuery.data?.headOfficeAddress || '');
      }
      if (!getValues('conductionIanaTimezone')) {
        setValue('conductionIanaTimezone', getPartnerProfileQuery.data?.ianaTimezone || '');
      }
    }
  }, [getPartnerProfileQuery]);

  // useEffect(() => {
  //   if(formState.errors.internalName){
  //     setStepStatuses([
  //       getStepStatus('details'),
  //       getStepStatus('publishing'),
  //     ])
  //   }
  // }, [formState])

  const getStatusFromFields = (fieldNames: Array<keyof LearningProgramFormValuesSchema>): ColorSchema | null => {
    let output = null as ColorSchema | null;
    // console.log('heree getStatusFromFields1', fieldNames)

    for (const fieldNameKey in fieldNames) {
      const fieldName = fieldNames[fieldNameKey] as keyof FieldErrors<LearningProgramFormValuesSchema>;
      // console.log('heree getStatusFromFields', fieldName, formState.errors[fieldName as keyof FieldErrors<LearningProgramFormValuesSchema>])
      if (formState.errors[fieldName as keyof FieldErrors<LearningProgramFormValuesSchema>]?.message) {
        output = 'error';
        break;
      }
      if (methods.getFieldState(fieldName as keyof LearningProgramFormValuesSchema)?.error?.message) {
        output = 'error';
        break;
      }
      // if(!formState.touchedFields[fieldName as keyof FieldErrors<LearningProgramFormValuesSchema>] && !isEdit){
      //   console.log('heree', fieldName, formState.touchedFields, formState.touchedFields[fieldName as keyof FieldErrors<LearningProgramFormValuesSchema>]);

      //   output = 'warning';
      //   break;
      // }      
    }

    return output;
  }

  function getStepStatus(stepKey: string): ColorSchema | null {
    const stepWasOpened = openedSteps.indexOf(stepKey) > -1;
    if (!stepWasOpened && !isEdit) return 'warning';

    if (stepKey === 'type') {
      return getStatusFromFields(stepFieldNames['type']) || null;
    }
    if (stepKey === 'details') {
      return getStatusFromFields(stepFieldNames['details']) || null;
    }
    else if (stepKey === 'delivery') {


      let status = getStatusFromFields(stepFieldNames['delivery']);
      if (status) {
        //console.log('heree chapters', status);
        return status;
      }

      // check each chapter and session
      const chapters = methods.getValues('chapters') || [];
      //console.log('heree chap', chapters);
      for (let chapterIndex = 0; chapterIndex < chapters.length; chapterIndex++) {
        const chapterStatus = getStatusFromFields([`chapters.${chapterIndex}` as any]);
        //console.log('heree chap', chapterStatus);
        // if(!chapterStatus && methods.formState.errors.chapters){

        // }
        if (chapterStatus) return chapterStatus;

        const sessions = methods.getValues(`chapters.${chapterIndex}.sessions` as 'chapters.0.sessions') || [];
        // console.log('heree sess', sessions);
        const sessionsStatus = getStatusFromFields([`chapters.${chapterIndex}.sessions` as any]);

        for (let sessionIndex = 0; sessionIndex < sessions.length; sessionIndex++) {
          const sessionStatus = getStatusFromFields([`chapters.${chapterIndex}.sessions.${sessionIndex}` as any]);
          // console.log('heree sess status', sessionStatus);
          if (sessionStatus) return sessionStatus;

          const dateStatus = getStatusFromFields([`chapters.${chapterIndex}.sessions.${sessionIndex}.sessionDate` as any]);
          if (dateStatus) return dateStatus;
          const startStatus = getStatusFromFields([`chapters.${chapterIndex}.sessions.${sessionIndex}.sessionStartTime` as any]);
          if (startStatus) return startStatus;
          const finishStatus = getStatusFromFields([`chapters.${chapterIndex}.sessions.${sessionIndex}.sessionFinishTime` as any]);
          if (finishStatus) return finishStatus;
        }
        if (sessionsStatus) {
          // if(formState.errors[`chapters.${chapterIndex}` as 'chapters.0'])
          // console.log('heree sessions', sessionsStatus);
          // return sessionsStatus;
        }
      }
    }
    // else if(stepKey === 'accreditations'){
    //   const wasOpened = openedSteps.indexOf('accreditations') > -1 || isEdit;
    //   if(!wasOpened) return 'warning';
    // }
    // else if(stepKey === 'prerequisites'){
    //   const stepWasOpened = openedSteps.indexOf('prerequisites') > -1;
    //   return stepWasOpened ? null : 'warning';
    // }    
    else if (stepKey === 'publishing') {
      // const untouchedIsConsideredComplete = openedSteps.indexOf('publishing') > -1;
      return getStatusFromFields(stepFieldNames['publishing']);
    }

    return null;
  }

  const showStep = async (stepIndex: number): Promise<void> => {
    if (stepIndex === activeStep) return;

    const prevStepIndex = activeStep + 0;
    const prevStepKey = steps[prevStepIndex].key as keyof typeof stepFieldNames;
    const fieldsToValidate = stepFieldNames[prevStepKey] as Array<keyof LearningProgramFormValuesSchema>;
    // fieldsToValidate.forEach(element => {
    //   trigger(element, { shouldFocus: true });
    // });
    await trigger(fieldsToValidate, { shouldFocus: false });

    await setOpenedSteps([...openedSteps, steps[activeStep].key]);
    await setActiveStep(stepIndex);
  }

  const { isSubmitting } = formState;

  const isLoading = getCourseTemplateLazyQueryState.isLoading || getPartnerProfileQuery.isLoading || getCourseTemplateContentLazyQueryState.isLoading || accreditationQuery.isLoading || attributeQuery.isLoading || isSubmitting;

  const onNextStepClick = () => {
    function progressFunc() {
      showStep(activeStep + 1);
    }

    // Either form is valid or the current step is valid
    if (formState.isValid || getStepStatus(steps[activeStep].key) !== 'error') {
      progressFunc();
    }
    else {

    }
  }

  const saveButton = <LoadingButton onClick={() => submitFormAllowed() && onSubmit(getValues())} variant="contained" loading={isSubmitting} disabled={!formState.isDirty || isLoading || !formState.isValid}>
    {isEdit ? 'Update program' : 'Save program'}
  </LoadingButton>;

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit((form) => { submitFormAllowed() && onSubmit(form) })}>
      {/* <code style={{whiteSpace: 'pre-wrap'}}>{JSON.stringify(methods.formState.errors, null, 2)}</code> */}

      {isLoading ? <LoadingScreen /> : <>
        <HeaderBreadcrumbs
          heading={!isEdit ? 'Create program' : internalNameWatch}
          links={links}
          action={
            <>
              {/* {
                  isEdit ?
                  <Button
                    variant="outlined"
                    color='error'
                    startIcon={<Iconify icon={'eva:trash-fill'} />}
                    onClick={()=> handleDeleteRow()}
                    sx={{mr: 5}}
                  >
                    Delete
                  </Button>
                  : undefined
                } */}

              {saveButton}
            </>
          }
        />
        <Stack rowGap={2} divider={<Divider orientation="horizontal" flexItem />}>
          <Stack rowGap={5}>
            <Box sx={{ width: '100%' }}>
              <Stepper nonLinear activeStep={activeStep} alternativeLabel key={openedSteps.length}>
                {steps.map((el, index) => {
                  const isCurrentStep = index === activeStep;
                  let status = isCurrentStep ? null : (!courseTemplateIdWatch ? 'warning' : getStepStatus(el.key));
                  if (index === 0 && !isCurrentStep) status = null;

                  const color = status ? theme.palette[status].main : (isCurrentStep ? theme.palette.info.main : theme.palette.text.primary);
                  const icon = status === 'warning' ? 'eva:edit-fill' : (isCurrentStep ? 'eva:radio-button-on-fill' : (!status ? 'eva:checkmark-circle-2-fill' : 'eva:alert-triangle-fill'));
                  return (
                    <Step key={index} disabled={isLoading || !courseTemplateIdWatch}>
                      <StepButton icon={<Iconify sx={{ fontSize: 22 }} icon={icon} color={color} />} onClick={() => showStep(index)}>
                        <StepLabel>{el.label}</StepLabel>
                      </StepButton>
                    </Step>
                  )
                })}
              </Stepper>
            </Box>

            <Box sx={{ p: 3, pt: 0 }}>
              {/* <code>{JSON.stringify(stepStatus, null, 2)}</code> */}
              {/* <p>{methods.watch('internalName')}</p>
                <code>{JSON.stringify(stepStatus, null, 2)}</code> */}
              {/* <code>{methods ? JSON.stringify(methods.getFieldState('internalName'), null, 2) : undefined}</code> */}
              {/* <code>{JSON.stringify(formState.submitCount, null, 2)}</code>
                <code>{JSON.stringify(openedSteps.length, null, 2)}</code> */}

              {activeStep === 0 ? <>
                <LearningCourseProgramWizardFormCourseTypeStep isEdit={isEdit} onSelectCourseTemplateId={onSelectCourseTemplateId} />
              </> : undefined}
              {activeStep === 1 ? <>
                <LearningCourseProgramWizardFormDetailsStep />
              </> : undefined}
              {activeStep === 2 ? <>
                {/* <code>{JSON.stringify(formState.errors, null, 2)}</code>
                  <code>{JSON.stringify(formState, null, 2)}</code>
                  <code>{JSON.stringify(formState.submitCount, null, 2)}</code> */}
                {/* <code>{JSON.stringify(watch('chapters'), null, 2)}</code> */}
                <LearningCourseProgramWizardFormDeliveryStep courseTemplate={courseTemplate} courseTemplateContent={courseTemplateContent} courseProgramId={courseProgramId} control={control} />
              </> : undefined}
              {activeStep === 3 ? <>
                <LearningCourseProgramWizardFormAccreditationStep courseTemplate={courseTemplate} />
              </> : undefined}
              {activeStep === 4 ? <>
                <LearningCourseProgramWizardFormPreRequisitesStep courseTemplate={courseTemplate} courseProgramId={courseProgramId} isEdit={isEdit} />
              </> : undefined}
              {activeStep === 5 ? <>
                <LearningCourseProgramWizardFormPublishingStep isEdit={isEdit} courseTemplate={courseTemplate} />
              </> : undefined}
            </Box>
          </Stack>

          <Stack direction='row' alignItems='flex-start' justifyContent={'flex-end'} sx={{ mt: 3 }}>
            {
              activeStep === (steps.length - 1) ?
                saveButton
                :
                <Button variant={'contained'} color={'primary'} disabled={!courseTemplateIdWatch || getStepStatus(steps[activeStep].key) === 'error'} onClick={onNextStepClick}>Next</Button>
            }
          </Stack>
        </Stack>
      </>
      }
    </FormProvider>
  );
}
