export interface AppSettings {
    selfUrl: string;
    apiUrl: string;
    buildVersion: string;
    tenancyDescription: string;
    tenancyShortName: string;
    assetsBaseUrl: string;
    oAuthAuthority: string;
    oAuthClientId: string;
    oAuthRedirectUrl: string;
    oAuthResponseType: string;
    oAuthScope: string;
    googleMapsApiKey: string;
    openTelemetryOtlpTracesEndpoint: string;
    seqApiKey: string;
}

const appSettings = (window as any).appSettings as AppSettings;

// Handle subdomains for tenants
const currentSelfAuthority = window.location.host.toLowerCase();
if (currentSelfAuthority !== appSettings.selfUrl.split("://")[1].toLowerCase()) {
    // Hosted on sub domain
    const subDomain = currentSelfAuthority.split('.')[0].toLowerCase();
    appSettings.apiUrl = appSettings.apiUrl.replace('https://', 'https://' + subDomain + '.');
    appSettings.oAuthAuthority = appSettings.oAuthAuthority.replace('https://', 'https://' + subDomain + '.');
    appSettings.oAuthRedirectUrl = appSettings.oAuthRedirectUrl.replace('https://', 'https://' + subDomain + '.');
    appSettings.selfUrl = appSettings.selfUrl.replace('https://', 'https://' + subDomain + '.');
}

// console.log('heyy', JSON.stringify({ appSettings, currentSelfAuthority }));

export default appSettings;
