import { get } from 'lodash-es';
import { useSnackbar } from 'notistack';
import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Activ8CoursesContentLearningCourseContentBlockType,
  Activ8CoursesContentLearningCourseContentResourceType,
  Activ8CoursesTemplatesLearningCourseTemplateStatus
} from 'src/api/redux/app/appApi';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import Iconify from 'src/components/Iconify';
import * as Yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import {
  Box, Button, Divider, Stack, Step, StepButton, StepLabel, Stepper, useTheme
} from '@mui/material';

import { LearningCourseContentQuizQuestionType } from 'src/api/generated/app-service-proxies';
import { FormProvider } from '../../../../components/hook-form';
import { ColorSchema } from '../../../../theme/palette';
import LearningCourseTemplateWizardFormAccreditationStep from './Steps/LearningCourseTemplateWizardFormAccreditationStep';
import {
  LearningCourseTemplateWizardFormDeliveryStep
} from './Steps/LearningCourseTemplateWizardFormDeliveryStep/Index';
import LearningCourseTemplateWizardFormDetailsStep from './Steps/LearningCourseTemplateWizardFormDetailStep';
import LearningCourseTemplateWizardFormLicensingStep from './Steps/LearningCourseTemplateWizardFormLicensingStep';
import LearningCourseTemplateWizardFormPrerequisitesStep from './Steps/LearningCourseTemplateWizardFormPrerequisitesStep';
import LearningCourseTemplateWizardFormPublishingStep from './Steps/LearningCourseTemplateWizardFormPublishingStep';

// ----------------------------------------------------------------------

const Activ8CoursesContentLearningCourseContentResourceSchema = Yup.object({
  id: Yup.string().required('Required').nullable(false),
  type: Yup.mixed<Activ8CoursesContentLearningCourseContentResourceType>().oneOf<Activ8CoursesContentLearningCourseContentResourceType>(['Generic', 'Image', 'Video', 'Pdf', 'Audio']).required('Required').nullable(false),
  description: Yup.string().max(60),
  contentType: Yup.string().required('Required'),
  fileExtension: Yup.string().required('Required'),
  fileName: Yup.string().required('Required'),
  blobReference: Yup.string().required('Required'),
  fileSizeBytes: Yup.number().required('Required').moreThan(-1)
}).required();

const Activ8CoursesContentLearningCourseContentBlockSchema = Yup.object({
  id: Yup.string().required('Required'),
  richText: Yup.string(),
  type: Yup.mixed<Activ8CoursesContentLearningCourseContentBlockType>().oneOf<Activ8CoursesContentLearningCourseContentBlockType>(['RichText', 'Quiz', 'Resources', 'Media', 'PageBreak']).required('Required'),
  quiz: Yup.object({
    id: Yup.string().required('Required'),
    isAssessed: Yup.boolean().required('Required'),
    isAnswerFeedbackShown: Yup.boolean().required('Required'),
    isQuizShownInLine: Yup.boolean().required('Required'),
    description: Yup.string().nullable(),
    questions: Yup.array().required().of(Yup.object({
      id: Yup.string().required('Required'),
      questionText: Yup.string().required('Required').min(5, 'Question not long enough'),
      questionType: Yup.mixed<LearningCourseContentQuizQuestionType>().oneOf<LearningCourseContentQuizQuestionType>([LearningCourseContentQuizQuestionType.MultipleChoice, LearningCourseContentQuizQuestionType.FreeTextMultiLine]).required('Required'),
      choiceOptions: Yup.array().required().of(Yup.object({
        id: Yup.string().required('Required'),
        answerText: Yup.string().required('Required'),
        isCorrect: Yup.boolean().required('Required'),
      }).required())
        .test('choices-mustHaveChoices', 'Question must contain at least 2 choices', (options, p) => {
          const parent = p.parent.questionType as LearningCourseContentQuizQuestionType;
          if (parent === LearningCourseContentQuizQuestionType.Undefined || parent === LearningCourseContentQuizQuestionType.MultipleChoice) {
            if (options && options.length < 2) return false;
          }
          else if (parent === LearningCourseContentQuizQuestionType.FreeTextMultiLine) {
            return true;
          }

          return true;
        })
        .test('choices-mustHaveOneCorrect', 'Question must have at least 1 correct answer', (options, p) => {
          const parent = p.parent.questionType as LearningCourseContentQuizQuestionType;
          if (parent === LearningCourseContentQuizQuestionType.Undefined || parent === LearningCourseContentQuizQuestionType.MultipleChoice) {
            // Undefined is for legacy data
            if ((options || []).filter(a => a.isCorrect).length === 0) return false;
          }
          else if (parent === LearningCourseContentQuizQuestionType.FreeTextMultiLine) {
            return true;
          }

          return true;
        })
    }).required())
  }).nullable(),
  resources: Yup.array().of(Activ8CoursesContentLearningCourseContentResourceSchema)
})
  .test('contentBlock-richTextBlock-mustHaveContent', 'Text block must have content', block => {
    if (block.type !== 'RichText') return true;
    return Boolean(block.richText);
  })
  .test('contentBlock-quizBlock-mustHaveContent', 'Quiz block must have content', block => {
    if (block.type !== 'Quiz') return true;
    return Boolean(block.quiz);
  })
  .test('contentBlock-mediaBlock-mustHaveContent', 'Media block must have content', block => {
    if (block.type !== 'Media') return true;

    return Boolean(block.resources) && (block?.resources || []).length > 0;
  })
  .test('contentBlock-resourceBlock-mustHaveContent', 'Resource block must have content', block => {
    if (block.type !== 'Resources') return true;

    return Boolean(block.resources) && (block?.resources || []).length > 0;
  })
  .required();

const Activ8CoursesContentLearningCourseContentChapterSchema = Yup.object({
  id: Yup.string().required('Required'),
  name: Yup.string().required('Required').max(30, 'Max length 30'),
  duration: Yup.string().nullable(false).default('00:00:00'),
  sessions: Yup.array().required('Required').min(1, 'Chapter must contain at least one session').of(Yup.object({
    id: Yup.string().required('Required'),
    name: Yup.string().required('Required').max(30, 'Max length 30'),
    duration: Yup.string().nullable(false).default('00:00:00'),
    facilitatorNotes: Yup.string(),
    facilitatorResources: Yup.array().of(Activ8CoursesContentLearningCourseContentResourceSchema),
    content: Yup.array().of(Activ8CoursesContentLearningCourseContentBlockSchema)
  }).test('session-mustHaveContent', 'Session must have either facilitator or participant content', session => {
    const hasFacilitatorContent = Boolean(session.facilitatorNotes) || (session.facilitatorResources || []).length > 0;
    const hasParticipantContent = (session.content || []).length > 0;
    if (!hasFacilitatorContent && !hasParticipantContent) return false;

    return true;
  })
  )
}).required();

// const Activ8CoursesTenantLicenseToLearningCourseTemplateAssignmentSchema = Yup.object({
//   id: Yup.string().required('Required'),
//   tenantId: Yup.string().required('Required'),
//   learningCourseTemplateId: Yup.string().required('Required')
// }).required();

const LearningTemplateSchema = Yup.object({
  internalName: Yup.string().max(50, 'Maximum 50 characters').required('Required'),
  publicName: Yup.string().max(50, 'Maximum 50 characters').required('Required'),
  publicShortDescription: Yup.string().required('Required'),
  status: Yup.mixed<Activ8CoursesTemplatesLearningCourseTemplateStatus>().oneOf<Activ8CoursesTemplatesLearningCourseTemplateStatus>(['Draft', 'Paused', 'Live']).required('Required'),
  isRecommendedForPartnerPublicWebsite: Yup.boolean().required('Required'),
  enrollmentAllowedForIdentityRoleName: Yup.string().required('Required'),
  partnerShortDescription: Yup.string().required('Required'),
  facilitatorShortDescription: Yup.string().required('Required'),
  //bannerImage
  publicLongRichTextDescription: Yup.string().required('Required').nullable(false),
  // .test('publicLongRichTextDescription-notEmpty', 'Public long description is required', desc => {
  // console.log('heree publicLongRichTextDescription', desc);

  // return true;
  // }),
  partnerLongRichTextDescription: Yup.string().required('Required'),
  facilitatorLongRichTextDescription: Yup.string().required('Required'),
  // participantWelcomeEmailTemplate: Yup.string().required('Required'),  
  chapters: Yup.array().required('Required').min(1, 'Course must contain at least one chapter').of(Activ8CoursesContentLearningCourseContentChapterSchema),
  facilitatorAccreditationIdsPreRequisites: Yup.array().required('Required').of(Yup.string()),
  participantAccreditationIdsPreRequisites: Yup.array().required('Required').of(Yup.string()),
  facilitatorAttributeIdsPreRequisites: Yup.array().required('Required').of(Yup.string()),
  postCompletionAccreditationsIds: Yup.array().required('Required').of(Yup.string()),
  licensedTenantIds: Yup.array().required('Required').of(Yup.string()),
}).required();

export type LearningTemplateFormValuesSchema = Yup.InferType<typeof LearningTemplateSchema>;

type ExtraFormValueProps = {

}

type Props = {
  courseTemplateId: string;
  isEdit: boolean;
  defaultValues: LearningTemplateFormValuesSchema;
  onSubmit: (template: LearningTemplateFormValuesSchema) => Promise<void>;
  links: { name: string, href: string }[]
};


const steps = [
  { key: 'details', label: 'Details' },
  { key: 'prerequisites', label: 'Prerequisites' },
  { key: 'delivery', label: 'Delivery' },
  { key: 'accreditations', label: 'Accreditations' },
  { key: 'licensing', label: 'Licensing' },
  { key: 'publishing', label: 'Publishing' },
]

export default function LearningCourseTemplateWizardForm({ courseTemplateId, defaultValues, onSubmit, links, isEdit }: Props) {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const [openedSteps, setOpenedSteps] = useState<string[]>(['details']);
  const { enqueueSnackbar } = useSnackbar();

  const initialValues = useMemo<LearningTemplateFormValuesSchema>(
    () => ({
      ...defaultValues
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [defaultValues, courseTemplateId, isEdit]
  );

  const methods = useForm<LearningTemplateFormValuesSchema>({
    resolver: yupResolver(LearningTemplateSchema),
    // reValidateMode: 'onChange',
    // shouldUnregister: false,
    mode: 'onBlur',
    criteriaMode: 'all',
    defaultValues: initialValues,
  });

  const submitFormAllowed = (): boolean => {
    if (!formState.isValid) {
      console.error('Form is not valid', formState.errors);
    }

    if (formState.isValid) return true;

    const formErrors: string[] = [];

    if (formState.errors.chapters) {
      for (let chapterIndex = 0; chapterIndex < (formState.errors.chapters as unknown as any[]).length; chapterIndex++) {
        const chapterError = (formState.errors.chapters as unknown as any[])[chapterIndex];
        if (chapterError && chapterError.message) {
          formErrors.push(`Ch ${(chapterIndex + 1)}: ${chapterError.message}`);
        }

        if (chapterError && chapterError.sessions) {
          for (let sessionIndex = 0; sessionIndex < chapterError.sessions.length; sessionIndex++) {
            const sessionError = chapterError.sessions[sessionIndex];
            if (sessionError && sessionError.message) {
              formErrors.push(`Ch ${(chapterIndex + 1)} Ses ${(sessionIndex + 1)}: ${sessionError.message}`);
            }

            if (sessionError && sessionError.content && sessionError.content.length > 0) {
              for (let contentIndex = 0; contentIndex < sessionError.content.length; contentIndex++) {
                const contentError = sessionError.content[contentIndex];
                if (contentError && contentError.message) {
                  formErrors.push(`Ch ${(chapterIndex + 1)} Ses ${(sessionIndex + 1)} block ${contentIndex + 1}: ${contentError.message}`);
                }
                if (contentError && contentError.quiz) {
                  formErrors.push(`Ch ${(chapterIndex + 1)} Ses ${(sessionIndex + 1)} block ${contentIndex + 1}: Quiz invalid`);
                }
              }
            }
          }
        }
      }
    }

    // console.log('heree formErrors', { chaps: formState.errors.chapters, formErrors })

    if (formErrors.length > 0) {
      for (let index = 0; index < formErrors.length; index++) {
        const element = formErrors[index];
        enqueueSnackbar(element, {
          variant: 'error',
          autoHideDuration: 10000,
          anchorOrigin: { vertical: 'top', horizontal: 'left' }
        })
      }
    }

    return false;
  }

  const {
    reset,
    handleSubmit,
    formState,
    watch,
    trigger,
    setFocus,
    control,
    getValues,
  } = methods;

  const internalNameWatch = watch('internalName');

  const stepFieldNames = {
    'details': ['internalName', 'partnerShortDescription', 'partnerLongRichTextDescription', 'facilitatorShortDescription', 'facilitatorLongRichTextDescription', 'enrollmentAllowedForIdentityRoleName'] as Array<keyof LearningTemplateFormValuesSchema>,
    'prerequisites': ['facilitatorAccreditationIdsPreRequisites', 'participantAccreditationIdsPreRequisites', 'facilitatorAttributeIdsPreRequisites'] as Array<keyof LearningTemplateFormValuesSchema>,
    'delivery': ['chapters'] as Array<keyof LearningTemplateFormValuesSchema>,
    'accreditations': ['postCompletionAccreditationsIds'] as Array<keyof LearningTemplateFormValuesSchema>,
    'licensing': ['licensedTenantIds'] as Array<keyof LearningTemplateFormValuesSchema>,
    'publishing': ['publicName', 'publicShortDescription', 'isRecommendedForPartnerPublicWebsite', 'status', 'publicLongRichTextDescription'] as Array<keyof LearningTemplateFormValuesSchema>,
  };

  // useEffect(() => {
  //   if(formState.errors.internalName){
  //     setStepStatuses([
  //       getStepStatus('details'),
  //       getStepStatus('publishing'),
  //     ])
  //   }
  // }, [formState])

  const getStatusFromFields = (fieldNames: string[]): ColorSchema | null => {

    // console.log('heree getStatusFromFields1', fieldNames);
    // if(fieldNames.indexOf('publicLongRichTextDescription') > -1 && get(formState.errors, 'publicLongRichTextDescription')) return 'error';

    let output = null as ColorSchema | null;
    for (const fieldNameKey in fieldNames) {
      // if(output) continue;

      // if (Object.prototype.hasOwnProperty.call(fieldNames, fieldNameKey)) {
      const fieldName = fieldNames[fieldNameKey];
      // console.log('heree getStatusFromFields3', fieldName, get(formState.errors, fieldName))      

      // const fieldName = fieldNames[fieldNameKey];
      // console.log('heree getStatusFromFields2', fieldName, get(formState.errors, fieldName))      

      if (get(formState.errors, fieldName)) {
        output = 'error';
        break;
      }
      else if (methods.getFieldState(fieldName as any)?.error) {
        output = 'error';
        break;
      }
      // else if(!formState.touchedFields[fieldName as keyof LearningTemplateFormValuesSchema] && !isEdit && !untouchedIsConsideredComplete){
      //   output = 'warning';
      //   break;
      // }    
      // }
    }
    // for (const fieldNameKey in fieldNames) {
    // console.log('heree getStatusFromFields3', fieldNameKey, get(formState.errors, fieldNameKey))      

    // const fieldName = fieldNames[fieldNameKey];
    // console.log('heree getStatusFromFields2', fieldName, get(formState.errors, fieldName))      

    // if((formState.errors as any)[fieldName]) {
    //   output = 'error';
    //   break;
    // }
    // if(methods.getFieldState(fieldName as keyof FieldErrors<LearningTemplateFormValuesSchema>)?.error?.message) {
    //   output = 'error';
    //   break;
    // }
    // if(!formState.touchedFields[fieldName as keyof FieldErrors<LearningTemplateFormValuesSchema>] && !isEdit && !untouchedIsConsideredComplete){
    //   output = 'warning';
    //   break;
    // }    
    // }

    return output;
  }

  function getStepStatus(stepKey: string): ColorSchema | null {
    const stepWasOpened = openedSteps.indexOf(stepKey) > -1;
    if (!stepWasOpened && !isEdit) return 'warning';

    // console.log('heree getStepStatus ', stepKey)
    if (stepKey === 'details') {
      return getStatusFromFields(stepFieldNames[stepKey]) || null;
    }
    else if (stepKey === 'prerequisites') {
      return getStatusFromFields(stepFieldNames[stepKey]);
    }
    else if (stepKey === 'delivery') {
      let status = getStatusFromFields(stepFieldNames[stepKey]);
      if (status) {
        //console.log('heree chapters', status);
        return status;
      }

      // check each chapter and session
      const chapters = methods.getValues('chapters') || [];
      //console.log('heree chap', chapters);
      for (let chapterIndex = 0; chapterIndex < chapters.length; chapterIndex++) {
        const chapterStatus = getStatusFromFields(`chapters.${chapterIndex}` as any);
        //console.log('heree chap', chapterStatus);
        // if(!chapterStatus && methods.formState.errors.chapters){

        // }
        if (chapterStatus) return chapterStatus;

        const sessions = methods.getValues(`chapters.${chapterIndex}.sessions` as 'chapters.0.sessions') || [];
        // console.log('heree sess', sessions);
        const sessionsStatus = getStatusFromFields(`chapters.${chapterIndex}.sessions` as any);
        if (sessionsStatus) {
          // console.log('heree sessions', sessionsStatus);
          return sessionsStatus;
        }
        for (let sessionIndex = 0; sessionIndex < sessions.length; sessionIndex++) {
          const sessionStatus = getStatusFromFields(`chapters.${chapterIndex}.sessions.${sessionIndex}` as any);
          // console.log('heree sess', sessionStatus);
          if (sessionStatus) return sessionStatus;
        }
      }
    }
    else if (stepKey === 'accreditations') {
      return getStatusFromFields(stepFieldNames[stepKey]);
    }
    else if (stepKey === 'licensing') {
      return getStatusFromFields(stepFieldNames[stepKey]);
    }
    else if (stepKey === 'publishing') {
      return getStatusFromFields(stepFieldNames[stepKey]);
    }

    return null;
  }

  const showStep = async (stepIndex: number): Promise<void> => {
    if (stepIndex === activeStep) return;

    const prevStepIndex = activeStep + 0;
    const prevStepKey = steps[prevStepIndex].key as keyof typeof stepFieldNames;
    const fieldsToValidate = stepFieldNames[prevStepKey] as Array<keyof LearningTemplateFormValuesSchema>;
    // fieldsToValidate.forEach(element => {
    //   trigger(element, { shouldFocus: true });
    // });
    await trigger(fieldsToValidate, { shouldFocus: false });

    await setOpenedSteps([...openedSteps, steps[activeStep].key]);
    await setActiveStep(stepIndex);
  }

  const { isSubmitting } = formState;

  const onNextStepClick = () => {
    function progressFunc() {
      showStep(activeStep + 1);
    }

    // Either form is valid or the current step is valid
    if (formState.isValid || getStepStatus(steps[activeStep].key) !== 'error') {
      progressFunc();
    }
    else {

    }
  }

  const saveButton = <LoadingButton onClick={() => submitFormAllowed() && onSubmit(getValues())} variant="contained" loading={isSubmitting} disabled={!formState.isDirty || !formState.isValid || formState.isValidating || !submitFormAllowed()}>
    {isEdit ? 'Update course' : 'Save course'}
  </LoadingButton>;

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit((form) => { submitFormAllowed() && onSubmit(form) })}>
      {/* <code style={{ whiteSpace: 'pre-wrap' }}>{JSON.stringify(formState.errors, null, 2)}</code> */}

      <HeaderBreadcrumbs
        heading={!isEdit ? 'Create course' : internalNameWatch}
        links={links}
        action={
          <>
            {/* {
                isEdit ?
                <Button
                  variant="outlined"
                  color='error'
                  startIcon={<Iconify icon={'eva:trash-fill'} />}
                  onClick={()=> handleDeleteRow()}
                  sx={{mr: 5}}
                >
                  Delete
                </Button>
                : undefined
              } */}

            {saveButton}
          </>
        }
      />
      <Stack rowGap={2} divider={<Divider orientation="horizontal" flexItem />}>
        <Stack rowGap={5}>
          <Box sx={{ width: '100%' }}>
            <Stepper nonLinear activeStep={activeStep} alternativeLabel key={openedSteps.length}>
              {steps.map((el, index) => {
                const isCurrentStep = index === activeStep;
                const status = isCurrentStep ? null : getStepStatus(el.key);
                const color = status ? theme.palette[status].main : (isCurrentStep ? theme.palette.info.main : theme.palette.text.primary);
                const icon = status === 'warning' ? 'eva:edit-fill' : (isCurrentStep ? 'eva:radio-button-on-fill' : (!status ? 'eva:checkmark-circle-2-fill' : 'eva:alert-triangle-fill'));
                return (
                  <Step key={index}>
                    <StepButton icon={<Iconify sx={{ fontSize: 22 }} icon={icon} color={color} />} onClick={() => showStep(index)}>
                      <StepLabel>{el.label}</StepLabel>
                    </StepButton>
                  </Step>
                )
              })}
            </Stepper>
          </Box>

          <Box sx={{ p: 3, pt: 0 }}>
            {/* <code>{JSON.stringify(stepStatus, null, 2)}</code> */}
            {/* <p>{methods.watch('internalName')}</p>
              <code>{JSON.stringify(stepStatus, null, 2)}</code> */}
            {/* <code>{methods ? JSON.stringify(methods.getFieldState('internalName'), null, 2) : undefined}</code> */}
            {/* <code>{JSON.stringify(formState.submitCount, null, 2)}</code>
              <code>{JSON.stringify(openedSteps.length, null, 2)}</code> */}

            {activeStep === 0 ? <>
              <LearningCourseTemplateWizardFormDetailsStep />
            </> : undefined}
            {activeStep === 1 ? <>
              <LearningCourseTemplateWizardFormPrerequisitesStep />
            </> : undefined}
            {activeStep === 2 ? <>
              {/* <code>{JSON.stringify(watch('chapters'), null, 2)}</code> */}
              <LearningCourseTemplateWizardFormDeliveryStep courseTemplateId={courseTemplateId} isEdit={isEdit} />
            </> : undefined}
            {activeStep === 3 ? <>
              <LearningCourseTemplateWizardFormAccreditationStep />
            </> : undefined}
            {activeStep === 4 ? <>
              <LearningCourseTemplateWizardFormLicensingStep />
            </> : undefined}
            {activeStep === 5 ? <>
              <LearningCourseTemplateWizardFormPublishingStep isEdit={isEdit} />
            </> : undefined}
          </Box>
        </Stack>

        <Stack direction='row' alignItems='flex-start' justifyContent={'flex-end'} sx={{ mt: 3 }}>
          {
            activeStep === (steps.length - 1) ?
              saveButton
              :
              <Button variant={'contained'} color={'primary'} disabled={getStepStatus(steps[activeStep].key) === 'error'} onClick={onNextStepClick}>Next</Button>
          }
        </Stack>
      </Stack>
    </FormProvider>
  );
}
