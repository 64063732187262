import { debounce, last, merge } from 'lodash-es';
import { useCallback, useEffect, useState } from 'react';
import Cropper from 'react-easy-crop';
import { Area, Point } from 'react-easy-crop/types';
import ReactPlayer from 'react-player/lazy';
import { CircularProgressWithLabel } from 'src/sections/shared/CircularProgressWithLabel';
import { IResourceContentManagerFile } from 'src/sections/shared/ResourceContentManager';

import {
  Box, Button, Card, CardActions, CardContent, CardMedia, Grid, IconButton, Slider, Stack,
  TextField, Tooltip, Typography
} from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';

import { fData } from '../../utils/formatNumber';
import Iconify from '../Iconify';
import { UploadMultiFileProps } from './type';

// ----------------------------------------------------------------------
const CROP_AREA_ASPECT = 5 / 3;
const MAX_ZOOM = 5;
const MIN_ZOOM = 1;

export default function MultiFilePreview({
  files,
  onDelete,
  onPreview,
  onDownload,
  autoplay,
  onUpdateMetadata,
  direction,
  restrictedToMedia,
  prepareReadUrl,
  preload
}: UploadMultiFileProps) {
  const hasFile = files.length > 0;

  const onDefaultAction = (file: IResourceContentManagerFile): void => {
    switch (file.type) {
      case 'Generic': onDownload && onDownload(file); break;
      case 'Image': onPreview(file); break;
      case 'Video': onPreview(file); break;
      case 'Audio': onPreview(file); break;
      case 'Pdf': onPreview(file); break;
      default: onDownload && onDownload(file); break;
    }
  }

  return (
    <Grid container sx={{ ...(hasFile && { my: 0.2 }) }} spacing={1}>
      {/* <AnimatePresence> */}
      {files.map((file, index) => <MultiFilePreviewItem key={file.id} file={file} index={index} onDefaultAction={onDefaultAction} {...{ files, onDelete, onPreview, onDownload, onUpdateMetadata, direction, restrictedToMedia, prepareReadUrl, autoplay, preload }} />)}
      {/* </AnimatePresence> */}
    </Grid>
  );
}

interface IProps extends UploadMultiFileProps {
  file: IResourceContentManagerFile;
  index: number;
  preload?: boolean;
  onDefaultAction: (file: IResourceContentManagerFile) => void;
}

function MultiFilePreviewItem({
  file,
  index,
  onDelete,
  onPreview,
  onDownload,
  onUpdateMetadata,
  direction,
  restrictedToMedia,
  files,
  autoplay,
  preload,
  onDefaultAction,
  prepareReadUrl }: IProps) {
  const [mediaViewUrl, setMediaViewUrl] = useState<string | undefined>(undefined);
  const [fileMetadataState, setFileMetadataState] = useState<IResourceContentManagerFile>(file);
  const [isMediaCropShown, setIsMediaCropShown] = useState<boolean>(false);
  const theme = useTheme();

  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(file.mediaCrop && file.mediaCrop.zoom ? file.mediaCrop.zoom : 1);
  const onCropComplete = useCallback(
    (croppedArea: Area, croppedAreaPixels: Area) => {
      // console.log('onCropComplete');
      onMetadataChange({
        mediaCrop: {
          percentageArea: { width: croppedArea.width, height: croppedArea.height, coordinates: { x: parseInt(croppedArea.x.toFixed(0)), y: parseInt(croppedArea.y.toFixed(2)) } },
          pixelArea: { width: croppedAreaPixels.width, height: croppedAreaPixels.height, coordinates: { x: croppedAreaPixels.x, y: croppedAreaPixels.y } },
        }
      });
    },
    []
  );

  const onZoomChange = useCallback((zoom: number) => {
    // console.log('onZoomChange');
    setZoom(zoom)
    onMetadataChange({ mediaCrop: { zoom: zoom } });
  }, [])

  const onMetadataChange = (resource: any) => {
    const merged = merge(fileMetadataState, resource);
    // console.log('onMetadataChange', {resource, merged});
    setFileMetadataState(merged);
    onMetadataChangeDebounced();
  }

  const onMetadataChangeDebounced = debounce(() => {
    if (onUpdateMetadata) {
      // console.log('onMetadataChangeDebounced', fileMetadataState.mediaCrop);
      onUpdateMetadata(fileMetadataState);
    }
  }, 200);

  const isMediaCropAllowed = file.type === 'Image';

  function getSuccessIcon() {
    switch (file.type) {
      case 'Generic': return 'eva:file-fill';
      case 'Image': return 'eva:image-2-fill';
      case 'Audio': return 'eva:music-outline';
      case 'Video': return 'ic:baseline-local-movies';
      case 'Pdf': return 'ic:round-picture-as-pdf';
    }

    return 'eva:file-fill';
  }

  function getFileTypeShort() {
    const t = last(file.contentType?.split('/'));
    if (t === 'octet-stream') return 'application';
    if (t === 'vnd.openxmlformats-officedocument.presentationml.presentation') return 'presentation';

    return t;
  }

  useEffect(() => {
    if (restrictedToMedia && prepareReadUrl && (file.status === 'PreviouslyUploaded' || file.status === 'Uploaded') && (file.type === 'Image' || file.type === 'Video' || file.type === 'Audio')) {
      prepareReadUrl(file, false).then(u => setMediaViewUrl(u))
    }
  }, [file.status])

  const actionButtons = file.status === 'Uploaded' || file.status === 'PreviouslyUploaded' ?
    (
      <Stack sx={{ width: '100%' }} direction='row' alignItems='center' alignContent={isMediaCropShown ? 'centre' : undefined} justifyContent={isMediaCropShown ? 'space-evenly' : 'flex-end'} spacing={1}>
        {
          !isMediaCropShown && !restrictedToMedia && (file.type === 'Image' || file.type === 'Video' || file.type === 'Pdf' || file.type === 'Audio') ?
            <Tooltip title='Preview'><IconButton onClick={() => onPreview(file)}>
              <Iconify icon={file.type === 'Audio' || file.type === 'Video' ? 'ic:baseline-play-circle' : 'eva:eye-fill'} />
            </IconButton></Tooltip> : undefined
        }

        {!isMediaCropShown && onDownload ?
          <Tooltip title='Download'><IconButton size={restrictedToMedia ? 'small' : undefined} onClick={() => onDownload ? onDownload(file) : undefined}>
            <Iconify icon={'eva:download-fill'} />
          </IconButton></Tooltip> : undefined}

        {!isMediaCropShown && onDelete ? <Tooltip title='Delete'><IconButton size={restrictedToMedia ? 'small' : undefined} onClick={() => onDelete ? onDelete(file) : undefined}>
          <Iconify icon={'eva:trash-outline'} />
        </IconButton></Tooltip> : undefined}

        {!isMediaCropShown && isMediaCropAllowed ? <Tooltip title='Crop'><IconButton size={restrictedToMedia ? 'small' : undefined} onClick={() => setIsMediaCropShown(true)}>
          <Iconify icon={'eva:crop-outline'} />
        </IconButton></Tooltip> : undefined}

        {isMediaCropShown ? <Button sx={{ mt: 2 }} variant='contained' endIcon={<Iconify icon={'eva:checkmark-circle-2-outline'} />} size={restrictedToMedia ? 'small' : undefined} onClick={() => setIsMediaCropShown(false)}>
          Finish cropping
        </Button> : undefined}

        {
          !isMediaCropShown && onUpdateMetadata && restrictedToMedia ?

            <Stack direction='row' sx={{ width: '100%' }} gap={2}>
              <TextField inputProps={{ maxLength: 60 }} style={{ width: '100%' }} variant='standard' defaultValue={(fileMetadataState.description || fileMetadataState.fileName)?.substring(0, 60)} fullWidth onChange={(e) => {
                // console.log(e);
                onMetadataChange({ description: e.target.value });
              }} />
              {fileMetadataState.fileSizeBytes ? <Typography color={(fileMetadataState.fileSizeBytes / 1024) > 1024 ? theme.palette.warning.dark : undefined} variant='caption' sx={{ lineHeight: '32px' }}>{fData(fileMetadataState.fileSizeBytes).replace(/ /g, '')}</Typography> : undefined}
            </Stack>

            : (!isMediaCropShown && restrictedToMedia ? <Typography variant='subtitle2'>{(fileMetadataState.description || fileMetadataState.fileName)}</Typography> : undefined)
        }
      </Stack>
    ) : undefined;

  const mediaHeight = file.type === 'Audio' ? '140px' : (files.length === 1 ? '550px' : '350px');

  const actionStyles = { zIndex: 10, backgroundColor: alpha(theme.palette.grey[100], isMediaCropShown ? 0 : 0.7), p: 0, px: 1 };

  const objectFit = 'contain';

  // Cropped image props
  const scale = 100 / (fileMetadataState.mediaCrop?.percentageArea?.width || 1);
  const transform = fileMetadataState.mediaCrop && fileMetadataState.mediaCrop.percentageArea && fileMetadataState.mediaCrop.percentageArea.coordinates && fileMetadataState.mediaCrop.percentageArea.coordinates.x && fileMetadataState.mediaCrop.percentageArea.coordinates.y ? {
    x: `${-fileMetadataState.mediaCrop.percentageArea.coordinates.x * scale}%`,
    y: `${-fileMetadataState.mediaCrop.percentageArea.coordinates.y * scale}%`,
    scale,
    width: "calc(100% + 0.5px)",
    height: "auto"
  } : undefined;

  const imageStyle = transform ? {
    transform: `translate3d(${transform.x}, ${transform.y}, 0) scale3d(${transform.scale},${transform.scale},1)`,
    width: transform.width,
    height: transform.height,
    position: 'absolute' as any,
    top: 0,
    left: 0,
    transformOrigin: 'top left'
  } : undefined;

  return (
    <Grid item xs={!direction || direction === 'column' ? 12 : (files.length > 1 ? 6 : 12)} sx={{}}>
      <Box
        // key={`${file.id}-${file.uploadProgressPercent}`}
        // component={m.div}
        // {...varFade().inRight}
        sx={{
          // my: 0.7,
          px: mediaViewUrl ? 0 : 2,
          py: mediaViewUrl ? 0 : 0.5,
          width: '100%',
          borderRadius: 0.75,
          border: (theme) => restrictedToMedia ? 'none' : `solid 1px ${theme.palette.divider}`,
        }}
      >
        <Stack direction={direction === 'row' ? 'column' : 'row'} justifyContent={'space-between'} sx={{
          minHeight: '52px',
          width: '100%'
        }}>
          <Stack direction='row' alignItems={direction === 'row' ? '' : 'stretch'} justifyContent={direction === 'row' ? 'stretch' : 'space-between'} spacing={1} sx={{ width: '100%' }}>
            {
              file.status === 'Uploading' ? <CircularProgressWithLabel key={file.uploadProgressPercent} value={file.uploadProgressPercent || 0} sx={{ width: 28, height: 28, color: theme.palette.warning.main, mr: 2 }} /> : undefined
            }

            {
              file.status === 'PendingStart' ?
                <Iconify
                  icon={'ic:baseline-hourglass-top'}
                  sx={{ width: 28, height: 28, color: theme.palette.warning.main, mr: 2 }}
                /> : undefined
            }

            {
              !restrictedToMedia && (file.status === 'Uploaded' || file.status === 'PreviouslyUploaded') ?
                <IconButton edge="start" size="large" onClick={() => onDefaultAction(file)} sx={{ color: file.status === 'PreviouslyUploaded' ? theme.palette.text.secondary : theme.palette.success.main, mr: 1 }}>
                  <Iconify icon={getSuccessIcon()} />
                </IconButton>
                : undefined
            }

            {
              file.status === 'Failed' ?
                <Iconify
                  color={'red'}
                  icon={'eva:alert-circle-outline'}
                  sx={{ width: 28, height: 28, color: theme.palette.error.main, mr: 2 }}
                /> : undefined
            }

            {
              !restrictedToMedia ?
                <Stack spacing={0.4} sx={{ width: '100%' }}>
                  {
                    onUpdateMetadata ? <TextField inputProps={{ maxLength: 60 }} style={{ width: '100%' }} variant='standard' defaultValue={(fileMetadataState.description || fileMetadataState.fileName)?.substring(0, 60)} onChange={(e) => {
                      // console.log(e);
                      onMetadataChange({ description: e.target.value });
                    }} /> : <Typography variant='subtitle2'>{(fileMetadataState.description || fileMetadataState.fileName)}</Typography>
                  }
                  <div>
                    <Typography variant='caption' component='span' sx={{ whiteSpace: 'nowrap', display: 'inline-block', textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: direction === 'row' ? '200px' : '200px' }}>{fData(fileMetadataState.fileSizeBytes || 0)}</Typography>
                    <Typography variant='caption' component='span' sx={{ whiteSpace: 'nowrap', display: 'inline-block', textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: direction === 'row' ? '200px' : '200px', pl: 1 }}>{getFileTypeShort()}</Typography>
                  </div>
                </Stack>
                : undefined
            }

            {!restrictedToMedia ? actionButtons : undefined}
          </Stack>

          {restrictedToMedia && mediaViewUrl ?
            <Card>
              {
                onDelete || onDownload ?
                  <div style={{ position: 'relative' }}>
                    <CardActions sx={{ left: 0, right: 0, position: 'absolute', backfaceVisibility: 'hidden', ...actionStyles }}>
                      {actionButtons}
                    </CardActions>
                  </div>
                  : undefined
              }

              {
                isMediaCropAllowed && isMediaCropShown ?
                  <>
                    <div style={{ height: mediaHeight, marginTop: '-56px' }}>
                      <div className="crop-container">
                        <Cropper
                          image={mediaViewUrl}
                          crop={crop}
                          zoom={zoom}
                          aspect={CROP_AREA_ASPECT}
                          onCropChange={setCrop}
                          initialCroppedAreaPercentages={file.mediaCrop && file.mediaCrop.percentageArea ? { x: file.mediaCrop.percentageArea.coordinates?.x || 0, y: file.mediaCrop.percentageArea.coordinates?.y || 0, width: file.mediaCrop.percentageArea.width || 0, height: file.mediaCrop.percentageArea.height || 0 } : undefined}
                          initialCroppedAreaPixels={file.mediaCrop && file.mediaCrop.pixelArea ? { x: file.mediaCrop.pixelArea.coordinates?.x || 0, y: file.mediaCrop.pixelArea.coordinates?.y || 0, width: file.mediaCrop.pixelArea.width || 0, height: file.mediaCrop.pixelArea.height || 0 } : undefined}
                          objectFit={objectFit}
                          maxZoom={MAX_ZOOM}
                          minZoom={MIN_ZOOM}
                          onCropComplete={onCropComplete}
                          //onCropAreaChange={onCropComplete}
                          onZoomChange={onZoomChange}
                        />
                      </div>
                      <div style={{ position: 'absolute', left: 0, bottom: 0, width: '100%', height: '50px', display: 'flex', alignItems: 'center', ...actionStyles }}>
                        <div style={{ left: '50%', bottom: 0, width: '50%', margin: '0 auto' }}>
                          <Slider
                            value={zoom}
                            min={MIN_ZOOM}
                            max={MAX_ZOOM}
                            step={0.05}
                            aria-labelledby="Zoom"
                            onChange={(e, zoom) => onZoomChange(Number(zoom))}
                            classes={{ root: "slider" }}
                          />
                        </div>
                      </div>
                    </div>
                  </> : <>
                    {
                      (file.type === 'Audio' || file.type === 'Video') ?
                        ReactPlayer.canPlay(mediaViewUrl) ? <ReactPlayer style={{ marginTop: '-56px', zIndex: 1, backgroundColor: '#F1F3F4', borderRadius: theme.shape.borderRadius }} url={mediaViewUrl} playing={autoplay} controls={true} light={preload} muted={false} width={'100%'} height={mediaHeight} /> : <>File type not supported</>
                        : undefined
                    }

                    {
                      file.type === 'Image' && imageStyle ?
                        <div style={{
                          height: mediaHeight,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}>
                          <div
                            style={{
                              position: 'relative',
                              width: '100%',
                              height: mediaHeight,
                              maxHeight: mediaHeight,
                              overflow: 'hidden',
                              paddingBottom: `${110 / CROP_AREA_ASPECT}%`
                            }}
                          >
                            <img src={mediaViewUrl} alt="" style={imageStyle} />
                          </div>
                        </div>
                        : undefined}

                    {
                      file.type === 'Image' && !imageStyle ?
                        <CardMedia
                          component="img"
                          height={mediaHeight}
                          sx={{ mt: '-56px', objectFit: objectFit, backgroundSize: objectFit }}
                          image={mediaViewUrl}
                        />

                        : undefined
                    }
                  </>
              }

              {file.description && !onUpdateMetadata ? <CardContent sx={{ p: theme.spacing(1.5), pb: theme.spacing(1.5) + '!important' }}><Typography variant='body1'>{file.description}</Typography></CardContent> : undefined}
            </Card>
            : undefined}
        </Stack>
      </Box>
    </Grid>
  );
}