// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css';

import {
    Activ8CoursesContentLearningCourseContentBlock,
    Activ8CoursesContentLearningCourseContentResource,
    Activ8CoursesContentQuizResultsLearningCourseContentQuizResult
} from 'src/api/redux/app/appApi';
import Iconify from 'src/components/Iconify';
import { ResourceContentManager } from 'src/sections/shared/ResourceContentManager';

import { Box, Button, Stack, SxProps, Typography, useTheme } from '@mui/material';

import { LearningCourseProgramSessionContentQuizBlockQuestion } from 'src/sections/courses/programs/sessionContentView/LearningCourseProgramSessionContentQuizBlockQuestion';
import { htmlToReact } from 'src/utils/htmlToReact';

type LearningCourseProgramSessionContentBlockViewProps = {
    contentBlock: Activ8CoursesContentLearningCourseContentBlock;
    prepareContentResourceReadUrl: (file: Activ8CoursesContentLearningCourseContentResource, isDownload: boolean) => Promise<string>;
    onQuizOptionSelectToggle: (optionId: string, isSelected: boolean) => void;
    onFreeTextAnswerTextChange: (text: string) => void;
    onNextClick: () => Promise<void>;
    onQuizStart: (quizContentBlock: Activ8CoursesContentLearningCourseContentBlock) => void;
    currentQuizQuestionIndex: number | undefined;
    loading: boolean;
    isQuizQuestionAnswered: boolean;
    completedQuizIds: string[];
    quizResults: Activ8CoursesContentQuizResultsLearningCourseContentQuizResult[];
}

export function LearningCourseProgramSessionContentBlockView({ contentBlock, isQuizQuestionAnswered, prepareContentResourceReadUrl, onNextClick, onFreeTextAnswerTextChange, completedQuizIds, onQuizOptionSelectToggle, onQuizStart, currentQuizQuestionIndex, quizResults, loading }: LearningCourseProgramSessionContentBlockViewProps) {
    const theme = useTheme();

    const quizResult = quizResults && quizResults.length > 0 ? quizResults.find(q => q.learningCourseContentQuizId === contentBlock.quiz?.id) : undefined;
    const isQuizComplete = contentBlock.quiz ? completedQuizIds.indexOf(contentBlock.quiz.id as string) > -1 : Boolean(quizResult?.isCompleted);

    console.log('Content block loaded', { completedQuizIds, contentBlock, quizResult })

    // const isQuizInLine = contentBlock.quiz?.isQuizShownInLine === true;
    const freeTextResults = quizResult?.questions?.filter(e => e.freeTextAnswer).map(e => {
        const question = contentBlock.quiz?.questions?.find(q => q.id === e.learningCourseContentQuizQuestionId);
        if (!question) return null;

        return {
            questionText: question.questionText,
            questionAnswer: e.freeTextAnswer
        }
    }).filter(Boolean) || []

    const boxStyle: SxProps = isQuizComplete ? {
        border: '2px solid ' + theme.palette.grey[300],
        background: theme.palette.grey[200]
    } : {
        border: '2px solid ' + theme.palette.primary.main,
        background: theme.palette.grey[300]
    }

    return (
        <div id={`contentblock-${contentBlock.id}`}>
            {contentBlock.type === 'RichText' ?
                <div className="ql-container">
                    <div className="ql-editor">{htmlToReact(contentBlock.richText as string)}</div>
                </div>
                : undefined}

            {contentBlock.type === 'Resources' || contentBlock.type === 'Media' ?
                <ResourceContentManager
                    defaultValue={contentBlock.resources || []}
                    hideDropzone={true}
                    preload={true}
                    preventDownload={contentBlock.type === 'Media'}
                    restrictedToMedia={contentBlock.type === 'Media'}
                    direction={contentBlock.type === 'Resources' ? 'column' : 'row'}
                    autoplay={false}
                    prepareReadUrl={prepareContentResourceReadUrl}
                />
                : undefined}

            {contentBlock.type === 'Quiz' && (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', m: 2, borderRadius: '8px', p: 2, ...boxStyle }}>
                    <Typography variant='h5' sx={{ mb: 2 }}>{contentBlock.quiz?.description}</Typography>

                    {/* Completed Quiz Display */}
                    {isQuizComplete && (
                        <Stack gap={2} sx={{ width: '100%' }}>
                            {/* Show quiz results */}
                            {quizResult?.questions?.map((questionResult, index) => {
                                const question = contentBlock.quiz?.questions?.[index];
                                if (!question) return null;

                                return (
                                    <Stack key={question.id} gap={1}>
                                        <Typography variant="h6" sx={{ fontWeight: 'normal' }}>{question.questionText}</Typography>

                                        {/* Show free text answer */}
                                        {questionResult.freeTextAnswer && (
                                            <Typography variant="body1" sx={{ whiteSpace: 'pre-wrap', pl: 2 }}>
                                                {questionResult.freeTextAnswer}
                                            </Typography>
                                        )}

                                        {/* Show multiple choice answers */}
                                        {questionResult.multipleChoiceAnswers && questionResult.multipleChoiceAnswers.length > 0 && (
                                            <Stack gap={1} sx={{ pl: 2 }}>
                                                {question.choiceOptions?.map(option => {
                                                    const answerResult = questionResult.multipleChoiceAnswers?.find(a =>
                                                        a.learningCourseContentQuizQuestionMultipleChoiceOptionId === option.id
                                                    );
                                                    if (!answerResult) return null;

                                                    const color = answerResult.isCorrectAnswer ? theme.palette.success.main :
                                                        answerResult.wasSelectedByUser ? theme.palette.error.main :
                                                            theme.palette.text.primary;

                                                    return (
                                                        <Typography
                                                            key={option.id}
                                                            variant="body1"
                                                            sx={{
                                                                color,
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                gap: 1
                                                            }}
                                                        >
                                                            <Iconify
                                                                fontSize={'18px'}
                                                                icon={answerResult.isCorrectAnswer ? 'eva:checkmark-circle-outline' :
                                                                    answerResult.wasSelectedByUser ? 'eva:close-circle-outline' :
                                                                        'eva:radio-button-off-outline'}
                                                            />
                                                            {option.answerText}
                                                        </Typography>
                                                    );
                                                })}
                                            </Stack>
                                        )}
                                    </Stack>
                                );
                            })}
                        </Stack>
                    )}

                    {/* Quiz In Progress */}
                    {!isQuizComplete && currentQuizQuestionIndex !== undefined && (
                        <Box sx={{ width: '100%' }}>
                            <Stack gap={5}>
                                <Box sx={{ minWidth: '500px' }}>
                                    <LearningCourseProgramSessionContentQuizBlockQuestion
                                        onFreeTextAnswerTextChange={onFreeTextAnswerTextChange}
                                        quizResult={quizResult}
                                        loading={loading}
                                        contentBlock={contentBlock}
                                        currentQuizQuestionIndex={currentQuizQuestionIndex}
                                        onQuizOptionSelectToggle={onQuizOptionSelectToggle}
                                    />
                                </Box>
                                <Box>
                                    <Stack direction="row" justifyContent="flex-end">
                                        <Button disabled={!isQuizQuestionAnswered || loading} variant="contained" color="primary" onClick={onNextClick}>Next</Button>
                                    </Stack>
                                </Box>
                            </Stack>
                        </Box>
                    )}

                    {/* Quiz Not Started */}
                    {!isQuizComplete && currentQuizQuestionIndex === undefined && (
                        <Button variant='contained' onClick={() => { onQuizStart(contentBlock) }}>
                            Start quiz
                        </Button>
                    )}
                </Box>
            )}
        </div>
    )
}