import { useConfirm } from 'material-ui-confirm';
import { useState } from 'react';
import { Control, useFieldArray, useFormContext } from 'react-hook-form';
import {
    Activ8CoursesContentLearningCourseContentBlockType,
    Activ8CoursesContentLearningCourseContentResource,
    Activ8CoursesTemplatesPrepareResourceOperationResponse
} from 'src/api/redux/app/appApi';
import Iconify from 'src/components/Iconify';
import uuidv4 from 'src/utils/uuidv4';

import {
    closestCenter, DndContext, DragEndEvent, KeyboardSensor, PointerSensor, useSensor, useSensors
} from '@dnd-kit/core';
import {
    SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy
} from '@dnd-kit/sortable';
import { Box, Button, Paper, Stack, useTheme } from '@mui/material';

import { LearningTemplateFormValuesSchema } from '../../LearningCourseTemplateWizardForm';
import {
    CourseTemplateContentBuilderContentBlock
} from './CourseTemplateContentBuilderContentBlock';

export interface ICourseTemplateContentBuilderProps {
    courseTemplateId: string;
    sessionIndex: number;
    chapterIndex: number;
    control: Control<LearningTemplateFormValuesSchema>;
    prepareReadUrl: (resource: Activ8CoursesContentLearningCourseContentResource, isDownload: boolean) => Promise<string>;
    prepareUpload: (file: File) => Promise<{ response: Activ8CoursesTemplatesPrepareResourceOperationResponse | null, fileId: string }>;
}

export function CourseTemplateContentBuilder({ courseTemplateId, chapterIndex, sessionIndex, control, prepareReadUrl, prepareUpload }: ICourseTemplateContentBuilderProps) {
    const [editingContentIndex, setEditingContentIndex] = useState<number | undefined>(undefined);
    const [moveCounter, setMoveCounter] = useState<number>(0);
    const theme = useTheme();
    const confirm = useConfirm();
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const { watch, setValue, getValues } = useFormContext<LearningTemplateFormValuesSchema>();
    const contentArrayFieldName = `chapters.${chapterIndex}.sessions.${sessionIndex}.content` as 'chapters.0.sessions.0.content';
    const { fields, append, remove, update, move } = useFieldArray<LearningTemplateFormValuesSchema>({
        control,
        name: contentArrayFieldName
    });

    function isContentBlockEmpty(index: number): boolean {
        const block = getValues(`${contentArrayFieldName}.${index}` as 'chapters.0.sessions.0.content.0');

        if (block.type === 'RichText' && (!block.richText || block.richText === '<p></p>')) return true;
        if ((block.type === 'Resources' || block.type === 'Media') && (!block.resources || block.resources?.length === 0)) return true;
        if (block.type === 'Quiz' && !block.quiz) return true;

        return false;
    }

    const handleSetEditingContentIndex = (index: number | undefined): void => {
        // if(index !== undefined && index !== null && editingContentIndex !== undefined && editingContentIndex !== null && editingContentIndex !== index){
        //     onContentBlockBlur(index);
        //     return;
        // }

        setEditingContentIndex(index);
    }

    const onContentBlockBlur = (index: number): void => {
        if (isContentBlockEmpty(index)) {
            deleteContentBlock(index);
        }

        handleSetEditingContentIndex(undefined);
        // if (fields.length - 1 > -1) {
        //     handleSetEditingContentIndex(fields.length - 1);
        // }
    }

    const addContentBlock = (type: Activ8CoursesContentLearningCourseContentBlockType): void => {
        append({
            id: uuidv4(),
            type: type,
            richText: '',
            quiz: type === 'Quiz' ? {
                id: uuidv4(), description: '', isAssessed: true, isAnswerFeedbackShown: false, isQuizShownInLine: false, questions: [
                    // {
                    //     id: uuidv4(),
                    //     questionText: '',
                    //     questionType: LearningCourseContentQuizQuestionType.MultipleChoice,
                    //     choiceOptions: [
                    //         {
                    //             id: uuidv4(),
                    //             answerText: '',
                    //             isCorrect: true,
                    //         },
                    //         {
                    //             id: uuidv4(),
                    //             answerText: '',
                    //             isCorrect: false,
                    //         }
                    //     ]
                    // }
                ]
            } : null,
            resources: []
        });
        handleSetEditingContentIndex(fields.length);
    }

    const deleteContentBlock = (index: number): void => {
        function deleteFunc() {
            remove(index);
            handleSetEditingContentIndex(undefined);
        }

        if (isContentBlockEmpty(index)) {
            deleteFunc();
            return;
        }

        const block = getValues(`${contentArrayFieldName}.${index}` as 'chapters.0.sessions.0.content.0');
        if (block.type === 'PageBreak') {
            deleteFunc();
        }
        else {
            // confirm({ description: `Are you sure you want to delete the ${(block.type === 'PageBreak' ? 'page break' : block.type.toLowerCase())} block?`})
            confirm({ description: `Are you sure you want to delete the ${block.type.toLowerCase()} block?` })
                .then(async () => {
                    deleteFunc();
                })
                .catch(() => { });;
        }
    }

    const contentArrayWatch = watch(contentArrayFieldName);
    function handleDragEnd(event: DragEndEvent) {
        const { active, over } = event;

        if (active.id !== over?.id) {
            move(contentArrayWatch?.findIndex(e => e.id === active.id) as number, contentArrayWatch?.findIndex(e => e.id === over?.id) as number);
        }
        handleSetEditingContentIndex(undefined);
        setMoveCounter(moveCounter + 1)
    }

    return (
        <>
            {/* <code>{JSON.stringify(contentArrayWatch, null, 2)}</code> */}

            <Stack gap={0} style={{ cursor: 'pointer' }}>
                <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragEnd={handleDragEnd}
                >
                    <SortableContext
                        items={contentArrayWatch as any[]}
                        strategy={verticalListSortingStrategy}
                    >
                        {
                            (contentArrayWatch || []).map((block, index) =>
                                <CourseTemplateContentBuilderContentBlock
                                    key={block.id}
                                    prepareUpload={prepareUpload}
                                    prepareReadUrl={prepareReadUrl}
                                    onDeleteContentBlock={deleteContentBlock}
                                    onBlur={onContentBlockBlur}
                                    moveCounter={moveCounter}
                                    isLastBlockInPage={contentArrayWatch ? (contentArrayWatch.length === index + 1 || contentArrayWatch[index + 1].type === 'PageBreak') : false}
                                    isFirstBlockInPage={contentArrayWatch ? (index === 0 || contentArrayWatch[index - 1].type === 'PageBreak') : false}
                                    isEditing={editingContentIndex === index}
                                    contentIndex={index}
                                    onClick={() => handleSetEditingContentIndex(index)}
                                    {...{ courseTemplateId, chapterIndex, sessionIndex, control }} />
                            )
                        }
                    </SortableContext>
                </DndContext>

                <Box sx={{ width: '80%', margin: '0 auto', pt: theme.spacing(6), mt: theme.spacing(1) }}>
                    <Paper variant="outlined" sx={{ p: 1, textAlign: 'center', borderWidth: 0, borderTopWidth: 3, borderStyle: 'dotted', borderColor: theme.palette.grey[300] }}>
                        <Stack gap={2}>
                            {/* <Typography variant='body2'>Drop files here or</Typography> */}
                            <div>
                                <Stack direction='row' justifyContent={'center'} alignContent='space-evenly' spacing={4}>
                                    <Button size='small' variant='text' startIcon={<Iconify icon='eva:plus-outline' />} onClick={() => { addContentBlock('RichText') }}>Text block</Button>
                                    <Button size='small' variant='text' startIcon={<Iconify icon='eva:plus-outline' />} onClick={() => { addContentBlock('Quiz') }}>Quiz block</Button>
                                    <Button size='small' variant='text' startIcon={<Iconify icon='eva:plus-outline' />} onClick={() => { addContentBlock('Media') }}>Media block</Button>
                                    <Button size='small' variant='text' startIcon={<Iconify icon='eva:plus-outline' />} onClick={() => { addContentBlock('Resources') }}>Resource block</Button>
                                    <Button size='small' variant='text' startIcon={<Iconify icon='eva:plus-outline' />} onClick={() => { addContentBlock('PageBreak') }}>Page break</Button>
                                </Stack>
                            </div>
                        </Stack>
                    </Paper>
                </Box>
            </Stack>
        </>
    )
}

