import { getWebAutoInstrumentations } from '@opentelemetry/auto-instrumentations-web';
import { CompositePropagator, W3CBaggagePropagator, W3CTraceContextPropagator } from '@opentelemetry/core';
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-proto';
import { registerInstrumentations } from '@opentelemetry/instrumentation';
import { DocumentLoadInstrumentation } from '@opentelemetry/instrumentation-document-load';
import { XMLHttpRequestInstrumentation } from '@opentelemetry/instrumentation-xml-http-request';
import { Resource, browserDetector } from '@opentelemetry/resources';
import { detectResourcesSync } from '@opentelemetry/resources/build/src/detect-resources';

import {
    BatchSpanProcessor, ConsoleSpanExporter,
    SimpleSpanProcessor
} from '@opentelemetry/sdk-trace-base';
import { WebTracerProvider, } from '@opentelemetry/sdk-trace-web';
import { ATTR_SERVICE_NAME } from '@opentelemetry/semantic-conventions';
import appSettings from 'src/appSettings';
import { SessionIdProcessor } from './SessionIdProcessor';

const FrontendTracer = async () => {
    if (!appSettings.openTelemetryOtlpTracesEndpoint) {
        console.log('OpenTelemetry endpoint not configured');
        return;
    }
    else {
        console.log('OpenTelemetry endpoint configured', appSettings.openTelemetryOtlpTracesEndpoint);
    }

    const { ZoneContextManager } = await import('@opentelemetry/context-zone');

    let resource = new Resource({
        [ATTR_SERVICE_NAME]: 'react-app',
    });
    const detectedResources = detectResourcesSync({ detectors: [browserDetector] });
    resource = resource.merge(detectedResources);

    const provider = new WebTracerProvider({
        resource,
        spanProcessors: [
            new SessionIdProcessor(),
            new BatchSpanProcessor(
                new OTLPTraceExporter({
                    url: appSettings.openTelemetryOtlpTracesEndpoint,
                    headers: {
                        'X-Seq-ApiKey': appSettings.seqApiKey
                    }
                }),
                {
                    scheduledDelayMillis: 500,
                }
            ),
            new SimpleSpanProcessor(new ConsoleSpanExporter())
        ],
    });

    const contextManager = new ZoneContextManager();

    provider.register({
        contextManager,
        propagator: new CompositePropagator({
            propagators: [
                new W3CBaggagePropagator(),
                new W3CTraceContextPropagator()
            ],
        }),

    });

    registerInstrumentations({
        tracerProvider: provider,
        instrumentations: [
            getWebAutoInstrumentations({
                '@opentelemetry/instrumentation-fetch': {
                    // Explicitly include the Seq endpoint in CORS URLs pattern
                    propagateTraceHeaderCorsUrls: [
                        /.*/,
                        new RegExp(appSettings.openTelemetryOtlpTracesEndpoint)
                    ],
                    clearTimingResources: true,
                    applyCustomAttributesOnSpan(span) {
                        span.setAttribute('app.tenant.name', appSettings.tenancyShortName);
                        span.setAttribute('app.build.version', appSettings.buildVersion);
                    },
                }
            }),
            new XMLHttpRequestInstrumentation({
                propagateTraceHeaderCorsUrls: [
                    /.*/,
                    new RegExp(appSettings.openTelemetryOtlpTracesEndpoint)
                ],
                // Ensure withCredentials is set for CORS requests
                applyCustomAttributesOnSpan: (span, xhr) => {
                    if (xhr && xhr instanceof XMLHttpRequest) {
                        xhr.withCredentials = true;
                    }
                }
            }),
            new DocumentLoadInstrumentation()
        ],
    });
};

export default FrontendTracer;