import { emptySplitApi as api } from "./baseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    abpApiDefinitionGet: build.query<
      AbpApiDefinitionGetApiResponse,
      AbpApiDefinitionGetApiArg
    >({
      query: (queryArg) => ({
        url: `/api/abp/api-definition`,
        params: { IncludeTypes: queryArg.includeTypes },
      }),
    }),
    abpApplicationConfigurationGet: build.query<
      AbpApplicationConfigurationGetApiResponse,
      AbpApplicationConfigurationGetApiArg
    >({
      query: (queryArg) => ({
        url: `/api/abp/application-configuration`,
        params: {
          IncludeLocalizationResources: queryArg.includeLocalizationResources,
        },
      }),
    }),
    abpApplicationLocalizationGet: build.query<
      AbpApplicationLocalizationGetApiResponse,
      AbpApplicationLocalizationGetApiArg
    >({
      query: (queryArg) => ({
        url: `/api/abp/application-localization`,
        params: {
          CultureName: queryArg.cultureName,
          OnlyDynamics: queryArg.onlyDynamics,
        },
      }),
    }),
    abpTenantFindTenantByName: build.query<
      AbpTenantFindTenantByNameApiResponse,
      AbpTenantFindTenantByNameApiArg
    >({
      query: (queryArg) => ({
        url: `/api/abp/multi-tenancy/tenants/by-name/${queryArg.name}`,
      }),
    }),
    abpTenantFindTenantById: build.query<
      AbpTenantFindTenantByIdApiResponse,
      AbpTenantFindTenantByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/abp/multi-tenancy/tenants/by-id/${queryArg.id}`,
      }),
    }),
    accountRegister: build.mutation<
      AccountRegisterApiResponse,
      AccountRegisterApiArg
    >({
      query: (queryArg) => ({
        url: `/api/account/register`,
        method: "POST",
        body: queryArg.voloAbpAccountRegisterDto,
      }),
    }),
    accountSendPasswordResetCode: build.mutation<
      AccountSendPasswordResetCodeApiResponse,
      AccountSendPasswordResetCodeApiArg
    >({
      query: (queryArg) => ({
        url: `/api/account/send-password-reset-code`,
        method: "POST",
        body: queryArg.voloAbpAccountSendPasswordResetCodeDto,
      }),
    }),
    accountVerifyPasswordResetToken: build.mutation<
      AccountVerifyPasswordResetTokenApiResponse,
      AccountVerifyPasswordResetTokenApiArg
    >({
      query: (queryArg) => ({
        url: `/api/account/verify-password-reset-token`,
        method: "POST",
        body: queryArg.voloAbpAccountVerifyPasswordResetTokenInput,
      }),
    }),
    accountResetPassword: build.mutation<
      AccountResetPasswordApiResponse,
      AccountResetPasswordApiArg
    >({
      query: (queryArg) => ({
        url: `/api/account/reset-password`,
        method: "POST",
        body: queryArg.voloAbpAccountResetPasswordDto,
      }),
    }),
    accountGetConfirmationState: build.query<
      AccountGetConfirmationStateApiResponse,
      AccountGetConfirmationStateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/account/confirmation-state`,
        params: { id: queryArg.id },
      }),
    }),
    accountSendPhoneNumberConfirmationToken: build.mutation<
      AccountSendPhoneNumberConfirmationTokenApiResponse,
      AccountSendPhoneNumberConfirmationTokenApiArg
    >({
      query: (queryArg) => ({
        url: `/api/account/send-phone-number-confirmation-token`,
        method: "POST",
        body: queryArg.voloAbpAccountSendPhoneNumberConfirmationTokenDto,
      }),
    }),
    accountSendEmailConfirmationToken: build.mutation<
      AccountSendEmailConfirmationTokenApiResponse,
      AccountSendEmailConfirmationTokenApiArg
    >({
      query: (queryArg) => ({
        url: `/api/account/send-email-confirmation-token`,
        method: "POST",
        body: queryArg.voloAbpAccountSendEmailConfirmationTokenDto,
      }),
    }),
    accountVerifyEmailConfirmationToken: build.mutation<
      AccountVerifyEmailConfirmationTokenApiResponse,
      AccountVerifyEmailConfirmationTokenApiArg
    >({
      query: (queryArg) => ({
        url: `/api/account/verify-email-confirmation-token`,
        method: "POST",
        body: queryArg.voloAbpAccountVerifyEmailConfirmationTokenInput,
      }),
    }),
    accountConfirmPhoneNumber: build.mutation<
      AccountConfirmPhoneNumberApiResponse,
      AccountConfirmPhoneNumberApiArg
    >({
      query: (queryArg) => ({
        url: `/api/account/confirm-phone-number`,
        method: "POST",
        body: queryArg.voloAbpAccountConfirmPhoneNumberInput,
      }),
    }),
    accountConfirmEmail: build.mutation<
      AccountConfirmEmailApiResponse,
      AccountConfirmEmailApiArg
    >({
      query: (queryArg) => ({
        url: `/api/account/confirm-email`,
        method: "POST",
        body: queryArg.voloAbpAccountConfirmEmailInput,
      }),
    }),
    accountSetProfilePicture: build.mutation<
      AccountSetProfilePictureApiResponse,
      AccountSetProfilePictureApiArg
    >({
      query: (queryArg) => ({
        url: `/api/account/profile-picture`,
        method: "POST",
        body: queryArg.body,
        params: { Type: queryArg["type"] },
      }),
    }),
    accountGetProfilePicture: build.query<
      AccountGetProfilePictureApiResponse,
      AccountGetProfilePictureApiArg
    >({
      query: (queryArg) => ({
        url: `/api/account/profile-picture/${queryArg.id}`,
      }),
    }),
    accountGetTwoFactorProviders: build.query<
      AccountGetTwoFactorProvidersApiResponse,
      AccountGetTwoFactorProvidersApiArg
    >({
      query: (queryArg) => ({
        url: `/api/account/two-factor-providers`,
        params: { UserId: queryArg.userId, Token: queryArg.token },
      }),
    }),
    accountSendTwoFactorCode: build.mutation<
      AccountSendTwoFactorCodeApiResponse,
      AccountSendTwoFactorCodeApiArg
    >({
      query: (queryArg) => ({
        url: `/api/account/send-two-factor-code`,
        method: "POST",
        body: queryArg.voloAbpAccountSendTwoFactorCodeInput,
      }),
    }),
    accountGetSecurityLogList: build.query<
      AccountGetSecurityLogListApiResponse,
      AccountGetSecurityLogListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/account/security-logs`,
        params: {
          StartTime: queryArg.startTime,
          EndTime: queryArg.endTime,
          ApplicationName: queryArg.applicationName,
          Identity: queryArg.identity,
          Action: queryArg.action,
          UserName: queryArg.userName,
          ClientId: queryArg.clientId,
          CorrelationId: queryArg.correlationId,
          Sorting: queryArg.sorting,
          SkipCount: queryArg.skipCount,
          MaxResultCount: queryArg.maxResultCount,
          ExtraProperties: queryArg.extraProperties,
        },
      }),
    }),
    accountVerifyAuthenticatorCode: build.mutation<
      AccountVerifyAuthenticatorCodeApiResponse,
      AccountVerifyAuthenticatorCodeApiArg
    >({
      query: (queryArg) => ({
        url: `/api/account/verify-authenticator-code`,
        method: "POST",
        body: queryArg.voloAbpAccountVerifyAuthenticatorCodeInput,
      }),
    }),
    accountResetAuthenticator: build.mutation<
      AccountResetAuthenticatorApiResponse,
      AccountResetAuthenticatorApiArg
    >({
      query: () => ({
        url: `/api/account/reset-authenticator`,
        method: "POST",
      }),
    }),
    accountHasAuthenticator: build.query<
      AccountHasAuthenticatorApiResponse,
      AccountHasAuthenticatorApiArg
    >({
      query: () => ({ url: `/api/account/has-authenticator-key` }),
    }),
    accountGetAuthenticatorInfo: build.query<
      AccountGetAuthenticatorInfoApiResponse,
      AccountGetAuthenticatorInfoApiArg
    >({
      query: () => ({ url: `/api/account/authenticator-info` }),
    }),
    accountGetProfilePictureFile: build.query<
      AccountGetProfilePictureFileApiResponse,
      AccountGetProfilePictureFileApiArg
    >({
      query: (queryArg) => ({
        url: `/api/account/profile-picture-file/${queryArg.id}`,
      }),
    }),
    accountRecaptcha: build.query<
      AccountRecaptchaApiResponse,
      AccountRecaptchaApiArg
    >({
      query: (queryArg) => ({
        url: `/api/account/recaptcha-validate`,
        params: { captchaResponse: queryArg.captchaResponse },
      }),
    }),
    accountExternalProviderGetAll: build.query<
      AccountExternalProviderGetAllApiResponse,
      AccountExternalProviderGetAllApiArg
    >({
      query: () => ({ url: `/api/account/external-provider` }),
    }),
    accountExternalProviderGetByName: build.query<
      AccountExternalProviderGetByNameApiResponse,
      AccountExternalProviderGetByNameApiArg
    >({
      query: (queryArg) => ({
        url: `/api/account/external-provider/by-name`,
        params: { TenantId: queryArg.tenantId, Name: queryArg.name },
      }),
    }),
    accountSettingsGet: build.query<
      AccountSettingsGetApiResponse,
      AccountSettingsGetApiArg
    >({
      query: () => ({ url: `/api/account-admin/settings` }),
    }),
    accountSettingsUpdate: build.mutation<
      AccountSettingsUpdateApiResponse,
      AccountSettingsUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/account-admin/settings`,
        method: "PUT",
        body: queryArg.voloAbpAccountAccountSettingsDto,
      }),
    }),
    accountSettingsGetTwoFactor: build.query<
      AccountSettingsGetTwoFactorApiResponse,
      AccountSettingsGetTwoFactorApiArg
    >({
      query: () => ({ url: `/api/account-admin/settings/two-factor` }),
    }),
    accountSettingsUpdateTwoFactor: build.mutation<
      AccountSettingsUpdateTwoFactorApiResponse,
      AccountSettingsUpdateTwoFactorApiArg
    >({
      query: (queryArg) => ({
        url: `/api/account-admin/settings/two-factor`,
        method: "PUT",
        body: queryArg.voloAbpAccountAccountTwoFactorSettingsDto,
      }),
    }),
    accountSettingsGetRecaptcha: build.query<
      AccountSettingsGetRecaptchaApiResponse,
      AccountSettingsGetRecaptchaApiArg
    >({
      query: () => ({ url: `/api/account-admin/settings/recaptcha` }),
    }),
    accountSettingsUpdateRecaptcha: build.mutation<
      AccountSettingsUpdateRecaptchaApiResponse,
      AccountSettingsUpdateRecaptchaApiArg
    >({
      query: (queryArg) => ({
        url: `/api/account-admin/settings/recaptcha`,
        method: "PUT",
        body: queryArg.voloAbpAccountAccountRecaptchaSettingsDto,
      }),
    }),
    accountSettingsGetExternalProvider: build.query<
      AccountSettingsGetExternalProviderApiResponse,
      AccountSettingsGetExternalProviderApiArg
    >({
      query: () => ({ url: `/api/account-admin/settings/external-provider` }),
    }),
    accountSettingsUpdateExternalProvider: build.mutation<
      AccountSettingsUpdateExternalProviderApiResponse,
      AccountSettingsUpdateExternalProviderApiArg
    >({
      query: (queryArg) => ({
        url: `/api/account-admin/settings/external-provider`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    activeCampaignIntegrationGetSettings: build.query<
      ActiveCampaignIntegrationGetSettingsApiResponse,
      ActiveCampaignIntegrationGetSettingsApiArg
    >({
      query: () => ({ url: `/api/app/active-campaign/settings` }),
    }),
    activeCampaignIntegrationSetSettings: build.mutation<
      ActiveCampaignIntegrationSetSettingsApiResponse,
      ActiveCampaignIntegrationSetSettingsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/active-campaign/set-settings`,
        method: "POST",
        body: queryArg.activ8IntegrationsActiveCampaignActiveCampaignIntegrationSettingsDto,
      }),
    }),
    apiIntegrationCompleteProgramExpressionOfInterestRequest: build.mutation<
      ApiIntegrationCompleteProgramExpressionOfInterestRequestApiResponse,
      ApiIntegrationCompleteProgramExpressionOfInterestRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/api/complete-program-expression-of-interest-request`,
        method: "POST",
        body: queryArg.activ8CoursesProgramsCoursesLearningCourseProgramPublicApiExpressionOfInterestProgramSelectionRequest,
        headers: { "X-PublicApiKey": queryArg["X-PublicApiKey"] },
      }),
    }),
    apiIntegrationGetPartnerInfo: build.query<
      ApiIntegrationGetPartnerInfoApiResponse,
      ApiIntegrationGetPartnerInfoApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/api/partner-info`,
        headers: { "X-PublicApiKey": queryArg["X-PublicApiKey"] },
      }),
    }),
    apiIntegrationListActivePrograms: build.mutation<
      ApiIntegrationListActiveProgramsApiResponse,
      ApiIntegrationListActiveProgramsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/api/list-active-programs`,
        method: "POST",
        body: queryArg.activ8CoursesProgramsCoursesLearningCourseProgramPublicApiExpressionOfInterestListProgramsRequest,
        headers: { "X-PublicApiKey": queryArg["X-PublicApiKey"] },
      }),
    }),
    apiIntegrationGetIntegrationSettings: build.query<
      ApiIntegrationGetIntegrationSettingsApiResponse,
      ApiIntegrationGetIntegrationSettingsApiArg
    >({
      query: () => ({ url: `/api/app/api/integration-settings` }),
    }),
    applicationGet: build.query<
      ApplicationGetApiResponse,
      ApplicationGetApiArg
    >({
      query: (queryArg) => ({
        url: `/api/openiddict/applications/${queryArg.id}`,
      }),
    }),
    applicationUpdate: build.mutation<
      ApplicationUpdateApiResponse,
      ApplicationUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/openiddict/applications/${queryArg.id}`,
        method: "PUT",
        body: queryArg.voloAbpOpenIddictApplicationsDtosUpdateApplicationInput,
      }),
    }),
    applicationGetList: build.query<
      ApplicationGetListApiResponse,
      ApplicationGetListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/openiddict/applications`,
        params: {
          Filter: queryArg.filter,
          Sorting: queryArg.sorting,
          SkipCount: queryArg.skipCount,
          MaxResultCount: queryArg.maxResultCount,
        },
      }),
    }),
    applicationCreate: build.mutation<
      ApplicationCreateApiResponse,
      ApplicationCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/openiddict/applications`,
        method: "POST",
        body: queryArg.voloAbpOpenIddictApplicationsDtosCreateApplicationInput,
      }),
    }),
    applicationDelete: build.mutation<
      ApplicationDeleteApiResponse,
      ApplicationDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/openiddict/applications`,
        method: "DELETE",
        params: { id: queryArg.id },
      }),
    }),
    applicationGetTokenLifetime: build.query<
      ApplicationGetTokenLifetimeApiResponse,
      ApplicationGetTokenLifetimeApiArg
    >({
      query: (queryArg) => ({
        url: `/api/openiddict/applications/${queryArg.id}/token-lifetime`,
      }),
    }),
    applicationSetTokenLifetime: build.mutation<
      ApplicationSetTokenLifetimeApiResponse,
      ApplicationSetTokenLifetimeApiArg
    >({
      query: (queryArg) => ({
        url: `/api/openiddict/applications/${queryArg.id}/token-lifetime`,
        method: "PUT",
        body: queryArg.voloAbpOpenIddictApplicationsDtosApplicationTokenLifetimeDto,
      }),
    }),
    auditLogsGetList: build.query<
      AuditLogsGetListApiResponse,
      AuditLogsGetListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/audit-logging/audit-logs`,
        params: {
          StartTime: queryArg.startTime,
          EndTime: queryArg.endTime,
          Url: queryArg.url,
          ClientId: queryArg.clientId,
          UserName: queryArg.userName,
          ApplicationName: queryArg.applicationName,
          ClientIpAddress: queryArg.clientIpAddress,
          CorrelationId: queryArg.correlationId,
          HttpMethod: queryArg.httpMethod,
          HttpStatusCode: queryArg.httpStatusCode,
          MaxExecutionDuration: queryArg.maxExecutionDuration,
          MinExecutionDuration: queryArg.minExecutionDuration,
          HasException: queryArg.hasException,
          Sorting: queryArg.sorting,
          SkipCount: queryArg.skipCount,
          MaxResultCount: queryArg.maxResultCount,
        },
      }),
    }),
    auditLogsGet: build.query<AuditLogsGetApiResponse, AuditLogsGetApiArg>({
      query: (queryArg) => ({
        url: `/api/audit-logging/audit-logs/${queryArg.id}`,
      }),
    }),
    auditLogsGetErrorRate: build.query<
      AuditLogsGetErrorRateApiResponse,
      AuditLogsGetErrorRateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/audit-logging/audit-logs/statistics/error-rate`,
        params: { StartDate: queryArg.startDate, EndDate: queryArg.endDate },
      }),
    }),
    auditLogsGetAverageExecutionDurationPerDay: build.query<
      AuditLogsGetAverageExecutionDurationPerDayApiResponse,
      AuditLogsGetAverageExecutionDurationPerDayApiArg
    >({
      query: (queryArg) => ({
        url: `/api/audit-logging/audit-logs/statistics/average-execution-duration-per-day`,
        params: { StartDate: queryArg.startDate, EndDate: queryArg.endDate },
      }),
    }),
    auditLogsGetEntityChanges: build.query<
      AuditLogsGetEntityChangesApiResponse,
      AuditLogsGetEntityChangesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/audit-logging/audit-logs/entity-changes`,
        params: {
          AuditLogId: queryArg.auditLogId,
          EntityChangeType: queryArg.entityChangeType,
          EntityId: queryArg.entityId,
          EntityTypeFullName: queryArg.entityTypeFullName,
          StartDate: queryArg.startDate,
          EndDate: queryArg.endDate,
          Sorting: queryArg.sorting,
          SkipCount: queryArg.skipCount,
          MaxResultCount: queryArg.maxResultCount,
        },
      }),
    }),
    auditLogsGetEntityChangesWithUsername: build.query<
      AuditLogsGetEntityChangesWithUsernameApiResponse,
      AuditLogsGetEntityChangesWithUsernameApiArg
    >({
      query: (queryArg) => ({
        url: `/api/audit-logging/audit-logs/entity-changes-with-username`,
        params: {
          EntityId: queryArg.entityId,
          EntityTypeFullName: queryArg.entityTypeFullName,
        },
      }),
    }),
    auditLogsGetEntityChangeWithUsername: build.query<
      AuditLogsGetEntityChangeWithUsernameApiResponse,
      AuditLogsGetEntityChangeWithUsernameApiArg
    >({
      query: (queryArg) => ({
        url: `/api/audit-logging/audit-logs/entity-change-with-username/${queryArg.entityChangeId}`,
      }),
    }),
    auditLogsGetEntityChange: build.query<
      AuditLogsGetEntityChangeApiResponse,
      AuditLogsGetEntityChangeApiArg
    >({
      query: (queryArg) => ({
        url: `/api/audit-logging/audit-logs/entity-changes/${queryArg.entityChangeId}`,
      }),
    }),
    identityClaimTypeGetList: build.query<
      IdentityClaimTypeGetListApiResponse,
      IdentityClaimTypeGetListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/claim-types`,
        params: {
          Filter: queryArg.filter,
          Sorting: queryArg.sorting,
          SkipCount: queryArg.skipCount,
          MaxResultCount: queryArg.maxResultCount,
          ExtraProperties: queryArg.extraProperties,
        },
      }),
    }),
    identityClaimTypeCreate: build.mutation<
      IdentityClaimTypeCreateApiResponse,
      IdentityClaimTypeCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/claim-types`,
        method: "POST",
        body: queryArg.voloAbpIdentityCreateClaimTypeDto,
      }),
    }),
    identityClaimTypeGet: build.query<
      IdentityClaimTypeGetApiResponse,
      IdentityClaimTypeGetApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/claim-types/${queryArg.id}`,
      }),
    }),
    identityClaimTypeUpdate: build.mutation<
      IdentityClaimTypeUpdateApiResponse,
      IdentityClaimTypeUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/claim-types/${queryArg.id}`,
        method: "PUT",
        body: queryArg.voloAbpIdentityUpdateClaimTypeDto,
      }),
    }),
    identityClaimTypeDelete: build.mutation<
      IdentityClaimTypeDeleteApiResponse,
      IdentityClaimTypeDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/claim-types/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    dataExportTriggerUserExportEmail: build.mutation<
      DataExportTriggerUserExportEmailApiResponse,
      DataExportTriggerUserExportEmailApiArg
    >({
      query: () => ({
        url: `/api/app/data-export/trigger-user-export-email`,
        method: "POST",
      }),
    }),
    dataExportTriggerFacilitatorExportEmail: build.mutation<
      DataExportTriggerFacilitatorExportEmailApiResponse,
      DataExportTriggerFacilitatorExportEmailApiArg
    >({
      query: () => ({
        url: `/api/app/data-export/trigger-facilitator-export-email`,
        method: "POST",
      }),
    }),
    dataExportTriggerParticipantExportEmail: build.mutation<
      DataExportTriggerParticipantExportEmailApiResponse,
      DataExportTriggerParticipantExportEmailApiArg
    >({
      query: () => ({
        url: `/api/app/data-export/trigger-participant-export-email`,
        method: "POST",
      }),
    }),
    dataExportTriggerCourseTemplateExportEmail: build.mutation<
      DataExportTriggerCourseTemplateExportEmailApiResponse,
      DataExportTriggerCourseTemplateExportEmailApiArg
    >({
      query: () => ({
        url: `/api/app/data-export/trigger-course-template-export-email`,
        method: "POST",
      }),
    }),
    dataExportTriggerCourseProgramExportEmail: build.mutation<
      DataExportTriggerCourseProgramExportEmailApiResponse,
      DataExportTriggerCourseProgramExportEmailApiArg
    >({
      query: () => ({
        url: `/api/app/data-export/trigger-course-program-export-email`,
        method: "POST",
      }),
    }),
    dataExportTriggerCourseProgramParticipantExportEmail: build.mutation<
      DataExportTriggerCourseProgramParticipantExportEmailApiResponse,
      DataExportTriggerCourseProgramParticipantExportEmailApiArg
    >({
      query: () => ({
        url: `/api/app/data-export/trigger-course-program-participant-export-email`,
        method: "POST",
      }),
    }),
    dataImportTriggerParticipantImport: build.mutation<
      DataImportTriggerParticipantImportApiResponse,
      DataImportTriggerParticipantImportApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/data-import/trigger-participant-import`,
        method: "POST",
        body: queryArg.activ8DataDataImportTriggerParticipantDataImportRequest,
      }),
    }),
    dataImportPrepareImportFileWrite: build.mutation<
      DataImportPrepareImportFileWriteApiResponse,
      DataImportPrepareImportFileWriteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/data-import/prepare-import-file-write`,
        method: "POST",
        params: {
          contentType: queryArg.contentType,
          contentFileName: queryArg.contentFileName,
          remoteIp: queryArg.remoteIp,
        },
      }),
    }),
    directoryDescriptorGet: build.query<
      DirectoryDescriptorGetApiResponse,
      DirectoryDescriptorGetApiArg
    >({
      query: (queryArg) => ({
        url: `/api/file-management/directory-descriptor/${queryArg.id}`,
      }),
    }),
    directoryDescriptorRename: build.mutation<
      DirectoryDescriptorRenameApiResponse,
      DirectoryDescriptorRenameApiArg
    >({
      query: (queryArg) => ({
        url: `/api/file-management/directory-descriptor/${queryArg.id}`,
        method: "POST",
        body: queryArg.voloFileManagementDirectoriesRenameDirectoryInput,
      }),
    }),
    directoryDescriptorDelete: build.mutation<
      DirectoryDescriptorDeleteApiResponse,
      DirectoryDescriptorDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/file-management/directory-descriptor/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    directoryDescriptorGetList: build.query<
      DirectoryDescriptorGetListApiResponse,
      DirectoryDescriptorGetListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/file-management/directory-descriptor/sub-directories`,
        params: { parentId: queryArg.parentId },
      }),
    }),
    directoryDescriptorCreate: build.mutation<
      DirectoryDescriptorCreateApiResponse,
      DirectoryDescriptorCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/file-management/directory-descriptor`,
        method: "POST",
        body: queryArg.voloFileManagementDirectoriesCreateDirectoryInput,
      }),
    }),
    directoryDescriptorGetContent: build.query<
      DirectoryDescriptorGetContentApiResponse,
      DirectoryDescriptorGetContentApiArg
    >({
      query: (queryArg) => ({
        url: `/api/file-management/directory-descriptor`,
        params: {
          Filter: queryArg.filter,
          Id: queryArg.id,
          Sorting: queryArg.sorting,
          SkipCount: queryArg.skipCount,
          MaxResultCount: queryArg.maxResultCount,
        },
      }),
    }),
    directoryDescriptorMove: build.mutation<
      DirectoryDescriptorMoveApiResponse,
      DirectoryDescriptorMoveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/file-management/directory-descriptor/move`,
        method: "POST",
        body: queryArg.voloFileManagementDirectoriesMoveDirectoryInput,
      }),
    }),
    dynamicClaimsRefresh: build.mutation<
      DynamicClaimsRefreshApiResponse,
      DynamicClaimsRefreshApiArg
    >({
      query: () => ({
        url: `/api/account/dynamic-claims/refresh`,
        method: "POST",
      }),
    }),
    editionGet: build.query<EditionGetApiResponse, EditionGetApiArg>({
      query: (queryArg) => ({ url: `/api/saas/editions/${queryArg.id}` }),
    }),
    editionUpdate: build.mutation<
      EditionUpdateApiResponse,
      EditionUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/saas/editions/${queryArg.id}`,
        method: "PUT",
        body: queryArg.voloSaasHostDtosEditionUpdateDto,
      }),
    }),
    editionDelete: build.mutation<
      EditionDeleteApiResponse,
      EditionDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/saas/editions/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    editionGetList: build.query<
      EditionGetListApiResponse,
      EditionGetListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/saas/editions`,
        params: {
          Filter: queryArg.filter,
          Sorting: queryArg.sorting,
          SkipCount: queryArg.skipCount,
          MaxResultCount: queryArg.maxResultCount,
        },
      }),
    }),
    editionCreate: build.mutation<
      EditionCreateApiResponse,
      EditionCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/saas/editions`,
        method: "POST",
        body: queryArg.voloSaasHostDtosEditionCreateDto,
      }),
    }),
    editionMoveAllTenants: build.mutation<
      EditionMoveAllTenantsApiResponse,
      EditionMoveAllTenantsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/saas/editions/${queryArg.id}/move-all-tenants`,
        method: "PUT",
        params: { editionId: queryArg.editionId },
      }),
    }),
    editionGetAllList: build.query<
      EditionGetAllListApiResponse,
      EditionGetAllListApiArg
    >({
      query: () => ({ url: `/api/saas/editions/all` }),
    }),
    editionGetUsageStatistics: build.query<
      EditionGetUsageStatisticsApiResponse,
      EditionGetUsageStatisticsApiArg
    >({
      query: () => ({ url: `/api/saas/editions/statistics/usage-statistic` }),
    }),
    editionGetPlanLookup: build.query<
      EditionGetPlanLookupApiResponse,
      EditionGetPlanLookupApiArg
    >({
      query: () => ({ url: `/api/saas/editions/plan-lookup` }),
    }),
    subscriptionCreateSubscription: build.mutation<
      SubscriptionCreateSubscriptionApiResponse,
      SubscriptionCreateSubscriptionApiArg
    >({
      query: (queryArg) => ({
        url: `/api/saas/subscription`,
        method: "POST",
        params: { editionId: queryArg.editionId, tenantId: queryArg.tenantId },
      }),
    }),
    emailSettingsGet: build.query<
      EmailSettingsGetApiResponse,
      EmailSettingsGetApiArg
    >({
      query: () => ({ url: `/api/setting-management/emailing` }),
    }),
    emailSettingsUpdate: build.mutation<
      EmailSettingsUpdateApiResponse,
      EmailSettingsUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/setting-management/emailing`,
        method: "POST",
        body: queryArg.voloAbpSettingManagementUpdateEmailSettingsDto,
      }),
    }),
    emailSettingsSendTestEmail: build.mutation<
      EmailSettingsSendTestEmailApiResponse,
      EmailSettingsSendTestEmailApiArg
    >({
      query: (queryArg) => ({
        url: `/api/setting-management/emailing/send-test-email`,
        method: "POST",
        body: queryArg.voloAbpSettingManagementSendTestEmailInput,
      }),
    }),
    identityExternalLoginCreateOrUpdate: build.mutation<
      IdentityExternalLoginCreateOrUpdateApiResponse,
      IdentityExternalLoginCreateOrUpdateApiArg
    >({
      query: () => ({ url: `/api/identity/external-login`, method: "POST" }),
    }),
    facilitatorCreate: build.mutation<
      FacilitatorCreateApiResponse,
      FacilitatorCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/facilitator`,
        method: "POST",
        body: queryArg.activ8FacilitatorsFacilitatorCreateDto,
      }),
    }),
    facilitatorGetList: build.query<
      FacilitatorGetListApiResponse,
      FacilitatorGetListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/facilitator`,
        params: {
          AssignedPersonAttributeIds: queryArg.assignedPersonAttributeIds,
          AssignedPersonAccreditationIds:
            queryArg.assignedPersonAccreditationIds,
          Filter: queryArg.filter,
          RoleId: queryArg.roleId,
          OrganizationUnitId: queryArg.organizationUnitId,
          UserName: queryArg.userName,
          PhoneNumber: queryArg.phoneNumber,
          EmailAddress: queryArg.emailAddress,
          Name: queryArg.name,
          Surname: queryArg.surname,
          IsLockedOut: queryArg.isLockedOut,
          NotActive: queryArg.notActive,
          EmailConfirmed: queryArg.emailConfirmed,
          IsExternal: queryArg.isExternal,
          MaxCreationTime: queryArg.maxCreationTime,
          MinCreationTime: queryArg.minCreationTime,
          MaxModifitionTime: queryArg.maxModifitionTime,
          MinModifitionTime: queryArg.minModifitionTime,
          Sorting: queryArg.sorting,
          SkipCount: queryArg.skipCount,
          MaxResultCount: queryArg.maxResultCount,
          ExtraProperties: queryArg.extraProperties,
        },
      }),
    }),
    facilitatorUpdate: build.mutation<
      FacilitatorUpdateApiResponse,
      FacilitatorUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/facilitator/${queryArg.id}`,
        method: "PUT",
        body: queryArg.activ8FacilitatorsFacilitatorUpdateDto,
      }),
    }),
    facilitatorGet: build.query<
      FacilitatorGetApiResponse,
      FacilitatorGetApiArg
    >({
      query: (queryArg) => ({ url: `/api/app/facilitator/${queryArg.id}` }),
    }),
    facilitatorDelete: build.mutation<
      FacilitatorDeleteApiResponse,
      FacilitatorDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/facilitator/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    featuresGet: build.query<FeaturesGetApiResponse, FeaturesGetApiArg>({
      query: (queryArg) => ({
        url: `/api/feature-management/features`,
        params: {
          providerName: queryArg.providerName,
          providerKey: queryArg.providerKey,
        },
      }),
    }),
    featuresUpdate: build.mutation<
      FeaturesUpdateApiResponse,
      FeaturesUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/feature-management/features`,
        method: "PUT",
        body: queryArg.voloAbpFeatureManagementUpdateFeaturesDto,
        params: {
          providerName: queryArg.providerName,
          providerKey: queryArg.providerKey,
        },
      }),
    }),
    featuresDelete: build.mutation<
      FeaturesDeleteApiResponse,
      FeaturesDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/feature-management/features`,
        method: "DELETE",
        params: {
          providerName: queryArg.providerName,
          providerKey: queryArg.providerKey,
        },
      }),
    }),
    fileDescriptorGet: build.query<
      FileDescriptorGetApiResponse,
      FileDescriptorGetApiArg
    >({
      query: (queryArg) => ({
        url: `/api/file-management/file-descriptor/${queryArg.id}`,
      }),
    }),
    fileDescriptorRename: build.mutation<
      FileDescriptorRenameApiResponse,
      FileDescriptorRenameApiArg
    >({
      query: (queryArg) => ({
        url: `/api/file-management/file-descriptor/${queryArg.id}`,
        method: "POST",
        body: queryArg.voloFileManagementFilesRenameFileInput,
      }),
    }),
    fileDescriptorDelete: build.mutation<
      FileDescriptorDeleteApiResponse,
      FileDescriptorDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/file-management/file-descriptor/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    fileDescriptorGetList: build.query<
      FileDescriptorGetListApiResponse,
      FileDescriptorGetListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/file-management/file-descriptor`,
        params: { directoryId: queryArg.directoryId },
      }),
    }),
    fileDescriptorCreate: build.mutation<
      FileDescriptorCreateApiResponse,
      FileDescriptorCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/file-management/file-descriptor/upload`,
        method: "POST",
        body: queryArg.body,
        params: {
          directoryId: queryArg.directoryId,
          Name: queryArg.name,
          OverrideExisting: queryArg.overrideExisting,
          ExtraProperties: queryArg.extraProperties,
        },
      }),
    }),
    fileDescriptorMove: build.mutation<
      FileDescriptorMoveApiResponse,
      FileDescriptorMoveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/file-management/file-descriptor/move`,
        method: "POST",
        body: queryArg.voloFileManagementFilesMoveFileInput,
      }),
    }),
    fileDescriptorGetPreInfo: build.mutation<
      FileDescriptorGetPreInfoApiResponse,
      FileDescriptorGetPreInfoApiArg
    >({
      query: (queryArg) => ({
        url: `/api/file-management/file-descriptor/pre-upload-info`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    fileDescriptorGetContent: build.query<
      FileDescriptorGetContentApiResponse,
      FileDescriptorGetContentApiArg
    >({
      query: (queryArg) => ({
        url: `/api/file-management/file-descriptor/content`,
        params: { id: queryArg.id },
      }),
    }),
    fileDescriptorGetDownloadToken: build.query<
      FileDescriptorGetDownloadTokenApiResponse,
      FileDescriptorGetDownloadTokenApiArg
    >({
      query: (queryArg) => ({
        url: `/api/file-management/file-descriptor/download/${queryArg.id}/token`,
      }),
    }),
    fileDescriptorDownload: build.query<
      FileDescriptorDownloadApiResponse,
      FileDescriptorDownloadApiArg
    >({
      query: (queryArg) => ({
        url: `/api/file-management/file-descriptor/download/${queryArg.id}`,
        params: { token: queryArg.token },
      }),
    }),
    languageGetAllList: build.query<
      LanguageGetAllListApiResponse,
      LanguageGetAllListApiArg
    >({
      query: () => ({ url: `/api/language-management/languages/all` }),
    }),
    languageGetList: build.query<
      LanguageGetListApiResponse,
      LanguageGetListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/language-management/languages`,
        params: {
          Filter: queryArg.filter,
          ResourceName: queryArg.resourceName,
          BaseCultureName: queryArg.baseCultureName,
          TargetCultureName: queryArg.targetCultureName,
          GetOnlyEmptyValues: queryArg.getOnlyEmptyValues,
          Sorting: queryArg.sorting,
          SkipCount: queryArg.skipCount,
          MaxResultCount: queryArg.maxResultCount,
        },
      }),
    }),
    languageCreate: build.mutation<
      LanguageCreateApiResponse,
      LanguageCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/language-management/languages`,
        method: "POST",
        body: queryArg.voloAbpLanguageManagementDtoCreateLanguageDto,
      }),
    }),
    languageGet: build.query<LanguageGetApiResponse, LanguageGetApiArg>({
      query: (queryArg) => ({
        url: `/api/language-management/languages/${queryArg.id}`,
      }),
    }),
    languageUpdate: build.mutation<
      LanguageUpdateApiResponse,
      LanguageUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/language-management/languages/${queryArg.id}`,
        method: "PUT",
        body: queryArg.voloAbpLanguageManagementDtoUpdateLanguageDto,
      }),
    }),
    languageDelete: build.mutation<
      LanguageDeleteApiResponse,
      LanguageDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/language-management/languages/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    languageSetAsDefault: build.mutation<
      LanguageSetAsDefaultApiResponse,
      LanguageSetAsDefaultApiArg
    >({
      query: (queryArg) => ({
        url: `/api/language-management/languages/${queryArg.id}/set-as-default`,
        method: "PUT",
      }),
    }),
    languageGetResources: build.query<
      LanguageGetResourcesApiResponse,
      LanguageGetResourcesApiArg
    >({
      query: () => ({ url: `/api/language-management/languages/resources` }),
    }),
    languageGetCulturelist: build.query<
      LanguageGetCulturelistApiResponse,
      LanguageGetCulturelistApiArg
    >({
      query: () => ({ url: `/api/language-management/languages/culture-list` }),
    }),
    languageTextGetList: build.query<
      LanguageTextGetListApiResponse,
      LanguageTextGetListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/language-management/language-texts`,
        params: {
          Filter: queryArg.filter,
          ResourceName: queryArg.resourceName,
          BaseCultureName: queryArg.baseCultureName,
          TargetCultureName: queryArg.targetCultureName,
          GetOnlyEmptyValues: queryArg.getOnlyEmptyValues,
          Sorting: queryArg.sorting,
          SkipCount: queryArg.skipCount,
          MaxResultCount: queryArg.maxResultCount,
        },
      }),
    }),
    languageTextGet: build.query<
      LanguageTextGetApiResponse,
      LanguageTextGetApiArg
    >({
      query: (queryArg) => ({
        url: `/api/language-management/language-texts/${queryArg.resourceName}/${queryArg.cultureName}/${queryArg.name}`,
        params: { baseCultureName: queryArg.baseCultureName },
      }),
    }),
    languageTextUpdate: build.mutation<
      LanguageTextUpdateApiResponse,
      LanguageTextUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/language-management/language-texts/${queryArg.resourceName}/${queryArg.cultureName}/${queryArg.name}`,
        method: "PUT",
        params: { value: queryArg.value },
      }),
    }),
    languageTextRestoreToDefault: build.mutation<
      LanguageTextRestoreToDefaultApiResponse,
      LanguageTextRestoreToDefaultApiArg
    >({
      query: (queryArg) => ({
        url: `/api/language-management/language-texts/${queryArg.resourceName}/${queryArg.cultureName}/${queryArg.name}/restore`,
        method: "PUT",
      }),
    }),
    learningCourseProgramUpdate: build.mutation<
      LearningCourseProgramUpdateApiResponse,
      LearningCourseProgramUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/learning-course-program/${queryArg.id}`,
        method: "PUT",
        body: queryArg.activ8CoursesProgramsCoursesLearningCourseProgramUpdateDto,
      }),
    }),
    learningCourseProgramGet: build.query<
      LearningCourseProgramGetApiResponse,
      LearningCourseProgramGetApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/learning-course-program/${queryArg.id}`,
      }),
    }),
    learningCourseProgramDelete: build.mutation<
      LearningCourseProgramDeleteApiResponse,
      LearningCourseProgramDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/learning-course-program/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    learningCourseProgramCreate: build.mutation<
      LearningCourseProgramCreateApiResponse,
      LearningCourseProgramCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/learning-course-program`,
        method: "POST",
        body: queryArg.activ8CoursesProgramsCoursesLearningCourseProgramCreateDto,
      }),
    }),
    learningCourseProgramGetList: build.query<
      LearningCourseProgramGetListApiResponse,
      LearningCourseProgramGetListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/learning-course-program`,
        params: {
          FilterText: queryArg.filterText,
          AssignedToFacilitatorIds: queryArg.assignedToFacilitatorIds,
          AssignedToParticipantIds: queryArg.assignedToParticipantIds,
          Statuses: queryArg.statuses,
          IncludeStatistics: queryArg.includeStatistics,
          Sorting: queryArg.sorting,
          SkipCount: queryArg.skipCount,
          MaxResultCount: queryArg.maxResultCount,
        },
      }),
    }),
    learningCourseProgramSetProgramStatus: build.mutation<
      LearningCourseProgramSetProgramStatusApiResponse,
      LearningCourseProgramSetProgramStatusApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/learning-course-program/set-program-status`,
        method: "POST",
        body: queryArg.activ8CoursesProgramsCoursesProgramStatusSetRequestDto,
      }),
    }),
    learningCourseProgramCloneCourseProgram: build.mutation<
      LearningCourseProgramCloneCourseProgramApiResponse,
      LearningCourseProgramCloneCourseProgramApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/learning-course-program/clone-course-program/${queryArg.courseProgramId}`,
        method: "POST",
      }),
    }),
    learningCourseProgramAssignParticipantToProgram: build.mutation<
      LearningCourseProgramAssignParticipantToProgramApiResponse,
      LearningCourseProgramAssignParticipantToProgramApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/learning-course-program/assign-participant-to-program`,
        method: "POST",
        params: {
          courseProgramId: queryArg.courseProgramId,
          participantIdentityUserId: queryArg.participantIdentityUserId,
        },
      }),
    }),
    learningCourseProgramUnassignParticipantToProgram: build.mutation<
      LearningCourseProgramUnassignParticipantToProgramApiResponse,
      LearningCourseProgramUnassignParticipantToProgramApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/learning-course-program/unassign-participant-to-program`,
        method: "DELETE",
        params: {
          courseProgramId: queryArg.courseProgramId,
          participantIdentityUserId: queryArg.participantIdentityUserId,
        },
      }),
    }),
    learningCourseProgramGenerateUrlForDownloadCourseContent: build.query<
      LearningCourseProgramGenerateUrlForDownloadCourseContentApiResponse,
      LearningCourseProgramGenerateUrlForDownloadCourseContentApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/learning-course-program/generate-url-for-download-course-content/${queryArg.courseProgramId}`,
      }),
    }),
    learningCourseProgramFacilitatorGet: build.query<
      LearningCourseProgramFacilitatorGetApiResponse,
      LearningCourseProgramFacilitatorGetApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/learning-course-program-facilitator/${queryArg.id}`,
      }),
    }),
    learningCourseProgramFacilitatorUpdate: build.mutation<
      LearningCourseProgramFacilitatorUpdateApiResponse,
      LearningCourseProgramFacilitatorUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/learning-course-program-facilitator/${queryArg.id}`,
        method: "PUT",
        body: queryArg.activ8CoursesProgramsFacilitatorsFacilitatorToLearningCourseProgramAssignmentUpdateDto,
      }),
    }),
    learningCourseProgramFacilitatorDelete: build.mutation<
      LearningCourseProgramFacilitatorDeleteApiResponse,
      LearningCourseProgramFacilitatorDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/learning-course-program-facilitator/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    learningCourseProgramFacilitatorGetList: build.query<
      LearningCourseProgramFacilitatorGetListApiResponse,
      LearningCourseProgramFacilitatorGetListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/learning-course-program-facilitator`,
        params: {
          FilterText: queryArg.filterText,
          FacilitatorIdentityUserIds: queryArg.facilitatorIdentityUserIds,
          LearningCourseProgramIds: queryArg.learningCourseProgramIds,
          IncludeProgramDetails: queryArg.includeProgramDetails,
          IncludeFacilitatorDetails: queryArg.includeFacilitatorDetails,
          ProgramStatuses: queryArg.programStatuses,
          Sorting: queryArg.sorting,
          SkipCount: queryArg.skipCount,
          MaxResultCount: queryArg.maxResultCount,
        },
      }),
    }),
    learningCourseProgramFacilitatorCreate: build.mutation<
      LearningCourseProgramFacilitatorCreateApiResponse,
      LearningCourseProgramFacilitatorCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/learning-course-program-facilitator`,
        method: "POST",
        body: queryArg.activ8CoursesProgramsFacilitatorsFacilitatorToLearningCourseProgramAssignmentCreateDto,
      }),
    }),
    learningCourseProgramParticipantGetList: build.query<
      LearningCourseProgramParticipantGetListApiResponse,
      LearningCourseProgramParticipantGetListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/learning-course-program-participant`,
        params: {
          FilterText: queryArg.filterText,
          LearningCourseProgramIds: queryArg.learningCourseProgramIds,
          ParticipantIdentityUserIds: queryArg.participantIdentityUserIds,
          IncludeProgramDetails: queryArg.includeProgramDetails,
          IncludeParticipantDetails: queryArg.includeParticipantDetails,
          ProgramStatuses: queryArg.programStatuses,
          Sorting: queryArg.sorting,
          SkipCount: queryArg.skipCount,
          MaxResultCount: queryArg.maxResultCount,
        },
      }),
    }),
    learningCourseProgramParticipantCreate: build.mutation<
      LearningCourseProgramParticipantCreateApiResponse,
      LearningCourseProgramParticipantCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/learning-course-program-participant`,
        method: "POST",
        body: queryArg.activ8CoursesProgramsParticipantsParticipantToLearningCourseProgramAssignmentCreateDto,
      }),
    }),
    learningCourseProgramParticipantGet: build.query<
      LearningCourseProgramParticipantGetApiResponse,
      LearningCourseProgramParticipantGetApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/learning-course-program-participant/${queryArg.id}`,
      }),
    }),
    learningCourseProgramParticipantUpdate: build.mutation<
      LearningCourseProgramParticipantUpdateApiResponse,
      LearningCourseProgramParticipantUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/learning-course-program-participant/${queryArg.id}`,
        method: "PUT",
        body: queryArg.activ8CoursesProgramsParticipantsParticipantToLearningCourseProgramAssignmentUpdateDto,
      }),
    }),
    learningCourseProgramParticipantDelete: build.mutation<
      LearningCourseProgramParticipantDeleteApiResponse,
      LearningCourseProgramParticipantDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/learning-course-program-participant/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    learningCourseProgramParticipantGetByProgramAndParticipant: build.query<
      LearningCourseProgramParticipantGetByProgramAndParticipantApiResponse,
      LearningCourseProgramParticipantGetByProgramAndParticipantApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/learning-course-program-participant/by-program-and-participant`,
        params: {
          programId: queryArg.programId,
          participantId: queryArg.participantId,
        },
      }),
    }),
    learningCourseProgramSessionPrepareSessionContent: build.query<
      LearningCourseProgramSessionPrepareSessionContentApiResponse,
      LearningCourseProgramSessionPrepareSessionContentApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/learning-course-program-session/prepare-session-content`,
        params: {
          courseProgramId: queryArg.courseProgramId,
          courseProgramChapterId: queryArg.courseProgramChapterId,
          courseProgramChapterSessionId: queryArg.courseProgramChapterSessionId,
        },
      }),
    }),
    learningCourseProgramSessionProgressThroughSessionContent: build.mutation<
      LearningCourseProgramSessionProgressThroughSessionContentApiResponse,
      LearningCourseProgramSessionProgressThroughSessionContentApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/learning-course-program-session/progress-through-session-content`,
        method: "POST",
        body: queryArg.activ8CoursesProgramsSessionsProgressThroughSessionContentRequest,
      }),
    }),
    learningCourseProgramSessionProgressThroughSessionContentBatch:
      build.mutation<
        LearningCourseProgramSessionProgressThroughSessionContentBatchApiResponse,
        LearningCourseProgramSessionProgressThroughSessionContentBatchApiArg
      >({
        query: (queryArg) => ({
          url: `/api/app/learning-course-program-session/progress-through-session-content-batch`,
          method: "POST",
          body: queryArg.activ8CoursesProgramsSessionsProgressThroughSessionContentBatchRequest,
        }),
      }),
    learningCourseTemplateSetTemplateStatus: build.mutation<
      LearningCourseTemplateSetTemplateStatusApiResponse,
      LearningCourseTemplateSetTemplateStatusApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/learning-course-template/set-template-status`,
        method: "POST",
        body: queryArg.activ8CoursesTemplatesTemplateStatusSetRequestDto,
      }),
    }),
    learningCourseTemplateGetRoleNamesApplicableToCourseAssignments:
      build.query<
        LearningCourseTemplateGetRoleNamesApplicableToCourseAssignmentsApiResponse,
        LearningCourseTemplateGetRoleNamesApplicableToCourseAssignmentsApiArg
      >({
        query: () => ({
          url: `/api/app/learning-course-template/role-names-applicable-to-course-assignments`,
        }),
      }),
    learningCourseTemplateDelete: build.mutation<
      LearningCourseTemplateDeleteApiResponse,
      LearningCourseTemplateDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/learning-course-template/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    learningCourseTemplateGet: build.query<
      LearningCourseTemplateGetApiResponse,
      LearningCourseTemplateGetApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/learning-course-template/${queryArg.id}`,
      }),
    }),
    learningCourseTemplateUpdate: build.mutation<
      LearningCourseTemplateUpdateApiResponse,
      LearningCourseTemplateUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/learning-course-template/${queryArg.id}`,
        method: "PUT",
        body: queryArg.activ8CoursesTemplatesLearningCourseTemplateUpdateDto,
      }),
    }),
    learningCourseTemplateCloneCourseTemplate: build.mutation<
      LearningCourseTemplateCloneCourseTemplateApiResponse,
      LearningCourseTemplateCloneCourseTemplateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/learning-course-template/clone-course-template/${queryArg.courseTemplateId}`,
        method: "POST",
      }),
    }),
    learningCourseTemplateGetTemplatePartnerLicensing: build.query<
      LearningCourseTemplateGetTemplatePartnerLicensingApiResponse,
      LearningCourseTemplateGetTemplatePartnerLicensingApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/learning-course-template/template-partner-licensing/${queryArg.courseTemplateId}`,
      }),
    }),
    learningCourseTemplatePrepareResourceWrite: build.mutation<
      LearningCourseTemplatePrepareResourceWriteApiResponse,
      LearningCourseTemplatePrepareResourceWriteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/learning-course-template/prepare-resource-write`,
        method: "POST",
        params: {
          courseTemplateId: queryArg.courseTemplateId,
          resourceId: queryArg.resourceId,
          contentType: queryArg.contentType,
          contentFileName: queryArg.contentFileName,
        },
      }),
    }),
    learningCourseTemplatePrepareResourceRead: build.query<
      LearningCourseTemplatePrepareResourceReadApiResponse,
      LearningCourseTemplatePrepareResourceReadApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/learning-course-template/prepare-resource-read`,
        params: {
          courseTemplateId: queryArg.courseTemplateId,
          resourceId: queryArg.resourceId,
          isDownload: queryArg.isDownload,
          contentType: queryArg.contentType,
          contentFileName: queryArg.contentFileName,
        },
      }),
    }),
    learningCourseTemplateCreate: build.mutation<
      LearningCourseTemplateCreateApiResponse,
      LearningCourseTemplateCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/learning-course-template`,
        method: "POST",
        body: queryArg.activ8CoursesTemplatesLearningCourseTemplateCreateDto,
      }),
    }),
    learningCourseTemplateGetList: build.query<
      LearningCourseTemplateGetListApiResponse,
      LearningCourseTemplateGetListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/learning-course-template`,
        params: {
          FilterText: queryArg.filterText,
          LicensedToTenantIds: queryArg.licensedToTenantIds,
          EnrollmentAllowedForIdentityRoleNames:
            queryArg.enrollmentAllowedForIdentityRoleNames,
          Statuses: queryArg.statuses,
          Sorting: queryArg.sorting,
          SkipCount: queryArg.skipCount,
          MaxResultCount: queryArg.maxResultCount,
        },
      }),
    }),
    learningCourseTemplateGetCountOfLiveProgramsUsingCourseTemplate:
      build.query<
        LearningCourseTemplateGetCountOfLiveProgramsUsingCourseTemplateApiResponse,
        LearningCourseTemplateGetCountOfLiveProgramsUsingCourseTemplateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/app/learning-course-template/count-of-live-programs-using-course-template/${queryArg.courseTemplateId}`,
        }),
      }),
    learningCourseTemplateGetTemplateContent: build.query<
      LearningCourseTemplateGetTemplateContentApiResponse,
      LearningCourseTemplateGetTemplateContentApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/learning-course-template/template-content/${queryArg.courseTemplateId}`,
      }),
    }),
    learningCourseTemplateGenerateUrlForDownloadCourseContent: build.query<
      LearningCourseTemplateGenerateUrlForDownloadCourseContentApiResponse,
      LearningCourseTemplateGenerateUrlForDownloadCourseContentApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/learning-course-template/generate-url-for-download-course-content/${queryArg.courseTemplateId}`,
      }),
    }),
    organizationUnitAddRoles: build.mutation<
      OrganizationUnitAddRolesApiResponse,
      OrganizationUnitAddRolesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/organization-units/${queryArg.id}/roles`,
        method: "PUT",
        body: queryArg.voloAbpIdentityOrganizationUnitRoleInput,
      }),
    }),
    organizationUnitGetRoles: build.query<
      OrganizationUnitGetRolesApiResponse,
      OrganizationUnitGetRolesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/organization-units/${queryArg.id}/roles`,
        params: {
          Sorting: queryArg.sorting,
          SkipCount: queryArg.skipCount,
          MaxResultCount: queryArg.maxResultCount,
        },
      }),
    }),
    organizationUnitAddMembers: build.mutation<
      OrganizationUnitAddMembersApiResponse,
      OrganizationUnitAddMembersApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/organization-units/${queryArg.id}/members`,
        method: "PUT",
        body: queryArg.voloAbpIdentityOrganizationUnitUserInput,
      }),
    }),
    organizationUnitGetMembers: build.query<
      OrganizationUnitGetMembersApiResponse,
      OrganizationUnitGetMembersApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/organization-units/${queryArg.id}/members`,
        params: {
          Filter: queryArg.filter,
          RoleId: queryArg.roleId,
          OrganizationUnitId: queryArg.organizationUnitId,
          UserName: queryArg.userName,
          PhoneNumber: queryArg.phoneNumber,
          EmailAddress: queryArg.emailAddress,
          Name: queryArg.name,
          Surname: queryArg.surname,
          IsLockedOut: queryArg.isLockedOut,
          NotActive: queryArg.notActive,
          EmailConfirmed: queryArg.emailConfirmed,
          IsExternal: queryArg.isExternal,
          MaxCreationTime: queryArg.maxCreationTime,
          MinCreationTime: queryArg.minCreationTime,
          MaxModifitionTime: queryArg.maxModifitionTime,
          MinModifitionTime: queryArg.minModifitionTime,
          Sorting: queryArg.sorting,
          SkipCount: queryArg.skipCount,
          MaxResultCount: queryArg.maxResultCount,
          ExtraProperties: queryArg.extraProperties,
        },
      }),
    }),
    organizationUnitCreate: build.mutation<
      OrganizationUnitCreateApiResponse,
      OrganizationUnitCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/organization-units`,
        method: "POST",
        body: queryArg.voloAbpIdentityOrganizationUnitCreateDto,
      }),
    }),
    organizationUnitDelete: build.mutation<
      OrganizationUnitDeleteApiResponse,
      OrganizationUnitDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/organization-units`,
        method: "DELETE",
        params: { id: queryArg.id },
      }),
    }),
    organizationUnitGetList: build.query<
      OrganizationUnitGetListApiResponse,
      OrganizationUnitGetListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/organization-units`,
        params: {
          Filter: queryArg.filter,
          Sorting: queryArg.sorting,
          SkipCount: queryArg.skipCount,
          MaxResultCount: queryArg.maxResultCount,
          ExtraProperties: queryArg.extraProperties,
        },
      }),
    }),
    organizationUnitGet: build.query<
      OrganizationUnitGetApiResponse,
      OrganizationUnitGetApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/organization-units/${queryArg.id}`,
      }),
    }),
    organizationUnitUpdate: build.mutation<
      OrganizationUnitUpdateApiResponse,
      OrganizationUnitUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/organization-units/${queryArg.id}`,
        method: "PUT",
        body: queryArg.voloAbpIdentityOrganizationUnitUpdateDto,
      }),
    }),
    organizationUnitGetListAll: build.query<
      OrganizationUnitGetListAllApiResponse,
      OrganizationUnitGetListAllApiArg
    >({
      query: () => ({ url: `/api/identity/organization-units/all` }),
    }),
    organizationUnitMove: build.mutation<
      OrganizationUnitMoveApiResponse,
      OrganizationUnitMoveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/organization-units/${queryArg.id}/move`,
        method: "PUT",
        body: queryArg.voloAbpIdentityOrganizationUnitMoveInput,
      }),
    }),
    organizationUnitGetAvailableUsers: build.query<
      OrganizationUnitGetAvailableUsersApiResponse,
      OrganizationUnitGetAvailableUsersApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/organization-units/available-users`,
        params: {
          Filter: queryArg.filter,
          Id: queryArg.id,
          Sorting: queryArg.sorting,
          SkipCount: queryArg.skipCount,
          MaxResultCount: queryArg.maxResultCount,
          ExtraProperties: queryArg.extraProperties,
        },
      }),
    }),
    organizationUnitGetAvailableRoles: build.query<
      OrganizationUnitGetAvailableRolesApiResponse,
      OrganizationUnitGetAvailableRolesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/organization-units/available-roles`,
        params: {
          Filter: queryArg.filter,
          Id: queryArg.id,
          Sorting: queryArg.sorting,
          SkipCount: queryArg.skipCount,
          MaxResultCount: queryArg.maxResultCount,
          ExtraProperties: queryArg.extraProperties,
        },
      }),
    }),
    organizationUnitMoveAllUsers: build.mutation<
      OrganizationUnitMoveAllUsersApiResponse,
      OrganizationUnitMoveAllUsersApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/organization-units/${queryArg.id}/move-all-users`,
        method: "PUT",
        params: { organizationId: queryArg.organizationId },
      }),
    }),
    organizationUnitRemoveMember: build.mutation<
      OrganizationUnitRemoveMemberApiResponse,
      OrganizationUnitRemoveMemberApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/organization-units/${queryArg.id}/members/${queryArg.memberId}`,
        method: "DELETE",
      }),
    }),
    organizationUnitRemoveRole: build.mutation<
      OrganizationUnitRemoveRoleApiResponse,
      OrganizationUnitRemoveRoleApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/organization-units/${queryArg.id}/roles/${queryArg.roleId}`,
        method: "DELETE",
      }),
    }),
    participantCreate: build.mutation<
      ParticipantCreateApiResponse,
      ParticipantCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/participant`,
        method: "POST",
        body: queryArg.activ8ParticipantsParticipantCreateDto,
      }),
    }),
    participantGetList: build.query<
      ParticipantGetListApiResponse,
      ParticipantGetListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/participant`,
        params: {
          AssignedPersonAttributeIds: queryArg.assignedPersonAttributeIds,
          AssignedPersonAccreditationIds:
            queryArg.assignedPersonAccreditationIds,
          Filter: queryArg.filter,
          RoleId: queryArg.roleId,
          OrganizationUnitId: queryArg.organizationUnitId,
          UserName: queryArg.userName,
          PhoneNumber: queryArg.phoneNumber,
          EmailAddress: queryArg.emailAddress,
          Name: queryArg.name,
          Surname: queryArg.surname,
          IsLockedOut: queryArg.isLockedOut,
          NotActive: queryArg.notActive,
          EmailConfirmed: queryArg.emailConfirmed,
          IsExternal: queryArg.isExternal,
          MaxCreationTime: queryArg.maxCreationTime,
          MinCreationTime: queryArg.minCreationTime,
          MaxModifitionTime: queryArg.maxModifitionTime,
          MinModifitionTime: queryArg.minModifitionTime,
          Sorting: queryArg.sorting,
          SkipCount: queryArg.skipCount,
          MaxResultCount: queryArg.maxResultCount,
          ExtraProperties: queryArg.extraProperties,
        },
      }),
    }),
    participantUpdate: build.mutation<
      ParticipantUpdateApiResponse,
      ParticipantUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/participant/${queryArg.id}`,
        method: "PUT",
        body: queryArg.activ8ParticipantsParticipantUpdateDto,
      }),
    }),
    participantGet: build.query<
      ParticipantGetApiResponse,
      ParticipantGetApiArg
    >({
      query: (queryArg) => ({ url: `/api/app/participant/${queryArg.id}` }),
    }),
    participantDelete: build.mutation<
      ParticipantDeleteApiResponse,
      ParticipantDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/participant/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    participantRemoveIdentityUserLink: build.mutation<
      ParticipantRemoveIdentityUserLinkApiResponse,
      ParticipantRemoveIdentityUserLinkApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/participant/remove-identity-user-link`,
        method: "POST",
        params: {
          parentIdentityUserId: queryArg.parentIdentityUserId,
          childIdentityUserId: queryArg.childIdentityUserId,
        },
      }),
    }),
    participantMaintainIdentityUserLinks: build.mutation<
      ParticipantMaintainIdentityUserLinksApiResponse,
      ParticipantMaintainIdentityUserLinksApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/participant/maintain-identity-user-links`,
        method: "POST",
        params: {
          parentIdentityUserId: queryArg.parentIdentityUserId,
          childIdentityUserId: queryArg.childIdentityUserId,
          unlinkAllChildParentLinks: queryArg.unlinkAllChildParentLinks,
        },
      }),
    }),
    partnerAppNotificationGetAll: build.query<
      PartnerAppNotificationGetAllApiResponse,
      PartnerAppNotificationGetAllApiArg
    >({
      query: () => ({ url: `/api/app/partner-app-notification` }),
    }),
    partnerDashboardGet: build.query<
      PartnerDashboardGetApiResponse,
      PartnerDashboardGetApiArg
    >({
      query: () => ({ url: `/api/app/partner-dashboard` }),
    }),
    partnerManagementEnsurePartnerSetup: build.mutation<
      PartnerManagementEnsurePartnerSetupApiResponse,
      PartnerManagementEnsurePartnerSetupApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/partner-management/ensure-partner-setup/${queryArg.tenantId}`,
        method: "POST",
      }),
    }),
    partnerProfileGet: build.query<
      PartnerProfileGetApiResponse,
      PartnerProfileGetApiArg
    >({
      query: () => ({ url: `/api/app/partner-profile` }),
    }),
    partnerProfileSet: build.mutation<
      PartnerProfileSetApiResponse,
      PartnerProfileSetApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/partner-profile/set`,
        method: "POST",
        body: queryArg.activ8PartnersProfilePartnerProfileDetailSetRequest,
      }),
    }),
    permissionsGet: build.query<
      PermissionsGetApiResponse,
      PermissionsGetApiArg
    >({
      query: (queryArg) => ({
        url: `/api/permission-management/permissions`,
        params: {
          providerName: queryArg.providerName,
          providerKey: queryArg.providerKey,
        },
      }),
    }),
    permissionsUpdate: build.mutation<
      PermissionsUpdateApiResponse,
      PermissionsUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/permission-management/permissions`,
        method: "PUT",
        body: queryArg.voloAbpPermissionManagementUpdatePermissionsDto,
        params: {
          providerName: queryArg.providerName,
          providerKey: queryArg.providerKey,
        },
      }),
    }),
    personAccreditationGetAssignmentsForUser: build.query<
      PersonAccreditationGetAssignmentsForUserApiResponse,
      PersonAccreditationGetAssignmentsForUserApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/person-accreditation/assignments-for-user/${queryArg.userId}`,
      }),
    }),
    personAccreditationSetAssignmentsForUser: build.mutation<
      PersonAccreditationSetAssignmentsForUserApiResponse,
      PersonAccreditationSetAssignmentsForUserApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/person-accreditation/set-assignments-for-user/${queryArg.userId}`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    personAccreditationGetList: build.query<
      PersonAccreditationGetListApiResponse,
      PersonAccreditationGetListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/person-accreditation`,
        params: {
          FilterText: queryArg.filterText,
          Sorting: queryArg.sorting,
          SkipCount: queryArg.skipCount,
          MaxResultCount: queryArg.maxResultCount,
        },
      }),
    }),
    personAccreditationCreate: build.mutation<
      PersonAccreditationCreateApiResponse,
      PersonAccreditationCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/person-accreditation`,
        method: "POST",
        body: queryArg.activ8PersonAccreditationsPersonAccreditationCreateOrUpdateDto,
      }),
    }),
    personAccreditationUpdate: build.mutation<
      PersonAccreditationUpdateApiResponse,
      PersonAccreditationUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/person-accreditation/${queryArg.id}`,
        method: "PUT",
        body: queryArg.activ8PersonAccreditationsPersonAccreditationCreateOrUpdateDto,
      }),
    }),
    personAccreditationDelete: build.mutation<
      PersonAccreditationDeleteApiResponse,
      PersonAccreditationDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/person-accreditation/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    personAccreditationGet: build.query<
      PersonAccreditationGetApiResponse,
      PersonAccreditationGetApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/person-accreditation/${queryArg.id}`,
      }),
    }),
    personAttributeGetAssignmentsForUser: build.query<
      PersonAttributeGetAssignmentsForUserApiResponse,
      PersonAttributeGetAssignmentsForUserApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/person-attribute/assignments-for-user/${queryArg.userId}`,
      }),
    }),
    personAttributeSetAssignmentsForUser: build.mutation<
      PersonAttributeSetAssignmentsForUserApiResponse,
      PersonAttributeSetAssignmentsForUserApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/person-attribute/set-assignments-for-user/${queryArg.userId}`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    personAttributeGetList: build.query<
      PersonAttributeGetListApiResponse,
      PersonAttributeGetListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/person-attribute`,
        params: {
          FilterText: queryArg.filterText,
          Sorting: queryArg.sorting,
          SkipCount: queryArg.skipCount,
          MaxResultCount: queryArg.maxResultCount,
        },
      }),
    }),
    personAttributeCreate: build.mutation<
      PersonAttributeCreateApiResponse,
      PersonAttributeCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/person-attribute`,
        method: "POST",
        body: queryArg.activ8PersonAttributesPersonAttributeCreateOrUpdateDto,
      }),
    }),
    personAttributeUpdate: build.mutation<
      PersonAttributeUpdateApiResponse,
      PersonAttributeUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/person-attribute/${queryArg.id}`,
        method: "PUT",
        body: queryArg.activ8PersonAttributesPersonAttributeCreateOrUpdateDto,
      }),
    }),
    personAttributeDelete: build.mutation<
      PersonAttributeDeleteApiResponse,
      PersonAttributeDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/person-attribute/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    personAttributeGet: build.query<
      PersonAttributeGetApiResponse,
      PersonAttributeGetApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/person-attribute/${queryArg.id}`,
      }),
    }),
    profileGet: build.query<ProfileGetApiResponse, ProfileGetApiArg>({
      query: () => ({ url: `/api/account/my-profile` }),
    }),
    profileUpdate: build.mutation<
      ProfileUpdateApiResponse,
      ProfileUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/account/my-profile`,
        method: "PUT",
        body: queryArg.voloAbpAccountUpdateProfileDto,
      }),
    }),
    profileChangePassword: build.mutation<
      ProfileChangePasswordApiResponse,
      ProfileChangePasswordApiArg
    >({
      query: (queryArg) => ({
        url: `/api/account/my-profile/change-password`,
        method: "POST",
        body: queryArg.voloAbpAccountChangePasswordInput,
      }),
    }),
    profileGetTwoFactorEnabled: build.query<
      ProfileGetTwoFactorEnabledApiResponse,
      ProfileGetTwoFactorEnabledApiArg
    >({
      query: () => ({ url: `/api/account/my-profile/two-factor-enabled` }),
    }),
    profileSetTwoFactorEnabled: build.mutation<
      ProfileSetTwoFactorEnabledApiResponse,
      ProfileSetTwoFactorEnabledApiArg
    >({
      query: (queryArg) => ({
        url: `/api/account/my-profile/set-two-factor-enabled`,
        method: "POST",
        params: { enabled: queryArg.enabled },
      }),
    }),
    profileCanEnableTwoFactor: build.query<
      ProfileCanEnableTwoFactorApiResponse,
      ProfileCanEnableTwoFactorApiArg
    >({
      query: () => ({ url: `/api/account/my-profile/can-enable-two-factor` }),
    }),
    profileGetTimezones: build.query<
      ProfileGetTimezonesApiResponse,
      ProfileGetTimezonesApiArg
    >({
      query: () => ({ url: `/api/account/my-profile/timezones` }),
    }),
    identityRoleGet: build.query<
      IdentityRoleGetApiResponse,
      IdentityRoleGetApiArg
    >({
      query: (queryArg) => ({ url: `/api/identity/roles/${queryArg.id}` }),
    }),
    identityRoleUpdate: build.mutation<
      IdentityRoleUpdateApiResponse,
      IdentityRoleUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/roles/${queryArg.id}`,
        method: "PUT",
        body: queryArg.voloAbpIdentityIdentityRoleUpdateDto,
      }),
    }),
    identityRoleDelete: build.mutation<
      IdentityRoleDeleteApiResponse,
      IdentityRoleDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/roles/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    identityRoleCreate: build.mutation<
      IdentityRoleCreateApiResponse,
      IdentityRoleCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/roles`,
        method: "POST",
        body: queryArg.voloAbpIdentityIdentityRoleCreateDto,
      }),
    }),
    identityRoleGetList: build.query<
      IdentityRoleGetListApiResponse,
      IdentityRoleGetListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/roles`,
        params: {
          Filter: queryArg.filter,
          Sorting: queryArg.sorting,
          SkipCount: queryArg.skipCount,
          MaxResultCount: queryArg.maxResultCount,
          ExtraProperties: queryArg.extraProperties,
        },
      }),
    }),
    identityRoleGetAllList: build.query<
      IdentityRoleGetAllListApiResponse,
      IdentityRoleGetAllListApiArg
    >({
      query: () => ({ url: `/api/identity/roles/all` }),
    }),
    identityRoleUpdateClaims: build.mutation<
      IdentityRoleUpdateClaimsApiResponse,
      IdentityRoleUpdateClaimsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/roles/${queryArg.id}/claims`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    identityRoleGetClaims: build.query<
      IdentityRoleGetClaimsApiResponse,
      IdentityRoleGetClaimsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/roles/${queryArg.id}/claims`,
      }),
    }),
    identityRoleMoveAllUsers: build.mutation<
      IdentityRoleMoveAllUsersApiResponse,
      IdentityRoleMoveAllUsersApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/roles/${queryArg.id}/move-all-users`,
        method: "PUT",
        params: { roleId: queryArg.roleId },
      }),
    }),
    identityRoleGetAllClaimTypes: build.query<
      IdentityRoleGetAllClaimTypesApiResponse,
      IdentityRoleGetAllClaimTypesApiArg
    >({
      query: () => ({ url: `/api/identity/roles/all-claim-types` }),
    }),
    scopeGet: build.query<ScopeGetApiResponse, ScopeGetApiArg>({
      query: (queryArg) => ({ url: `/api/openiddict/scopes/${queryArg.id}` }),
    }),
    scopeUpdate: build.mutation<ScopeUpdateApiResponse, ScopeUpdateApiArg>({
      query: (queryArg) => ({
        url: `/api/openiddict/scopes/${queryArg.id}`,
        method: "PUT",
        body: queryArg.voloAbpOpenIddictScopesDtosUpdateScopeInput,
      }),
    }),
    scopeGetList: build.query<ScopeGetListApiResponse, ScopeGetListApiArg>({
      query: (queryArg) => ({
        url: `/api/openiddict/scopes`,
        params: {
          Filter: queryArg.filter,
          Sorting: queryArg.sorting,
          SkipCount: queryArg.skipCount,
          MaxResultCount: queryArg.maxResultCount,
        },
      }),
    }),
    scopeCreate: build.mutation<ScopeCreateApiResponse, ScopeCreateApiArg>({
      query: (queryArg) => ({
        url: `/api/openiddict/scopes`,
        method: "POST",
        body: queryArg.voloAbpOpenIddictScopesDtosCreateScopeInput,
      }),
    }),
    scopeDelete: build.mutation<ScopeDeleteApiResponse, ScopeDeleteApiArg>({
      query: (queryArg) => ({
        url: `/api/openiddict/scopes`,
        method: "DELETE",
        params: { id: queryArg.id },
      }),
    }),
    scopeGetAllScopes: build.query<
      ScopeGetAllScopesApiResponse,
      ScopeGetAllScopesApiArg
    >({
      query: () => ({ url: `/api/openiddict/scopes/all` }),
    }),
    identitySecurityLogGetList: build.query<
      IdentitySecurityLogGetListApiResponse,
      IdentitySecurityLogGetListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/security-logs`,
        params: {
          StartTime: queryArg.startTime,
          EndTime: queryArg.endTime,
          ApplicationName: queryArg.applicationName,
          Identity: queryArg.identity,
          Action: queryArg.action,
          UserName: queryArg.userName,
          ClientId: queryArg.clientId,
          CorrelationId: queryArg.correlationId,
          Sorting: queryArg.sorting,
          SkipCount: queryArg.skipCount,
          MaxResultCount: queryArg.maxResultCount,
          ExtraProperties: queryArg.extraProperties,
        },
      }),
    }),
    identitySecurityLogGet: build.query<
      IdentitySecurityLogGetApiResponse,
      IdentitySecurityLogGetApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/security-logs/${queryArg.id}`,
      }),
    }),
    accountSessionGetList: build.query<
      AccountSessionGetListApiResponse,
      AccountSessionGetListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/account/sessions`,
        params: {
          Device: queryArg.device,
          ClientId: queryArg.clientId,
          Sorting: queryArg.sorting,
          SkipCount: queryArg.skipCount,
          MaxResultCount: queryArg.maxResultCount,
          ExtraProperties: queryArg.extraProperties,
        },
      }),
    }),
    accountSessionGet: build.query<
      AccountSessionGetApiResponse,
      AccountSessionGetApiArg
    >({
      query: (queryArg) => ({ url: `/api/account/sessions/${queryArg.id}` }),
    }),
    accountSessionRevoke: build.mutation<
      AccountSessionRevokeApiResponse,
      AccountSessionRevokeApiArg
    >({
      query: (queryArg) => ({
        url: `/api/account/sessions/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    identitySessionGetList: build.query<
      IdentitySessionGetListApiResponse,
      IdentitySessionGetListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/sessions`,
        params: {
          UserId: queryArg.userId,
          Device: queryArg.device,
          ClientId: queryArg.clientId,
          Sorting: queryArg.sorting,
          SkipCount: queryArg.skipCount,
          MaxResultCount: queryArg.maxResultCount,
          ExtraProperties: queryArg.extraProperties,
        },
      }),
    }),
    identitySessionGet: build.query<
      IdentitySessionGetApiResponse,
      IdentitySessionGetApiArg
    >({
      query: (queryArg) => ({ url: `/api/identity/sessions/${queryArg.id}` }),
    }),
    identitySessionRevoke: build.mutation<
      IdentitySessionRevokeApiResponse,
      IdentitySessionRevokeApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/sessions/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    auditLogSettingsGet: build.query<
      AuditLogSettingsGetApiResponse,
      AuditLogSettingsGetApiArg
    >({
      query: () => ({ url: `/api/audit-logging/settings` }),
    }),
    auditLogSettingsUpdate: build.mutation<
      AuditLogSettingsUpdateApiResponse,
      AuditLogSettingsUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/audit-logging/settings`,
        method: "PUT",
        body: queryArg.voloAbpAuditLoggingAuditLogSettingsDto,
      }),
    }),
    auditLogSettingsGetGlobal: build.query<
      AuditLogSettingsGetGlobalApiResponse,
      AuditLogSettingsGetGlobalApiArg
    >({
      query: () => ({ url: `/api/audit-logging/settings/global` }),
    }),
    auditLogSettingsUpdateGlobal: build.mutation<
      AuditLogSettingsUpdateGlobalApiResponse,
      AuditLogSettingsUpdateGlobalApiArg
    >({
      query: (queryArg) => ({
        url: `/api/audit-logging/settings/global`,
        method: "PUT",
        body: queryArg.voloAbpAuditLoggingAuditLogGlobalSettingsDto,
      }),
    }),
    identitySettingsGet: build.query<
      IdentitySettingsGetApiResponse,
      IdentitySettingsGetApiArg
    >({
      query: () => ({ url: `/api/identity/settings` }),
    }),
    identitySettingsUpdate: build.mutation<
      IdentitySettingsUpdateApiResponse,
      IdentitySettingsUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/settings`,
        method: "PUT",
        body: queryArg.voloAbpIdentityIdentitySettingsDto,
      }),
    }),
    identitySettingsGetLdap: build.query<
      IdentitySettingsGetLdapApiResponse,
      IdentitySettingsGetLdapApiArg
    >({
      query: () => ({ url: `/api/identity/settings/ldap` }),
    }),
    identitySettingsUpdateLdap: build.mutation<
      IdentitySettingsUpdateLdapApiResponse,
      IdentitySettingsUpdateLdapApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/settings/ldap`,
        method: "PUT",
        body: queryArg.voloAbpIdentityIdentityLdapSettingsDto,
      }),
    }),
    identitySettingsGetOAuth: build.query<
      IdentitySettingsGetOAuthApiResponse,
      IdentitySettingsGetOAuthApiArg
    >({
      query: () => ({ url: `/api/identity/settings/oauth` }),
    }),
    identitySettingsUpdateOAuth: build.mutation<
      IdentitySettingsUpdateOAuthApiResponse,
      IdentitySettingsUpdateOAuthApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/settings/oauth`,
        method: "PUT",
        body: queryArg.voloAbpIdentityIdentityOAuthSettingsDto,
      }),
    }),
    identitySettingsGetSession: build.query<
      IdentitySettingsGetSessionApiResponse,
      IdentitySettingsGetSessionApiArg
    >({
      query: () => ({ url: `/api/identity/settings/session` }),
    }),
    identitySettingsUpdateSession: build.mutation<
      IdentitySettingsUpdateSessionApiResponse,
      IdentitySettingsUpdateSessionApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/settings/session`,
        method: "PUT",
        body: queryArg.voloAbpIdentityIdentitySessionSettingsDto,
      }),
    }),
    tenantGet: build.query<TenantGetApiResponse, TenantGetApiArg>({
      query: (queryArg) => ({ url: `/api/saas/tenants/${queryArg.id}` }),
    }),
    tenantUpdate: build.mutation<TenantUpdateApiResponse, TenantUpdateApiArg>({
      query: (queryArg) => ({
        url: `/api/saas/tenants/${queryArg.id}`,
        method: "PUT",
        body: queryArg.activ8ControllersSaasTenantUpdateDtoWithExtraPropertiesWrite,
      }),
    }),
    tenantDelete: build.mutation<TenantDeleteApiResponse, TenantDeleteApiArg>({
      query: (queryArg) => ({
        url: `/api/saas/tenants/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    tenantGetList: build.query<TenantGetListApiResponse, TenantGetListApiArg>({
      query: (queryArg) => ({
        url: `/api/saas/tenants`,
        params: {
          Filter: queryArg.filter,
          GetEditionNames: queryArg.getEditionNames,
          EditionId: queryArg.editionId,
          ExpirationDateMin: queryArg.expirationDateMin,
          ExpirationDateMax: queryArg.expirationDateMax,
          ActivationState: queryArg.activationState,
          ActivationEndDateMin: queryArg.activationEndDateMin,
          ActivationEndDateMax: queryArg.activationEndDateMax,
          Sorting: queryArg.sorting,
          SkipCount: queryArg.skipCount,
          MaxResultCount: queryArg.maxResultCount,
        },
      }),
    }),
    tenantCreate: build.mutation<TenantCreateApiResponse, TenantCreateApiArg>({
      query: (queryArg) => ({
        url: `/api/saas/tenants`,
        method: "POST",
        body: queryArg.activ8ControllersSaasTenantCreateDtoWithExtraPropertiesWrite,
      }),
    }),
    tenantGetDatabases: build.query<
      TenantGetDatabasesApiResponse,
      TenantGetDatabasesApiArg
    >({
      query: () => ({ url: `/api/saas/tenants/databases` }),
    }),
    tenantGetConnectionStrings: build.query<
      TenantGetConnectionStringsApiResponse,
      TenantGetConnectionStringsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/saas/tenants/${queryArg.id}/connection-strings`,
      }),
    }),
    tenantUpdateConnectionStrings: build.mutation<
      TenantUpdateConnectionStringsApiResponse,
      TenantUpdateConnectionStringsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/saas/tenants/${queryArg.id}/connection-strings`,
        method: "PUT",
        body: queryArg.voloSaasHostDtosSaasTenantConnectionStringsDto,
      }),
    }),
    tenantApplyDatabaseMigrations: build.mutation<
      TenantApplyDatabaseMigrationsApiResponse,
      TenantApplyDatabaseMigrationsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/saas/tenants/${queryArg.id}/apply-database-migrations`,
        method: "POST",
      }),
    }),
    tenantGetEditionLookup: build.query<
      TenantGetEditionLookupApiResponse,
      TenantGetEditionLookupApiArg
    >({
      query: () => ({ url: `/api/saas/tenants/lookup/editions` }),
    }),
    tenantCheckConnectionString: build.query<
      TenantCheckConnectionStringApiResponse,
      TenantCheckConnectionStringApiArg
    >({
      query: (queryArg) => ({
        url: `/api/saas/tenants/check-connection-string`,
        params: { connectionString: queryArg.connectionString },
      }),
    }),
    tenantSetPassword: build.mutation<
      TenantSetPasswordApiResponse,
      TenantSetPasswordApiArg
    >({
      query: (queryArg) => ({
        url: `/api/saas/tenants/${queryArg.id}/set-password`,
        method: "PUT",
        body: queryArg.voloSaasHostDtosSaasTenantSetPasswordDto,
      }),
    }),
    timezoneLookupGetAllIana: build.query<
      TimezoneLookupGetAllIanaApiResponse,
      TimezoneLookupGetAllIanaApiArg
    >({
      query: () => ({ url: `/api/app/timezone-lookup/iana` }),
    }),
    timezoneLookupGetAllWindows: build.query<
      TimezoneLookupGetAllWindowsApiResponse,
      TimezoneLookupGetAllWindowsApiArg
    >({
      query: () => ({ url: `/api/app/timezone-lookup/windows` }),
    }),
    timeZoneSettingsGet: build.query<
      TimeZoneSettingsGetApiResponse,
      TimeZoneSettingsGetApiArg
    >({
      query: () => ({ url: `/api/setting-management/timezone` }),
    }),
    timeZoneSettingsUpdate: build.mutation<
      TimeZoneSettingsUpdateApiResponse,
      TimeZoneSettingsUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/setting-management/timezone`,
        method: "POST",
        params: { timezone: queryArg.timezone },
      }),
    }),
    timeZoneSettingsGetTimezones: build.query<
      TimeZoneSettingsGetTimezonesApiResponse,
      TimeZoneSettingsGetTimezonesApiArg
    >({
      query: () => ({ url: `/api/setting-management/timezone/timezones` }),
    }),
    identityUserGet: build.query<
      IdentityUserGetApiResponse,
      IdentityUserGetApiArg
    >({
      query: (queryArg) => ({ url: `/api/identity/users/${queryArg.id}` }),
    }),
    identityUserUpdate: build.mutation<
      IdentityUserUpdateApiResponse,
      IdentityUserUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/users/${queryArg.id}`,
        method: "PUT",
        body: queryArg.activ8ControllersIdentityUserUpdateDtoWithExtraPropertiesWrite,
      }),
    }),
    identityUserDelete: build.mutation<
      IdentityUserDeleteApiResponse,
      IdentityUserDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/users/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    identityUserGetList: build.query<
      IdentityUserGetListApiResponse,
      IdentityUserGetListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/users`,
        params: {
          Filter: queryArg.filter,
          RoleId: queryArg.roleId,
          OrganizationUnitId: queryArg.organizationUnitId,
          UserName: queryArg.userName,
          PhoneNumber: queryArg.phoneNumber,
          EmailAddress: queryArg.emailAddress,
          Name: queryArg.name,
          Surname: queryArg.surname,
          IsLockedOut: queryArg.isLockedOut,
          NotActive: queryArg.notActive,
          EmailConfirmed: queryArg.emailConfirmed,
          IsExternal: queryArg.isExternal,
          MaxCreationTime: queryArg.maxCreationTime,
          MinCreationTime: queryArg.minCreationTime,
          MaxModifitionTime: queryArg.maxModifitionTime,
          MinModifitionTime: queryArg.minModifitionTime,
          Sorting: queryArg.sorting,
          SkipCount: queryArg.skipCount,
          MaxResultCount: queryArg.maxResultCount,
          ExtraProperties: queryArg.extraProperties,
        },
      }),
    }),
    identityUserCreate: build.mutation<
      IdentityUserCreateApiResponse,
      IdentityUserCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/users`,
        method: "POST",
        body: queryArg.activ8ControllersIdentityUserCreateDtoWithExtraPropertiesWrite,
      }),
    }),
    identityUserSetUserTimezone: build.mutation<
      IdentityUserSetUserTimezoneApiResponse,
      IdentityUserSetUserTimezoneApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/users/timezone`,
        method: "PUT",
        params: { ianaTimezoneId: queryArg.ianaTimezoneId },
      }),
    }),
    identityUserFindById: build.query<
      IdentityUserFindByIdApiResponse,
      IdentityUserFindByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/users/by-id/${queryArg.id}`,
      }),
    }),
    identityUserGetRoles: build.query<
      IdentityUserGetRolesApiResponse,
      IdentityUserGetRolesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/users/${queryArg.id}/roles`,
      }),
    }),
    identityUserUpdateRoles: build.mutation<
      IdentityUserUpdateRolesApiResponse,
      IdentityUserUpdateRolesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/users/${queryArg.id}/roles`,
        method: "PUT",
        body: queryArg.voloAbpIdentityIdentityUserUpdateRolesDto,
      }),
    }),
    identityUserGetAssignableRoles: build.query<
      IdentityUserGetAssignableRolesApiResponse,
      IdentityUserGetAssignableRolesApiArg
    >({
      query: () => ({ url: `/api/identity/users/assignable-roles` }),
    }),
    identityUserGetAvailableOrganizationUnits: build.query<
      IdentityUserGetAvailableOrganizationUnitsApiResponse,
      IdentityUserGetAvailableOrganizationUnitsApiArg
    >({
      query: () => ({
        url: `/api/identity/users/available-organization-units`,
      }),
    }),
    identityUserGetAllClaimTypes: build.query<
      IdentityUserGetAllClaimTypesApiResponse,
      IdentityUserGetAllClaimTypesApiArg
    >({
      query: () => ({ url: `/api/identity/users/all-claim-types` }),
    }),
    identityUserGetClaims: build.query<
      IdentityUserGetClaimsApiResponse,
      IdentityUserGetClaimsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/users/${queryArg.id}/claims`,
      }),
    }),
    identityUserUpdateClaims: build.mutation<
      IdentityUserUpdateClaimsApiResponse,
      IdentityUserUpdateClaimsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/users/${queryArg.id}/claims`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    identityUserGetOrganizationUnits: build.query<
      IdentityUserGetOrganizationUnitsApiResponse,
      IdentityUserGetOrganizationUnitsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/users/${queryArg.id}/organization-units`,
      }),
    }),
    identityUserLock: build.mutation<
      IdentityUserLockApiResponse,
      IdentityUserLockApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/users/${queryArg.id}/lock/${queryArg.lockoutEnd}`,
        method: "PUT",
      }),
    }),
    identityUserUnlock: build.mutation<
      IdentityUserUnlockApiResponse,
      IdentityUserUnlockApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/users/${queryArg.id}/unlock`,
        method: "PUT",
      }),
    }),
    identityUserFindByUsername: build.query<
      IdentityUserFindByUsernameApiResponse,
      IdentityUserFindByUsernameApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/users/by-username/${queryArg.username}`,
      }),
    }),
    identityUserFindByEmail: build.query<
      IdentityUserFindByEmailApiResponse,
      IdentityUserFindByEmailApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/users/by-email/${queryArg.email}`,
      }),
    }),
    identityUserGetTwoFactorEnabled: build.query<
      IdentityUserGetTwoFactorEnabledApiResponse,
      IdentityUserGetTwoFactorEnabledApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/users/${queryArg.id}/two-factor-enabled`,
      }),
    }),
    identityUserSetTwoFactorEnabled: build.mutation<
      IdentityUserSetTwoFactorEnabledApiResponse,
      IdentityUserSetTwoFactorEnabledApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/users/${queryArg.id}/two-factor/${queryArg.enabled}`,
        method: "PUT",
      }),
    }),
    identityUserUpdatePassword: build.mutation<
      IdentityUserUpdatePasswordApiResponse,
      IdentityUserUpdatePasswordApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/users/${queryArg.id}/change-password`,
        method: "PUT",
        body: queryArg.voloAbpIdentityIdentityUserUpdatePasswordInput,
      }),
    }),
    identityUserGetRoleLookup: build.query<
      IdentityUserGetRoleLookupApiResponse,
      IdentityUserGetRoleLookupApiArg
    >({
      query: () => ({ url: `/api/identity/users/lookup/roles` }),
    }),
    identityUserGetOrganizationUnitLookup: build.query<
      IdentityUserGetOrganizationUnitLookupApiResponse,
      IdentityUserGetOrganizationUnitLookupApiArg
    >({
      query: () => ({ url: `/api/identity/users/lookup/organization-units` }),
    }),
    identityUserGetExternalLoginProviders: build.query<
      IdentityUserGetExternalLoginProvidersApiResponse,
      IdentityUserGetExternalLoginProvidersApiArg
    >({
      query: () => ({ url: `/api/identity/users/external-login-Providers` }),
    }),
    identityUserImportExternalUser: build.mutation<
      IdentityUserImportExternalUserApiResponse,
      IdentityUserImportExternalUserApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/users/import-external-user`,
        method: "POST",
        body: queryArg.voloAbpIdentityImportExternalUserInput,
      }),
    }),
    identityUserGetListAsExcelFile: build.query<
      IdentityUserGetListAsExcelFileApiResponse,
      IdentityUserGetListAsExcelFileApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/users/export-as-excel`,
        params: {
          Token: queryArg.token,
          Filter: queryArg.filter,
          RoleId: queryArg.roleId,
          OrganizationUnitId: queryArg.organizationUnitId,
          UserName: queryArg.userName,
          PhoneNumber: queryArg.phoneNumber,
          EmailAddress: queryArg.emailAddress,
          Name: queryArg.name,
          Surname: queryArg.surname,
          IsLockedOut: queryArg.isLockedOut,
          NotActive: queryArg.notActive,
          EmailConfirmed: queryArg.emailConfirmed,
          IsExternal: queryArg.isExternal,
          MaxCreationTime: queryArg.maxCreationTime,
          MinCreationTime: queryArg.minCreationTime,
          MaxModifitionTime: queryArg.maxModifitionTime,
          MinModifitionTime: queryArg.minModifitionTime,
          Sorting: queryArg.sorting,
          SkipCount: queryArg.skipCount,
          MaxResultCount: queryArg.maxResultCount,
          ExtraProperties: queryArg.extraProperties,
        },
      }),
    }),
    identityUserGetListAsCsvFile: build.query<
      IdentityUserGetListAsCsvFileApiResponse,
      IdentityUserGetListAsCsvFileApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/users/export-as-csv`,
        params: {
          Token: queryArg.token,
          Filter: queryArg.filter,
          RoleId: queryArg.roleId,
          OrganizationUnitId: queryArg.organizationUnitId,
          UserName: queryArg.userName,
          PhoneNumber: queryArg.phoneNumber,
          EmailAddress: queryArg.emailAddress,
          Name: queryArg.name,
          Surname: queryArg.surname,
          IsLockedOut: queryArg.isLockedOut,
          NotActive: queryArg.notActive,
          EmailConfirmed: queryArg.emailConfirmed,
          IsExternal: queryArg.isExternal,
          MaxCreationTime: queryArg.maxCreationTime,
          MinCreationTime: queryArg.minCreationTime,
          MaxModifitionTime: queryArg.maxModifitionTime,
          MinModifitionTime: queryArg.minModifitionTime,
          Sorting: queryArg.sorting,
          SkipCount: queryArg.skipCount,
          MaxResultCount: queryArg.maxResultCount,
          ExtraProperties: queryArg.extraProperties,
        },
      }),
    }),
    identityUserGetDownloadToken: build.query<
      IdentityUserGetDownloadTokenApiResponse,
      IdentityUserGetDownloadTokenApiArg
    >({
      query: () => ({ url: `/api/identity/users/download-token` }),
    }),
    identityUserGetImportUsersSampleFile: build.query<
      IdentityUserGetImportUsersSampleFileApiResponse,
      IdentityUserGetImportUsersSampleFileApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/users/import-users-sample-file`,
        params: { FileType: queryArg.fileType, Token: queryArg.token },
      }),
    }),
    identityUserImportUsersFromFile: build.mutation<
      IdentityUserImportUsersFromFileApiResponse,
      IdentityUserImportUsersFromFileApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/users/import-users-from-file`,
        method: "POST",
        body: queryArg.body,
        params: { FileType: queryArg.fileType },
      }),
    }),
    identityUserGetImportInvalidUsersFile: build.query<
      IdentityUserGetImportInvalidUsersFileApiResponse,
      IdentityUserGetImportInvalidUsersFileApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/users/download-import-invalid-users-file`,
        params: { Token: queryArg.token },
      }),
    }),
    identityLinkUserLink: build.mutation<
      IdentityLinkUserLinkApiResponse,
      IdentityLinkUserLinkApiArg
    >({
      query: (queryArg) => ({
        url: `/api/account/link-user/link`,
        method: "POST",
        body: queryArg.voloAbpAccountLinkUserInput,
      }),
    }),
    identityLinkUserUnlink: build.mutation<
      IdentityLinkUserUnlinkApiResponse,
      IdentityLinkUserUnlinkApiArg
    >({
      query: (queryArg) => ({
        url: `/api/account/link-user/unlink`,
        method: "POST",
        body: queryArg.voloAbpAccountUnLinkUserInput,
      }),
    }),
    identityLinkUserIsLinked: build.mutation<
      IdentityLinkUserIsLinkedApiResponse,
      IdentityLinkUserIsLinkedApiArg
    >({
      query: (queryArg) => ({
        url: `/api/account/link-user/is-linked`,
        method: "POST",
        body: queryArg.voloAbpAccountIsLinkedInput,
      }),
    }),
    identityLinkUserGenerateLinkToken: build.mutation<
      IdentityLinkUserGenerateLinkTokenApiResponse,
      IdentityLinkUserGenerateLinkTokenApiArg
    >({
      query: () => ({
        url: `/api/account/link-user/generate-link-token`,
        method: "POST",
      }),
    }),
    identityLinkUserVerifyLinkToken: build.mutation<
      IdentityLinkUserVerifyLinkTokenApiResponse,
      IdentityLinkUserVerifyLinkTokenApiArg
    >({
      query: (queryArg) => ({
        url: `/api/account/link-user/verify-link-token`,
        method: "POST",
        body: queryArg.voloAbpAccountVerifyLinkTokenInput,
      }),
    }),
    identityLinkUserGenerateLinkLoginToken: build.mutation<
      IdentityLinkUserGenerateLinkLoginTokenApiResponse,
      IdentityLinkUserGenerateLinkLoginTokenApiArg
    >({
      query: () => ({
        url: `/api/account/link-user/generate-link-login-token`,
        method: "POST",
      }),
    }),
    identityLinkUserVerifyLinkLoginToken: build.mutation<
      IdentityLinkUserVerifyLinkLoginTokenApiResponse,
      IdentityLinkUserVerifyLinkLoginTokenApiArg
    >({
      query: (queryArg) => ({
        url: `/api/account/link-user/verify-link-login-token`,
        method: "POST",
        body: queryArg.voloAbpAccountVerifyLinkLoginTokenInput,
      }),
    }),
    identityLinkUserGetAllList: build.query<
      IdentityLinkUserGetAllListApiResponse,
      IdentityLinkUserGetAllListApiArg
    >({
      query: () => ({ url: `/api/account/link-user` }),
    }),
    identityUserDelegationGetDelegatedUsers: build.query<
      IdentityUserDelegationGetDelegatedUsersApiResponse,
      IdentityUserDelegationGetDelegatedUsersApiArg
    >({
      query: () => ({ url: `/api/account/user-delegation/delegated-users` }),
    }),
    identityUserDelegationGetMyDelegatedUsers: build.query<
      IdentityUserDelegationGetMyDelegatedUsersApiResponse,
      IdentityUserDelegationGetMyDelegatedUsersApiArg
    >({
      query: () => ({ url: `/api/account/user-delegation/my-delegated-users` }),
    }),
    identityUserDelegationGetActiveDelegations: build.query<
      IdentityUserDelegationGetActiveDelegationsApiResponse,
      IdentityUserDelegationGetActiveDelegationsApiArg
    >({
      query: () => ({ url: `/api/account/user-delegation/active-delegations` }),
    }),
    identityUserDelegationGetUserLookup: build.query<
      IdentityUserDelegationGetUserLookupApiResponse,
      IdentityUserDelegationGetUserLookupApiArg
    >({
      query: (queryArg) => ({
        url: `/api/account/user-delegation/user-lookup`,
        params: { UserName: queryArg.userName },
      }),
    }),
    identityUserDelegationDelegateNewUser: build.mutation<
      IdentityUserDelegationDelegateNewUserApiResponse,
      IdentityUserDelegationDelegateNewUserApiArg
    >({
      query: (queryArg) => ({
        url: `/api/account/user-delegation/delegate-new-user`,
        method: "POST",
        body: queryArg.voloAbpAccountDelegateNewUserInput,
      }),
    }),
    identityUserDelegationDeleteDelegation: build.mutation<
      IdentityUserDelegationDeleteDelegationApiResponse,
      IdentityUserDelegationDeleteDelegationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/account/user-delegation/delete-delegation`,
        method: "POST",
        params: { id: queryArg.id },
      }),
    }),
    userLinkageShareUserWithAnotherTenant: build.mutation<
      UserLinkageShareUserWithAnotherTenantApiResponse,
      UserLinkageShareUserWithAnotherTenantApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/user-linkage/share-user-with-another-tenant`,
        method: "POST",
        body: queryArg.activ8UsersShareUserWithAnotherPartnerRequest,
      }),
    }),
    userLinkageGetAllUserLinkages: build.query<
      UserLinkageGetAllUserLinkagesApiResponse,
      UserLinkageGetAllUserLinkagesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/user-linkage/user-linkages`,
        params: { userId: queryArg.userId, tenantId: queryArg.tenantId },
      }),
    }),
    identityUserLookupFindById: build.query<
      IdentityUserLookupFindByIdApiResponse,
      IdentityUserLookupFindByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/users/lookup/${queryArg.id}`,
      }),
    }),
    identityUserLookupFindByUserName: build.query<
      IdentityUserLookupFindByUserNameApiResponse,
      IdentityUserLookupFindByUserNameApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/users/lookup/by-username/${queryArg.userName}`,
      }),
    }),
    identityUserLookupSearch: build.query<
      IdentityUserLookupSearchApiResponse,
      IdentityUserLookupSearchApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/users/lookup/search`,
        params: {
          Sorting: queryArg.sorting,
          Filter: queryArg.filter,
          SkipCount: queryArg.skipCount,
          MaxResultCount: queryArg.maxResultCount,
          ExtraProperties: queryArg.extraProperties,
        },
      }),
    }),
    identityUserLookupGetCount: build.query<
      IdentityUserLookupGetCountApiResponse,
      IdentityUserLookupGetCountApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/users/lookup/count`,
        params: { Filter: queryArg.filter },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as appApi };
export type AbpApiDefinitionGetApiResponse =
  /** status 200 Success */ VoloAbpHttpModelingApplicationApiDescriptionModel;
export type AbpApiDefinitionGetApiArg = {
  includeTypes?: boolean;
};
export type AbpApplicationConfigurationGetApiResponse =
  /** status 200 Success */ VoloAbpAspNetCoreMvcApplicationConfigurationsApplicationConfigurationDtoRead;
export type AbpApplicationConfigurationGetApiArg = {
  includeLocalizationResources?: boolean;
};
export type AbpApplicationLocalizationGetApiResponse =
  /** status 200 Success */ VoloAbpAspNetCoreMvcApplicationConfigurationsApplicationLocalizationDto;
export type AbpApplicationLocalizationGetApiArg = {
  cultureName: string;
  onlyDynamics?: boolean;
};
export type AbpTenantFindTenantByNameApiResponse =
  /** status 200 Success */ VoloAbpAspNetCoreMvcMultiTenancyFindTenantResultDto;
export type AbpTenantFindTenantByNameApiArg = {
  name: string;
};
export type AbpTenantFindTenantByIdApiResponse =
  /** status 200 Success */ VoloAbpAspNetCoreMvcMultiTenancyFindTenantResultDto;
export type AbpTenantFindTenantByIdApiArg = {
  id: string;
};
export type AccountRegisterApiResponse =
  /** status 200 Success */ VoloAbpIdentityIdentityUserDtoRead;
export type AccountRegisterApiArg = {
  voloAbpAccountRegisterDto: VoloAbpAccountRegisterDto;
};
export type AccountSendPasswordResetCodeApiResponse =
  /** status 200 Success */ void;
export type AccountSendPasswordResetCodeApiArg = {
  voloAbpAccountSendPasswordResetCodeDto: VoloAbpAccountSendPasswordResetCodeDto;
};
export type AccountVerifyPasswordResetTokenApiResponse =
  /** status 200 Success */ boolean;
export type AccountVerifyPasswordResetTokenApiArg = {
  voloAbpAccountVerifyPasswordResetTokenInput: VoloAbpAccountVerifyPasswordResetTokenInput;
};
export type AccountResetPasswordApiResponse = /** status 200 Success */ void;
export type AccountResetPasswordApiArg = {
  voloAbpAccountResetPasswordDto: VoloAbpAccountResetPasswordDto;
};
export type AccountGetConfirmationStateApiResponse =
  /** status 200 Success */ VoloAbpAccountIdentityUserConfirmationStateDto;
export type AccountGetConfirmationStateApiArg = {
  id?: string;
};
export type AccountSendPhoneNumberConfirmationTokenApiResponse =
  /** status 200 Success */ void;
export type AccountSendPhoneNumberConfirmationTokenApiArg = {
  voloAbpAccountSendPhoneNumberConfirmationTokenDto: VoloAbpAccountSendPhoneNumberConfirmationTokenDto;
};
export type AccountSendEmailConfirmationTokenApiResponse =
  /** status 200 Success */ void;
export type AccountSendEmailConfirmationTokenApiArg = {
  voloAbpAccountSendEmailConfirmationTokenDto: VoloAbpAccountSendEmailConfirmationTokenDto;
};
export type AccountVerifyEmailConfirmationTokenApiResponse =
  /** status 200 Success */ boolean;
export type AccountVerifyEmailConfirmationTokenApiArg = {
  voloAbpAccountVerifyEmailConfirmationTokenInput: VoloAbpAccountVerifyEmailConfirmationTokenInput;
};
export type AccountConfirmPhoneNumberApiResponse =
  /** status 200 Success */ void;
export type AccountConfirmPhoneNumberApiArg = {
  voloAbpAccountConfirmPhoneNumberInput: VoloAbpAccountConfirmPhoneNumberInput;
};
export type AccountConfirmEmailApiResponse = /** status 200 Success */ void;
export type AccountConfirmEmailApiArg = {
  voloAbpAccountConfirmEmailInput: VoloAbpAccountConfirmEmailInput;
};
export type AccountSetProfilePictureApiResponse =
  /** status 200 Success */ void;
export type AccountSetProfilePictureApiArg = {
  type?: VoloAbpAccountProfilePictureType;
  body: {
    ImageContent?: Blob;
  };
};
export type AccountGetProfilePictureApiResponse =
  /** status 200 Success */ VoloAbpAccountProfilePictureSourceDto;
export type AccountGetProfilePictureApiArg = {
  id: string;
};
export type AccountGetTwoFactorProvidersApiResponse =
  /** status 200 Success */ string[];
export type AccountGetTwoFactorProvidersApiArg = {
  userId: string;
  token: string;
};
export type AccountSendTwoFactorCodeApiResponse =
  /** status 200 Success */ void;
export type AccountSendTwoFactorCodeApiArg = {
  voloAbpAccountSendTwoFactorCodeInput: VoloAbpAccountSendTwoFactorCodeInput;
};
export type AccountGetSecurityLogListApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosPagedResultDto601VoloAbpIdentityIdentitySecurityLogDto20VoloAbpIdentityProApplicationContracts20Version831020CultureNeutral20PublicKeyTokenNull;
export type AccountGetSecurityLogListApiArg = {
  startTime?: string;
  endTime?: string;
  applicationName?: string;
  identity?: string;
  action?: string;
  userName?: string;
  clientId?: string;
  correlationId?: string;
  sorting?: string;
  skipCount?: number;
  maxResultCount?: number;
  extraProperties?: {
    [key: string]: any;
  };
};
export type AccountVerifyAuthenticatorCodeApiResponse =
  /** status 200 Success */ VoloAbpAccountVerifyAuthenticatorCodeDto;
export type AccountVerifyAuthenticatorCodeApiArg = {
  voloAbpAccountVerifyAuthenticatorCodeInput: VoloAbpAccountVerifyAuthenticatorCodeInput;
};
export type AccountResetAuthenticatorApiResponse =
  /** status 200 Success */ void;
export type AccountResetAuthenticatorApiArg = void;
export type AccountHasAuthenticatorApiResponse =
  /** status 200 Success */ boolean;
export type AccountHasAuthenticatorApiArg = void;
export type AccountGetAuthenticatorInfoApiResponse =
  /** status 200 Success */ VoloAbpAccountAuthenticatorInfoDto;
export type AccountGetAuthenticatorInfoApiArg = void;
export type AccountGetProfilePictureFileApiResponse =
  /** status 200 Success */ Blob;
export type AccountGetProfilePictureFileApiArg = {
  id: string;
};
export type AccountRecaptchaApiResponse = /** status 200 Success */ void;
export type AccountRecaptchaApiArg = {
  captchaResponse?: string;
};
export type AccountExternalProviderGetAllApiResponse =
  /** status 200 Success */ VoloAbpAccountExternalProvidersExternalProviderDto;
export type AccountExternalProviderGetAllApiArg = void;
export type AccountExternalProviderGetByNameApiResponse =
  /** status 200 Success */ VoloAbpAccountExternalProvidersExternalProviderItemWithSecretDto;
export type AccountExternalProviderGetByNameApiArg = {
  tenantId?: string;
  name?: string;
};
export type AccountSettingsGetApiResponse =
  /** status 200 Success */ VoloAbpAccountAccountSettingsDto;
export type AccountSettingsGetApiArg = void;
export type AccountSettingsUpdateApiResponse = /** status 200 Success */ void;
export type AccountSettingsUpdateApiArg = {
  voloAbpAccountAccountSettingsDto: VoloAbpAccountAccountSettingsDto;
};
export type AccountSettingsGetTwoFactorApiResponse =
  /** status 200 Success */ VoloAbpAccountAccountTwoFactorSettingsDto;
export type AccountSettingsGetTwoFactorApiArg = void;
export type AccountSettingsUpdateTwoFactorApiResponse =
  /** status 200 Success */ void;
export type AccountSettingsUpdateTwoFactorApiArg = {
  voloAbpAccountAccountTwoFactorSettingsDto: VoloAbpAccountAccountTwoFactorSettingsDto;
};
export type AccountSettingsGetRecaptchaApiResponse =
  /** status 200 Success */ VoloAbpAccountAccountRecaptchaSettingsDto;
export type AccountSettingsGetRecaptchaApiArg = void;
export type AccountSettingsUpdateRecaptchaApiResponse =
  /** status 200 Success */ void;
export type AccountSettingsUpdateRecaptchaApiArg = {
  voloAbpAccountAccountRecaptchaSettingsDto: VoloAbpAccountAccountRecaptchaSettingsDto;
};
export type AccountSettingsGetExternalProviderApiResponse =
  /** status 200 Success */ VoloAbpAccountAccountExternalProviderSettingsDto;
export type AccountSettingsGetExternalProviderApiArg = void;
export type AccountSettingsUpdateExternalProviderApiResponse =
  /** status 200 Success */ void;
export type AccountSettingsUpdateExternalProviderApiArg = {
  body: VoloAbpAccountUpdateExternalProviderDto[];
};
export type ActiveCampaignIntegrationGetSettingsApiResponse =
  /** status 200 Success */ Activ8IntegrationsActiveCampaignActiveCampaignIntegrationSettingsDto;
export type ActiveCampaignIntegrationGetSettingsApiArg = void;
export type ActiveCampaignIntegrationSetSettingsApiResponse =
  /** status 200 Success */ void;
export type ActiveCampaignIntegrationSetSettingsApiArg = {
  activ8IntegrationsActiveCampaignActiveCampaignIntegrationSettingsDto: Activ8IntegrationsActiveCampaignActiveCampaignIntegrationSettingsDto;
};
export type ApiIntegrationCompleteProgramExpressionOfInterestRequestApiResponse =
  /** status 200 Success */ void;
export type ApiIntegrationCompleteProgramExpressionOfInterestRequestApiArg = {
  "X-PublicApiKey": string;
  activ8CoursesProgramsCoursesLearningCourseProgramPublicApiExpressionOfInterestProgramSelectionRequest: Activ8CoursesProgramsCoursesLearningCourseProgramPublicApiExpressionOfInterestProgramSelectionRequest;
};
export type ApiIntegrationGetPartnerInfoApiResponse =
  /** status 200 Success */ Activ8CoursesProgramsCoursesLearningCourseProgramPublicApiPartnerInfoResponse;
export type ApiIntegrationGetPartnerInfoApiArg = {
  "X-PublicApiKey": string;
};
export type ApiIntegrationListActiveProgramsApiResponse =
  /** status 200 Success */ Activ8CoursesProgramsCoursesLearningCourseProgramPublicApiSummaryResponse;
export type ApiIntegrationListActiveProgramsApiArg = {
  "X-PublicApiKey": string;
  activ8CoursesProgramsCoursesLearningCourseProgramPublicApiExpressionOfInterestListProgramsRequest: Activ8CoursesProgramsCoursesLearningCourseProgramPublicApiExpressionOfInterestListProgramsRequest;
};
export type ApiIntegrationGetIntegrationSettingsApiResponse =
  /** status 200 Success */ Activ8IntegrationsApiApiIntegrationSettingsDto;
export type ApiIntegrationGetIntegrationSettingsApiArg = void;
export type ApplicationGetApiResponse =
  /** status 200 Success */ VoloAbpOpenIddictApplicationsDtosApplicationDtoRead;
export type ApplicationGetApiArg = {
  id: string;
};
export type ApplicationUpdateApiResponse =
  /** status 200 Success */ VoloAbpOpenIddictApplicationsDtosApplicationDtoRead;
export type ApplicationUpdateApiArg = {
  id: string;
  voloAbpOpenIddictApplicationsDtosUpdateApplicationInput: VoloAbpOpenIddictApplicationsDtosUpdateApplicationInput;
};
export type ApplicationGetListApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosPagedResultDto601VoloAbpOpenIddictApplicationsDtosApplicationDto20VoloAbpOpenIddictProApplicationContracts20Version831020CultureNeutral20PublicKeyTokenNullRead;
export type ApplicationGetListApiArg = {
  filter?: string;
  sorting?: string;
  skipCount?: number;
  maxResultCount?: number;
};
export type ApplicationCreateApiResponse =
  /** status 200 Success */ VoloAbpOpenIddictApplicationsDtosApplicationDtoRead;
export type ApplicationCreateApiArg = {
  voloAbpOpenIddictApplicationsDtosCreateApplicationInput: VoloAbpOpenIddictApplicationsDtosCreateApplicationInput;
};
export type ApplicationDeleteApiResponse = /** status 200 Success */ void;
export type ApplicationDeleteApiArg = {
  id?: string;
};
export type ApplicationGetTokenLifetimeApiResponse =
  /** status 200 Success */ VoloAbpOpenIddictApplicationsDtosApplicationTokenLifetimeDto;
export type ApplicationGetTokenLifetimeApiArg = {
  id: string;
};
export type ApplicationSetTokenLifetimeApiResponse =
  /** status 200 Success */ void;
export type ApplicationSetTokenLifetimeApiArg = {
  id: string;
  voloAbpOpenIddictApplicationsDtosApplicationTokenLifetimeDto: VoloAbpOpenIddictApplicationsDtosApplicationTokenLifetimeDto;
};
export type AuditLogsGetListApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosPagedResultDto601VoloAbpAuditLoggingAuditLogDto20VoloAbpAuditLoggingApplicationContracts20Version831020CultureNeutral20PublicKeyTokenNullRead;
export type AuditLogsGetListApiArg = {
  startTime?: string;
  endTime?: string;
  url?: string;
  clientId?: string;
  userName?: string;
  applicationName?: string;
  clientIpAddress?: string;
  correlationId?: string;
  httpMethod?: string;
  httpStatusCode?: SystemNetHttpStatusCode;
  maxExecutionDuration?: number;
  minExecutionDuration?: number;
  hasException?: boolean;
  sorting?: string;
  skipCount?: number;
  maxResultCount?: number;
};
export type AuditLogsGetApiResponse =
  /** status 200 Success */ VoloAbpAuditLoggingAuditLogDtoRead;
export type AuditLogsGetApiArg = {
  id: string;
};
export type AuditLogsGetErrorRateApiResponse =
  /** status 200 Success */ VoloAbpAuditLoggingGetErrorRateOutput;
export type AuditLogsGetErrorRateApiArg = {
  startDate?: string;
  endDate?: string;
};
export type AuditLogsGetAverageExecutionDurationPerDayApiResponse =
  /** status 200 Success */ VoloAbpAuditLoggingGetAverageExecutionDurationPerDayOutput;
export type AuditLogsGetAverageExecutionDurationPerDayApiArg = {
  startDate?: string;
  endDate?: string;
};
export type AuditLogsGetEntityChangesApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosPagedResultDto601VoloAbpAuditLoggingEntityChangeDto20VoloAbpAuditLoggingApplicationContracts20Version831020CultureNeutral20PublicKeyTokenNullRead;
export type AuditLogsGetEntityChangesApiArg = {
  auditLogId?: string;
  entityChangeType?: VoloAbpAuditingEntityChangeType;
  entityId?: string;
  entityTypeFullName?: string;
  startDate?: string;
  endDate?: string;
  sorting?: string;
  skipCount?: number;
  maxResultCount?: number;
};
export type AuditLogsGetEntityChangesWithUsernameApiResponse =
  /** status 200 Success */ VoloAbpAuditLoggingEntityChangeWithUsernameDtoRead[];
export type AuditLogsGetEntityChangesWithUsernameApiArg = {
  entityId?: string;
  entityTypeFullName?: string;
};
export type AuditLogsGetEntityChangeWithUsernameApiResponse =
  /** status 200 Success */ VoloAbpAuditLoggingEntityChangeWithUsernameDtoRead;
export type AuditLogsGetEntityChangeWithUsernameApiArg = {
  entityChangeId: string;
};
export type AuditLogsGetEntityChangeApiResponse =
  /** status 200 Success */ VoloAbpAuditLoggingEntityChangeDtoRead;
export type AuditLogsGetEntityChangeApiArg = {
  entityChangeId: string;
};
export type IdentityClaimTypeGetListApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosPagedResultDto601VoloAbpIdentityClaimTypeDto20VoloAbpIdentityProApplicationContracts20Version831020CultureNeutral20PublicKeyTokenNullRead;
export type IdentityClaimTypeGetListApiArg = {
  filter?: string;
  sorting?: string;
  skipCount?: number;
  maxResultCount?: number;
  extraProperties?: {
    [key: string]: any;
  };
};
export type IdentityClaimTypeCreateApiResponse =
  /** status 200 Success */ VoloAbpIdentityClaimTypeDtoRead;
export type IdentityClaimTypeCreateApiArg = {
  voloAbpIdentityCreateClaimTypeDto: VoloAbpIdentityCreateClaimTypeDto;
};
export type IdentityClaimTypeGetApiResponse =
  /** status 200 Success */ VoloAbpIdentityClaimTypeDtoRead;
export type IdentityClaimTypeGetApiArg = {
  id: string;
};
export type IdentityClaimTypeUpdateApiResponse =
  /** status 200 Success */ VoloAbpIdentityClaimTypeDtoRead;
export type IdentityClaimTypeUpdateApiArg = {
  id: string;
  voloAbpIdentityUpdateClaimTypeDto: VoloAbpIdentityUpdateClaimTypeDto;
};
export type IdentityClaimTypeDeleteApiResponse = /** status 200 Success */ void;
export type IdentityClaimTypeDeleteApiArg = {
  id: string;
};
export type DataExportTriggerUserExportEmailApiResponse =
  /** status 200 Success */ void;
export type DataExportTriggerUserExportEmailApiArg = void;
export type DataExportTriggerFacilitatorExportEmailApiResponse =
  /** status 200 Success */ void;
export type DataExportTriggerFacilitatorExportEmailApiArg = void;
export type DataExportTriggerParticipantExportEmailApiResponse =
  /** status 200 Success */ void;
export type DataExportTriggerParticipantExportEmailApiArg = void;
export type DataExportTriggerCourseTemplateExportEmailApiResponse =
  /** status 200 Success */ void;
export type DataExportTriggerCourseTemplateExportEmailApiArg = void;
export type DataExportTriggerCourseProgramExportEmailApiResponse =
  /** status 200 Success */ void;
export type DataExportTriggerCourseProgramExportEmailApiArg = void;
export type DataExportTriggerCourseProgramParticipantExportEmailApiResponse =
  /** status 200 Success */ void;
export type DataExportTriggerCourseProgramParticipantExportEmailApiArg = void;
export type DataImportTriggerParticipantImportApiResponse =
  /** status 200 Success */ void;
export type DataImportTriggerParticipantImportApiArg = {
  activ8DataDataImportTriggerParticipantDataImportRequest: Activ8DataDataImportTriggerParticipantDataImportRequest;
};
export type DataImportPrepareImportFileWriteApiResponse =
  /** status 200 Success */ Activ8CoursesTemplatesPrepareResourceOperationResponse;
export type DataImportPrepareImportFileWriteApiArg = {
  contentType?: string;
  contentFileName?: string;
  remoteIp?: string;
};
export type DirectoryDescriptorGetApiResponse =
  /** status 200 Success */ VoloFileManagementDirectoriesDirectoryDescriptorDtoRead;
export type DirectoryDescriptorGetApiArg = {
  id: string;
};
export type DirectoryDescriptorRenameApiResponse =
  /** status 200 Success */ VoloFileManagementDirectoriesDirectoryDescriptorDtoRead;
export type DirectoryDescriptorRenameApiArg = {
  id: string;
  voloFileManagementDirectoriesRenameDirectoryInput: VoloFileManagementDirectoriesRenameDirectoryInput;
};
export type DirectoryDescriptorDeleteApiResponse =
  /** status 200 Success */ void;
export type DirectoryDescriptorDeleteApiArg = {
  id: string;
};
export type DirectoryDescriptorGetListApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosListResultDto601VoloFileManagementDirectoriesDirectoryDescriptorInfoDto20VoloFileManagementApplicationContracts20Version831020CultureNeutral20PublicKeyTokenNull;
export type DirectoryDescriptorGetListApiArg = {
  parentId?: string;
};
export type DirectoryDescriptorCreateApiResponse =
  /** status 200 Success */ VoloFileManagementDirectoriesDirectoryDescriptorDtoRead;
export type DirectoryDescriptorCreateApiArg = {
  voloFileManagementDirectoriesCreateDirectoryInput: VoloFileManagementDirectoriesCreateDirectoryInput;
};
export type DirectoryDescriptorGetContentApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosPagedResultDto601VoloFileManagementDirectoriesDirectoryContentDto20VoloFileManagementApplicationContracts20Version831020CultureNeutral20PublicKeyTokenNullRead;
export type DirectoryDescriptorGetContentApiArg = {
  filter?: string;
  id?: string;
  sorting?: string;
  skipCount?: number;
  maxResultCount?: number;
};
export type DirectoryDescriptorMoveApiResponse =
  /** status 200 Success */ VoloFileManagementDirectoriesDirectoryDescriptorDtoRead;
export type DirectoryDescriptorMoveApiArg = {
  voloFileManagementDirectoriesMoveDirectoryInput: VoloFileManagementDirectoriesMoveDirectoryInput;
};
export type DynamicClaimsRefreshApiResponse = /** status 200 Success */ void;
export type DynamicClaimsRefreshApiArg = void;
export type EditionGetApiResponse =
  /** status 200 Success */ VoloSaasHostDtosEditionDtoRead;
export type EditionGetApiArg = {
  id: string;
};
export type EditionUpdateApiResponse =
  /** status 200 Success */ VoloSaasHostDtosEditionDtoRead;
export type EditionUpdateApiArg = {
  id: string;
  voloSaasHostDtosEditionUpdateDto: VoloSaasHostDtosEditionUpdateDto;
};
export type EditionDeleteApiResponse = /** status 200 Success */ void;
export type EditionDeleteApiArg = {
  id: string;
};
export type EditionGetListApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosPagedResultDto601VoloSaasHostDtosEditionDto20VoloSaasHostApplicationContracts20Version831020CultureNeutral20PublicKeyTokenNullRead;
export type EditionGetListApiArg = {
  filter?: string;
  sorting?: string;
  skipCount?: number;
  maxResultCount?: number;
};
export type EditionCreateApiResponse =
  /** status 200 Success */ VoloSaasHostDtosEditionDtoRead;
export type EditionCreateApiArg = {
  voloSaasHostDtosEditionCreateDto: VoloSaasHostDtosEditionCreateDto;
};
export type EditionMoveAllTenantsApiResponse = /** status 200 Success */ void;
export type EditionMoveAllTenantsApiArg = {
  id: string;
  editionId?: string;
};
export type EditionGetAllListApiResponse =
  /** status 200 Success */ VoloSaasHostDtosEditionDtoRead[];
export type EditionGetAllListApiArg = void;
export type EditionGetUsageStatisticsApiResponse =
  /** status 200 Success */ VoloSaasHostGetEditionUsageStatisticsResultDto;
export type EditionGetUsageStatisticsApiArg = void;
export type EditionGetPlanLookupApiResponse =
  /** status 200 Success */ VoloPaymentPlansPlanDtoRead[];
export type EditionGetPlanLookupApiArg = void;
export type SubscriptionCreateSubscriptionApiResponse =
  /** status 200 Success */ VoloPaymentRequestsPaymentRequestWithDetailsDtoRead;
export type SubscriptionCreateSubscriptionApiArg = {
  editionId?: string;
  tenantId?: string;
};
export type EmailSettingsGetApiResponse =
  /** status 200 Success */ VoloAbpSettingManagementEmailSettingsDto;
export type EmailSettingsGetApiArg = void;
export type EmailSettingsUpdateApiResponse = /** status 200 Success */ void;
export type EmailSettingsUpdateApiArg = {
  voloAbpSettingManagementUpdateEmailSettingsDto: VoloAbpSettingManagementUpdateEmailSettingsDto;
};
export type EmailSettingsSendTestEmailApiResponse =
  /** status 200 Success */ void;
export type EmailSettingsSendTestEmailApiArg = {
  voloAbpSettingManagementSendTestEmailInput: VoloAbpSettingManagementSendTestEmailInput;
};
export type IdentityExternalLoginCreateOrUpdateApiResponse =
  /** status 200 Success */ void;
export type IdentityExternalLoginCreateOrUpdateApiArg = void;
export type FacilitatorCreateApiResponse =
  /** status 200 Success */ Activ8FacilitatorsFacilitatorDto;
export type FacilitatorCreateApiArg = {
  activ8FacilitatorsFacilitatorCreateDto: Activ8FacilitatorsFacilitatorCreateDto;
};
export type FacilitatorGetListApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosPagedResultDto601Activ8FacilitatorsFacilitatorDto20Activ8ApplicationContracts20Version100020CultureNeutral20PublicKeyTokenNull;
export type FacilitatorGetListApiArg = {
  assignedPersonAttributeIds?: string[];
  assignedPersonAccreditationIds?: string[];
  filter?: string;
  roleId?: string;
  organizationUnitId?: string;
  userName?: string;
  phoneNumber?: string;
  emailAddress?: string;
  name?: string;
  surname?: string;
  isLockedOut?: boolean;
  notActive?: boolean;
  emailConfirmed?: boolean;
  isExternal?: boolean;
  maxCreationTime?: string;
  minCreationTime?: string;
  maxModifitionTime?: string;
  minModifitionTime?: string;
  sorting?: string;
  skipCount?: number;
  maxResultCount?: number;
  extraProperties?: {
    [key: string]: any;
  };
};
export type FacilitatorUpdateApiResponse =
  /** status 200 Success */ Activ8FacilitatorsFacilitatorDto;
export type FacilitatorUpdateApiArg = {
  id: string;
  activ8FacilitatorsFacilitatorUpdateDto: Activ8FacilitatorsFacilitatorUpdateDto;
};
export type FacilitatorGetApiResponse =
  /** status 200 Success */ Activ8FacilitatorsFacilitatorDto;
export type FacilitatorGetApiArg = {
  id: string;
};
export type FacilitatorDeleteApiResponse = /** status 200 Success */ void;
export type FacilitatorDeleteApiArg = {
  id: string;
};
export type FeaturesGetApiResponse =
  /** status 200 Success */ VoloAbpFeatureManagementGetFeatureListResultDtoRead;
export type FeaturesGetApiArg = {
  providerName?: string;
  providerKey?: string;
};
export type FeaturesUpdateApiResponse = /** status 200 Success */ void;
export type FeaturesUpdateApiArg = {
  providerName?: string;
  providerKey?: string;
  voloAbpFeatureManagementUpdateFeaturesDto: VoloAbpFeatureManagementUpdateFeaturesDto;
};
export type FeaturesDeleteApiResponse = /** status 200 Success */ void;
export type FeaturesDeleteApiArg = {
  providerName?: string;
  providerKey?: string;
};
export type FileDescriptorGetApiResponse =
  /** status 200 Success */ VoloFileManagementFilesFileDescriptorDtoRead;
export type FileDescriptorGetApiArg = {
  id: string;
};
export type FileDescriptorRenameApiResponse =
  /** status 200 Success */ VoloFileManagementFilesFileDescriptorDtoRead;
export type FileDescriptorRenameApiArg = {
  id: string;
  voloFileManagementFilesRenameFileInput: VoloFileManagementFilesRenameFileInput;
};
export type FileDescriptorDeleteApiResponse = /** status 200 Success */ void;
export type FileDescriptorDeleteApiArg = {
  id: string;
};
export type FileDescriptorGetListApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosListResultDto601VoloFileManagementFilesFileDescriptorDto20VoloFileManagementApplicationContracts20Version831020CultureNeutral20PublicKeyTokenNullRead;
export type FileDescriptorGetListApiArg = {
  directoryId?: string;
};
export type FileDescriptorCreateApiResponse =
  /** status 200 Success */ VoloFileManagementFilesFileDescriptorDtoRead;
export type FileDescriptorCreateApiArg = {
  directoryId?: string;
  name: string;
  overrideExisting?: boolean;
  extraProperties?: {
    [key: string]: any;
  };
  body: {
    File?: Blob;
  };
};
export type FileDescriptorMoveApiResponse =
  /** status 200 Success */ VoloFileManagementFilesFileDescriptorDtoRead;
export type FileDescriptorMoveApiArg = {
  voloFileManagementFilesMoveFileInput: VoloFileManagementFilesMoveFileInput;
};
export type FileDescriptorGetPreInfoApiResponse =
  /** status 200 Success */ VoloFileManagementFilesFileUploadPreInfoDto[];
export type FileDescriptorGetPreInfoApiArg = {
  body: VoloFileManagementFilesFileUploadPreInfoRequest[];
};
export type FileDescriptorGetContentApiResponse =
  /** status 200 Success */ string;
export type FileDescriptorGetContentApiArg = {
  id?: string;
};
export type FileDescriptorGetDownloadTokenApiResponse =
  /** status 200 Success */ VoloFileManagementFilesDownloadTokenResultDto;
export type FileDescriptorGetDownloadTokenApiArg = {
  id: string;
};
export type FileDescriptorDownloadApiResponse = /** status 200 Success */ Blob;
export type FileDescriptorDownloadApiArg = {
  id: string;
  token?: string;
};
export type LanguageGetAllListApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosListResultDto601VoloAbpLanguageManagementDtoLanguageDto20VoloAbpLanguageManagementApplicationContracts20Version831020CultureNeutral20PublicKeyTokenNullRead;
export type LanguageGetAllListApiArg = void;
export type LanguageGetListApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosPagedResultDto601VoloAbpLanguageManagementDtoLanguageDto20VoloAbpLanguageManagementApplicationContracts20Version831020CultureNeutral20PublicKeyTokenNullRead;
export type LanguageGetListApiArg = {
  filter?: string;
  resourceName?: string;
  baseCultureName?: string;
  targetCultureName?: string;
  getOnlyEmptyValues?: boolean;
  sorting?: string;
  skipCount?: number;
  maxResultCount?: number;
};
export type LanguageCreateApiResponse =
  /** status 200 Success */ VoloAbpLanguageManagementDtoLanguageDtoRead;
export type LanguageCreateApiArg = {
  voloAbpLanguageManagementDtoCreateLanguageDto: VoloAbpLanguageManagementDtoCreateLanguageDto;
};
export type LanguageGetApiResponse =
  /** status 200 Success */ VoloAbpLanguageManagementDtoLanguageDtoRead;
export type LanguageGetApiArg = {
  id: string;
};
export type LanguageUpdateApiResponse =
  /** status 200 Success */ VoloAbpLanguageManagementDtoLanguageDtoRead;
export type LanguageUpdateApiArg = {
  id: string;
  voloAbpLanguageManagementDtoUpdateLanguageDto: VoloAbpLanguageManagementDtoUpdateLanguageDto;
};
export type LanguageDeleteApiResponse = /** status 200 Success */ void;
export type LanguageDeleteApiArg = {
  id: string;
};
export type LanguageSetAsDefaultApiResponse = /** status 200 Success */ void;
export type LanguageSetAsDefaultApiArg = {
  id: string;
};
export type LanguageGetResourcesApiResponse =
  /** status 200 Success */ VoloAbpLanguageManagementDtoLanguageResourceDto[];
export type LanguageGetResourcesApiArg = void;
export type LanguageGetCulturelistApiResponse =
  /** status 200 Success */ VoloAbpLanguageManagementDtoCultureInfoDto[];
export type LanguageGetCulturelistApiArg = void;
export type LanguageTextGetListApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosPagedResultDto601VoloAbpLanguageManagementDtoLanguageTextDto20VoloAbpLanguageManagementApplicationContracts20Version831020CultureNeutral20PublicKeyTokenNull;
export type LanguageTextGetListApiArg = {
  filter?: string;
  resourceName?: string;
  baseCultureName?: string;
  targetCultureName?: string;
  getOnlyEmptyValues?: boolean;
  sorting?: string;
  skipCount?: number;
  maxResultCount?: number;
};
export type LanguageTextGetApiResponse =
  /** status 200 Success */ VoloAbpLanguageManagementDtoLanguageTextDto;
export type LanguageTextGetApiArg = {
  resourceName: string;
  cultureName: string;
  name: string;
  baseCultureName?: string;
};
export type LanguageTextUpdateApiResponse = /** status 200 Success */ void;
export type LanguageTextUpdateApiArg = {
  resourceName: string;
  cultureName: string;
  name: string;
  value?: string;
};
export type LanguageTextRestoreToDefaultApiResponse =
  /** status 200 Success */ void;
export type LanguageTextRestoreToDefaultApiArg = {
  resourceName: string;
  cultureName: string;
  name: string;
};
export type LearningCourseProgramUpdateApiResponse =
  /** status 200 Success */ Activ8CoursesProgramsCoursesLearningCourseProgramDtoRead;
export type LearningCourseProgramUpdateApiArg = {
  id: string;
  activ8CoursesProgramsCoursesLearningCourseProgramUpdateDto: Activ8CoursesProgramsCoursesLearningCourseProgramUpdateDto;
};
export type LearningCourseProgramGetApiResponse =
  /** status 200 Success */ Activ8CoursesProgramsCoursesLearningCourseProgramDtoRead;
export type LearningCourseProgramGetApiArg = {
  id: string;
};
export type LearningCourseProgramDeleteApiResponse =
  /** status 200 Success */ void;
export type LearningCourseProgramDeleteApiArg = {
  id: string;
};
export type LearningCourseProgramCreateApiResponse =
  /** status 200 Success */ Activ8CoursesProgramsCoursesLearningCourseProgramDtoRead;
export type LearningCourseProgramCreateApiArg = {
  activ8CoursesProgramsCoursesLearningCourseProgramCreateDto: Activ8CoursesProgramsCoursesLearningCourseProgramCreateDto;
};
export type LearningCourseProgramGetListApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosPagedResultDto601Activ8CoursesProgramsCoursesLearningCourseProgramSummaryDto20Activ8ApplicationContracts20Version100020CultureNeutral20PublicKeyTokenNullRead;
export type LearningCourseProgramGetListApiArg = {
  filterText?: string;
  assignedToFacilitatorIds?: string[];
  assignedToParticipantIds?: string[];
  statuses?: Activ8CoursesProgramsLearningCourseProgramStatus[];
  includeStatistics?: boolean;
  sorting?: string;
  skipCount?: number;
  maxResultCount?: number;
};
export type LearningCourseProgramSetProgramStatusApiResponse =
  /** status 200 Success */ void;
export type LearningCourseProgramSetProgramStatusApiArg = {
  activ8CoursesProgramsCoursesProgramStatusSetRequestDto: Activ8CoursesProgramsCoursesProgramStatusSetRequestDto;
};
export type LearningCourseProgramCloneCourseProgramApiResponse =
  /** status 200 Success */ string;
export type LearningCourseProgramCloneCourseProgramApiArg = {
  courseProgramId: string;
};
export type LearningCourseProgramAssignParticipantToProgramApiResponse =
  /** status 200 Success */ Activ8CoursesProgramsParticipantsParticipantToLearningCourseProgramAssignmentDtoRead;
export type LearningCourseProgramAssignParticipantToProgramApiArg = {
  courseProgramId?: string;
  participantIdentityUserId?: string;
};
export type LearningCourseProgramUnassignParticipantToProgramApiResponse =
  /** status 200 Success */ void;
export type LearningCourseProgramUnassignParticipantToProgramApiArg = {
  courseProgramId?: string;
  participantIdentityUserId?: string;
};
export type LearningCourseProgramGenerateUrlForDownloadCourseContentApiResponse =
  /** status 200 Success */ Activ8CoursesTemplatesPrepareResourceOperationResponse;
export type LearningCourseProgramGenerateUrlForDownloadCourseContentApiArg = {
  courseProgramId: string;
};
export type LearningCourseProgramFacilitatorGetApiResponse =
  /** status 200 Success */ Activ8CoursesProgramsFacilitatorsFacilitatorToLearningCourseProgramAssignmentDtoRead;
export type LearningCourseProgramFacilitatorGetApiArg = {
  id: string;
};
export type LearningCourseProgramFacilitatorUpdateApiResponse =
  /** status 200 Success */ Activ8CoursesProgramsFacilitatorsFacilitatorToLearningCourseProgramAssignmentDtoRead;
export type LearningCourseProgramFacilitatorUpdateApiArg = {
  id: string;
  activ8CoursesProgramsFacilitatorsFacilitatorToLearningCourseProgramAssignmentUpdateDto: Activ8CoursesProgramsFacilitatorsFacilitatorToLearningCourseProgramAssignmentUpdateDto;
};
export type LearningCourseProgramFacilitatorDeleteApiResponse =
  /** status 200 Success */ void;
export type LearningCourseProgramFacilitatorDeleteApiArg = {
  id: string;
};
export type LearningCourseProgramFacilitatorGetListApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosPagedResultDto601Activ8CoursesProgramsFacilitatorsFacilitatorToLearningCourseProgramAssignmentDto20Activ8ApplicationContracts20Version100020CultureNeutral20PublicKeyTokenNullRead;
export type LearningCourseProgramFacilitatorGetListApiArg = {
  filterText?: string;
  facilitatorIdentityUserIds?: string[];
  learningCourseProgramIds?: string[];
  includeProgramDetails?: boolean;
  includeFacilitatorDetails?: boolean;
  programStatuses?: Activ8CoursesProgramsLearningCourseProgramStatus[];
  sorting?: string;
  skipCount?: number;
  maxResultCount?: number;
};
export type LearningCourseProgramFacilitatorCreateApiResponse =
  /** status 200 Success */ Activ8CoursesProgramsFacilitatorsFacilitatorToLearningCourseProgramAssignmentDtoRead;
export type LearningCourseProgramFacilitatorCreateApiArg = {
  activ8CoursesProgramsFacilitatorsFacilitatorToLearningCourseProgramAssignmentCreateDto: Activ8CoursesProgramsFacilitatorsFacilitatorToLearningCourseProgramAssignmentCreateDto;
};
export type LearningCourseProgramParticipantGetListApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosPagedResultDto601Activ8CoursesProgramsParticipantsParticipantToLearningCourseProgramAssignmentDto20Activ8ApplicationContracts20Version100020CultureNeutral20PublicKeyTokenNullRead;
export type LearningCourseProgramParticipantGetListApiArg = {
  filterText?: string;
  learningCourseProgramIds?: string[];
  participantIdentityUserIds?: string[];
  includeProgramDetails?: boolean;
  includeParticipantDetails?: boolean;
  programStatuses?: Activ8CoursesProgramsLearningCourseProgramStatus[];
  sorting?: string;
  skipCount?: number;
  maxResultCount?: number;
};
export type LearningCourseProgramParticipantCreateApiResponse =
  /** status 200 Success */ Activ8CoursesProgramsParticipantsParticipantToLearningCourseProgramAssignmentDtoRead;
export type LearningCourseProgramParticipantCreateApiArg = {
  activ8CoursesProgramsParticipantsParticipantToLearningCourseProgramAssignmentCreateDto: Activ8CoursesProgramsParticipantsParticipantToLearningCourseProgramAssignmentCreateDto;
};
export type LearningCourseProgramParticipantGetApiResponse =
  /** status 200 Success */ Activ8CoursesProgramsParticipantsParticipantToLearningCourseProgramAssignmentDtoRead;
export type LearningCourseProgramParticipantGetApiArg = {
  id: string;
};
export type LearningCourseProgramParticipantUpdateApiResponse =
  /** status 200 Success */ Activ8CoursesProgramsParticipantsParticipantToLearningCourseProgramAssignmentDtoRead;
export type LearningCourseProgramParticipantUpdateApiArg = {
  id: string;
  activ8CoursesProgramsParticipantsParticipantToLearningCourseProgramAssignmentUpdateDto: Activ8CoursesProgramsParticipantsParticipantToLearningCourseProgramAssignmentUpdateDto;
};
export type LearningCourseProgramParticipantDeleteApiResponse =
  /** status 200 Success */ void;
export type LearningCourseProgramParticipantDeleteApiArg = {
  id: string;
};
export type LearningCourseProgramParticipantGetByProgramAndParticipantApiResponse =
  /** status 200 Success */ Activ8CoursesProgramsParticipantsParticipantToLearningCourseProgramAssignmentDtoRead;
export type LearningCourseProgramParticipantGetByProgramAndParticipantApiArg = {
  programId?: string;
  participantId?: string;
};
export type LearningCourseProgramSessionPrepareSessionContentApiResponse =
  /** status 200 Success */ Activ8CoursesProgramsSessionsLearningCourseProgramSessionPreparedContentDtoRead;
export type LearningCourseProgramSessionPrepareSessionContentApiArg = {
  courseProgramId?: string;
  courseProgramChapterId?: string;
  courseProgramChapterSessionId?: string;
};
export type LearningCourseProgramSessionProgressThroughSessionContentApiResponse =
  /** status 200 Success */ Activ8CoursesProgramsSessionsProgressThroughSessionContentResponse;
export type LearningCourseProgramSessionProgressThroughSessionContentApiArg = {
  activ8CoursesProgramsSessionsProgressThroughSessionContentRequest: Activ8CoursesProgramsSessionsProgressThroughSessionContentRequest;
};
export type LearningCourseProgramSessionProgressThroughSessionContentBatchApiResponse =
  /** status 200 Success */ Activ8CoursesProgramsSessionsProgressThroughSessionContentBatchResponse;
export type LearningCourseProgramSessionProgressThroughSessionContentBatchApiArg =
  {
    activ8CoursesProgramsSessionsProgressThroughSessionContentBatchRequest: Activ8CoursesProgramsSessionsProgressThroughSessionContentBatchRequest;
  };
export type LearningCourseTemplateSetTemplateStatusApiResponse =
  /** status 200 Success */ void;
export type LearningCourseTemplateSetTemplateStatusApiArg = {
  activ8CoursesTemplatesTemplateStatusSetRequestDto: Activ8CoursesTemplatesTemplateStatusSetRequestDto;
};
export type LearningCourseTemplateGetRoleNamesApplicableToCourseAssignmentsApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosListResultDto601SystemString20SystemPrivateCoreLib20Version800020CultureNeutral20PublicKeyToken7Cec85D7Bea7798E;
export type LearningCourseTemplateGetRoleNamesApplicableToCourseAssignmentsApiArg =
  void;
export type LearningCourseTemplateDeleteApiResponse =
  /** status 200 Success */ void;
export type LearningCourseTemplateDeleteApiArg = {
  id: string;
};
export type LearningCourseTemplateGetApiResponse =
  /** status 200 Success */ Activ8CoursesTemplatesLearningCourseTemplateDtoRead;
export type LearningCourseTemplateGetApiArg = {
  id: string;
};
export type LearningCourseTemplateUpdateApiResponse =
  /** status 200 Success */ Activ8CoursesTemplatesLearningCourseTemplateDtoRead;
export type LearningCourseTemplateUpdateApiArg = {
  id: string;
  activ8CoursesTemplatesLearningCourseTemplateUpdateDto: Activ8CoursesTemplatesLearningCourseTemplateUpdateDto;
};
export type LearningCourseTemplateCloneCourseTemplateApiResponse =
  /** status 200 Success */ string;
export type LearningCourseTemplateCloneCourseTemplateApiArg = {
  courseTemplateId: string;
};
export type LearningCourseTemplateGetTemplatePartnerLicensingApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosListResultDto601Activ8CoursesTemplatesTenantLicenseToLearningCourseTemplateAssignmentDto20Activ8ApplicationContracts20Version100020CultureNeutral20PublicKeyTokenNull;
export type LearningCourseTemplateGetTemplatePartnerLicensingApiArg = {
  courseTemplateId: string;
};
export type LearningCourseTemplatePrepareResourceWriteApiResponse =
  /** status 200 Success */ Activ8CoursesTemplatesPrepareResourceOperationResponse;
export type LearningCourseTemplatePrepareResourceWriteApiArg = {
  courseTemplateId?: string;
  resourceId?: string;
  contentType?: string;
  contentFileName?: string;
};
export type LearningCourseTemplatePrepareResourceReadApiResponse =
  /** status 200 Success */ Activ8CoursesTemplatesPrepareResourceOperationResponse;
export type LearningCourseTemplatePrepareResourceReadApiArg = {
  courseTemplateId?: string;
  resourceId?: string;
  isDownload?: boolean;
  contentType?: string;
  contentFileName?: string;
};
export type LearningCourseTemplateCreateApiResponse =
  /** status 200 Success */ Activ8CoursesTemplatesLearningCourseTemplateDtoRead;
export type LearningCourseTemplateCreateApiArg = {
  activ8CoursesTemplatesLearningCourseTemplateCreateDto: Activ8CoursesTemplatesLearningCourseTemplateCreateDto;
};
export type LearningCourseTemplateGetListApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosPagedResultDto601Activ8CoursesTemplatesLearningCourseTemplateSummaryDto20Activ8ApplicationContracts20Version100020CultureNeutral20PublicKeyTokenNullRead;
export type LearningCourseTemplateGetListApiArg = {
  filterText?: string;
  licensedToTenantIds?: string[];
  enrollmentAllowedForIdentityRoleNames?: string[];
  statuses?: Activ8CoursesTemplatesLearningCourseTemplateStatus[];
  sorting?: string;
  skipCount?: number;
  maxResultCount?: number;
};
export type LearningCourseTemplateGetCountOfLiveProgramsUsingCourseTemplateApiResponse =
  /** status 200 Success */ number;
export type LearningCourseTemplateGetCountOfLiveProgramsUsingCourseTemplateApiArg =
  {
    courseTemplateId: string;
  };
export type LearningCourseTemplateGetTemplateContentApiResponse =
  /** status 200 Success */ Activ8CoursesContentLearningCourseContentRead;
export type LearningCourseTemplateGetTemplateContentApiArg = {
  courseTemplateId: string;
};
export type LearningCourseTemplateGenerateUrlForDownloadCourseContentApiResponse =
  /** status 200 Success */ Activ8CoursesTemplatesPrepareResourceOperationResponse;
export type LearningCourseTemplateGenerateUrlForDownloadCourseContentApiArg = {
  courseTemplateId: string;
};
export type OrganizationUnitAddRolesApiResponse =
  /** status 200 Success */ void;
export type OrganizationUnitAddRolesApiArg = {
  id: string;
  voloAbpIdentityOrganizationUnitRoleInput: VoloAbpIdentityOrganizationUnitRoleInput;
};
export type OrganizationUnitGetRolesApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosPagedResultDto601VoloAbpIdentityIdentityRoleDto20VoloAbpIdentityProApplicationContracts20Version831020CultureNeutral20PublicKeyTokenNullRead;
export type OrganizationUnitGetRolesApiArg = {
  id: string;
  sorting?: string;
  skipCount?: number;
  maxResultCount?: number;
};
export type OrganizationUnitAddMembersApiResponse =
  /** status 200 Success */ void;
export type OrganizationUnitAddMembersApiArg = {
  id: string;
  voloAbpIdentityOrganizationUnitUserInput: VoloAbpIdentityOrganizationUnitUserInput;
};
export type OrganizationUnitGetMembersApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosPagedResultDto601VoloAbpIdentityIdentityUserDto20VoloAbpIdentityProApplicationContracts20Version831020CultureNeutral20PublicKeyTokenNullRead;
export type OrganizationUnitGetMembersApiArg = {
  id: string;
  filter?: string;
  roleId?: string;
  organizationUnitId?: string;
  userName?: string;
  phoneNumber?: string;
  emailAddress?: string;
  name?: string;
  surname?: string;
  isLockedOut?: boolean;
  notActive?: boolean;
  emailConfirmed?: boolean;
  isExternal?: boolean;
  maxCreationTime?: string;
  minCreationTime?: string;
  maxModifitionTime?: string;
  minModifitionTime?: string;
  sorting?: string;
  skipCount?: number;
  maxResultCount?: number;
  extraProperties?: {
    [key: string]: any;
  };
};
export type OrganizationUnitCreateApiResponse =
  /** status 200 Success */ VoloAbpIdentityOrganizationUnitWithDetailsDtoRead;
export type OrganizationUnitCreateApiArg = {
  voloAbpIdentityOrganizationUnitCreateDto: VoloAbpIdentityOrganizationUnitCreateDto;
};
export type OrganizationUnitDeleteApiResponse = /** status 200 Success */ void;
export type OrganizationUnitDeleteApiArg = {
  id?: string;
};
export type OrganizationUnitGetListApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosPagedResultDto601VoloAbpIdentityOrganizationUnitWithDetailsDto20VoloAbpIdentityProApplicationContracts20Version831020CultureNeutral20PublicKeyTokenNullRead;
export type OrganizationUnitGetListApiArg = {
  filter?: string;
  sorting?: string;
  skipCount?: number;
  maxResultCount?: number;
  extraProperties?: {
    [key: string]: any;
  };
};
export type OrganizationUnitGetApiResponse =
  /** status 200 Success */ VoloAbpIdentityOrganizationUnitWithDetailsDtoRead;
export type OrganizationUnitGetApiArg = {
  id: string;
};
export type OrganizationUnitUpdateApiResponse =
  /** status 200 Success */ VoloAbpIdentityOrganizationUnitWithDetailsDtoRead;
export type OrganizationUnitUpdateApiArg = {
  id: string;
  voloAbpIdentityOrganizationUnitUpdateDto: VoloAbpIdentityOrganizationUnitUpdateDto;
};
export type OrganizationUnitGetListAllApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosListResultDto601VoloAbpIdentityOrganizationUnitWithDetailsDto20VoloAbpIdentityProApplicationContracts20Version831020CultureNeutral20PublicKeyTokenNullRead;
export type OrganizationUnitGetListAllApiArg = void;
export type OrganizationUnitMoveApiResponse = /** status 200 Success */ void;
export type OrganizationUnitMoveApiArg = {
  id: string;
  voloAbpIdentityOrganizationUnitMoveInput: VoloAbpIdentityOrganizationUnitMoveInput;
};
export type OrganizationUnitGetAvailableUsersApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosPagedResultDto601VoloAbpIdentityIdentityUserDto20VoloAbpIdentityProApplicationContracts20Version831020CultureNeutral20PublicKeyTokenNullRead;
export type OrganizationUnitGetAvailableUsersApiArg = {
  filter?: string;
  id?: string;
  sorting?: string;
  skipCount?: number;
  maxResultCount?: number;
  extraProperties?: {
    [key: string]: any;
  };
};
export type OrganizationUnitGetAvailableRolesApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosPagedResultDto601VoloAbpIdentityIdentityRoleDto20VoloAbpIdentityProApplicationContracts20Version831020CultureNeutral20PublicKeyTokenNullRead;
export type OrganizationUnitGetAvailableRolesApiArg = {
  filter?: string;
  id?: string;
  sorting?: string;
  skipCount?: number;
  maxResultCount?: number;
  extraProperties?: {
    [key: string]: any;
  };
};
export type OrganizationUnitMoveAllUsersApiResponse =
  /** status 200 Success */ void;
export type OrganizationUnitMoveAllUsersApiArg = {
  id: string;
  organizationId?: string;
};
export type OrganizationUnitRemoveMemberApiResponse =
  /** status 200 Success */ void;
export type OrganizationUnitRemoveMemberApiArg = {
  id: string;
  memberId: string;
};
export type OrganizationUnitRemoveRoleApiResponse =
  /** status 200 Success */ void;
export type OrganizationUnitRemoveRoleApiArg = {
  id: string;
  roleId: string;
};
export type ParticipantCreateApiResponse =
  /** status 200 Success */ Activ8ParticipantsParticipantDtoRead;
export type ParticipantCreateApiArg = {
  activ8ParticipantsParticipantCreateDto: Activ8ParticipantsParticipantCreateDto;
};
export type ParticipantGetListApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosPagedResultDto601Activ8ParticipantsParticipantDto20Activ8ApplicationContracts20Version100020CultureNeutral20PublicKeyTokenNullRead;
export type ParticipantGetListApiArg = {
  assignedPersonAttributeIds?: string[];
  assignedPersonAccreditationIds?: string[];
  filter?: string;
  roleId?: string;
  organizationUnitId?: string;
  userName?: string;
  phoneNumber?: string;
  emailAddress?: string;
  name?: string;
  surname?: string;
  isLockedOut?: boolean;
  notActive?: boolean;
  emailConfirmed?: boolean;
  isExternal?: boolean;
  maxCreationTime?: string;
  minCreationTime?: string;
  maxModifitionTime?: string;
  minModifitionTime?: string;
  sorting?: string;
  skipCount?: number;
  maxResultCount?: number;
  extraProperties?: {
    [key: string]: any;
  };
};
export type ParticipantUpdateApiResponse =
  /** status 200 Success */ Activ8ParticipantsParticipantDtoRead;
export type ParticipantUpdateApiArg = {
  id: string;
  activ8ParticipantsParticipantUpdateDto: Activ8ParticipantsParticipantUpdateDto;
};
export type ParticipantGetApiResponse =
  /** status 200 Success */ Activ8ParticipantsParticipantDtoRead;
export type ParticipantGetApiArg = {
  id: string;
};
export type ParticipantDeleteApiResponse = /** status 200 Success */ void;
export type ParticipantDeleteApiArg = {
  id: string;
};
export type ParticipantRemoveIdentityUserLinkApiResponse =
  /** status 200 Success */ void;
export type ParticipantRemoveIdentityUserLinkApiArg = {
  parentIdentityUserId?: string;
  childIdentityUserId?: string;
};
export type ParticipantMaintainIdentityUserLinksApiResponse =
  /** status 200 Success */ void;
export type ParticipantMaintainIdentityUserLinksApiArg = {
  parentIdentityUserId?: string;
  childIdentityUserId?: string;
  unlinkAllChildParentLinks?: boolean;
};
export type PartnerAppNotificationGetAllApiResponse =
  /** status 200 Success */ Activ8AppNotificationsAppNotification[];
export type PartnerAppNotificationGetAllApiArg = void;
export type PartnerDashboardGetApiResponse =
  /** status 200 Success */ Activ8PartnersDashboardPartnerDashboardResponse;
export type PartnerDashboardGetApiArg = void;
export type PartnerManagementEnsurePartnerSetupApiResponse =
  /** status 200 Success */ void;
export type PartnerManagementEnsurePartnerSetupApiArg = {
  tenantId: string;
};
export type PartnerProfileGetApiResponse =
  /** status 200 Success */ Activ8PartnersPartnerProfileDetail;
export type PartnerProfileGetApiArg = void;
export type PartnerProfileSetApiResponse = /** status 200 Success */ void;
export type PartnerProfileSetApiArg = {
  activ8PartnersProfilePartnerProfileDetailSetRequest: Activ8PartnersProfilePartnerProfileDetailSetRequest;
};
export type PermissionsGetApiResponse =
  /** status 200 Success */ VoloAbpPermissionManagementGetPermissionListResultDto;
export type PermissionsGetApiArg = {
  providerName?: string;
  providerKey?: string;
};
export type PermissionsUpdateApiResponse = /** status 200 Success */ void;
export type PermissionsUpdateApiArg = {
  providerName?: string;
  providerKey?: string;
  voloAbpPermissionManagementUpdatePermissionsDto: VoloAbpPermissionManagementUpdatePermissionsDto;
};
export type PersonAccreditationGetAssignmentsForUserApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosListResultDto601Activ8PersonAccreditationsPersonAccreditationToUserAssignmentDto20Activ8ApplicationContracts20Version100020CultureNeutral20PublicKeyTokenNull;
export type PersonAccreditationGetAssignmentsForUserApiArg = {
  userId: string;
};
export type PersonAccreditationSetAssignmentsForUserApiResponse =
  /** status 200 Success */ void;
export type PersonAccreditationSetAssignmentsForUserApiArg = {
  userId: string;
  body: string[];
};
export type PersonAccreditationGetListApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosPagedResultDto601Activ8PersonAccreditationsPersonAccreditationDto20Activ8ApplicationContracts20Version100020CultureNeutral20PublicKeyTokenNull;
export type PersonAccreditationGetListApiArg = {
  filterText?: string;
  sorting?: string;
  skipCount?: number;
  maxResultCount?: number;
};
export type PersonAccreditationCreateApiResponse =
  /** status 200 Success */ Activ8PersonAccreditationsPersonAccreditationDto;
export type PersonAccreditationCreateApiArg = {
  activ8PersonAccreditationsPersonAccreditationCreateOrUpdateDto: Activ8PersonAccreditationsPersonAccreditationCreateOrUpdateDto;
};
export type PersonAccreditationUpdateApiResponse =
  /** status 200 Success */ Activ8PersonAccreditationsPersonAccreditationDto;
export type PersonAccreditationUpdateApiArg = {
  id: string;
  activ8PersonAccreditationsPersonAccreditationCreateOrUpdateDto: Activ8PersonAccreditationsPersonAccreditationCreateOrUpdateDto;
};
export type PersonAccreditationDeleteApiResponse =
  /** status 200 Success */ void;
export type PersonAccreditationDeleteApiArg = {
  id: string;
};
export type PersonAccreditationGetApiResponse =
  /** status 200 Success */ Activ8PersonAccreditationsPersonAccreditationDto;
export type PersonAccreditationGetApiArg = {
  id: string;
};
export type PersonAttributeGetAssignmentsForUserApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosListResultDto601Activ8PersonAttributesPersonAttributeToUserAssignmentDto20Activ8ApplicationContracts20Version100020CultureNeutral20PublicKeyTokenNull;
export type PersonAttributeGetAssignmentsForUserApiArg = {
  userId: string;
};
export type PersonAttributeSetAssignmentsForUserApiResponse =
  /** status 200 Success */ void;
export type PersonAttributeSetAssignmentsForUserApiArg = {
  userId: string;
  body: string[];
};
export type PersonAttributeGetListApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosPagedResultDto601Activ8PersonAttributesPersonAttributeDto20Activ8ApplicationContracts20Version100020CultureNeutral20PublicKeyTokenNull;
export type PersonAttributeGetListApiArg = {
  filterText?: string;
  sorting?: string;
  skipCount?: number;
  maxResultCount?: number;
};
export type PersonAttributeCreateApiResponse =
  /** status 200 Success */ Activ8PersonAttributesPersonAttributeDto;
export type PersonAttributeCreateApiArg = {
  activ8PersonAttributesPersonAttributeCreateOrUpdateDto: Activ8PersonAttributesPersonAttributeCreateOrUpdateDto;
};
export type PersonAttributeUpdateApiResponse =
  /** status 200 Success */ Activ8PersonAttributesPersonAttributeDto;
export type PersonAttributeUpdateApiArg = {
  id: string;
  activ8PersonAttributesPersonAttributeCreateOrUpdateDto: Activ8PersonAttributesPersonAttributeCreateOrUpdateDto;
};
export type PersonAttributeDeleteApiResponse = /** status 200 Success */ void;
export type PersonAttributeDeleteApiArg = {
  id: string;
};
export type PersonAttributeGetApiResponse =
  /** status 200 Success */ Activ8PersonAttributesPersonAttributeDto;
export type PersonAttributeGetApiArg = {
  id: string;
};
export type ProfileGetApiResponse =
  /** status 200 Success */ VoloAbpAccountProfileDtoRead;
export type ProfileGetApiArg = void;
export type ProfileUpdateApiResponse =
  /** status 200 Success */ VoloAbpAccountProfileDtoRead;
export type ProfileUpdateApiArg = {
  voloAbpAccountUpdateProfileDto: VoloAbpAccountUpdateProfileDto;
};
export type ProfileChangePasswordApiResponse = /** status 200 Success */ void;
export type ProfileChangePasswordApiArg = {
  voloAbpAccountChangePasswordInput: VoloAbpAccountChangePasswordInput;
};
export type ProfileGetTwoFactorEnabledApiResponse =
  /** status 200 Success */ boolean;
export type ProfileGetTwoFactorEnabledApiArg = void;
export type ProfileSetTwoFactorEnabledApiResponse =
  /** status 200 Success */ void;
export type ProfileSetTwoFactorEnabledApiArg = {
  enabled?: boolean;
};
export type ProfileCanEnableTwoFactorApiResponse =
  /** status 200 Success */ boolean;
export type ProfileCanEnableTwoFactorApiArg = void;
export type ProfileGetTimezonesApiResponse =
  /** status 200 Success */ VoloAbpNameValue[];
export type ProfileGetTimezonesApiArg = void;
export type IdentityRoleGetApiResponse =
  /** status 200 Success */ VoloAbpIdentityIdentityRoleDtoRead;
export type IdentityRoleGetApiArg = {
  id: string;
};
export type IdentityRoleUpdateApiResponse =
  /** status 200 Success */ VoloAbpIdentityIdentityRoleDtoRead;
export type IdentityRoleUpdateApiArg = {
  id: string;
  voloAbpIdentityIdentityRoleUpdateDto: VoloAbpIdentityIdentityRoleUpdateDto;
};
export type IdentityRoleDeleteApiResponse = /** status 200 Success */ void;
export type IdentityRoleDeleteApiArg = {
  id: string;
};
export type IdentityRoleCreateApiResponse =
  /** status 200 Success */ VoloAbpIdentityIdentityRoleDtoRead;
export type IdentityRoleCreateApiArg = {
  voloAbpIdentityIdentityRoleCreateDto: VoloAbpIdentityIdentityRoleCreateDto;
};
export type IdentityRoleGetListApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosPagedResultDto601VoloAbpIdentityIdentityRoleDto20VoloAbpIdentityProApplicationContracts20Version831020CultureNeutral20PublicKeyTokenNullRead;
export type IdentityRoleGetListApiArg = {
  filter?: string;
  sorting?: string;
  skipCount?: number;
  maxResultCount?: number;
  extraProperties?: {
    [key: string]: any;
  };
};
export type IdentityRoleGetAllListApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosListResultDto601VoloAbpIdentityIdentityRoleDto20VoloAbpIdentityProApplicationContracts20Version831020CultureNeutral20PublicKeyTokenNullRead;
export type IdentityRoleGetAllListApiArg = void;
export type IdentityRoleUpdateClaimsApiResponse =
  /** status 200 Success */ void;
export type IdentityRoleUpdateClaimsApiArg = {
  id: string;
  body: VoloAbpIdentityIdentityRoleClaimDto[];
};
export type IdentityRoleGetClaimsApiResponse =
  /** status 200 Success */ VoloAbpIdentityIdentityRoleClaimDto[];
export type IdentityRoleGetClaimsApiArg = {
  id: string;
};
export type IdentityRoleMoveAllUsersApiResponse =
  /** status 200 Success */ void;
export type IdentityRoleMoveAllUsersApiArg = {
  id: string;
  roleId?: string;
};
export type IdentityRoleGetAllClaimTypesApiResponse =
  /** status 200 Success */ VoloAbpIdentityClaimTypeDtoRead[];
export type IdentityRoleGetAllClaimTypesApiArg = void;
export type ScopeGetApiResponse =
  /** status 200 Success */ VoloAbpOpenIddictScopesDtosScopeDtoRead;
export type ScopeGetApiArg = {
  id: string;
};
export type ScopeUpdateApiResponse =
  /** status 200 Success */ VoloAbpOpenIddictScopesDtosScopeDtoRead;
export type ScopeUpdateApiArg = {
  id: string;
  voloAbpOpenIddictScopesDtosUpdateScopeInput: VoloAbpOpenIddictScopesDtosUpdateScopeInput;
};
export type ScopeGetListApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosPagedResultDto601VoloAbpOpenIddictScopesDtosScopeDto20VoloAbpOpenIddictProApplicationContracts20Version831020CultureNeutral20PublicKeyTokenNullRead;
export type ScopeGetListApiArg = {
  filter?: string;
  sorting?: string;
  skipCount?: number;
  maxResultCount?: number;
};
export type ScopeCreateApiResponse =
  /** status 200 Success */ VoloAbpOpenIddictScopesDtosScopeDtoRead;
export type ScopeCreateApiArg = {
  voloAbpOpenIddictScopesDtosCreateScopeInput: VoloAbpOpenIddictScopesDtosCreateScopeInput;
};
export type ScopeDeleteApiResponse = /** status 200 Success */ void;
export type ScopeDeleteApiArg = {
  id?: string;
};
export type ScopeGetAllScopesApiResponse =
  /** status 200 Success */ VoloAbpOpenIddictScopesDtosScopeDtoRead[];
export type ScopeGetAllScopesApiArg = void;
export type IdentitySecurityLogGetListApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosPagedResultDto601VoloAbpIdentityIdentitySecurityLogDto20VoloAbpIdentityProApplicationContracts20Version831020CultureNeutral20PublicKeyTokenNull;
export type IdentitySecurityLogGetListApiArg = {
  startTime?: string;
  endTime?: string;
  applicationName?: string;
  identity?: string;
  action?: string;
  userName?: string;
  clientId?: string;
  correlationId?: string;
  sorting?: string;
  skipCount?: number;
  maxResultCount?: number;
  extraProperties?: {
    [key: string]: any;
  };
};
export type IdentitySecurityLogGetApiResponse =
  /** status 200 Success */ VoloAbpIdentityIdentitySecurityLogDto;
export type IdentitySecurityLogGetApiArg = {
  id: string;
};
export type AccountSessionGetListApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosPagedResultDto601VoloAbpIdentityIdentitySessionDto20VoloAbpIdentityProApplicationContracts20Version831020CultureNeutral20PublicKeyTokenNull;
export type AccountSessionGetListApiArg = {
  device?: string;
  clientId?: string;
  sorting?: string;
  skipCount?: number;
  maxResultCount?: number;
  extraProperties?: {
    [key: string]: any;
  };
};
export type AccountSessionGetApiResponse =
  /** status 200 Success */ VoloAbpIdentityIdentitySessionDto;
export type AccountSessionGetApiArg = {
  id: string;
};
export type AccountSessionRevokeApiResponse = /** status 200 Success */ void;
export type AccountSessionRevokeApiArg = {
  id: string;
};
export type IdentitySessionGetListApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosPagedResultDto601VoloAbpIdentityIdentitySessionDto20VoloAbpIdentityProApplicationContracts20Version831020CultureNeutral20PublicKeyTokenNull;
export type IdentitySessionGetListApiArg = {
  userId?: string;
  device?: string;
  clientId?: string;
  sorting?: string;
  skipCount?: number;
  maxResultCount?: number;
  extraProperties?: {
    [key: string]: any;
  };
};
export type IdentitySessionGetApiResponse =
  /** status 200 Success */ VoloAbpIdentityIdentitySessionDto;
export type IdentitySessionGetApiArg = {
  id: string;
};
export type IdentitySessionRevokeApiResponse = /** status 200 Success */ void;
export type IdentitySessionRevokeApiArg = {
  id: string;
};
export type AuditLogSettingsGetApiResponse =
  /** status 200 Success */ VoloAbpAuditLoggingAuditLogSettingsDto;
export type AuditLogSettingsGetApiArg = void;
export type AuditLogSettingsUpdateApiResponse = /** status 200 Success */ void;
export type AuditLogSettingsUpdateApiArg = {
  voloAbpAuditLoggingAuditLogSettingsDto: VoloAbpAuditLoggingAuditLogSettingsDto;
};
export type AuditLogSettingsGetGlobalApiResponse =
  /** status 200 Success */ VoloAbpAuditLoggingAuditLogGlobalSettingsDto;
export type AuditLogSettingsGetGlobalApiArg = void;
export type AuditLogSettingsUpdateGlobalApiResponse =
  /** status 200 Success */ void;
export type AuditLogSettingsUpdateGlobalApiArg = {
  voloAbpAuditLoggingAuditLogGlobalSettingsDto: VoloAbpAuditLoggingAuditLogGlobalSettingsDto;
};
export type IdentitySettingsGetApiResponse =
  /** status 200 Success */ VoloAbpIdentityIdentitySettingsDto;
export type IdentitySettingsGetApiArg = void;
export type IdentitySettingsUpdateApiResponse = /** status 200 Success */ void;
export type IdentitySettingsUpdateApiArg = {
  voloAbpIdentityIdentitySettingsDto: VoloAbpIdentityIdentitySettingsDto;
};
export type IdentitySettingsGetLdapApiResponse =
  /** status 200 Success */ VoloAbpIdentityIdentityLdapSettingsDto;
export type IdentitySettingsGetLdapApiArg = void;
export type IdentitySettingsUpdateLdapApiResponse =
  /** status 200 Success */ void;
export type IdentitySettingsUpdateLdapApiArg = {
  voloAbpIdentityIdentityLdapSettingsDto: VoloAbpIdentityIdentityLdapSettingsDto;
};
export type IdentitySettingsGetOAuthApiResponse =
  /** status 200 Success */ VoloAbpIdentityIdentityOAuthSettingsDto;
export type IdentitySettingsGetOAuthApiArg = void;
export type IdentitySettingsUpdateOAuthApiResponse =
  /** status 200 Success */ void;
export type IdentitySettingsUpdateOAuthApiArg = {
  voloAbpIdentityIdentityOAuthSettingsDto: VoloAbpIdentityIdentityOAuthSettingsDto;
};
export type IdentitySettingsGetSessionApiResponse =
  /** status 200 Success */ VoloAbpIdentityIdentitySessionSettingsDto;
export type IdentitySettingsGetSessionApiArg = void;
export type IdentitySettingsUpdateSessionApiResponse =
  /** status 200 Success */ void;
export type IdentitySettingsUpdateSessionApiArg = {
  voloAbpIdentityIdentitySessionSettingsDto: VoloAbpIdentityIdentitySessionSettingsDto;
};
export type TenantGetApiResponse =
  /** status 200 Success */ VoloSaasHostDtosSaasTenantDtoRead;
export type TenantGetApiArg = {
  id: string;
};
export type TenantUpdateApiResponse =
  /** status 200 Success */ VoloSaasHostDtosSaasTenantDtoRead;
export type TenantUpdateApiArg = {
  id: string;
  activ8ControllersSaasTenantUpdateDtoWithExtraPropertiesWrite: Activ8ControllersSaasTenantUpdateDtoWithExtraPropertiesWrite;
};
export type TenantDeleteApiResponse = /** status 200 Success */ void;
export type TenantDeleteApiArg = {
  id: string;
};
export type TenantGetListApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosPagedResultDto601VoloSaasHostDtosSaasTenantDto20VoloSaasHostApplicationContracts20Version831020CultureNeutral20PublicKeyTokenNullRead;
export type TenantGetListApiArg = {
  filter?: string;
  getEditionNames?: boolean;
  editionId?: string;
  expirationDateMin?: string;
  expirationDateMax?: string;
  activationState?: VoloSaasTenantActivationState;
  activationEndDateMin?: string;
  activationEndDateMax?: string;
  sorting?: string;
  skipCount?: number;
  maxResultCount?: number;
};
export type TenantCreateApiResponse =
  /** status 200 Success */ VoloSaasHostDtosSaasTenantDtoRead;
export type TenantCreateApiArg = {
  activ8ControllersSaasTenantCreateDtoWithExtraPropertiesWrite: Activ8ControllersSaasTenantCreateDtoWithExtraPropertiesWrite;
};
export type TenantGetDatabasesApiResponse =
  /** status 200 Success */ VoloSaasHostDtosSaasTenantDatabasesDtoRead;
export type TenantGetDatabasesApiArg = void;
export type TenantGetConnectionStringsApiResponse =
  /** status 200 Success */ VoloSaasHostDtosSaasTenantConnectionStringsDtoRead;
export type TenantGetConnectionStringsApiArg = {
  id: string;
};
export type TenantUpdateConnectionStringsApiResponse =
  /** status 200 Success */ void;
export type TenantUpdateConnectionStringsApiArg = {
  id: string;
  voloSaasHostDtosSaasTenantConnectionStringsDto: VoloSaasHostDtosSaasTenantConnectionStringsDto;
};
export type TenantApplyDatabaseMigrationsApiResponse =
  /** status 200 Success */ void;
export type TenantApplyDatabaseMigrationsApiArg = {
  id: string;
};
export type TenantGetEditionLookupApiResponse =
  /** status 200 Success */ VoloSaasHostDtosEditionLookupDtoRead[];
export type TenantGetEditionLookupApiArg = void;
export type TenantCheckConnectionStringApiResponse =
  /** status 200 Success */ boolean;
export type TenantCheckConnectionStringApiArg = {
  connectionString?: string;
};
export type TenantSetPasswordApiResponse = /** status 200 Success */ void;
export type TenantSetPasswordApiArg = {
  id: string;
  voloSaasHostDtosSaasTenantSetPasswordDto: VoloSaasHostDtosSaasTenantSetPasswordDto;
};
export type TimezoneLookupGetAllIanaApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosListResultDto601VoloAbpNameValue20VoloAbpCore20Version831020CultureNeutral20PublicKeyTokenNull;
export type TimezoneLookupGetAllIanaApiArg = void;
export type TimezoneLookupGetAllWindowsApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosListResultDto601VoloAbpNameValue20VoloAbpCore20Version831020CultureNeutral20PublicKeyTokenNull;
export type TimezoneLookupGetAllWindowsApiArg = void;
export type TimeZoneSettingsGetApiResponse = /** status 200 Success */ string;
export type TimeZoneSettingsGetApiArg = void;
export type TimeZoneSettingsUpdateApiResponse = /** status 200 Success */ void;
export type TimeZoneSettingsUpdateApiArg = {
  timezone?: string;
};
export type TimeZoneSettingsGetTimezonesApiResponse =
  /** status 200 Success */ VoloAbpNameValue[];
export type TimeZoneSettingsGetTimezonesApiArg = void;
export type IdentityUserGetApiResponse =
  /** status 200 Success */ VoloAbpIdentityIdentityUserDtoRead;
export type IdentityUserGetApiArg = {
  id: string;
};
export type IdentityUserUpdateApiResponse =
  /** status 200 Success */ VoloAbpIdentityIdentityUserDtoRead;
export type IdentityUserUpdateApiArg = {
  id: string;
  activ8ControllersIdentityUserUpdateDtoWithExtraPropertiesWrite: Activ8ControllersIdentityUserUpdateDtoWithExtraPropertiesWrite;
};
export type IdentityUserDeleteApiResponse = /** status 200 Success */ void;
export type IdentityUserDeleteApiArg = {
  id: string;
};
export type IdentityUserGetListApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosPagedResultDto601VoloAbpIdentityIdentityUserDto20VoloAbpIdentityProApplicationContracts20Version831020CultureNeutral20PublicKeyTokenNullRead;
export type IdentityUserGetListApiArg = {
  filter?: string;
  roleId?: string;
  organizationUnitId?: string;
  userName?: string;
  phoneNumber?: string;
  emailAddress?: string;
  name?: string;
  surname?: string;
  isLockedOut?: boolean;
  notActive?: boolean;
  emailConfirmed?: boolean;
  isExternal?: boolean;
  maxCreationTime?: string;
  minCreationTime?: string;
  maxModifitionTime?: string;
  minModifitionTime?: string;
  sorting?: string;
  skipCount?: number;
  maxResultCount?: number;
  extraProperties?: {
    [key: string]: any;
  };
};
export type IdentityUserCreateApiResponse =
  /** status 200 Success */ VoloAbpIdentityIdentityUserDtoRead;
export type IdentityUserCreateApiArg = {
  activ8ControllersIdentityUserCreateDtoWithExtraPropertiesWrite: Activ8ControllersIdentityUserCreateDtoWithExtraPropertiesWrite;
};
export type IdentityUserSetUserTimezoneApiResponse =
  /** status 200 Success */ void;
export type IdentityUserSetUserTimezoneApiArg = {
  ianaTimezoneId?: string;
};
export type IdentityUserFindByIdApiResponse =
  /** status 200 Success */ VoloAbpIdentityIdentityUserDtoRead;
export type IdentityUserFindByIdApiArg = {
  id: string;
};
export type IdentityUserGetRolesApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosListResultDto601VoloAbpIdentityIdentityRoleDto20VoloAbpIdentityProApplicationContracts20Version831020CultureNeutral20PublicKeyTokenNullRead;
export type IdentityUserGetRolesApiArg = {
  id: string;
};
export type IdentityUserUpdateRolesApiResponse = /** status 200 Success */ void;
export type IdentityUserUpdateRolesApiArg = {
  id: string;
  voloAbpIdentityIdentityUserUpdateRolesDto: VoloAbpIdentityIdentityUserUpdateRolesDto;
};
export type IdentityUserGetAssignableRolesApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosListResultDto601VoloAbpIdentityIdentityRoleDto20VoloAbpIdentityProApplicationContracts20Version831020CultureNeutral20PublicKeyTokenNullRead;
export type IdentityUserGetAssignableRolesApiArg = void;
export type IdentityUserGetAvailableOrganizationUnitsApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosListResultDto601VoloAbpIdentityOrganizationUnitWithDetailsDto20VoloAbpIdentityProApplicationContracts20Version831020CultureNeutral20PublicKeyTokenNullRead;
export type IdentityUserGetAvailableOrganizationUnitsApiArg = void;
export type IdentityUserGetAllClaimTypesApiResponse =
  /** status 200 Success */ VoloAbpIdentityClaimTypeDtoRead[];
export type IdentityUserGetAllClaimTypesApiArg = void;
export type IdentityUserGetClaimsApiResponse =
  /** status 200 Success */ VoloAbpIdentityIdentityUserClaimDto[];
export type IdentityUserGetClaimsApiArg = {
  id: string;
};
export type IdentityUserUpdateClaimsApiResponse =
  /** status 200 Success */ void;
export type IdentityUserUpdateClaimsApiArg = {
  id: string;
  body: VoloAbpIdentityIdentityUserClaimDto[];
};
export type IdentityUserGetOrganizationUnitsApiResponse =
  /** status 200 Success */ VoloAbpIdentityOrganizationUnitDtoRead[];
export type IdentityUserGetOrganizationUnitsApiArg = {
  id: string;
};
export type IdentityUserLockApiResponse = /** status 200 Success */ void;
export type IdentityUserLockApiArg = {
  id: string;
  lockoutEnd: string;
};
export type IdentityUserUnlockApiResponse = /** status 200 Success */ void;
export type IdentityUserUnlockApiArg = {
  id: string;
};
export type IdentityUserFindByUsernameApiResponse =
  /** status 200 Success */ VoloAbpIdentityIdentityUserDtoRead;
export type IdentityUserFindByUsernameApiArg = {
  username: string;
};
export type IdentityUserFindByEmailApiResponse =
  /** status 200 Success */ VoloAbpIdentityIdentityUserDtoRead;
export type IdentityUserFindByEmailApiArg = {
  email: string;
};
export type IdentityUserGetTwoFactorEnabledApiResponse =
  /** status 200 Success */ boolean;
export type IdentityUserGetTwoFactorEnabledApiArg = {
  id: string;
};
export type IdentityUserSetTwoFactorEnabledApiResponse =
  /** status 200 Success */ void;
export type IdentityUserSetTwoFactorEnabledApiArg = {
  id: string;
  enabled: boolean;
};
export type IdentityUserUpdatePasswordApiResponse =
  /** status 200 Success */ void;
export type IdentityUserUpdatePasswordApiArg = {
  id: string;
  voloAbpIdentityIdentityUserUpdatePasswordInput: VoloAbpIdentityIdentityUserUpdatePasswordInput;
};
export type IdentityUserGetRoleLookupApiResponse =
  /** status 200 Success */ VoloAbpIdentityIdentityRoleLookupDto[];
export type IdentityUserGetRoleLookupApiArg = void;
export type IdentityUserGetOrganizationUnitLookupApiResponse =
  /** status 200 Success */ VoloAbpIdentityOrganizationUnitLookupDto[];
export type IdentityUserGetOrganizationUnitLookupApiArg = void;
export type IdentityUserGetExternalLoginProvidersApiResponse =
  /** status 200 Success */ VoloAbpIdentityExternalLoginProviderDto[];
export type IdentityUserGetExternalLoginProvidersApiArg = void;
export type IdentityUserImportExternalUserApiResponse =
  /** status 200 Success */ VoloAbpIdentityIdentityUserDtoRead;
export type IdentityUserImportExternalUserApiArg = {
  voloAbpIdentityImportExternalUserInput: VoloAbpIdentityImportExternalUserInput;
};
export type IdentityUserGetListAsExcelFileApiResponse =
  /** status 200 Success */ Blob;
export type IdentityUserGetListAsExcelFileApiArg = {
  token: string;
  filter?: string;
  roleId?: string;
  organizationUnitId?: string;
  userName?: string;
  phoneNumber?: string;
  emailAddress?: string;
  name?: string;
  surname?: string;
  isLockedOut?: boolean;
  notActive?: boolean;
  emailConfirmed?: boolean;
  isExternal?: boolean;
  maxCreationTime?: string;
  minCreationTime?: string;
  maxModifitionTime?: string;
  minModifitionTime?: string;
  sorting?: string;
  skipCount?: number;
  maxResultCount?: number;
  extraProperties?: {
    [key: string]: any;
  };
};
export type IdentityUserGetListAsCsvFileApiResponse =
  /** status 200 Success */ Blob;
export type IdentityUserGetListAsCsvFileApiArg = {
  token: string;
  filter?: string;
  roleId?: string;
  organizationUnitId?: string;
  userName?: string;
  phoneNumber?: string;
  emailAddress?: string;
  name?: string;
  surname?: string;
  isLockedOut?: boolean;
  notActive?: boolean;
  emailConfirmed?: boolean;
  isExternal?: boolean;
  maxCreationTime?: string;
  minCreationTime?: string;
  maxModifitionTime?: string;
  minModifitionTime?: string;
  sorting?: string;
  skipCount?: number;
  maxResultCount?: number;
  extraProperties?: {
    [key: string]: any;
  };
};
export type IdentityUserGetDownloadTokenApiResponse =
  /** status 200 Success */ VoloAbpIdentityDownloadTokenResultDto;
export type IdentityUserGetDownloadTokenApiArg = void;
export type IdentityUserGetImportUsersSampleFileApiResponse =
  /** status 200 Success */ Blob;
export type IdentityUserGetImportUsersSampleFileApiArg = {
  fileType?: VoloAbpIdentityImportUsersFromFileType;
  token: string;
};
export type IdentityUserImportUsersFromFileApiResponse =
  /** status 200 Success */ VoloAbpIdentityImportUsersFromFileOutputRead;
export type IdentityUserImportUsersFromFileApiArg = {
  fileType?: VoloAbpIdentityImportUsersFromFileType;
  body: {
    File?: Blob;
  };
};
export type IdentityUserGetImportInvalidUsersFileApiResponse =
  /** status 200 Success */ Blob;
export type IdentityUserGetImportInvalidUsersFileApiArg = {
  token: string;
};
export type IdentityLinkUserLinkApiResponse = /** status 200 Success */ void;
export type IdentityLinkUserLinkApiArg = {
  voloAbpAccountLinkUserInput: VoloAbpAccountLinkUserInput;
};
export type IdentityLinkUserUnlinkApiResponse = /** status 200 Success */ void;
export type IdentityLinkUserUnlinkApiArg = {
  voloAbpAccountUnLinkUserInput: VoloAbpAccountUnLinkUserInput;
};
export type IdentityLinkUserIsLinkedApiResponse =
  /** status 200 Success */ boolean;
export type IdentityLinkUserIsLinkedApiArg = {
  voloAbpAccountIsLinkedInput: VoloAbpAccountIsLinkedInput;
};
export type IdentityLinkUserGenerateLinkTokenApiResponse =
  /** status 200 Success */ string;
export type IdentityLinkUserGenerateLinkTokenApiArg = void;
export type IdentityLinkUserVerifyLinkTokenApiResponse =
  /** status 200 Success */ boolean;
export type IdentityLinkUserVerifyLinkTokenApiArg = {
  voloAbpAccountVerifyLinkTokenInput: VoloAbpAccountVerifyLinkTokenInput;
};
export type IdentityLinkUserGenerateLinkLoginTokenApiResponse =
  /** status 200 Success */ string;
export type IdentityLinkUserGenerateLinkLoginTokenApiArg = void;
export type IdentityLinkUserVerifyLinkLoginTokenApiResponse =
  /** status 200 Success */ boolean;
export type IdentityLinkUserVerifyLinkLoginTokenApiArg = {
  voloAbpAccountVerifyLinkLoginTokenInput: VoloAbpAccountVerifyLinkLoginTokenInput;
};
export type IdentityLinkUserGetAllListApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosListResultDto601VoloAbpAccountLinkUserDto20VoloAbpAccountProPublicApplicationContracts20Version831020CultureNeutral20PublicKeyTokenNull;
export type IdentityLinkUserGetAllListApiArg = void;
export type IdentityUserDelegationGetDelegatedUsersApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosListResultDto601VoloAbpAccountUserDelegationDto20VoloAbpAccountProPublicApplicationContracts20Version831020CultureNeutral20PublicKeyTokenNull;
export type IdentityUserDelegationGetDelegatedUsersApiArg = void;
export type IdentityUserDelegationGetMyDelegatedUsersApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosListResultDto601VoloAbpAccountUserDelegationDto20VoloAbpAccountProPublicApplicationContracts20Version831020CultureNeutral20PublicKeyTokenNull;
export type IdentityUserDelegationGetMyDelegatedUsersApiArg = void;
export type IdentityUserDelegationGetActiveDelegationsApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosListResultDto601VoloAbpAccountUserDelegationDto20VoloAbpAccountProPublicApplicationContracts20Version831020CultureNeutral20PublicKeyTokenNull;
export type IdentityUserDelegationGetActiveDelegationsApiArg = void;
export type IdentityUserDelegationGetUserLookupApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosListResultDto601VoloAbpAccountUserLookupDto20VoloAbpAccountProPublicApplicationContracts20Version831020CultureNeutral20PublicKeyTokenNull;
export type IdentityUserDelegationGetUserLookupApiArg = {
  userName?: string;
};
export type IdentityUserDelegationDelegateNewUserApiResponse =
  /** status 200 Success */ void;
export type IdentityUserDelegationDelegateNewUserApiArg = {
  voloAbpAccountDelegateNewUserInput: VoloAbpAccountDelegateNewUserInput;
};
export type IdentityUserDelegationDeleteDelegationApiResponse =
  /** status 200 Success */ void;
export type IdentityUserDelegationDeleteDelegationApiArg = {
  id?: string;
};
export type UserLinkageShareUserWithAnotherTenantApiResponse =
  /** status 200 Success */ VoloAbpIdentityIdentityUserDtoRead;
export type UserLinkageShareUserWithAnotherTenantApiArg = {
  activ8UsersShareUserWithAnotherPartnerRequest: Activ8UsersShareUserWithAnotherPartnerRequest;
};
export type UserLinkageGetAllUserLinkagesApiResponse =
  /** status 200 Success */ Activ8UsersUserLinkageInfoRead[];
export type UserLinkageGetAllUserLinkagesApiArg = {
  userId?: string;
  tenantId?: string;
};
export type IdentityUserLookupFindByIdApiResponse =
  /** status 200 Success */ VoloAbpUsersUserDataRead;
export type IdentityUserLookupFindByIdApiArg = {
  id: string;
};
export type IdentityUserLookupFindByUserNameApiResponse =
  /** status 200 Success */ VoloAbpUsersUserDataRead;
export type IdentityUserLookupFindByUserNameApiArg = {
  userName: string;
};
export type IdentityUserLookupSearchApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosListResultDto601VoloAbpUsersUserData20VoloAbpUsersAbstractions20Version831020CultureNeutral20PublicKeyTokenNullRead;
export type IdentityUserLookupSearchApiArg = {
  sorting?: string;
  filter?: string;
  skipCount?: number;
  maxResultCount?: number;
  extraProperties?: {
    [key: string]: any;
  };
};
export type IdentityUserLookupGetCountApiResponse =
  /** status 200 Success */ number;
export type IdentityUserLookupGetCountApiArg = {
  filter?: string;
};
export type VoloAbpHttpModelingMethodParameterApiDescriptionModel = {
  name?: string | null;
  typeAsString?: string | null;
  type?: string | null;
  typeSimple?: string | null;
  isOptional?: boolean;
  defaultValue?: any | null;
};
export type VoloAbpHttpModelingReturnValueApiDescriptionModel = {
  type?: string | null;
  typeSimple?: string | null;
};
export type VoloAbpHttpModelingInterfaceMethodApiDescriptionModel = {
  name?: string | null;
  parametersOnMethod?:
    | VoloAbpHttpModelingMethodParameterApiDescriptionModel[]
    | null;
  returnValue?: VoloAbpHttpModelingReturnValueApiDescriptionModel;
};
export type VoloAbpHttpModelingControllerInterfaceApiDescriptionModel = {
  type?: string | null;
  name?: string | null;
  methods?: VoloAbpHttpModelingInterfaceMethodApiDescriptionModel[] | null;
};
export type VoloAbpHttpModelingParameterApiDescriptionModel = {
  nameOnMethod?: string | null;
  name?: string | null;
  jsonName?: string | null;
  type?: string | null;
  typeSimple?: string | null;
  isOptional?: boolean;
  defaultValue?: any | null;
  constraintTypes?: string[] | null;
  bindingSourceId?: string | null;
  descriptorName?: string | null;
};
export type VoloAbpHttpModelingActionApiDescriptionModel = {
  uniqueName?: string | null;
  name?: string | null;
  httpMethod?: string | null;
  url?: string | null;
  supportedVersions?: string[] | null;
  parametersOnMethod?:
    | VoloAbpHttpModelingMethodParameterApiDescriptionModel[]
    | null;
  parameters?: VoloAbpHttpModelingParameterApiDescriptionModel[] | null;
  returnValue?: VoloAbpHttpModelingReturnValueApiDescriptionModel;
  allowAnonymous?: boolean | null;
  implementFrom?: string | null;
};
export type VoloAbpHttpModelingControllerApiDescriptionModel = {
  controllerName?: string | null;
  controllerGroupName?: string | null;
  isRemoteService?: boolean;
  isIntegrationService?: boolean;
  apiVersion?: string | null;
  type?: string | null;
  interfaces?:
    | VoloAbpHttpModelingControllerInterfaceApiDescriptionModel[]
    | null;
  actions?: {
    [key: string]: VoloAbpHttpModelingActionApiDescriptionModel;
  } | null;
};
export type VoloAbpHttpModelingModuleApiDescriptionModel = {
  rootPath?: string | null;
  remoteServiceName?: string | null;
  controllers?: {
    [key: string]: VoloAbpHttpModelingControllerApiDescriptionModel;
  } | null;
};
export type VoloAbpHttpModelingPropertyApiDescriptionModel = {
  name?: string | null;
  jsonName?: string | null;
  type?: string | null;
  typeSimple?: string | null;
  isRequired?: boolean;
  minLength?: number | null;
  maxLength?: number | null;
  minimum?: string | null;
  maximum?: string | null;
  regex?: string | null;
};
export type VoloAbpHttpModelingTypeApiDescriptionModel = {
  baseType?: string | null;
  isEnum?: boolean;
  enumNames?: string[] | null;
  enumValues?: any[] | null;
  genericArguments?: string[] | null;
  properties?: VoloAbpHttpModelingPropertyApiDescriptionModel[] | null;
};
export type VoloAbpHttpModelingApplicationApiDescriptionModel = {
  modules?: {
    [key: string]: VoloAbpHttpModelingModuleApiDescriptionModel;
  } | null;
  types?: {
    [key: string]: VoloAbpHttpModelingTypeApiDescriptionModel;
  } | null;
};
export type VoloAbpHttpRemoteServiceValidationErrorInfo = {
  message?: string | null;
  members?: string[] | null;
};
export type VoloAbpHttpRemoteServiceErrorInfo = {
  code?: string | null;
  message?: string | null;
  details?: string | null;
  data?: {
    [key: string]: any;
  } | null;
  validationErrors?: VoloAbpHttpRemoteServiceValidationErrorInfo[] | null;
};
export type VoloAbpHttpRemoteServiceErrorResponse = {
  error?: VoloAbpHttpRemoteServiceErrorInfo;
};
export type VoloAbpAspNetCoreMvcApplicationConfigurationsApplicationLocalizationResourceDto =
  {
    texts?: {
      [key: string]: string;
    } | null;
    baseResources?: string[] | null;
  };
export type VoloAbpLocalizationLanguageInfo = {
  cultureName?: string | null;
  uiCultureName?: string | null;
  displayName?: string | null;
};
export type VoloAbpLocalizationLanguageInfoRead = {
  cultureName?: string | null;
  uiCultureName?: string | null;
  displayName?: string | null;
  twoLetterISOLanguageName?: string | null;
};
export type VoloAbpAspNetCoreMvcApplicationConfigurationsDateTimeFormatDto = {
  calendarAlgorithmType?: string | null;
  dateTimeFormatLong?: string | null;
  shortDatePattern?: string | null;
  fullDateTimePattern?: string | null;
  dateSeparator?: string | null;
  shortTimePattern?: string | null;
  longTimePattern?: string | null;
};
export type VoloAbpAspNetCoreMvcApplicationConfigurationsCurrentCultureDto = {
  displayName?: string | null;
  englishName?: string | null;
  threeLetterIsoLanguageName?: string | null;
  twoLetterIsoLanguageName?: string | null;
  isRightToLeft?: boolean;
  cultureName?: string | null;
  name?: string | null;
  nativeName?: string | null;
  dateTimeFormat?: VoloAbpAspNetCoreMvcApplicationConfigurationsDateTimeFormatDto;
};
export type VoloAbpNameValue = {
  name?: string | null;
  value?: string | null;
};
export type VoloAbpAspNetCoreMvcApplicationConfigurationsApplicationLocalizationConfigurationDto =
  {
    values?: {
      [key: string]: {
        [key: string]: string;
      };
    } | null;
    resources?: {
      [
        key: string
      ]: VoloAbpAspNetCoreMvcApplicationConfigurationsApplicationLocalizationResourceDto;
    } | null;
    languages?: VoloAbpLocalizationLanguageInfo[] | null;
    currentCulture?: VoloAbpAspNetCoreMvcApplicationConfigurationsCurrentCultureDto;
    defaultResourceName?: string | null;
    languagesMap?: {
      [key: string]: VoloAbpNameValue[];
    } | null;
    languageFilesMap?: {
      [key: string]: VoloAbpNameValue[];
    } | null;
  };
export type VoloAbpAspNetCoreMvcApplicationConfigurationsApplicationLocalizationConfigurationDtoRead =
  {
    values?: {
      [key: string]: {
        [key: string]: string;
      };
    } | null;
    resources?: {
      [
        key: string
      ]: VoloAbpAspNetCoreMvcApplicationConfigurationsApplicationLocalizationResourceDto;
    } | null;
    languages?: VoloAbpLocalizationLanguageInfoRead[] | null;
    currentCulture?: VoloAbpAspNetCoreMvcApplicationConfigurationsCurrentCultureDto;
    defaultResourceName?: string | null;
    languagesMap?: {
      [key: string]: VoloAbpNameValue[];
    } | null;
    languageFilesMap?: {
      [key: string]: VoloAbpNameValue[];
    } | null;
  };
export type VoloAbpAspNetCoreMvcApplicationConfigurationsApplicationAuthConfigurationDto =
  {
    grantedPolicies?: {
      [key: string]: boolean;
    } | null;
  };
export type VoloAbpAspNetCoreMvcApplicationConfigurationsApplicationSettingConfigurationDto =
  {
    values?: {
      [key: string]: string | null;
    } | null;
  };
export type VoloAbpAspNetCoreMvcApplicationConfigurationsCurrentUserDto = {
  isAuthenticated?: boolean;
  id?: string | null;
  tenantId?: string | null;
  impersonatorUserId?: string | null;
  impersonatorTenantId?: string | null;
  impersonatorUserName?: string | null;
  impersonatorTenantName?: string | null;
  userName?: string | null;
  name?: string | null;
  surName?: string | null;
  email?: string | null;
  emailVerified?: boolean;
  phoneNumber?: string | null;
  phoneNumberVerified?: boolean;
  roles?: string[] | null;
  sessionId?: string | null;
};
export type VoloAbpAspNetCoreMvcApplicationConfigurationsApplicationFeatureConfigurationDto =
  {
    values?: {
      [key: string]: string | null;
    } | null;
  };
export type VoloAbpAspNetCoreMvcApplicationConfigurationsApplicationGlobalFeatureConfigurationDto =
  {
    enabledFeatures?: string[] | null;
  };
export type VoloAbpAspNetCoreMvcMultiTenancyMultiTenancyInfoDto = {
  isEnabled?: boolean;
};
export type VoloAbpAspNetCoreMvcMultiTenancyCurrentTenantDto = {
  id?: string | null;
  name?: string | null;
  isAvailable?: boolean;
};
export type VoloAbpAspNetCoreMvcApplicationConfigurationsIanaTimeZone = {
  timeZoneName?: string | null;
};
export type VoloAbpAspNetCoreMvcApplicationConfigurationsWindowsTimeZone = {
  timeZoneId?: string | null;
};
export type VoloAbpAspNetCoreMvcApplicationConfigurationsTimeZone = {
  iana?: VoloAbpAspNetCoreMvcApplicationConfigurationsIanaTimeZone;
  windows?: VoloAbpAspNetCoreMvcApplicationConfigurationsWindowsTimeZone;
};
export type VoloAbpAspNetCoreMvcApplicationConfigurationsTimingDto = {
  timeZone?: VoloAbpAspNetCoreMvcApplicationConfigurationsTimeZone;
};
export type VoloAbpAspNetCoreMvcApplicationConfigurationsClockDto = {
  kind?: string | null;
};
export type VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingLocalizableStringDto =
  {
    name?: string | null;
    resource?: string | null;
  };
export type VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionPropertyApiGetDto =
  {
    isAvailable?: boolean;
  };
export type VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionPropertyApiCreateDto =
  {
    isAvailable?: boolean;
  };
export type VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionPropertyApiUpdateDto =
  {
    isAvailable?: boolean;
  };
export type VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionPropertyApiDto =
  {
    onGet?: VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionPropertyApiGetDto;
    onCreate?: VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionPropertyApiCreateDto;
    onUpdate?: VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionPropertyApiUpdateDto;
  };
export type VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionPropertyUiTableDto =
  {
    isVisible?: boolean;
  };
export type VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionPropertyUiFormDto =
  {
    isVisible?: boolean;
  };
export type VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionPropertyUiLookupDto =
  {
    url?: string | null;
    resultListPropertyName?: string | null;
    displayPropertyName?: string | null;
    valuePropertyName?: string | null;
    filterParamName?: string | null;
  };
export type VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionPropertyUiDto =
  {
    onTable?: VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionPropertyUiTableDto;
    onCreateForm?: VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionPropertyUiFormDto;
    onEditForm?: VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionPropertyUiFormDto;
    lookup?: VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionPropertyUiLookupDto;
  };
export type VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionPropertyAttributeDto =
  {
    typeSimple?: string | null;
    config?: {
      [key: string]: any;
    } | null;
  };
export type VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionPropertyDto =
  {
    type?: string | null;
    typeSimple?: string | null;
    displayName?: VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingLocalizableStringDto;
    api?: VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionPropertyApiDto;
    ui?: VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionPropertyUiDto;
    attributes?:
      | VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionPropertyAttributeDto[]
      | null;
    configuration?: {
      [key: string]: any;
    } | null;
    defaultValue?: any | null;
  };
export type VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingEntityExtensionDto =
  {
    properties?: {
      [
        key: string
      ]: VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionPropertyDto;
    } | null;
    configuration?: {
      [key: string]: any;
    } | null;
  };
export type VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingModuleExtensionDto =
  {
    entities?: {
      [
        key: string
      ]: VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingEntityExtensionDto;
    } | null;
    configuration?: {
      [key: string]: any;
    } | null;
  };
export type VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionEnumFieldDto =
  {
    name?: string | null;
    value?: any | null;
  };
export type VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionEnumDto =
  {
    fields?:
      | VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionEnumFieldDto[]
      | null;
    localizationResource?: string | null;
  };
export type VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingObjectExtensionsDto =
  {
    modules?: {
      [
        key: string
      ]: VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingModuleExtensionDto;
    } | null;
    enums?: {
      [
        key: string
      ]: VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionEnumDto;
    } | null;
  };
export type VoloAbpAspNetCoreMvcApplicationConfigurationsApplicationConfigurationDto =
  {
    localization?: VoloAbpAspNetCoreMvcApplicationConfigurationsApplicationLocalizationConfigurationDto;
    auth?: VoloAbpAspNetCoreMvcApplicationConfigurationsApplicationAuthConfigurationDto;
    setting?: VoloAbpAspNetCoreMvcApplicationConfigurationsApplicationSettingConfigurationDto;
    currentUser?: VoloAbpAspNetCoreMvcApplicationConfigurationsCurrentUserDto;
    features?: VoloAbpAspNetCoreMvcApplicationConfigurationsApplicationFeatureConfigurationDto;
    globalFeatures?: VoloAbpAspNetCoreMvcApplicationConfigurationsApplicationGlobalFeatureConfigurationDto;
    multiTenancy?: VoloAbpAspNetCoreMvcMultiTenancyMultiTenancyInfoDto;
    currentTenant?: VoloAbpAspNetCoreMvcMultiTenancyCurrentTenantDto;
    timing?: VoloAbpAspNetCoreMvcApplicationConfigurationsTimingDto;
    clock?: VoloAbpAspNetCoreMvcApplicationConfigurationsClockDto;
    objectExtensions?: VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingObjectExtensionsDto;
    extraProperties?: {
      [key: string]: any;
    } | null;
  };
export type VoloAbpAspNetCoreMvcApplicationConfigurationsApplicationConfigurationDtoRead =
  {
    localization?: VoloAbpAspNetCoreMvcApplicationConfigurationsApplicationLocalizationConfigurationDtoRead;
    auth?: VoloAbpAspNetCoreMvcApplicationConfigurationsApplicationAuthConfigurationDto;
    setting?: VoloAbpAspNetCoreMvcApplicationConfigurationsApplicationSettingConfigurationDto;
    currentUser?: VoloAbpAspNetCoreMvcApplicationConfigurationsCurrentUserDto;
    features?: VoloAbpAspNetCoreMvcApplicationConfigurationsApplicationFeatureConfigurationDto;
    globalFeatures?: VoloAbpAspNetCoreMvcApplicationConfigurationsApplicationGlobalFeatureConfigurationDto;
    multiTenancy?: VoloAbpAspNetCoreMvcMultiTenancyMultiTenancyInfoDto;
    currentTenant?: VoloAbpAspNetCoreMvcMultiTenancyCurrentTenantDto;
    timing?: VoloAbpAspNetCoreMvcApplicationConfigurationsTimingDto;
    clock?: VoloAbpAspNetCoreMvcApplicationConfigurationsClockDto;
    objectExtensions?: VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingObjectExtensionsDto;
    extraProperties?: {
      [key: string]: any;
    } | null;
  };
export type VoloAbpAspNetCoreMvcApplicationConfigurationsApplicationLocalizationDto =
  {
    resources?: {
      [
        key: string
      ]: VoloAbpAspNetCoreMvcApplicationConfigurationsApplicationLocalizationResourceDto;
    } | null;
    currentCulture?: VoloAbpAspNetCoreMvcApplicationConfigurationsCurrentCultureDto;
  };
export type VoloAbpAspNetCoreMvcMultiTenancyFindTenantResultDto = {
  success?: boolean;
  tenantId?: string | null;
  name?: string | null;
  normalizedName?: string | null;
  isActive?: boolean;
};
export type VoloAbpIdentityIdentityUserDto = {
  id?: string;
  creationTime?: string;
  creatorId?: string | null;
  lastModificationTime?: string | null;
  lastModifierId?: string | null;
  isDeleted?: boolean;
  deleterId?: string | null;
  deletionTime?: string | null;
  tenantId?: string | null;
  userName?: string | null;
  email?: string | null;
  name?: string | null;
  surname?: string | null;
  emailConfirmed?: boolean;
  phoneNumber?: string | null;
  phoneNumberConfirmed?: boolean;
  supportTwoFactor?: boolean;
  twoFactorEnabled?: boolean;
  isActive?: boolean;
  lockoutEnabled?: boolean;
  isLockedOut?: boolean;
  lockoutEnd?: string | null;
  shouldChangePasswordOnNextLogin?: boolean;
  concurrencyStamp?: string | null;
  roleNames?: string[] | null;
  accessFailedCount?: number;
  lastPasswordChangeTime?: string | null;
  isExternal?: boolean;
};
export type VoloAbpIdentityIdentityUserDtoRead = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  id?: string;
  creationTime?: string;
  creatorId?: string | null;
  lastModificationTime?: string | null;
  lastModifierId?: string | null;
  isDeleted?: boolean;
  deleterId?: string | null;
  deletionTime?: string | null;
  tenantId?: string | null;
  userName?: string | null;
  email?: string | null;
  name?: string | null;
  surname?: string | null;
  emailConfirmed?: boolean;
  phoneNumber?: string | null;
  phoneNumberConfirmed?: boolean;
  supportTwoFactor?: boolean;
  twoFactorEnabled?: boolean;
  isActive?: boolean;
  lockoutEnabled?: boolean;
  isLockedOut?: boolean;
  lockoutEnd?: string | null;
  shouldChangePasswordOnNextLogin?: boolean;
  concurrencyStamp?: string | null;
  roleNames?: string[] | null;
  accessFailedCount?: number;
  lastPasswordChangeTime?: string | null;
  isExternal?: boolean;
};
export type VoloAbpAccountRegisterDto = {
  userName: string;
  emailAddress: string;
  password: string;
  appName: string;
  returnUrl?: string | null;
  returnUrlHash?: string | null;
  captchaResponse?: string | null;
};
export type VoloAbpAccountRegisterDtoRead = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  userName: string;
  emailAddress: string;
  password: string;
  appName: string;
  returnUrl?: string | null;
  returnUrlHash?: string | null;
  captchaResponse?: string | null;
};
export type VoloAbpAccountSendPasswordResetCodeDto = {
  email: string;
  appName: string;
  returnUrl?: string | null;
  returnUrlHash?: string | null;
};
export type VoloAbpAccountVerifyPasswordResetTokenInput = {
  userId?: string;
  resetToken: string;
};
export type VoloAbpAccountResetPasswordDto = {
  userId?: string;
  resetToken: string;
  password: string;
};
export type VoloAbpAccountIdentityUserConfirmationStateDto = {
  emailConfirmed?: boolean;
  phoneNumberConfirmed?: boolean;
};
export type VoloAbpAccountSendPhoneNumberConfirmationTokenDto = {
  userId: string;
  phoneNumber?: string | null;
};
export type VoloAbpAccountSendEmailConfirmationTokenDto = {
  appName: string;
  userId: string;
  returnUrl?: string | null;
  returnUrlHash?: string | null;
};
export type VoloAbpAccountVerifyEmailConfirmationTokenInput = {
  userId: string;
  token: string;
};
export type VoloAbpAccountConfirmPhoneNumberInput = {
  userId: string;
  token: string;
};
export type VoloAbpAccountConfirmEmailInput = {
  userId: string;
  token: string;
};
export type VoloAbpAccountProfilePictureType = "None" | "Gravatar" | "Image";
export type VoloAbpAccountProfilePictureSourceDto = {
  type?: VoloAbpAccountProfilePictureType;
  source?: string | null;
  fileContent?: string | null;
};
export type VoloAbpAccountSendTwoFactorCodeInput = {
  userId: string;
  provider: string;
  token: string;
};
export type VoloAbpIdentityIdentitySecurityLogDto = {
  id?: string;
  tenantId?: string | null;
  applicationName?: string | null;
  identity?: string | null;
  action?: string | null;
  userId?: string | null;
  userName?: string | null;
  tenantName?: string | null;
  clientId?: string | null;
  correlationId?: string | null;
  clientIpAddress?: string | null;
  browserInfo?: string | null;
  creationTime?: string;
  extraProperties?: {
    [key: string]: any | null;
  } | null;
};
export type VoloAbpApplicationDtosPagedResultDto601VoloAbpIdentityIdentitySecurityLogDto20VoloAbpIdentityProApplicationContracts20Version831020CultureNeutral20PublicKeyTokenNull =
  {
    items?: VoloAbpIdentityIdentitySecurityLogDto[] | null;
    totalCount?: number;
  };
export type VoloAbpAccountVerifyAuthenticatorCodeDto = {
  recoveryCodes?: string[] | null;
};
export type VoloAbpAccountVerifyAuthenticatorCodeInput = {
  code?: string | null;
};
export type VoloAbpAccountAuthenticatorInfoDto = {
  key?: string | null;
  uri?: string | null;
};
export type VoloAbpAccountExternalProvidersExternalProviderSettingsProperty = {
  name?: string | null;
  value?: string | null;
};
export type VoloAbpAccountExternalProvidersExternalProviderItemDto = {
  name?: string | null;
  enabled?: boolean;
  properties?:
    | VoloAbpAccountExternalProvidersExternalProviderSettingsProperty[]
    | null;
};
export type VoloAbpAccountExternalProvidersExternalProviderDto = {
  providers?: VoloAbpAccountExternalProvidersExternalProviderItemDto[] | null;
};
export type VoloAbpAccountExternalProvidersExternalProviderItemWithSecretDto = {
  success?: boolean;
  name?: string | null;
  enabled?: boolean;
  properties?:
    | VoloAbpAccountExternalProvidersExternalProviderSettingsProperty[]
    | null;
  secretProperties?:
    | VoloAbpAccountExternalProvidersExternalProviderSettingsProperty[]
    | null;
};
export type VoloAbpAccountAccountSettingsDto = {
  isSelfRegistrationEnabled?: boolean;
  enableLocalLogin?: boolean;
  preventEmailEnumeration?: boolean;
};
export type VoloAbpIdentityFeaturesIdentityProTwoFactorBehaviour =
  | "Optional"
  | "Disabled"
  | "Forced";
export type VoloAbpAccountAccountTwoFactorSettingsDto = {
  twoFactorBehaviour?: VoloAbpIdentityFeaturesIdentityProTwoFactorBehaviour;
  isRememberBrowserEnabled?: boolean;
  usersCanChange?: boolean;
};
export type VoloAbpAccountAccountRecaptchaSettingsDto = {
  useCaptchaOnLogin?: boolean;
  useCaptchaOnRegistration?: boolean;
  verifyBaseUrl?: string | null;
  siteKey?: string | null;
  siteSecret?: string | null;
  version?: number;
  score?: number;
};
export type VoloAbpAccountExternalProvidersExternalProviderSettings = {
  name?: string | null;
  enabled?: boolean;
  properties?:
    | VoloAbpAccountExternalProvidersExternalProviderSettingsProperty[]
    | null;
  secretProperties?:
    | VoloAbpAccountExternalProvidersExternalProviderSettingsProperty[]
    | null;
};
export type VoloAbpAccountAccountExternalProviderSettingsDto = {
  settings?: VoloAbpAccountExternalProvidersExternalProviderSettings[] | null;
};
export type VoloAbpAccountUpdateExternalProviderDto = {
  name?: string | null;
  enabled?: boolean;
  properties?:
    | VoloAbpAccountExternalProvidersExternalProviderSettingsProperty[]
    | null;
  secretProperties?:
    | VoloAbpAccountExternalProvidersExternalProviderSettingsProperty[]
    | null;
};
export type Activ8IntegrationsActiveCampaignActiveCampaignIntegrationSettingsDto =
  {
    apiUrl?: string | null;
    apiKey?: string | null;
  };
export type Activ8CoursesProgramsCoursesLearningCourseProgramPublicApiExpressionOfInterestParticipant =
  {
    fullname?: string | null;
    contactPhone?: string | null;
    monthOfBirth?: string | null;
    yearOfBirth?: string | null;
  };
export type Activ8CoursesProgramsCoursesLearningCourseProgramPublicApiExpressionOfInterestProgramSelectionRequest =
  {
    learningCourseProgramId?: string;
    contactId?: string | null;
    participants?:
      | Activ8CoursesProgramsCoursesLearningCourseProgramPublicApiExpressionOfInterestParticipant[]
      | null;
  };
export type Activ8CoursesProgramsCoursesLearningCourseProgramPublicApiPartnerInfoResponse =
  {
    partnerName?: string | null;
  };
export type Activ8CoursesProgramsCoursesLearningCourseProgramPublicApiSummaryDto =
  {
    id?: string;
    publicName?: string | null;
    publicShortDescription?: string | null;
    conductionAddress?: string | null;
    conductionIanaTimezone?: string | null;
    programReferenceCode?: string | null;
    publicLongRichTextDescription?: string | null;
    firstSessionDateAtUtc?: string | null;
    firstSessionDate?: string | null;
    firstSessionStartTime?: string | null;
  };
export type VoloAbpApplicationDtosListResultDto601Activ8CoursesProgramsCoursesLearningCourseProgramPublicApiSummaryDto20Activ8ApplicationContracts20Version100020CultureNeutral20PublicKeyTokenNull =
  {
    items?:
      | Activ8CoursesProgramsCoursesLearningCourseProgramPublicApiSummaryDto[]
      | null;
  };
export type Activ8CoursesProgramsCoursesLearningCourseProgramPublicApiSummaryResponse =
  {
    programs?: VoloAbpApplicationDtosListResultDto601Activ8CoursesProgramsCoursesLearningCourseProgramPublicApiSummaryDto20Activ8ApplicationContracts20Version100020CultureNeutral20PublicKeyTokenNull;
    contactId?: string | null;
  };
export type Activ8CoursesProgramsCoursesLearningCourseProgramPublicApiExpressionOfInterestListProgramsRequest =
  {
    emailAddress?: string | null;
    postcode?: string | null;
    countryCode?: string | null;
    partnerMarketingConsent?: boolean;
    activ8MarketingConsent?: boolean;
  };
export type Activ8IntegrationsApiApiIntegrationSettingsDto = {
  publicApiKey?: string | null;
};
export type VoloAbpOpenIddictApplicationsDtosApplicationDto = {
  id?: string;
  applicationType?: string | null;
  clientId?: string | null;
  displayName?: string | null;
  clientType?: string | null;
  clientSecret?: string | null;
  consentType?: string | null;
  extensionGrantTypes?: string[] | null;
  postLogoutRedirectUris?: string[] | null;
  redirectUris?: string[] | null;
  allowPasswordFlow?: boolean;
  allowClientCredentialsFlow?: boolean;
  allowAuthorizationCodeFlow?: boolean;
  allowRefreshTokenFlow?: boolean;
  allowHybridFlow?: boolean;
  allowImplicitFlow?: boolean;
  allowLogoutEndpoint?: boolean;
  allowDeviceEndpoint?: boolean;
  scopes?: string[] | null;
  clientUri?: string | null;
  logoUri?: string | null;
};
export type VoloAbpOpenIddictApplicationsDtosApplicationDtoRead = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  id?: string;
  applicationType?: string | null;
  clientId?: string | null;
  displayName?: string | null;
  clientType?: string | null;
  clientSecret?: string | null;
  consentType?: string | null;
  extensionGrantTypes?: string[] | null;
  postLogoutRedirectUris?: string[] | null;
  redirectUris?: string[] | null;
  allowPasswordFlow?: boolean;
  allowClientCredentialsFlow?: boolean;
  allowAuthorizationCodeFlow?: boolean;
  allowRefreshTokenFlow?: boolean;
  allowHybridFlow?: boolean;
  allowImplicitFlow?: boolean;
  allowLogoutEndpoint?: boolean;
  allowDeviceEndpoint?: boolean;
  scopes?: string[] | null;
  clientUri?: string | null;
  logoUri?: string | null;
};
export type VoloAbpOpenIddictApplicationsDtosUpdateApplicationInput = {
  applicationType: string;
  clientId: string;
  displayName: string;
  clientType?: string | null;
  clientSecret?: string | null;
  consentType?: string | null;
  extensionGrantTypes?: string[] | null;
  postLogoutRedirectUris?: string[] | null;
  redirectUris?: string[] | null;
  allowPasswordFlow?: boolean;
  allowClientCredentialsFlow?: boolean;
  allowAuthorizationCodeFlow?: boolean;
  allowRefreshTokenFlow?: boolean;
  allowHybridFlow?: boolean;
  allowImplicitFlow?: boolean;
  allowLogoutEndpoint?: boolean;
  allowDeviceEndpoint?: boolean;
  scopes?: string[] | null;
  clientUri?: string | null;
  logoUri?: string | null;
};
export type VoloAbpOpenIddictApplicationsDtosUpdateApplicationInputRead = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  applicationType: string;
  clientId: string;
  displayName: string;
  clientType?: string | null;
  clientSecret?: string | null;
  consentType?: string | null;
  extensionGrantTypes?: string[] | null;
  postLogoutRedirectUris?: string[] | null;
  redirectUris?: string[] | null;
  allowPasswordFlow?: boolean;
  allowClientCredentialsFlow?: boolean;
  allowAuthorizationCodeFlow?: boolean;
  allowRefreshTokenFlow?: boolean;
  allowHybridFlow?: boolean;
  allowImplicitFlow?: boolean;
  allowLogoutEndpoint?: boolean;
  allowDeviceEndpoint?: boolean;
  scopes?: string[] | null;
  clientUri?: string | null;
  logoUri?: string | null;
};
export type VoloAbpApplicationDtosPagedResultDto601VoloAbpOpenIddictApplicationsDtosApplicationDto20VoloAbpOpenIddictProApplicationContracts20Version831020CultureNeutral20PublicKeyTokenNull =
  {
    items?: VoloAbpOpenIddictApplicationsDtosApplicationDto[] | null;
    totalCount?: number;
  };
export type VoloAbpApplicationDtosPagedResultDto601VoloAbpOpenIddictApplicationsDtosApplicationDto20VoloAbpOpenIddictProApplicationContracts20Version831020CultureNeutral20PublicKeyTokenNullRead =
  {
    items?: VoloAbpOpenIddictApplicationsDtosApplicationDtoRead[] | null;
    totalCount?: number;
  };
export type VoloAbpOpenIddictApplicationsDtosCreateApplicationInput = {
  applicationType: string;
  clientId: string;
  displayName: string;
  clientType?: string | null;
  clientSecret?: string | null;
  consentType?: string | null;
  extensionGrantTypes?: string[] | null;
  postLogoutRedirectUris?: string[] | null;
  redirectUris?: string[] | null;
  allowPasswordFlow?: boolean;
  allowClientCredentialsFlow?: boolean;
  allowAuthorizationCodeFlow?: boolean;
  allowRefreshTokenFlow?: boolean;
  allowHybridFlow?: boolean;
  allowImplicitFlow?: boolean;
  allowLogoutEndpoint?: boolean;
  allowDeviceEndpoint?: boolean;
  scopes?: string[] | null;
  clientUri?: string | null;
  logoUri?: string | null;
};
export type VoloAbpOpenIddictApplicationsDtosCreateApplicationInputRead = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  applicationType: string;
  clientId: string;
  displayName: string;
  clientType?: string | null;
  clientSecret?: string | null;
  consentType?: string | null;
  extensionGrantTypes?: string[] | null;
  postLogoutRedirectUris?: string[] | null;
  redirectUris?: string[] | null;
  allowPasswordFlow?: boolean;
  allowClientCredentialsFlow?: boolean;
  allowAuthorizationCodeFlow?: boolean;
  allowRefreshTokenFlow?: boolean;
  allowHybridFlow?: boolean;
  allowImplicitFlow?: boolean;
  allowLogoutEndpoint?: boolean;
  allowDeviceEndpoint?: boolean;
  scopes?: string[] | null;
  clientUri?: string | null;
  logoUri?: string | null;
};
export type VoloAbpOpenIddictApplicationsDtosApplicationTokenLifetimeDto = {
  accessTokenLifetime?: number | null;
  authorizationCodeLifetime?: number | null;
  deviceCodeLifetime?: number | null;
  identityTokenLifetime?: number | null;
  refreshTokenLifetime?: number | null;
  userCodeLifetime?: number | null;
};
export type VoloAbpAuditingEntityChangeType = "Created" | "Updated" | "Deleted";
export type VoloAbpAuditLoggingEntityPropertyChangeDto = {
  id?: string;
  tenantId?: string | null;
  entityChangeId?: string;
  newValue?: string | null;
  originalValue?: string | null;
  propertyName?: string | null;
  propertyTypeFullName?: string | null;
};
export type VoloAbpAuditLoggingEntityChangeDto = {
  id?: string;
  auditLogId?: string;
  tenantId?: string | null;
  changeTime?: string;
  changeType?: VoloAbpAuditingEntityChangeType;
  entityId?: string | null;
  entityTypeFullName?: string | null;
  propertyChanges?: VoloAbpAuditLoggingEntityPropertyChangeDto[] | null;
};
export type VoloAbpAuditLoggingEntityChangeDtoRead = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  id?: string;
  auditLogId?: string;
  tenantId?: string | null;
  changeTime?: string;
  changeType?: VoloAbpAuditingEntityChangeType;
  entityId?: string | null;
  entityTypeFullName?: string | null;
  propertyChanges?: VoloAbpAuditLoggingEntityPropertyChangeDto[] | null;
};
export type VoloAbpAuditLoggingAuditLogActionDto = {
  id?: string;
  tenantId?: string | null;
  auditLogId?: string;
  serviceName?: string | null;
  methodName?: string | null;
  parameters?: string | null;
  executionTime?: string;
  executionDuration?: number;
};
export type VoloAbpAuditLoggingAuditLogActionDtoRead = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  id?: string;
  tenantId?: string | null;
  auditLogId?: string;
  serviceName?: string | null;
  methodName?: string | null;
  parameters?: string | null;
  executionTime?: string;
  executionDuration?: number;
};
export type VoloAbpAuditLoggingAuditLogDto = {
  id?: string;
  userId?: string | null;
  userName?: string | null;
  tenantId?: string | null;
  tenantName?: string | null;
  impersonatorUserId?: string | null;
  impersonatorUserName?: string | null;
  impersonatorTenantId?: string | null;
  impersonatorTenantName?: string | null;
  executionTime?: string;
  executionDuration?: number;
  clientIpAddress?: string | null;
  clientId?: string | null;
  clientName?: string | null;
  browserInfo?: string | null;
  httpMethod?: string | null;
  url?: string | null;
  exceptions?: string | null;
  comments?: string | null;
  httpStatusCode?: number | null;
  applicationName?: string | null;
  correlationId?: string | null;
  entityChanges?: VoloAbpAuditLoggingEntityChangeDto[] | null;
  actions?: VoloAbpAuditLoggingAuditLogActionDto[] | null;
};
export type VoloAbpAuditLoggingAuditLogDtoRead = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  id?: string;
  userId?: string | null;
  userName?: string | null;
  tenantId?: string | null;
  tenantName?: string | null;
  impersonatorUserId?: string | null;
  impersonatorUserName?: string | null;
  impersonatorTenantId?: string | null;
  impersonatorTenantName?: string | null;
  executionTime?: string;
  executionDuration?: number;
  clientIpAddress?: string | null;
  clientId?: string | null;
  clientName?: string | null;
  browserInfo?: string | null;
  httpMethod?: string | null;
  url?: string | null;
  exceptions?: string | null;
  comments?: string | null;
  httpStatusCode?: number | null;
  applicationName?: string | null;
  correlationId?: string | null;
  entityChanges?: VoloAbpAuditLoggingEntityChangeDtoRead[] | null;
  actions?: VoloAbpAuditLoggingAuditLogActionDtoRead[] | null;
};
export type VoloAbpApplicationDtosPagedResultDto601VoloAbpAuditLoggingAuditLogDto20VoloAbpAuditLoggingApplicationContracts20Version831020CultureNeutral20PublicKeyTokenNull =
  {
    items?: VoloAbpAuditLoggingAuditLogDto[] | null;
    totalCount?: number;
  };
export type VoloAbpApplicationDtosPagedResultDto601VoloAbpAuditLoggingAuditLogDto20VoloAbpAuditLoggingApplicationContracts20Version831020CultureNeutral20PublicKeyTokenNullRead =
  {
    items?: VoloAbpAuditLoggingAuditLogDtoRead[] | null;
    totalCount?: number;
  };
export type SystemNetHttpStatusCode =
  | "Continue"
  | "SwitchingProtocols"
  | "Processing"
  | "EarlyHints"
  | "OK"
  | "Created"
  | "Accepted"
  | "NonAuthoritativeInformation"
  | "NoContent"
  | "ResetContent"
  | "PartialContent"
  | "MultiStatus"
  | "AlreadyReported"
  | "IMUsed"
  | "MultipleChoices"
  | "Ambiguous"
  | "MovedPermanently"
  | "Moved"
  | "Found"
  | "Redirect"
  | "SeeOther"
  | "RedirectMethod"
  | "NotModified"
  | "UseProxy"
  | "Unused"
  | "TemporaryRedirect"
  | "RedirectKeepVerb"
  | "PermanentRedirect"
  | "BadRequest"
  | "Unauthorized"
  | "PaymentRequired"
  | "Forbidden"
  | "NotFound"
  | "MethodNotAllowed"
  | "NotAcceptable"
  | "ProxyAuthenticationRequired"
  | "RequestTimeout"
  | "Conflict"
  | "Gone"
  | "LengthRequired"
  | "PreconditionFailed"
  | "RequestEntityTooLarge"
  | "RequestUriTooLong"
  | "UnsupportedMediaType"
  | "RequestedRangeNotSatisfiable"
  | "ExpectationFailed"
  | "MisdirectedRequest"
  | "UnprocessableEntity"
  | "UnprocessableContent"
  | "Locked"
  | "FailedDependency"
  | "UpgradeRequired"
  | "PreconditionRequired"
  | "TooManyRequests"
  | "RequestHeaderFieldsTooLarge"
  | "UnavailableForLegalReasons"
  | "InternalServerError"
  | "NotImplemented"
  | "BadGateway"
  | "ServiceUnavailable"
  | "GatewayTimeout"
  | "HttpVersionNotSupported"
  | "VariantAlsoNegotiates"
  | "InsufficientStorage"
  | "LoopDetected"
  | "NotExtended"
  | "NetworkAuthenticationRequired";
export type VoloAbpAuditLoggingGetErrorRateOutput = {
  data?: {
    [key: string]: number | null;
  } | null;
};
export type VoloAbpAuditLoggingGetAverageExecutionDurationPerDayOutput = {
  data?: {
    [key: string]: number | null;
  } | null;
};
export type VoloAbpApplicationDtosPagedResultDto601VoloAbpAuditLoggingEntityChangeDto20VoloAbpAuditLoggingApplicationContracts20Version831020CultureNeutral20PublicKeyTokenNull =
  {
    items?: VoloAbpAuditLoggingEntityChangeDto[] | null;
    totalCount?: number;
  };
export type VoloAbpApplicationDtosPagedResultDto601VoloAbpAuditLoggingEntityChangeDto20VoloAbpAuditLoggingApplicationContracts20Version831020CultureNeutral20PublicKeyTokenNullRead =
  {
    items?: VoloAbpAuditLoggingEntityChangeDtoRead[] | null;
    totalCount?: number;
  };
export type VoloAbpAuditLoggingEntityChangeWithUsernameDto = {
  entityChange?: VoloAbpAuditLoggingEntityChangeDto;
  userName?: string | null;
};
export type VoloAbpAuditLoggingEntityChangeWithUsernameDtoRead = {
  entityChange?: VoloAbpAuditLoggingEntityChangeDtoRead;
  userName?: string | null;
};
export type VoloAbpIdentityIdentityClaimValueType =
  | "String"
  | "Int"
  | "Boolean"
  | "DateTime";
export type VoloAbpIdentityClaimTypeDto = {
  id?: string;
  name?: string | null;
  required?: boolean;
  isStatic?: boolean;
  regex?: string | null;
  regexDescription?: string | null;
  description?: string | null;
  valueType?: VoloAbpIdentityIdentityClaimValueType;
  valueTypeAsString?: string | null;
  concurrencyStamp?: string | null;
};
export type VoloAbpIdentityClaimTypeDtoRead = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  id?: string;
  name?: string | null;
  required?: boolean;
  isStatic?: boolean;
  regex?: string | null;
  regexDescription?: string | null;
  description?: string | null;
  valueType?: VoloAbpIdentityIdentityClaimValueType;
  valueTypeAsString?: string | null;
  concurrencyStamp?: string | null;
};
export type VoloAbpApplicationDtosPagedResultDto601VoloAbpIdentityClaimTypeDto20VoloAbpIdentityProApplicationContracts20Version831020CultureNeutral20PublicKeyTokenNull =
  {
    items?: VoloAbpIdentityClaimTypeDto[] | null;
    totalCount?: number;
  };
export type VoloAbpApplicationDtosPagedResultDto601VoloAbpIdentityClaimTypeDto20VoloAbpIdentityProApplicationContracts20Version831020CultureNeutral20PublicKeyTokenNullRead =
  {
    items?: VoloAbpIdentityClaimTypeDtoRead[] | null;
    totalCount?: number;
  };
export type VoloAbpIdentityCreateClaimTypeDto = {
  name: string;
  required?: boolean;
  regex?: string | null;
  regexDescription?: string | null;
  description?: string | null;
  valueType?: VoloAbpIdentityIdentityClaimValueType;
};
export type VoloAbpIdentityCreateClaimTypeDtoRead = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  name: string;
  required?: boolean;
  regex?: string | null;
  regexDescription?: string | null;
  description?: string | null;
  valueType?: VoloAbpIdentityIdentityClaimValueType;
};
export type VoloAbpIdentityUpdateClaimTypeDto = {
  name: string;
  required?: boolean;
  regex?: string | null;
  regexDescription?: string | null;
  description?: string | null;
  valueType?: VoloAbpIdentityIdentityClaimValueType;
  concurrencyStamp?: string | null;
};
export type VoloAbpIdentityUpdateClaimTypeDtoRead = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  name: string;
  required?: boolean;
  regex?: string | null;
  regexDescription?: string | null;
  description?: string | null;
  valueType?: VoloAbpIdentityIdentityClaimValueType;
  concurrencyStamp?: string | null;
};
export type Activ8DataDataImportTriggerParticipantDataImportRequest = {
  importFileBlobUrl?: string | null;
  assignToCourseProgramId?: string | null;
};
export type SaaSKitCoreModelsDtosStorageStorageBlobPropertiesDto = {
  fileId?: string | null;
  partitionId?: string | null;
  fileCategory?: string | null;
  entityTypeName?: string | null;
  entityFriendlyId?: string | null;
  isPubliclyViewable?: boolean;
  thumbnailUrl?: string | null;
  fileName?: string | null;
  contentType?: string | null;
  fileExtension?: string | null;
};
export type Activ8CoursesTemplatesPrepareResourceOperationResponse = {
  blobReference?: string | null;
  properties?: SaaSKitCoreModelsDtosStorageStorageBlobPropertiesDto;
  blobToken?: string | null;
};
export type VoloFileManagementDirectoriesDirectoryDescriptorDto = {
  id?: string;
  creationTime?: string;
  creatorId?: string | null;
  lastModificationTime?: string | null;
  lastModifierId?: string | null;
  name?: string | null;
  parentId?: string | null;
  concurrencyStamp?: string | null;
};
export type VoloFileManagementDirectoriesDirectoryDescriptorDtoRead = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  id?: string;
  creationTime?: string;
  creatorId?: string | null;
  lastModificationTime?: string | null;
  lastModifierId?: string | null;
  name?: string | null;
  parentId?: string | null;
  concurrencyStamp?: string | null;
};
export type VoloFileManagementDirectoriesRenameDirectoryInput = {
  name: string;
  concurrencyStamp?: string | null;
};
export type VoloFileManagementDirectoriesDirectoryDescriptorInfoDto = {
  id?: string;
  name?: string | null;
  parentId?: string | null;
  hasChildren?: boolean;
};
export type VoloAbpApplicationDtosListResultDto601VoloFileManagementDirectoriesDirectoryDescriptorInfoDto20VoloFileManagementApplicationContracts20Version831020CultureNeutral20PublicKeyTokenNull =
  {
    items?: VoloFileManagementDirectoriesDirectoryDescriptorInfoDto[] | null;
  };
export type VoloFileManagementDirectoriesCreateDirectoryInput = {
  parentId?: string | null;
  name: string;
};
export type VoloFileManagementDirectoriesCreateDirectoryInputRead = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  parentId?: string | null;
  name: string;
};
export type VoloFileManagementFilesFileIconType = "FontAwesome" | "Url";
export type VoloFileManagementFilesFileIconInfo = {
  icon?: string | null;
  type?: VoloFileManagementFilesFileIconType;
};
export type VoloFileManagementDirectoriesDirectoryContentDto = {
  id?: string;
  creationTime?: string;
  creatorId?: string | null;
  lastModificationTime?: string | null;
  lastModifierId?: string | null;
  name?: string | null;
  isDirectory?: boolean;
  size?: number;
  iconInfo?: VoloFileManagementFilesFileIconInfo;
  concurrencyStamp?: string | null;
};
export type VoloFileManagementDirectoriesDirectoryContentDtoRead = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  id?: string;
  creationTime?: string;
  creatorId?: string | null;
  lastModificationTime?: string | null;
  lastModifierId?: string | null;
  name?: string | null;
  isDirectory?: boolean;
  size?: number;
  iconInfo?: VoloFileManagementFilesFileIconInfo;
  concurrencyStamp?: string | null;
};
export type VoloAbpApplicationDtosPagedResultDto601VoloFileManagementDirectoriesDirectoryContentDto20VoloFileManagementApplicationContracts20Version831020CultureNeutral20PublicKeyTokenNull =
  {
    items?: VoloFileManagementDirectoriesDirectoryContentDto[] | null;
    totalCount?: number;
  };
export type VoloAbpApplicationDtosPagedResultDto601VoloFileManagementDirectoriesDirectoryContentDto20VoloFileManagementApplicationContracts20Version831020CultureNeutral20PublicKeyTokenNullRead =
  {
    items?: VoloFileManagementDirectoriesDirectoryContentDtoRead[] | null;
    totalCount?: number;
  };
export type VoloFileManagementDirectoriesMoveDirectoryInput = {
  id?: string;
  newParentId?: string | null;
  concurrencyStamp?: string | null;
};
export type VoloSaasHostDtosEditionDto = {
  id?: string;
  displayName?: string | null;
  planId?: string | null;
  planName?: string | null;
  concurrencyStamp?: string | null;
  tenantCount?: number;
};
export type VoloSaasHostDtosEditionDtoRead = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  id?: string;
  displayName?: string | null;
  planId?: string | null;
  planName?: string | null;
  concurrencyStamp?: string | null;
  tenantCount?: number;
};
export type VoloSaasHostDtosEditionUpdateDto = {
  displayName: string;
  planId?: string | null;
  concurrencyStamp?: string | null;
};
export type VoloSaasHostDtosEditionUpdateDtoRead = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  displayName: string;
  planId?: string | null;
  concurrencyStamp?: string | null;
};
export type VoloAbpApplicationDtosPagedResultDto601VoloSaasHostDtosEditionDto20VoloSaasHostApplicationContracts20Version831020CultureNeutral20PublicKeyTokenNull =
  {
    items?: VoloSaasHostDtosEditionDto[] | null;
    totalCount?: number;
  };
export type VoloAbpApplicationDtosPagedResultDto601VoloSaasHostDtosEditionDto20VoloSaasHostApplicationContracts20Version831020CultureNeutral20PublicKeyTokenNullRead =
  {
    items?: VoloSaasHostDtosEditionDtoRead[] | null;
    totalCount?: number;
  };
export type VoloSaasHostDtosEditionCreateDto = {
  displayName: string;
  planId?: string | null;
};
export type VoloSaasHostDtosEditionCreateDtoRead = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  displayName: string;
  planId?: string | null;
};
export type VoloSaasHostGetEditionUsageStatisticsResultDto = {
  data?: {
    [key: string]: number | null;
  } | null;
};
export type VoloPaymentPlansPlanDto = {
  id?: string;
  name?: string | null;
  concurrencyStamp?: string | null;
};
export type VoloPaymentPlansPlanDtoRead = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  id?: string;
  name?: string | null;
  concurrencyStamp?: string | null;
};
export type VoloPaymentRequestsPaymentType = "OneTime" | "Subscription";
export type VoloPaymentRequestsPaymentRequestProductDto = {
  paymentRequestId?: string;
  code?: string | null;
  name?: string | null;
  unitPrice?: number;
  count?: number;
  totalPrice?: number;
  paymentType?: VoloPaymentRequestsPaymentType;
  planId?: string;
  extraProperties?: {
    [key: string]: any;
  } | null;
};
export type VoloPaymentRequestsPaymentRequestState =
  | "Waiting"
  | "Completed"
  | "Failed"
  | "Refunded";
export type VoloPaymentRequestsPaymentRequestWithDetailsDto = {
  id?: string;
  products?: VoloPaymentRequestsPaymentRequestProductDto[] | null;
  currency?: string | null;
  state?: VoloPaymentRequestsPaymentRequestState;
  failReason?: string | null;
  emailSendDate?: string | null;
  gateway?: string | null;
  externalSubscriptionId?: string | null;
  totalPrice?: number;
  creationTime?: string;
};
export type VoloPaymentRequestsPaymentRequestWithDetailsDtoRead = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  id?: string;
  products?: VoloPaymentRequestsPaymentRequestProductDto[] | null;
  currency?: string | null;
  state?: VoloPaymentRequestsPaymentRequestState;
  failReason?: string | null;
  emailSendDate?: string | null;
  gateway?: string | null;
  externalSubscriptionId?: string | null;
  totalPrice?: number;
  creationTime?: string;
};
export type VoloAbpSettingManagementEmailSettingsDto = {
  smtpHost?: string | null;
  smtpPort?: number;
  smtpUserName?: string | null;
  smtpPassword?: string | null;
  smtpDomain?: string | null;
  smtpEnableSsl?: boolean;
  smtpUseDefaultCredentials?: boolean;
  defaultFromAddress?: string | null;
  defaultFromDisplayName?: string | null;
};
export type VoloAbpSettingManagementUpdateEmailSettingsDto = {
  smtpHost?: string | null;
  smtpPort?: number;
  smtpUserName?: string | null;
  smtpPassword?: string | null;
  smtpDomain?: string | null;
  smtpEnableSsl?: boolean;
  smtpUseDefaultCredentials?: boolean;
  defaultFromAddress: string;
  defaultFromDisplayName: string;
};
export type VoloAbpSettingManagementSendTestEmailInput = {
  senderEmailAddress: string;
  targetEmailAddress: string;
  subject: string;
  body?: string | null;
};
export type Activ8FacilitatorsFacilitatorDto = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  id?: string;
  creationTime?: string;
  creatorId?: string | null;
  lastModificationTime?: string | null;
  lastModifierId?: string | null;
  isDeleted?: boolean;
  deleterId?: string | null;
  deletionTime?: string | null;
  tenantId?: string | null;
  userName?: string | null;
  email?: string | null;
  name?: string | null;
  surname?: string | null;
  emailConfirmed?: boolean;
  phoneNumber?: string | null;
  phoneNumberConfirmed?: boolean;
  supportTwoFactor?: boolean;
  twoFactorEnabled?: boolean;
  isActive?: boolean;
  lockoutEnabled?: boolean;
  isLockedOut?: boolean;
  lockoutEnd?: string | null;
  shouldChangePasswordOnNextLogin?: boolean;
  concurrencyStamp?: string | null;
  roleNames?: string[] | null;
  accessFailedCount?: number;
  lastPasswordChangeTime?: string | null;
  isExternal?: boolean;
  address?: string | null;
};
export type Activ8FacilitatorsFacilitatorCreateDto = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  userName: string;
  name?: string | null;
  surname?: string | null;
  email: string;
  phoneNumber?: string | null;
  isActive?: boolean;
  shouldChangePasswordOnNextLogin?: boolean;
  lockoutEnabled?: boolean;
  roleNames?: string[] | null;
  organizationUnitIds?: string[] | null;
  password: string;
  sendConfirmationEmail?: boolean;
  emailConfirmed?: boolean;
  phoneNumberConfirmed?: boolean;
};
export type VoloAbpApplicationDtosPagedResultDto601Activ8FacilitatorsFacilitatorDto20Activ8ApplicationContracts20Version100020CultureNeutral20PublicKeyTokenNull =
  {
    items?: Activ8FacilitatorsFacilitatorDto[] | null;
    totalCount?: number;
  };
export type Activ8FacilitatorsFacilitatorUpdateDto = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  userName: string;
  name?: string | null;
  surname?: string | null;
  email: string;
  phoneNumber?: string | null;
  isActive?: boolean;
  shouldChangePasswordOnNextLogin?: boolean;
  lockoutEnabled?: boolean;
  roleNames?: string[] | null;
  organizationUnitIds?: string[] | null;
  emailConfirmed?: boolean;
  phoneNumberConfirmed?: boolean;
  concurrencyStamp?: string | null;
};
export type VoloAbpFeatureManagementFeatureProviderDto = {
  name?: string | null;
  key?: string | null;
};
export type VoloAbpValidationStringValuesIValueValidator = {};
export type VoloAbpValidationStringValuesIValueValidatorRead = {
  name?: string | null;
  properties?: {
    [key: string]: any;
  } | null;
};
export type VoloAbpValidationStringValuesIStringValueType = {
  validator?: VoloAbpValidationStringValuesIValueValidator;
};
export type VoloAbpValidationStringValuesIStringValueTypeRead = {
  name?: string | null;
  properties?: {
    [key: string]: any | null;
  } | null;
  validator?: VoloAbpValidationStringValuesIValueValidatorRead;
};
export type VoloAbpFeatureManagementFeatureDto = {
  name?: string | null;
  displayName?: string | null;
  value?: string | null;
  provider?: VoloAbpFeatureManagementFeatureProviderDto;
  description?: string | null;
  valueType?: VoloAbpValidationStringValuesIStringValueType;
  depth?: number;
  parentName?: string | null;
};
export type VoloAbpFeatureManagementFeatureDtoRead = {
  name?: string | null;
  displayName?: string | null;
  value?: string | null;
  provider?: VoloAbpFeatureManagementFeatureProviderDto;
  description?: string | null;
  valueType?: VoloAbpValidationStringValuesIStringValueTypeRead;
  depth?: number;
  parentName?: string | null;
};
export type VoloAbpFeatureManagementFeatureGroupDto = {
  name?: string | null;
  displayName?: string | null;
  features?: VoloAbpFeatureManagementFeatureDto[] | null;
};
export type VoloAbpFeatureManagementFeatureGroupDtoRead = {
  name?: string | null;
  displayName?: string | null;
  features?: VoloAbpFeatureManagementFeatureDtoRead[] | null;
};
export type VoloAbpFeatureManagementGetFeatureListResultDto = {
  groups?: VoloAbpFeatureManagementFeatureGroupDto[] | null;
};
export type VoloAbpFeatureManagementGetFeatureListResultDtoRead = {
  groups?: VoloAbpFeatureManagementFeatureGroupDtoRead[] | null;
};
export type VoloAbpFeatureManagementUpdateFeatureDto = {
  name?: string | null;
  value?: string | null;
};
export type VoloAbpFeatureManagementUpdateFeaturesDto = {
  features?: VoloAbpFeatureManagementUpdateFeatureDto[] | null;
};
export type VoloFileManagementFilesFileDescriptorDto = {
  id?: string;
  creationTime?: string;
  creatorId?: string | null;
  lastModificationTime?: string | null;
  lastModifierId?: string | null;
  directoryId?: string | null;
  name?: string | null;
  mimeType?: string | null;
  size?: number;
  concurrencyStamp?: string | null;
};
export type VoloFileManagementFilesFileDescriptorDtoRead = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  id?: string;
  creationTime?: string;
  creatorId?: string | null;
  lastModificationTime?: string | null;
  lastModifierId?: string | null;
  directoryId?: string | null;
  name?: string | null;
  mimeType?: string | null;
  size?: number;
  concurrencyStamp?: string | null;
};
export type VoloFileManagementFilesRenameFileInput = {
  name: string;
  concurrencyStamp?: string | null;
};
export type VoloAbpApplicationDtosListResultDto601VoloFileManagementFilesFileDescriptorDto20VoloFileManagementApplicationContracts20Version831020CultureNeutral20PublicKeyTokenNull =
  {
    items?: VoloFileManagementFilesFileDescriptorDto[] | null;
  };
export type VoloAbpApplicationDtosListResultDto601VoloFileManagementFilesFileDescriptorDto20VoloFileManagementApplicationContracts20Version831020CultureNeutral20PublicKeyTokenNullRead =
  {
    items?: VoloFileManagementFilesFileDescriptorDtoRead[] | null;
  };
export type VoloFileManagementFilesMoveFileInput = {
  id?: string;
  newDirectoryId?: string | null;
  concurrencyStamp?: string | null;
};
export type VoloFileManagementFilesFileUploadPreInfoDto = {
  fileName?: string | null;
  doesExist?: boolean;
  hasValidName?: boolean;
};
export type VoloFileManagementFilesFileUploadPreInfoRequest = {
  directoryId?: string | null;
  fileName?: string | null;
  size?: number;
};
export type VoloFileManagementFilesDownloadTokenResultDto = {
  token?: string | null;
};
export type VoloAbpLanguageManagementDtoLanguageDto = {
  id?: string;
  creationTime?: string;
  creatorId?: string | null;
  cultureName?: string | null;
  uiCultureName?: string | null;
  displayName?: string | null;
  isEnabled?: boolean;
  isDefaultLanguage?: boolean;
  concurrencyStamp?: string | null;
};
export type VoloAbpLanguageManagementDtoLanguageDtoRead = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  id?: string;
  creationTime?: string;
  creatorId?: string | null;
  cultureName?: string | null;
  uiCultureName?: string | null;
  displayName?: string | null;
  isEnabled?: boolean;
  isDefaultLanguage?: boolean;
  concurrencyStamp?: string | null;
};
export type VoloAbpApplicationDtosListResultDto601VoloAbpLanguageManagementDtoLanguageDto20VoloAbpLanguageManagementApplicationContracts20Version831020CultureNeutral20PublicKeyTokenNull =
  {
    items?: VoloAbpLanguageManagementDtoLanguageDto[] | null;
  };
export type VoloAbpApplicationDtosListResultDto601VoloAbpLanguageManagementDtoLanguageDto20VoloAbpLanguageManagementApplicationContracts20Version831020CultureNeutral20PublicKeyTokenNullRead =
  {
    items?: VoloAbpLanguageManagementDtoLanguageDtoRead[] | null;
  };
export type VoloAbpApplicationDtosPagedResultDto601VoloAbpLanguageManagementDtoLanguageDto20VoloAbpLanguageManagementApplicationContracts20Version831020CultureNeutral20PublicKeyTokenNull =
  {
    items?: VoloAbpLanguageManagementDtoLanguageDto[] | null;
    totalCount?: number;
  };
export type VoloAbpApplicationDtosPagedResultDto601VoloAbpLanguageManagementDtoLanguageDto20VoloAbpLanguageManagementApplicationContracts20Version831020CultureNeutral20PublicKeyTokenNullRead =
  {
    items?: VoloAbpLanguageManagementDtoLanguageDtoRead[] | null;
    totalCount?: number;
  };
export type VoloAbpLanguageManagementDtoCreateLanguageDto = {
  displayName?: string | null;
  cultureName?: string | null;
  uiCultureName?: string | null;
  isEnabled?: boolean;
};
export type VoloAbpLanguageManagementDtoCreateLanguageDtoRead = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  displayName?: string | null;
  cultureName?: string | null;
  uiCultureName?: string | null;
  isEnabled?: boolean;
};
export type VoloAbpLanguageManagementDtoUpdateLanguageDto = {
  displayName?: string | null;
  isEnabled?: boolean;
  concurrencyStamp?: string | null;
};
export type VoloAbpLanguageManagementDtoUpdateLanguageDtoRead = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  displayName?: string | null;
  isEnabled?: boolean;
  concurrencyStamp?: string | null;
};
export type VoloAbpLanguageManagementDtoLanguageResourceDto = {
  name?: string | null;
};
export type VoloAbpLanguageManagementDtoCultureInfoDto = {
  displayName?: string | null;
  name?: string | null;
};
export type VoloAbpLanguageManagementDtoLanguageTextDto = {
  resourceName?: string | null;
  cultureName?: string | null;
  baseCultureName?: string | null;
  baseValue?: string | null;
  name?: string | null;
  value?: string | null;
};
export type VoloAbpApplicationDtosPagedResultDto601VoloAbpLanguageManagementDtoLanguageTextDto20VoloAbpLanguageManagementApplicationContracts20Version831020CultureNeutral20PublicKeyTokenNull =
  {
    items?: VoloAbpLanguageManagementDtoLanguageTextDto[] | null;
    totalCount?: number;
  };
export type Activ8CoursesProgramsLearningCourseProgramStatus =
  | "Undefined"
  | "Draft"
  | "Live"
  | "Complete"
  | "Cancelled";
export type SaaSKitCoreModelsDtosStorageStorageBlobDto = {
  contentUrl?: string | null;
  fileSizeBytes?: number;
  creationTime?: string | null;
  modificationTime?: string | null;
  properties?: SaaSKitCoreModelsDtosStorageStorageBlobPropertiesDto;
  metadata?: {
    [key: string]: string;
  } | null;
};
export type Activ8CoursesProgramsCoursesLearningCourseProgramStatisticsDto = {
  averageParticipantModulesScorePercentage?: number | null;
  averageParticipantModuleCompletionPercentage?: number | null;
  totalContentViews?: number;
};
export type Activ8CoursesProgramsFacilitatorsFacilitatorToLearningCourseProgramAssignmentDto =
  {
    id?: string;
    creationTime?: string;
    creatorId?: string | null;
    lastModificationTime?: string | null;
    lastModifierId?: string | null;
    isDeleted?: boolean;
    deleterId?: string | null;
    deletionTime?: string | null;
    creator?: VoloAbpIdentityIdentityUserDto;
    lastModifier?: VoloAbpIdentityIdentityUserDto;
    deleter?: VoloAbpIdentityIdentityUserDto;
    learningCourseProgramId?: string;
    facilitatorIdentityUserId?: string;
    lastDownloadOfCourseContentAt?: string | null;
    facilitatorIdentityUser?: VoloAbpIdentityIdentityUserDto;
    learningCourseProgram?: Activ8CoursesProgramsCoursesLearningCourseProgramDto;
    isUsingOutdatedCourseContent?: boolean;
    contentViewCount?: number;
  };
export type Activ8CoursesProgramsFacilitatorsFacilitatorToLearningCourseProgramAssignmentDtoRead =
  {
    id?: string;
    creationTime?: string;
    creatorId?: string | null;
    lastModificationTime?: string | null;
    lastModifierId?: string | null;
    isDeleted?: boolean;
    deleterId?: string | null;
    deletionTime?: string | null;
    creator?: VoloAbpIdentityIdentityUserDtoRead;
    lastModifier?: VoloAbpIdentityIdentityUserDtoRead;
    deleter?: VoloAbpIdentityIdentityUserDtoRead;
    learningCourseProgramId?: string;
    facilitatorIdentityUserId?: string;
    lastDownloadOfCourseContentAt?: string | null;
    facilitatorIdentityUser?: VoloAbpIdentityIdentityUserDtoRead;
    learningCourseProgram?: Activ8CoursesProgramsCoursesLearningCourseProgramDto;
    isUsingOutdatedCourseContent?: boolean;
    contentViewCount?: number;
  };
export type Activ8CoursesProgramsCoursesLearningCourseProgramSessionDto = {
  id?: string;
  creationTime?: string;
  creatorId?: string | null;
  lastModificationTime?: string | null;
  lastModifierId?: string | null;
  learningCourseProgramId?: string;
  learningCourseTemplateContentChapterId?: string;
  learningCourseTemplateContentSessionId?: string;
  sessionDate?: string | null;
  sessionStartTime?: string | null;
  sessionFinishTime?: string | null;
};
export type Activ8CoursesProgramsCoursesLearningCourseProgramDto = {
  id?: string;
  creationTime?: string;
  creatorId?: string | null;
  lastModificationTime?: string | null;
  lastModifierId?: string | null;
  creator?: VoloAbpIdentityIdentityUserDto;
  lastModifier?: VoloAbpIdentityIdentityUserDto;
  internalName?: string | null;
  publicName?: string | null;
  publicShortDescription?: string | null;
  learningCourseTemplateId?: string;
  status?: Activ8CoursesProgramsLearningCourseProgramStatus;
  isListedOnPartnerPublicWebsite?: boolean;
  conductionAddress?: string | null;
  conductionIanaTimezone?: string | null;
  supportContactEmail?: string | null;
  supportContactPhone?: string | null;
  programReferenceCode?: string | null;
  bannerImage?: {
    [key: string]: SaaSKitCoreModelsDtosStorageStorageBlobDto;
  } | null;
  statistics?: Activ8CoursesProgramsCoursesLearningCourseProgramStatisticsDto;
  publicLongRichTextDescription?: string | null;
  cancellationReasonMessage?: string | null;
  facilitators?:
    | Activ8CoursesProgramsFacilitatorsFacilitatorToLearningCourseProgramAssignmentDto[]
    | null;
  sessions?:
    | Activ8CoursesProgramsCoursesLearningCourseProgramSessionDto[]
    | null;
};
export type Activ8CoursesProgramsCoursesLearningCourseProgramDtoRead = {
  id?: string;
  creationTime?: string;
  creatorId?: string | null;
  lastModificationTime?: string | null;
  lastModifierId?: string | null;
  creator?: VoloAbpIdentityIdentityUserDtoRead;
  lastModifier?: VoloAbpIdentityIdentityUserDtoRead;
  internalName?: string | null;
  publicName?: string | null;
  publicShortDescription?: string | null;
  learningCourseTemplateId?: string;
  status?: Activ8CoursesProgramsLearningCourseProgramStatus;
  isListedOnPartnerPublicWebsite?: boolean;
  conductionAddress?: string | null;
  conductionIanaTimezone?: string | null;
  supportContactEmail?: string | null;
  supportContactPhone?: string | null;
  programReferenceCode?: string | null;
  bannerImage?: {
    [key: string]: SaaSKitCoreModelsDtosStorageStorageBlobDto;
  } | null;
  statistics?: Activ8CoursesProgramsCoursesLearningCourseProgramStatisticsDto;
  publicLongRichTextDescription?: string | null;
  cancellationReasonMessage?: string | null;
  facilitators?:
    | Activ8CoursesProgramsFacilitatorsFacilitatorToLearningCourseProgramAssignmentDtoRead[]
    | null;
  sessions?:
    | Activ8CoursesProgramsCoursesLearningCourseProgramSessionDto[]
    | null;
};
export type Activ8CoursesProgramsCoursesLearningCourseProgramSessionCreateOrUpdateDto =
  {
    id?: string;
    learningCourseProgramId?: string;
    learningCourseTemplateContentChapterId?: string;
    learningCourseTemplateContentSessionId?: string;
    sessionDate?: string | null;
    sessionStartTime?: string | null;
    sessionFinishTime?: string | null;
  };
export type Activ8CoursesProgramsCoursesLearningCourseProgramUpdateDto = {
  internalName?: string | null;
  publicName?: string | null;
  publicShortDescription?: string | null;
  publicLongRichTextDescription?: string | null;
  status?: Activ8CoursesProgramsLearningCourseProgramStatus;
  isListedOnPartnerPublicWebsite?: boolean;
  conductionAddress?: string | null;
  conductionIanaTimezone?: string | null;
  supportContactEmail?: string | null;
  supportContactPhone?: string | null;
  bannerImage?: {
    [key: string]: SaaSKitCoreModelsDtosStorageStorageBlobDto;
  } | null;
  sessions?:
    | Activ8CoursesProgramsCoursesLearningCourseProgramSessionCreateOrUpdateDto[]
    | null;
};
export type Activ8CoursesProgramsCoursesLearningCourseProgramCreateDto = {
  internalName?: string | null;
  publicName?: string | null;
  publicShortDescription?: string | null;
  publicLongRichTextDescription?: string | null;
  status?: Activ8CoursesProgramsLearningCourseProgramStatus;
  isListedOnPartnerPublicWebsite?: boolean;
  conductionAddress?: string | null;
  conductionIanaTimezone?: string | null;
  supportContactEmail?: string | null;
  supportContactPhone?: string | null;
  bannerImage?: {
    [key: string]: SaaSKitCoreModelsDtosStorageStorageBlobDto;
  } | null;
  sessions?:
    | Activ8CoursesProgramsCoursesLearningCourseProgramSessionCreateOrUpdateDto[]
    | null;
  newId?: string;
  learningCourseTemplateId?: string;
};
export type Activ8CoursesProgramsCoursesLearningCourseProgramSummaryDto = {
  id?: string;
  creationTime?: string;
  creatorId?: string | null;
  lastModificationTime?: string | null;
  lastModifierId?: string | null;
  creator?: VoloAbpIdentityIdentityUserDto;
  lastModifier?: VoloAbpIdentityIdentityUserDto;
  internalName?: string | null;
  publicName?: string | null;
  publicShortDescription?: string | null;
  learningCourseTemplateId?: string;
  status?: Activ8CoursesProgramsLearningCourseProgramStatus;
  isListedOnPartnerPublicWebsite?: boolean;
  conductionAddress?: string | null;
  conductionIanaTimezone?: string | null;
  supportContactEmail?: string | null;
  supportContactPhone?: string | null;
  programReferenceCode?: string | null;
  bannerImage?: {
    [key: string]: SaaSKitCoreModelsDtosStorageStorageBlobDto;
  } | null;
  statistics?: Activ8CoursesProgramsCoursesLearningCourseProgramStatisticsDto;
};
export type Activ8CoursesProgramsCoursesLearningCourseProgramSummaryDtoRead = {
  id?: string;
  creationTime?: string;
  creatorId?: string | null;
  lastModificationTime?: string | null;
  lastModifierId?: string | null;
  creator?: VoloAbpIdentityIdentityUserDtoRead;
  lastModifier?: VoloAbpIdentityIdentityUserDtoRead;
  internalName?: string | null;
  publicName?: string | null;
  publicShortDescription?: string | null;
  learningCourseTemplateId?: string;
  status?: Activ8CoursesProgramsLearningCourseProgramStatus;
  isListedOnPartnerPublicWebsite?: boolean;
  conductionAddress?: string | null;
  conductionIanaTimezone?: string | null;
  supportContactEmail?: string | null;
  supportContactPhone?: string | null;
  programReferenceCode?: string | null;
  bannerImage?: {
    [key: string]: SaaSKitCoreModelsDtosStorageStorageBlobDto;
  } | null;
  statistics?: Activ8CoursesProgramsCoursesLearningCourseProgramStatisticsDto;
};
export type VoloAbpApplicationDtosPagedResultDto601Activ8CoursesProgramsCoursesLearningCourseProgramSummaryDto20Activ8ApplicationContracts20Version100020CultureNeutral20PublicKeyTokenNull =
  {
    items?:
      | Activ8CoursesProgramsCoursesLearningCourseProgramSummaryDto[]
      | null;
    totalCount?: number;
  };
export type VoloAbpApplicationDtosPagedResultDto601Activ8CoursesProgramsCoursesLearningCourseProgramSummaryDto20Activ8ApplicationContracts20Version100020CultureNeutral20PublicKeyTokenNullRead =
  {
    items?:
      | Activ8CoursesProgramsCoursesLearningCourseProgramSummaryDtoRead[]
      | null;
    totalCount?: number;
  };
export type Activ8CoursesProgramsCoursesProgramStatusSetRequestDto = {
  status?: Activ8CoursesProgramsLearningCourseProgramStatus;
  cancellationReasonMessage?: string | null;
  learningCourseProgramId: string;
};
export type Activ8CoursesProgramsParticipantToLearningCourseProgramAssignmentStatus =
  "Undefined" | "Registered" | "Started" | "Completed";
export type Activ8CoursesProgramsProgressionParticipantToLearningCourseProgramAssignmentProgressionInfoSummary =
  {
    inProgressCount?: number;
    notStartedCount?: number;
    incompleteCount?: number;
    completeCount?: number;
    totalModulesCount?: number;
  };
export type Activ8CoursesProgramsProgressionParticipantToLearningCourseProgramAssignmentSessionProgressionStatus =
  "NotRequired" | "NotStarted" | "InProgress" | "Complete";
export type Activ8CoursesContentQuizLearningCourseContentQuizQuestionType =
  | "Undefined"
  | "MultipleChoice"
  | "FreeTextMultiLine";
export type Activ8CoursesContentQuizResultsLearningCourseContentQuizQuestionResultMultipleChoiceOption =
  {
    id?: string;
    learningCourseContentQuizQuestionMultipleChoiceOptionId?: string;
    wasSelectedByUser?: boolean | null;
    isCorrectSelection?: boolean | null;
    isCorrectAnswer?: boolean;
  };
export type Activ8CoursesContentQuizResultsLearningCourseContentQuizQuestionResult =
  {
    id?: string;
    learningCourseContentQuizQuestionId?: string;
    isCompleted?: boolean;
    isUserAnswerCorrect?: boolean;
    isUserAnswerAssessed?: boolean;
    completionDuration?: string | null;
    entryTime?: string | null;
    exitTime?: string | null;
    questionType?: Activ8CoursesContentQuizLearningCourseContentQuizQuestionType;
    multipleChoiceAnswers?:
      | Activ8CoursesContentQuizResultsLearningCourseContentQuizQuestionResultMultipleChoiceOption[]
      | null;
    freeTextAnswer?: string | null;
  };
export type Activ8CoursesContentQuizResultsLearningCourseContentQuizResult = {
  id?: string;
  learningCourseContentQuizId?: string;
  isCompleted?: boolean;
  completionDuration?: string | null;
  questions?:
    | Activ8CoursesContentQuizResultsLearningCourseContentQuizQuestionResult[]
    | null;
};
export type Activ8CoursesProgramsProgressionParticipantToLearningCourseProgramAssignmentSessionProgressionInfo =
  {
    id?: string;
    learningCourseTemplateContentChapterId?: string;
    learningCourseTemplateContentSessionId?: string;
    progressionStatus?: Activ8CoursesProgramsProgressionParticipantToLearningCourseProgramAssignmentSessionProgressionStatus;
    quizResults?:
      | Activ8CoursesContentQuizResultsLearningCourseContentQuizResult[]
      | null;
  };
export type Activ8CoursesProgramsProgressionParticipantToLearningCourseProgramAssignmentProgressionInfo =
  {
    summary?: Activ8CoursesProgramsProgressionParticipantToLearningCourseProgramAssignmentProgressionInfoSummary;
    sessions?:
      | Activ8CoursesProgramsProgressionParticipantToLearningCourseProgramAssignmentSessionProgressionInfo[]
      | null;
  };
export type Activ8CoursesProgramsParticipantsParticipantToLearningCourseProgramAssignmentDto =
  {
    id?: string;
    creationTime?: string;
    creatorId?: string | null;
    lastModificationTime?: string | null;
    lastModifierId?: string | null;
    isDeleted?: boolean;
    deleterId?: string | null;
    deletionTime?: string | null;
    creator?: VoloAbpIdentityIdentityUserDto;
    lastModifier?: VoloAbpIdentityIdentityUserDto;
    deleter?: VoloAbpIdentityIdentityUserDto;
    learningCourseProgramId?: string;
    participantIdentityUserId?: string;
    participantIdentityUser?: VoloAbpIdentityIdentityUserDto;
    assignmentStatus?: Activ8CoursesProgramsParticipantToLearningCourseProgramAssignmentStatus;
    progression?: Activ8CoursesProgramsProgressionParticipantToLearningCourseProgramAssignmentProgressionInfo;
    learningCourseProgram?: Activ8CoursesProgramsCoursesLearningCourseProgramDto;
    onlineModuleScorePercentage?: number | null;
    onlineModuleCompletionPercentage?: number | null;
    contentViewCount?: number;
  };
export type Activ8CoursesProgramsParticipantsParticipantToLearningCourseProgramAssignmentDtoRead =
  {
    id?: string;
    creationTime?: string;
    creatorId?: string | null;
    lastModificationTime?: string | null;
    lastModifierId?: string | null;
    isDeleted?: boolean;
    deleterId?: string | null;
    deletionTime?: string | null;
    creator?: VoloAbpIdentityIdentityUserDtoRead;
    lastModifier?: VoloAbpIdentityIdentityUserDtoRead;
    deleter?: VoloAbpIdentityIdentityUserDtoRead;
    learningCourseProgramId?: string;
    participantIdentityUserId?: string;
    participantIdentityUser?: VoloAbpIdentityIdentityUserDtoRead;
    assignmentStatus?: Activ8CoursesProgramsParticipantToLearningCourseProgramAssignmentStatus;
    progression?: Activ8CoursesProgramsProgressionParticipantToLearningCourseProgramAssignmentProgressionInfo;
    learningCourseProgram?: Activ8CoursesProgramsCoursesLearningCourseProgramDtoRead;
    onlineModuleScorePercentage?: number | null;
    onlineModuleCompletionPercentage?: number | null;
    contentViewCount?: number;
  };
export type Activ8CoursesProgramsFacilitatorsFacilitatorToLearningCourseProgramAssignmentUpdateDto =
  object;
export type VoloAbpApplicationDtosPagedResultDto601Activ8CoursesProgramsFacilitatorsFacilitatorToLearningCourseProgramAssignmentDto20Activ8ApplicationContracts20Version100020CultureNeutral20PublicKeyTokenNull =
  {
    items?:
      | Activ8CoursesProgramsFacilitatorsFacilitatorToLearningCourseProgramAssignmentDto[]
      | null;
    totalCount?: number;
  };
export type VoloAbpApplicationDtosPagedResultDto601Activ8CoursesProgramsFacilitatorsFacilitatorToLearningCourseProgramAssignmentDto20Activ8ApplicationContracts20Version100020CultureNeutral20PublicKeyTokenNullRead =
  {
    items?:
      | Activ8CoursesProgramsFacilitatorsFacilitatorToLearningCourseProgramAssignmentDtoRead[]
      | null;
    totalCount?: number;
  };
export type Activ8CoursesProgramsFacilitatorsFacilitatorToLearningCourseProgramAssignmentCreateDto =
  {
    facilitatorIdentityUserId?: string;
    learningCourseProgramId?: string;
  };
export type VoloAbpApplicationDtosPagedResultDto601Activ8CoursesProgramsParticipantsParticipantToLearningCourseProgramAssignmentDto20Activ8ApplicationContracts20Version100020CultureNeutral20PublicKeyTokenNull =
  {
    items?:
      | Activ8CoursesProgramsParticipantsParticipantToLearningCourseProgramAssignmentDto[]
      | null;
    totalCount?: number;
  };
export type VoloAbpApplicationDtosPagedResultDto601Activ8CoursesProgramsParticipantsParticipantToLearningCourseProgramAssignmentDto20Activ8ApplicationContracts20Version100020CultureNeutral20PublicKeyTokenNullRead =
  {
    items?:
      | Activ8CoursesProgramsParticipantsParticipantToLearningCourseProgramAssignmentDtoRead[]
      | null;
    totalCount?: number;
  };
export type Activ8CoursesProgramsParticipantsParticipantToLearningCourseProgramAssignmentCreateDto =
  {
    participantIdentityUserId?: string;
    learningCourseProgramId?: string;
    createdByDataImportIdentifier?: string | null;
  };
export type Activ8CoursesProgramsParticipantsParticipantToLearningCourseProgramAssignmentUpdateDto =
  object;
export type SystemTimeSpan = {
  ticks?: number;
};
export type SystemTimeSpanRead = {
  ticks?: number;
  days?: number;
  hours?: number;
  milliseconds?: number;
  microseconds?: number;
  nanoseconds?: number;
  minutes?: number;
  seconds?: number;
  totalDays?: number;
  totalHours?: number;
  totalMilliseconds?: number;
  totalMicroseconds?: number;
  totalNanoseconds?: number;
  totalMinutes?: number;
  totalSeconds?: number;
};
export type Activ8CoursesContentLearningCourseContentChapterSummary = {
  id?: string;
  name?: string | null;
  duration?: SystemTimeSpan;
};
export type Activ8CoursesContentLearningCourseContentChapterSummaryRead = {
  id?: string;
  name?: string | null;
  duration?: SystemTimeSpanRead;
};
export type Activ8CoursesContentLearningCourseContentResourceType =
  | "Generic"
  | "Image"
  | "Video"
  | "Pdf"
  | "Audio";
export type SaaSKitCoreModelsDtosCoordinateDto = {
  x?: number;
  y?: number;
};
export type SaaSKitCoreModelsDtosMediaCropInfoAreaInfo = {
  coordinates?: SaaSKitCoreModelsDtosCoordinateDto;
  width?: number;
  height?: number;
};
export type SaaSKitCoreModelsDtosMediaCropInfoDto = {
  percentageArea?: SaaSKitCoreModelsDtosMediaCropInfoAreaInfo;
  pixelArea?: SaaSKitCoreModelsDtosMediaCropInfoAreaInfo;
  zoom?: number;
};
export type Activ8CoursesContentLearningCourseContentResource = {
  id?: string;
  type?: Activ8CoursesContentLearningCourseContentResourceType;
  description?: string | null;
  blobReference?: string | null;
  mediaCrop?: SaaSKitCoreModelsDtosMediaCropInfoDto;
  fileName?: string | null;
  contentType?: string | null;
  fileExtension?: string | null;
  fileSizeBytes?: number;
};
export type Activ8CoursesContentLearningCourseContentBlockType =
  | "Undefined"
  | "RichText"
  | "Quiz"
  | "Resources"
  | "Media"
  | "PageBreak";
export type Activ8CoursesContentQuizLearningCourseContentQuizQuestionMultipleChoiceOption =
  {
    id?: string;
    answerText?: string | null;
    isCorrect?: boolean;
  };
export type Activ8CoursesContentQuizLearningCourseContentQuizQuestion = {
  id?: string;
  questionType?: Activ8CoursesContentQuizLearningCourseContentQuizQuestionType;
  questionText?: string | null;
  choiceOptions?:
    | Activ8CoursesContentQuizLearningCourseContentQuizQuestionMultipleChoiceOption[]
    | null;
};
export type Activ8CoursesContentQuizLearningCourseContentQuiz = {
  id?: string;
  description?: string | null;
  isAssessed?: boolean;
  isAnswerFeedbackShown?: boolean;
  isQuizShownInLine?: boolean;
  questions?:
    | Activ8CoursesContentQuizLearningCourseContentQuizQuestion[]
    | null;
};
export type Activ8CoursesContentLearningCourseContentBlock = {
  id?: string;
  type?: Activ8CoursesContentLearningCourseContentBlockType;
  richText?: string | null;
  quiz?: Activ8CoursesContentQuizLearningCourseContentQuiz;
  resources?: Activ8CoursesContentLearningCourseContentResource[] | null;
};
export type Activ8CoursesContentLearningCourseContentChapterSession = {
  id?: string;
  name?: string | null;
  duration?: SystemTimeSpan;
  facilitatorNotes?: string | null;
  facilitatorResources?:
    | Activ8CoursesContentLearningCourseContentResource[]
    | null;
  content?: Activ8CoursesContentLearningCourseContentBlock[] | null;
};
export type Activ8CoursesContentLearningCourseContentChapterSessionRead = {
  id?: string;
  name?: string | null;
  duration?: SystemTimeSpanRead;
  facilitatorNotes?: string | null;
  facilitatorResources?:
    | Activ8CoursesContentLearningCourseContentResource[]
    | null;
  content?: Activ8CoursesContentLearningCourseContentBlock[] | null;
};
export type Activ8CoursesProgramsSessionsLearningCourseProgramSessionPreparedContentDto =
  {
    program?: Activ8CoursesProgramsCoursesLearningCourseProgramSummaryDto;
    chapter?: Activ8CoursesContentLearningCourseContentChapterSummary;
    session?: Activ8CoursesContentLearningCourseContentChapterSession;
    resourceContentUrls?: {
      [key: string]: string;
    } | null;
  };
export type Activ8CoursesProgramsSessionsLearningCourseProgramSessionPreparedContentDtoRead =
  {
    program?: Activ8CoursesProgramsCoursesLearningCourseProgramSummaryDtoRead;
    chapter?: Activ8CoursesContentLearningCourseContentChapterSummaryRead;
    session?: Activ8CoursesContentLearningCourseContentChapterSessionRead;
    resourceContentUrls?: {
      [key: string]: string;
    } | null;
  };
export type Activ8CoursesProgramsSessionsProgressThroughSessionContentResponse =
  {
    quizResults?:
      | Activ8CoursesContentQuizResultsLearningCourseContentQuizResult[]
      | null;
  };
export type Activ8CoursesProgramsSessionsProgressThroughSessionContentRequest =
  {
    entryTime?: string;
    exitTime?: string;
    learningCourseProgramId?: string;
    learningCourseTemplateContentChapterId?: string;
    learningCourseTemplateContentSessionId?: string;
    learningCourseContentBlockId?: string;
    learningCourseContentQuizId?: string | null;
    learningCourseContentQuizQuestionId?: string | null;
    learningCourseContentQuizQuestionType?: Activ8CoursesContentQuizLearningCourseContentQuizQuestionType;
    learningCourseContentQuizQuestionFreeTextAnswerText?: string | null;
    learningCourseContentQuizQuestionMultipleChoiceOptionIdsSelected?:
      | string[]
      | null;
  };
export type Activ8CoursesProgramsSessionsProgressThroughSessionContentBatchResponse =
  {
    responses?:
      | Activ8CoursesProgramsSessionsProgressThroughSessionContentResponse[]
      | null;
  };
export type Activ8CoursesProgramsSessionsProgressThroughSessionContentBatchRequest =
  {
    requests?:
      | Activ8CoursesProgramsSessionsProgressThroughSessionContentRequest[]
      | null;
  };
export type Activ8CoursesTemplatesLearningCourseTemplateStatus =
  | "Undefined"
  | "Draft"
  | "Paused"
  | "Live";
export type Activ8CoursesTemplatesTemplateStatusSetRequestDto = {
  status?: Activ8CoursesTemplatesLearningCourseTemplateStatus;
  learningCourseTemplateId: string;
};
export type VoloAbpApplicationDtosListResultDto601SystemString20SystemPrivateCoreLib20Version800020CultureNeutral20PublicKeyToken7Cec85D7Bea7798E =
  {
    items?: string[] | null;
  };
export type Activ8CoursesTemplatesLearningCourseTemplatePreRequisiteType =
  | "Undefined"
  | "Facilitator"
  | "Participant";
export type Activ8CoursesTemplatesPersonAccreditationPreRequisiteToLearningCourseTemplateAssignmentDto =
  {
    id?: string;
    creationTime?: string;
    creatorId?: string | null;
    lastModificationTime?: string | null;
    lastModifierId?: string | null;
    type?: Activ8CoursesTemplatesLearningCourseTemplatePreRequisiteType;
    personAccreditationId?: string;
  };
export type Activ8CoursesTemplatesPersonAttributePreRequisiteToLearningCourseTemplateAssignmentDto =
  {
    id?: string;
    creationTime?: string;
    creatorId?: string | null;
    lastModificationTime?: string | null;
    lastModifierId?: string | null;
    type?: Activ8CoursesTemplatesLearningCourseTemplatePreRequisiteType;
    personAttributeId?: string;
  };
export type Activ8CoursesTemplatesPersonAccreditationPostCompletionToLearningCourseTemplateAssignmentDto =
  {
    id?: string;
    creationTime?: string;
    creatorId?: string | null;
    lastModificationTime?: string | null;
    lastModifierId?: string | null;
    personAccreditationId: string;
  };
export type Activ8CoursesTemplatesTenantLicenseToLearningCourseTemplateAssignmentDto =
  {
    id?: string;
    creationTime?: string;
    creatorId?: string | null;
    lastModificationTime?: string | null;
    lastModifierId?: string | null;
    licensedToTenantId: string;
    learningCourseTemplateId: string;
  };
export type Activ8CoursesTemplatesLearningCourseTemplateDto = {
  id?: string;
  creationTime?: string;
  creatorId?: string | null;
  lastModificationTime?: string | null;
  lastModifierId?: string | null;
  creator?: VoloAbpIdentityIdentityUserDto;
  lastModifier?: VoloAbpIdentityIdentityUserDto;
  internalName?: string | null;
  publicName?: string | null;
  publicShortDescription?: string | null;
  status?: Activ8CoursesTemplatesLearningCourseTemplateStatus;
  isRecommendedForPartnerPublicWebsite?: boolean;
  courseReferenceCode?: string | null;
  enrollmentAllowedForIdentityRoleName?: string | null;
  partnerShortDescription?: string | null;
  facilitatorShortDescription?: string | null;
  bannerImage?: {
    [key: string]: SaaSKitCoreModelsDtosStorageStorageBlobDto;
  } | null;
  publicLongRichTextDescription?: string | null;
  partnerLongRichTextDescription?: string | null;
  facilitatorLongRichTextDescription?: string | null;
  participantWelcomeEmailTemplate?: string | null;
  accreditationPreRequisites?:
    | Activ8CoursesTemplatesPersonAccreditationPreRequisiteToLearningCourseTemplateAssignmentDto[]
    | null;
  attributePreRequisites?:
    | Activ8CoursesTemplatesPersonAttributePreRequisiteToLearningCourseTemplateAssignmentDto[]
    | null;
  postCompletionAccreditations?:
    | Activ8CoursesTemplatesPersonAccreditationPostCompletionToLearningCourseTemplateAssignmentDto[]
    | null;
  tenantLicenses?:
    | Activ8CoursesTemplatesTenantLicenseToLearningCourseTemplateAssignmentDto[]
    | null;
};
export type Activ8CoursesTemplatesLearningCourseTemplateDtoRead = {
  id?: string;
  creationTime?: string;
  creatorId?: string | null;
  lastModificationTime?: string | null;
  lastModifierId?: string | null;
  creator?: VoloAbpIdentityIdentityUserDtoRead;
  lastModifier?: VoloAbpIdentityIdentityUserDtoRead;
  internalName?: string | null;
  publicName?: string | null;
  publicShortDescription?: string | null;
  status?: Activ8CoursesTemplatesLearningCourseTemplateStatus;
  isRecommendedForPartnerPublicWebsite?: boolean;
  courseReferenceCode?: string | null;
  enrollmentAllowedForIdentityRoleName?: string | null;
  partnerShortDescription?: string | null;
  facilitatorShortDescription?: string | null;
  bannerImage?: {
    [key: string]: SaaSKitCoreModelsDtosStorageStorageBlobDto;
  } | null;
  publicLongRichTextDescription?: string | null;
  partnerLongRichTextDescription?: string | null;
  facilitatorLongRichTextDescription?: string | null;
  participantWelcomeEmailTemplate?: string | null;
  accreditationPreRequisites?:
    | Activ8CoursesTemplatesPersonAccreditationPreRequisiteToLearningCourseTemplateAssignmentDto[]
    | null;
  attributePreRequisites?:
    | Activ8CoursesTemplatesPersonAttributePreRequisiteToLearningCourseTemplateAssignmentDto[]
    | null;
  postCompletionAccreditations?:
    | Activ8CoursesTemplatesPersonAccreditationPostCompletionToLearningCourseTemplateAssignmentDto[]
    | null;
  tenantLicenses?:
    | Activ8CoursesTemplatesTenantLicenseToLearningCourseTemplateAssignmentDto[]
    | null;
};
export type Activ8CoursesContentLearningCourseContentChapter = {
  id?: string;
  name?: string | null;
  duration?: SystemTimeSpan;
  sessions?: Activ8CoursesContentLearningCourseContentChapterSession[] | null;
};
export type Activ8CoursesContentLearningCourseContentChapterRead = {
  id?: string;
  name?: string | null;
  duration?: SystemTimeSpanRead;
  sessions?:
    | Activ8CoursesContentLearningCourseContentChapterSessionRead[]
    | null;
};
export type Activ8CoursesContentLearningCourseContent = {
  chapters?: Activ8CoursesContentLearningCourseContentChapter[] | null;
};
export type Activ8CoursesContentLearningCourseContentRead = {
  chapters?: Activ8CoursesContentLearningCourseContentChapterRead[] | null;
};
export type Activ8CoursesTemplatesLearningCourseTemplateUpdateDto = {
  content?: Activ8CoursesContentLearningCourseContent;
  internalName?: string | null;
  publicName?: string | null;
  publicShortDescription?: string | null;
  status?: Activ8CoursesTemplatesLearningCourseTemplateStatus;
  isRecommendedForPartnerPublicWebsite?: boolean;
  enrollmentAllowedForIdentityRoleName?: string | null;
  partnerShortDescription?: string | null;
  facilitatorShortDescription?: string | null;
  bannerImage?: {
    [key: string]: SaaSKitCoreModelsDtosStorageStorageBlobDto;
  } | null;
  publicLongRichTextDescription?: string | null;
  partnerLongRichTextDescription?: string | null;
  facilitatorLongRichTextDescription?: string | null;
  participantWelcomeEmailTemplate?: string | null;
  facilitatorAccreditationIdsPreRequisites?: string[] | null;
  participantAccreditationIdsPreRequisites?: string[] | null;
  facilitatorAttributeIdsPreRequisites?: string[] | null;
  postCompletionAccreditationsIds?: string[] | null;
  licensedTenantIds?: string[] | null;
};
export type Activ8CoursesTemplatesLearningCourseTemplateUpdateDtoRead = {
  content?: Activ8CoursesContentLearningCourseContentRead;
  internalName?: string | null;
  publicName?: string | null;
  publicShortDescription?: string | null;
  status?: Activ8CoursesTemplatesLearningCourseTemplateStatus;
  isRecommendedForPartnerPublicWebsite?: boolean;
  enrollmentAllowedForIdentityRoleName?: string | null;
  partnerShortDescription?: string | null;
  facilitatorShortDescription?: string | null;
  bannerImage?: {
    [key: string]: SaaSKitCoreModelsDtosStorageStorageBlobDto;
  } | null;
  publicLongRichTextDescription?: string | null;
  partnerLongRichTextDescription?: string | null;
  facilitatorLongRichTextDescription?: string | null;
  participantWelcomeEmailTemplate?: string | null;
  facilitatorAccreditationIdsPreRequisites?: string[] | null;
  participantAccreditationIdsPreRequisites?: string[] | null;
  facilitatorAttributeIdsPreRequisites?: string[] | null;
  postCompletionAccreditationsIds?: string[] | null;
  licensedTenantIds?: string[] | null;
};
export type VoloAbpApplicationDtosListResultDto601Activ8CoursesTemplatesTenantLicenseToLearningCourseTemplateAssignmentDto20Activ8ApplicationContracts20Version100020CultureNeutral20PublicKeyTokenNull =
  {
    items?:
      | Activ8CoursesTemplatesTenantLicenseToLearningCourseTemplateAssignmentDto[]
      | null;
  };
export type Activ8CoursesTemplatesLearningCourseTemplateCreateDto = {
  content?: Activ8CoursesContentLearningCourseContent;
  internalName?: string | null;
  publicName?: string | null;
  publicShortDescription?: string | null;
  status?: Activ8CoursesTemplatesLearningCourseTemplateStatus;
  isRecommendedForPartnerPublicWebsite?: boolean;
  enrollmentAllowedForIdentityRoleName?: string | null;
  partnerShortDescription?: string | null;
  facilitatorShortDescription?: string | null;
  bannerImage?: {
    [key: string]: SaaSKitCoreModelsDtosStorageStorageBlobDto;
  } | null;
  publicLongRichTextDescription?: string | null;
  partnerLongRichTextDescription?: string | null;
  facilitatorLongRichTextDescription?: string | null;
  participantWelcomeEmailTemplate?: string | null;
  facilitatorAccreditationIdsPreRequisites?: string[] | null;
  participantAccreditationIdsPreRequisites?: string[] | null;
  facilitatorAttributeIdsPreRequisites?: string[] | null;
  postCompletionAccreditationsIds?: string[] | null;
  licensedTenantIds?: string[] | null;
  newId?: string;
};
export type Activ8CoursesTemplatesLearningCourseTemplateCreateDtoRead = {
  content?: Activ8CoursesContentLearningCourseContentRead;
  internalName?: string | null;
  publicName?: string | null;
  publicShortDescription?: string | null;
  status?: Activ8CoursesTemplatesLearningCourseTemplateStatus;
  isRecommendedForPartnerPublicWebsite?: boolean;
  enrollmentAllowedForIdentityRoleName?: string | null;
  partnerShortDescription?: string | null;
  facilitatorShortDescription?: string | null;
  bannerImage?: {
    [key: string]: SaaSKitCoreModelsDtosStorageStorageBlobDto;
  } | null;
  publicLongRichTextDescription?: string | null;
  partnerLongRichTextDescription?: string | null;
  facilitatorLongRichTextDescription?: string | null;
  participantWelcomeEmailTemplate?: string | null;
  facilitatorAccreditationIdsPreRequisites?: string[] | null;
  participantAccreditationIdsPreRequisites?: string[] | null;
  facilitatorAttributeIdsPreRequisites?: string[] | null;
  postCompletionAccreditationsIds?: string[] | null;
  licensedTenantIds?: string[] | null;
  newId?: string;
};
export type Activ8CoursesTemplatesLearningCourseTemplateSummaryDto = {
  id?: string;
  creationTime?: string;
  creatorId?: string | null;
  lastModificationTime?: string | null;
  lastModifierId?: string | null;
  creator?: VoloAbpIdentityIdentityUserDto;
  lastModifier?: VoloAbpIdentityIdentityUserDto;
  internalName?: string | null;
  publicName?: string | null;
  publicShortDescription?: string | null;
  status?: Activ8CoursesTemplatesLearningCourseTemplateStatus;
  isRecommendedForPartnerPublicWebsite?: boolean;
  courseReferenceCode?: string | null;
  enrollmentAllowedForIdentityRoleName?: string | null;
  partnerShortDescription?: string | null;
  facilitatorShortDescription?: string | null;
  bannerImage?: {
    [key: string]: SaaSKitCoreModelsDtosStorageStorageBlobDto;
  } | null;
};
export type Activ8CoursesTemplatesLearningCourseTemplateSummaryDtoRead = {
  id?: string;
  creationTime?: string;
  creatorId?: string | null;
  lastModificationTime?: string | null;
  lastModifierId?: string | null;
  creator?: VoloAbpIdentityIdentityUserDtoRead;
  lastModifier?: VoloAbpIdentityIdentityUserDtoRead;
  internalName?: string | null;
  publicName?: string | null;
  publicShortDescription?: string | null;
  status?: Activ8CoursesTemplatesLearningCourseTemplateStatus;
  isRecommendedForPartnerPublicWebsite?: boolean;
  courseReferenceCode?: string | null;
  enrollmentAllowedForIdentityRoleName?: string | null;
  partnerShortDescription?: string | null;
  facilitatorShortDescription?: string | null;
  bannerImage?: {
    [key: string]: SaaSKitCoreModelsDtosStorageStorageBlobDto;
  } | null;
};
export type VoloAbpApplicationDtosPagedResultDto601Activ8CoursesTemplatesLearningCourseTemplateSummaryDto20Activ8ApplicationContracts20Version100020CultureNeutral20PublicKeyTokenNull =
  {
    items?: Activ8CoursesTemplatesLearningCourseTemplateSummaryDto[] | null;
    totalCount?: number;
  };
export type VoloAbpApplicationDtosPagedResultDto601Activ8CoursesTemplatesLearningCourseTemplateSummaryDto20Activ8ApplicationContracts20Version100020CultureNeutral20PublicKeyTokenNullRead =
  {
    items?: Activ8CoursesTemplatesLearningCourseTemplateSummaryDtoRead[] | null;
    totalCount?: number;
  };
export type VoloAbpIdentityOrganizationUnitRoleInput = {
  roleIds?: string[] | null;
};
export type VoloAbpIdentityIdentityRoleDto = {
  id?: string;
  name?: string | null;
  isDefault?: boolean;
  isStatic?: boolean;
  isPublic?: boolean;
  userCount?: number;
  concurrencyStamp?: string | null;
};
export type VoloAbpIdentityIdentityRoleDtoRead = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  id?: string;
  name?: string | null;
  isDefault?: boolean;
  isStatic?: boolean;
  isPublic?: boolean;
  userCount?: number;
  concurrencyStamp?: string | null;
};
export type VoloAbpApplicationDtosPagedResultDto601VoloAbpIdentityIdentityRoleDto20VoloAbpIdentityProApplicationContracts20Version831020CultureNeutral20PublicKeyTokenNull =
  {
    items?: VoloAbpIdentityIdentityRoleDto[] | null;
    totalCount?: number;
  };
export type VoloAbpApplicationDtosPagedResultDto601VoloAbpIdentityIdentityRoleDto20VoloAbpIdentityProApplicationContracts20Version831020CultureNeutral20PublicKeyTokenNullRead =
  {
    items?: VoloAbpIdentityIdentityRoleDtoRead[] | null;
    totalCount?: number;
  };
export type VoloAbpIdentityOrganizationUnitUserInput = {
  userIds?: string[] | null;
};
export type VoloAbpApplicationDtosPagedResultDto601VoloAbpIdentityIdentityUserDto20VoloAbpIdentityProApplicationContracts20Version831020CultureNeutral20PublicKeyTokenNull =
  {
    items?: VoloAbpIdentityIdentityUserDto[] | null;
    totalCount?: number;
  };
export type VoloAbpApplicationDtosPagedResultDto601VoloAbpIdentityIdentityUserDto20VoloAbpIdentityProApplicationContracts20Version831020CultureNeutral20PublicKeyTokenNullRead =
  {
    items?: VoloAbpIdentityIdentityUserDtoRead[] | null;
    totalCount?: number;
  };
export type VoloAbpIdentityOrganizationUnitWithDetailsDto = {
  id?: string;
  creationTime?: string;
  creatorId?: string | null;
  lastModificationTime?: string | null;
  lastModifierId?: string | null;
  isDeleted?: boolean;
  deleterId?: string | null;
  deletionTime?: string | null;
  parentId?: string | null;
  code?: string | null;
  displayName?: string | null;
  roles?: VoloAbpIdentityIdentityRoleDto[] | null;
  userCount?: number;
  concurrencyStamp?: string | null;
};
export type VoloAbpIdentityOrganizationUnitWithDetailsDtoRead = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  id?: string;
  creationTime?: string;
  creatorId?: string | null;
  lastModificationTime?: string | null;
  lastModifierId?: string | null;
  isDeleted?: boolean;
  deleterId?: string | null;
  deletionTime?: string | null;
  parentId?: string | null;
  code?: string | null;
  displayName?: string | null;
  roles?: VoloAbpIdentityIdentityRoleDtoRead[] | null;
  userCount?: number;
  concurrencyStamp?: string | null;
};
export type VoloAbpIdentityOrganizationUnitCreateDto = {
  displayName: string;
  parentId?: string | null;
};
export type VoloAbpIdentityOrganizationUnitCreateDtoRead = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  displayName: string;
  parentId?: string | null;
};
export type VoloAbpApplicationDtosPagedResultDto601VoloAbpIdentityOrganizationUnitWithDetailsDto20VoloAbpIdentityProApplicationContracts20Version831020CultureNeutral20PublicKeyTokenNull =
  {
    items?: VoloAbpIdentityOrganizationUnitWithDetailsDto[] | null;
    totalCount?: number;
  };
export type VoloAbpApplicationDtosPagedResultDto601VoloAbpIdentityOrganizationUnitWithDetailsDto20VoloAbpIdentityProApplicationContracts20Version831020CultureNeutral20PublicKeyTokenNullRead =
  {
    items?: VoloAbpIdentityOrganizationUnitWithDetailsDtoRead[] | null;
    totalCount?: number;
  };
export type VoloAbpIdentityOrganizationUnitUpdateDto = {
  displayName: string;
  concurrencyStamp?: string | null;
};
export type VoloAbpIdentityOrganizationUnitUpdateDtoRead = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  displayName: string;
  concurrencyStamp?: string | null;
};
export type VoloAbpApplicationDtosListResultDto601VoloAbpIdentityOrganizationUnitWithDetailsDto20VoloAbpIdentityProApplicationContracts20Version831020CultureNeutral20PublicKeyTokenNull =
  {
    items?: VoloAbpIdentityOrganizationUnitWithDetailsDto[] | null;
  };
export type VoloAbpApplicationDtosListResultDto601VoloAbpIdentityOrganizationUnitWithDetailsDto20VoloAbpIdentityProApplicationContracts20Version831020CultureNeutral20PublicKeyTokenNullRead =
  {
    items?: VoloAbpIdentityOrganizationUnitWithDetailsDtoRead[] | null;
  };
export type VoloAbpIdentityOrganizationUnitMoveInput = {
  newParentId?: string | null;
};
export type Activ8ParticipantsParticipantDto = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  id?: string;
  creationTime?: string;
  creatorId?: string | null;
  lastModificationTime?: string | null;
  lastModifierId?: string | null;
  isDeleted?: boolean;
  deleterId?: string | null;
  deletionTime?: string | null;
  tenantId?: string | null;
  userName?: string | null;
  email?: string | null;
  name?: string | null;
  surname?: string | null;
  emailConfirmed?: boolean;
  phoneNumber?: string | null;
  phoneNumberConfirmed?: boolean;
  supportTwoFactor?: boolean;
  twoFactorEnabled?: boolean;
  isActive?: boolean;
  lockoutEnabled?: boolean;
  isLockedOut?: boolean;
  lockoutEnd?: string | null;
  shouldChangePasswordOnNextLogin?: boolean;
  concurrencyStamp?: string | null;
  roleNames?: string[] | null;
  accessFailedCount?: number;
  lastPasswordChangeTime?: string | null;
  isExternal?: boolean;
  address?: string | null;
  parentIdentityUser?: VoloAbpIdentityIdentityUserDto;
  childIdentityUsers?: VoloAbpIdentityIdentityUserDto[] | null;
};
export type Activ8ParticipantsParticipantDtoRead = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  id?: string;
  creationTime?: string;
  creatorId?: string | null;
  lastModificationTime?: string | null;
  lastModifierId?: string | null;
  isDeleted?: boolean;
  deleterId?: string | null;
  deletionTime?: string | null;
  tenantId?: string | null;
  userName?: string | null;
  email?: string | null;
  name?: string | null;
  surname?: string | null;
  emailConfirmed?: boolean;
  phoneNumber?: string | null;
  phoneNumberConfirmed?: boolean;
  supportTwoFactor?: boolean;
  twoFactorEnabled?: boolean;
  isActive?: boolean;
  lockoutEnabled?: boolean;
  isLockedOut?: boolean;
  lockoutEnd?: string | null;
  shouldChangePasswordOnNextLogin?: boolean;
  concurrencyStamp?: string | null;
  roleNames?: string[] | null;
  accessFailedCount?: number;
  lastPasswordChangeTime?: string | null;
  isExternal?: boolean;
  address?: string | null;
  parentIdentityUser?: VoloAbpIdentityIdentityUserDtoRead;
  childIdentityUsers?: VoloAbpIdentityIdentityUserDtoRead[] | null;
};
export type Activ8ParticipantsParticipantCreateDto = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  userName: string;
  name?: string | null;
  surname?: string | null;
  email: string;
  phoneNumber?: string | null;
  isActive?: boolean;
  shouldChangePasswordOnNextLogin?: boolean;
  lockoutEnabled?: boolean;
  roleNames?: string[] | null;
  organizationUnitIds?: string[] | null;
  password: string;
  sendConfirmationEmail?: boolean;
  emailConfirmed?: boolean;
  phoneNumberConfirmed?: boolean;
  createdAt?: string | null;
  isPartialUser?: boolean;
};
export type VoloAbpApplicationDtosPagedResultDto601Activ8ParticipantsParticipantDto20Activ8ApplicationContracts20Version100020CultureNeutral20PublicKeyTokenNull =
  {
    items?: Activ8ParticipantsParticipantDto[] | null;
    totalCount?: number;
  };
export type VoloAbpApplicationDtosPagedResultDto601Activ8ParticipantsParticipantDto20Activ8ApplicationContracts20Version100020CultureNeutral20PublicKeyTokenNullRead =
  {
    items?: Activ8ParticipantsParticipantDtoRead[] | null;
    totalCount?: number;
  };
export type Activ8ParticipantsParticipantUpdateDto = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  userName: string;
  name?: string | null;
  surname?: string | null;
  email: string;
  phoneNumber?: string | null;
  isActive?: boolean;
  shouldChangePasswordOnNextLogin?: boolean;
  lockoutEnabled?: boolean;
  roleNames?: string[] | null;
  organizationUnitIds?: string[] | null;
  emailConfirmed?: boolean;
  phoneNumberConfirmed?: boolean;
  concurrencyStamp?: string | null;
  isPartialUser?: boolean;
};
export type Activ8AppNotificationsAppNotificationSeverity =
  | "Information"
  | "Warning"
  | "Danger";
export type Activ8AppNotificationsAppNotification = {
  textMessage?: string | null;
  severity?: Activ8AppNotificationsAppNotificationSeverity;
  typeKey?: string | null;
  properties?: {
    [key: string]: any;
  } | null;
};
export type Activ8PartnersDashboardPartnerDashboardStatistics = {
  averageParticipantModulesScorePercentage?: number | null;
  averageParticipantModuleCompletionPercentage?: number | null;
};
export type Activ8PartnersDashboardPartnerDashboardResponse = {
  notifications?: Activ8AppNotificationsAppNotification[] | null;
  statistics?: Activ8PartnersDashboardPartnerDashboardStatistics;
};
export type Activ8PartnersPartnerProfileDetail = {
  publicWebsiteUrl?: string | null;
  publicWebsiteProgramBookUrl?: string | null;
  taxOrCompanyNumber?: string | null;
  headOfficeAddress?: string | null;
  ianaTimezone?: string | null;
  keyDecisionMakerContactName?: string | null;
  keyDecisionMakerContactEmail?: string | null;
  accountsContactName?: string | null;
  accountsContactEmail?: string | null;
  marketingContactName?: string | null;
  marketingContactEmail?: string | null;
  participantSupportContactName?: string | null;
  participantSupportContactEmail?: string | null;
};
export type Activ8PartnersProfilePartnerProfileDetailSetRequest = {
  publicWebsiteUrl?: string | null;
  publicWebsiteProgramBookUrl?: string | null;
  taxOrCompanyNumber?: string | null;
  headOfficeAddress?: string | null;
  ianaTimezone?: string | null;
  keyDecisionMakerContactName?: string | null;
  keyDecisionMakerContactEmail?: string | null;
  accountsContactName?: string | null;
  accountsContactEmail?: string | null;
  marketingContactName?: string | null;
  marketingContactEmail?: string | null;
  participantSupportContactName?: string | null;
  participantSupportContactEmail?: string | null;
};
export type VoloAbpPermissionManagementProviderInfoDto = {
  providerName?: string | null;
  providerKey?: string | null;
};
export type VoloAbpPermissionManagementPermissionGrantInfoDto = {
  name?: string | null;
  displayName?: string | null;
  parentName?: string | null;
  isGranted?: boolean;
  allowedProviders?: string[] | null;
  grantedProviders?: VoloAbpPermissionManagementProviderInfoDto[] | null;
};
export type VoloAbpPermissionManagementPermissionGroupDto = {
  name?: string | null;
  displayName?: string | null;
  displayNameKey?: string | null;
  displayNameResource?: string | null;
  permissions?: VoloAbpPermissionManagementPermissionGrantInfoDto[] | null;
};
export type VoloAbpPermissionManagementGetPermissionListResultDto = {
  entityDisplayName?: string | null;
  groups?: VoloAbpPermissionManagementPermissionGroupDto[] | null;
};
export type VoloAbpPermissionManagementUpdatePermissionDto = {
  name?: string | null;
  isGranted?: boolean;
};
export type VoloAbpPermissionManagementUpdatePermissionsDto = {
  permissions?: VoloAbpPermissionManagementUpdatePermissionDto[] | null;
};
export type Activ8PersonAccreditationsPersonAccreditationDto = {
  id?: string;
  description?: string | null;
  creationTime?: string;
  lastModificationTime?: string | null;
};
export type Activ8PersonAccreditationsPersonAccreditationToUserAssignmentDto = {
  id?: string;
  personAccreditation?: Activ8PersonAccreditationsPersonAccreditationDto;
  identityUserId?: string;
  creationTime?: string;
  lastModificationTime?: string | null;
};
export type VoloAbpApplicationDtosListResultDto601Activ8PersonAccreditationsPersonAccreditationToUserAssignmentDto20Activ8ApplicationContracts20Version100020CultureNeutral20PublicKeyTokenNull =
  {
    items?:
      | Activ8PersonAccreditationsPersonAccreditationToUserAssignmentDto[]
      | null;
  };
export type VoloAbpApplicationDtosPagedResultDto601Activ8PersonAccreditationsPersonAccreditationDto20Activ8ApplicationContracts20Version100020CultureNeutral20PublicKeyTokenNull =
  {
    items?: Activ8PersonAccreditationsPersonAccreditationDto[] | null;
    totalCount?: number;
  };
export type Activ8PersonAccreditationsPersonAccreditationCreateOrUpdateDto = {
  description?: string | null;
};
export type Activ8PersonAttributesPersonAttributeDto = {
  id?: string;
  description?: string | null;
  creationTime?: string;
  lastModificationTime?: string | null;
};
export type Activ8PersonAttributesPersonAttributeToUserAssignmentDto = {
  id?: string;
  personAttribute?: Activ8PersonAttributesPersonAttributeDto;
  identityUserId?: string;
  creationTime?: string;
  lastModificationTime?: string | null;
};
export type VoloAbpApplicationDtosListResultDto601Activ8PersonAttributesPersonAttributeToUserAssignmentDto20Activ8ApplicationContracts20Version100020CultureNeutral20PublicKeyTokenNull =
  {
    items?: Activ8PersonAttributesPersonAttributeToUserAssignmentDto[] | null;
  };
export type VoloAbpApplicationDtosPagedResultDto601Activ8PersonAttributesPersonAttributeDto20Activ8ApplicationContracts20Version100020CultureNeutral20PublicKeyTokenNull =
  {
    items?: Activ8PersonAttributesPersonAttributeDto[] | null;
    totalCount?: number;
  };
export type Activ8PersonAttributesPersonAttributeCreateOrUpdateDto = {
  description?: string | null;
};
export type VoloAbpAccountProfileDto = {
  userName?: string | null;
  email?: string | null;
  emailConfirmed?: boolean;
  name?: string | null;
  surname?: string | null;
  phoneNumber?: string | null;
  phoneNumberConfirmed?: boolean;
  isExternal?: boolean;
  hasPassword?: boolean;
  supportsMultipleTimezone?: boolean;
  timezone?: string | null;
  concurrencyStamp?: string | null;
};
export type VoloAbpAccountProfileDtoRead = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  userName?: string | null;
  email?: string | null;
  emailConfirmed?: boolean;
  name?: string | null;
  surname?: string | null;
  phoneNumber?: string | null;
  phoneNumberConfirmed?: boolean;
  isExternal?: boolean;
  hasPassword?: boolean;
  supportsMultipleTimezone?: boolean;
  timezone?: string | null;
  concurrencyStamp?: string | null;
};
export type VoloAbpAccountUpdateProfileDto = {
  userName: string;
  email?: string | null;
  name?: string | null;
  surname?: string | null;
  phoneNumber?: string | null;
  timezone?: string | null;
  concurrencyStamp?: string | null;
};
export type VoloAbpAccountUpdateProfileDtoRead = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  userName: string;
  email?: string | null;
  name?: string | null;
  surname?: string | null;
  phoneNumber?: string | null;
  timezone?: string | null;
  concurrencyStamp?: string | null;
};
export type VoloAbpAccountChangePasswordInput = {
  currentPassword?: string | null;
  newPassword: string;
};
export type VoloAbpIdentityIdentityRoleUpdateDto = {
  name: string;
  isDefault?: boolean;
  isPublic?: boolean;
  concurrencyStamp?: string | null;
};
export type VoloAbpIdentityIdentityRoleUpdateDtoRead = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  name: string;
  isDefault?: boolean;
  isPublic?: boolean;
  concurrencyStamp?: string | null;
};
export type VoloAbpIdentityIdentityRoleCreateDto = {
  name: string;
  isDefault?: boolean;
  isPublic?: boolean;
};
export type VoloAbpIdentityIdentityRoleCreateDtoRead = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  name: string;
  isDefault?: boolean;
  isPublic?: boolean;
};
export type VoloAbpApplicationDtosListResultDto601VoloAbpIdentityIdentityRoleDto20VoloAbpIdentityProApplicationContracts20Version831020CultureNeutral20PublicKeyTokenNull =
  {
    items?: VoloAbpIdentityIdentityRoleDto[] | null;
  };
export type VoloAbpApplicationDtosListResultDto601VoloAbpIdentityIdentityRoleDto20VoloAbpIdentityProApplicationContracts20Version831020CultureNeutral20PublicKeyTokenNullRead =
  {
    items?: VoloAbpIdentityIdentityRoleDtoRead[] | null;
  };
export type VoloAbpIdentityIdentityRoleClaimDto = {
  roleId?: string;
  claimType?: string | null;
  claimValue?: string | null;
};
export type VoloAbpOpenIddictScopesDtosScopeDto = {
  id?: string;
  name?: string | null;
  displayName?: string | null;
  description?: string | null;
  buildIn?: boolean;
  resources?: string[] | null;
};
export type VoloAbpOpenIddictScopesDtosScopeDtoRead = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  id?: string;
  name?: string | null;
  displayName?: string | null;
  description?: string | null;
  buildIn?: boolean;
  resources?: string[] | null;
};
export type VoloAbpOpenIddictScopesDtosUpdateScopeInput = {
  name: string;
  displayName?: string | null;
  description?: string | null;
  resources?: string[] | null;
};
export type VoloAbpOpenIddictScopesDtosUpdateScopeInputRead = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  name: string;
  displayName?: string | null;
  description?: string | null;
  resources?: string[] | null;
};
export type VoloAbpApplicationDtosPagedResultDto601VoloAbpOpenIddictScopesDtosScopeDto20VoloAbpOpenIddictProApplicationContracts20Version831020CultureNeutral20PublicKeyTokenNull =
  {
    items?: VoloAbpOpenIddictScopesDtosScopeDto[] | null;
    totalCount?: number;
  };
export type VoloAbpApplicationDtosPagedResultDto601VoloAbpOpenIddictScopesDtosScopeDto20VoloAbpOpenIddictProApplicationContracts20Version831020CultureNeutral20PublicKeyTokenNullRead =
  {
    items?: VoloAbpOpenIddictScopesDtosScopeDtoRead[] | null;
    totalCount?: number;
  };
export type VoloAbpOpenIddictScopesDtosCreateScopeInput = {
  name: string;
  displayName?: string | null;
  description?: string | null;
  resources?: string[] | null;
};
export type VoloAbpOpenIddictScopesDtosCreateScopeInputRead = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  name: string;
  displayName?: string | null;
  description?: string | null;
  resources?: string[] | null;
};
export type VoloAbpIdentityIdentitySessionDto = {
  id?: string;
  sessionId?: string | null;
  isCurrent?: boolean;
  device?: string | null;
  deviceInfo?: string | null;
  tenantId?: string | null;
  tenantName?: string | null;
  userId?: string;
  userName?: string | null;
  clientId?: string | null;
  ipAddresses?: string[] | null;
  signedIn?: string;
  lastAccessed?: string | null;
};
export type VoloAbpApplicationDtosPagedResultDto601VoloAbpIdentityIdentitySessionDto20VoloAbpIdentityProApplicationContracts20Version831020CultureNeutral20PublicKeyTokenNull =
  {
    items?: VoloAbpIdentityIdentitySessionDto[] | null;
    totalCount?: number;
  };
export type VoloAbpAuditLoggingAuditLogSettingsDto = {
  isExpiredDeleterEnabled?: boolean;
  expiredDeleterPeriod?: number;
};
export type VoloAbpAuditLoggingAuditLogGlobalSettingsDto = {
  isExpiredDeleterEnabled?: boolean;
  expiredDeleterPeriod?: number;
  isPeriodicDeleterEnabled?: boolean;
};
export type VoloAbpIdentityIdentityPasswordSettingsDto = {
  requiredLength?: number;
  requiredUniqueChars?: number;
  requireNonAlphanumeric?: boolean;
  requireLowercase?: boolean;
  requireUppercase?: boolean;
  requireDigit?: boolean;
  forceUsersToPeriodicallyChangePassword?: boolean;
  passwordChangePeriodDays?: number;
};
export type VoloAbpIdentityIdentityLockoutSettingsDto = {
  allowedForNewUsers?: boolean;
  lockoutDuration?: number;
  maxFailedAccessAttempts?: number;
};
export type VoloAbpIdentityIdentitySignInSettingsDto = {
  requireConfirmedEmail?: boolean;
  enablePhoneNumberConfirmation?: boolean;
  requireConfirmedPhoneNumber?: boolean;
};
export type VoloAbpIdentityIdentityUserSettingsDto = {
  isUserNameUpdateEnabled?: boolean;
  isEmailUpdateEnabled?: boolean;
};
export type VoloAbpIdentityIdentitySettingsDto = {
  password?: VoloAbpIdentityIdentityPasswordSettingsDto;
  lockout?: VoloAbpIdentityIdentityLockoutSettingsDto;
  signIn?: VoloAbpIdentityIdentitySignInSettingsDto;
  user?: VoloAbpIdentityIdentityUserSettingsDto;
};
export type VoloAbpIdentityIdentityLdapSettingsDto = {
  enableLdapLogin?: boolean;
  ldaps?: boolean;
  ldapServerHost?: string | null;
  ldapServerPort?: string | null;
  ldapBaseDc?: string | null;
  ldapDomain?: string | null;
  ldapUserName?: string | null;
  ldapPassword?: string | null;
};
export type VoloAbpIdentityIdentityOAuthSettingsDto = {
  enableOAuthLogin?: boolean;
  clientId: string;
  clientSecret?: string | null;
  authority: string;
  scope?: string | null;
  requireHttpsMetadata?: boolean;
  validateEndpoints?: boolean;
  validateIssuerName?: boolean;
};
export type VoloAbpIdentitySettingsIdentityProPreventConcurrentLoginBehaviour =
  | "Disabled"
  | "LogoutFromSameTypeDevices"
  | "LogoutFromAllDevices";
export type VoloAbpIdentityIdentitySessionSettingsDto = {
  preventConcurrentLogin?: VoloAbpIdentitySettingsIdentityProPreventConcurrentLoginBehaviour;
};
export type VoloSaasTenantActivationState =
  | "Active"
  | "ActiveWithLimitedTime"
  | "Passive";
export type VoloSaasHostDtosSaasTenantDto = {
  id?: string;
  name?: string | null;
  editionId?: string | null;
  editionEndDateUtc?: string | null;
  editionName?: string | null;
  hasDefaultConnectionString?: boolean;
  activationState?: VoloSaasTenantActivationState;
  activationEndDate?: string | null;
  concurrencyStamp?: string | null;
};
export type VoloSaasHostDtosSaasTenantDtoRead = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  id?: string;
  name?: string | null;
  editionId?: string | null;
  editionEndDateUtc?: string | null;
  editionName?: string | null;
  hasDefaultConnectionString?: boolean;
  activationState?: VoloSaasTenantActivationState;
  activationEndDate?: string | null;
  concurrencyStamp?: string | null;
};
export type Activ8ControllersSaasTenantUpdateDtoWithExtraPropertiesWrite = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  name: string;
  editionId?: string | null;
  activationState?: VoloSaasTenantActivationState;
  activationEndDate?: string | null;
  editionEndDateUtc?: string | null;
  concurrencyStamp?: string | null;
};
export type VoloAbpApplicationDtosPagedResultDto601VoloSaasHostDtosSaasTenantDto20VoloSaasHostApplicationContracts20Version831020CultureNeutral20PublicKeyTokenNull =
  {
    items?: VoloSaasHostDtosSaasTenantDto[] | null;
    totalCount?: number;
  };
export type VoloAbpApplicationDtosPagedResultDto601VoloSaasHostDtosSaasTenantDto20VoloSaasHostApplicationContracts20Version831020CultureNeutral20PublicKeyTokenNullRead =
  {
    items?: VoloSaasHostDtosSaasTenantDtoRead[] | null;
    totalCount?: number;
  };
export type VoloSaasHostDtosSaasTenantDatabaseConnectionStringsDto = {
  databaseName?: string | null;
  connectionString?: string | null;
};
export type VoloSaasHostDtosSaasTenantDatabaseConnectionStringsDtoRead = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  databaseName?: string | null;
  connectionString?: string | null;
};
export type VoloSaasHostDtosSaasTenantConnectionStringsDto = {
  default?: string | null;
  databases?: VoloSaasHostDtosSaasTenantDatabaseConnectionStringsDto[] | null;
};
export type VoloSaasHostDtosSaasTenantConnectionStringsDtoRead = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  default?: string | null;
  databases?:
    | VoloSaasHostDtosSaasTenantDatabaseConnectionStringsDtoRead[]
    | null;
};
export type Activ8ControllersSaasTenantCreateDtoWithExtraPropertiesWrite = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  name: string;
  editionId?: string | null;
  activationState?: VoloSaasTenantActivationState;
  activationEndDate?: string | null;
  editionEndDateUtc?: string | null;
  adminEmailAddress: string;
  adminPassword: string;
  connectionStrings?: VoloSaasHostDtosSaasTenantConnectionStringsDto;
};
export type Activ8ControllersSaasTenantCreateDtoWithExtraPropertiesWriteRead = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  name: string;
  editionId?: string | null;
  activationState?: VoloSaasTenantActivationState;
  activationEndDate?: string | null;
  editionEndDateUtc?: string | null;
  adminEmailAddress: string;
  adminPassword: string;
  connectionStrings?: VoloSaasHostDtosSaasTenantConnectionStringsDtoRead;
};
export type VoloSaasHostDtosSaasTenantDatabasesDto = {
  databases?: string[] | null;
};
export type VoloSaasHostDtosSaasTenantDatabasesDtoRead = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  databases?: string[] | null;
};
export type VoloSaasHostDtosEditionLookupDto = {
  id?: string;
  displayName?: string | null;
};
export type VoloSaasHostDtosEditionLookupDtoRead = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  id?: string;
  displayName?: string | null;
};
export type VoloSaasHostDtosSaasTenantSetPasswordDto = {
  username?: string | null;
  password?: string | null;
};
export type VoloAbpApplicationDtosListResultDto601VoloAbpNameValue20VoloAbpCore20Version831020CultureNeutral20PublicKeyTokenNull =
  {
    items?: VoloAbpNameValue[] | null;
  };
export type Activ8ControllersIdentityUserUpdateDtoWithExtraPropertiesWrite = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  userName: string;
  name?: string | null;
  surname?: string | null;
  email: string;
  phoneNumber?: string | null;
  isActive?: boolean;
  shouldChangePasswordOnNextLogin?: boolean;
  lockoutEnabled?: boolean;
  roleNames?: string[] | null;
  organizationUnitIds?: string[] | null;
  emailConfirmed?: boolean;
  phoneNumberConfirmed?: boolean;
  concurrencyStamp?: string | null;
};
export type Activ8ControllersIdentityUserCreateDtoWithExtraPropertiesWrite = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  userName: string;
  name?: string | null;
  surname?: string | null;
  email: string;
  phoneNumber?: string | null;
  isActive?: boolean;
  shouldChangePasswordOnNextLogin?: boolean;
  lockoutEnabled?: boolean;
  roleNames?: string[] | null;
  organizationUnitIds?: string[] | null;
  password: string;
  sendConfirmationEmail?: boolean;
  emailConfirmed?: boolean;
  phoneNumberConfirmed?: boolean;
};
export type VoloAbpIdentityIdentityUserUpdateRolesDto = {
  roleNames: string[];
};
export type VoloAbpIdentityIdentityUserClaimDto = {
  userId?: string;
  claimType?: string | null;
  claimValue?: string | null;
};
export type VoloAbpIdentityOrganizationUnitRoleDto = {
  creationTime?: string;
  creatorId?: string | null;
  organizationUnitId?: string;
  roleId?: string;
};
export type VoloAbpIdentityOrganizationUnitDto = {
  id?: string;
  creationTime?: string;
  creatorId?: string | null;
  lastModificationTime?: string | null;
  lastModifierId?: string | null;
  isDeleted?: boolean;
  deleterId?: string | null;
  deletionTime?: string | null;
  parentId?: string | null;
  code?: string | null;
  displayName?: string | null;
  roles?: VoloAbpIdentityOrganizationUnitRoleDto[] | null;
};
export type VoloAbpIdentityOrganizationUnitDtoRead = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  id?: string;
  creationTime?: string;
  creatorId?: string | null;
  lastModificationTime?: string | null;
  lastModifierId?: string | null;
  isDeleted?: boolean;
  deleterId?: string | null;
  deletionTime?: string | null;
  parentId?: string | null;
  code?: string | null;
  displayName?: string | null;
  roles?: VoloAbpIdentityOrganizationUnitRoleDto[] | null;
};
export type VoloAbpIdentityIdentityUserUpdatePasswordInput = {
  newPassword: string;
};
export type VoloAbpIdentityIdentityRoleLookupDto = {
  id?: string;
  name?: string | null;
};
export type VoloAbpIdentityOrganizationUnitLookupDto = {
  id?: string;
  displayName?: string | null;
};
export type VoloAbpIdentityExternalLoginProviderDto = {
  name?: string | null;
  canObtainUserInfoWithoutPassword?: boolean;
};
export type VoloAbpIdentityImportExternalUserInput = {
  provider: string;
  userNameOrEmailAddress: string;
  password?: string | null;
};
export type VoloAbpIdentityDownloadTokenResultDto = {
  token?: string | null;
};
export type VoloAbpIdentityImportUsersFromFileType = "Excel" | "Csv";
export type VoloAbpIdentityImportUsersFromFileOutput = {
  allCount?: number;
  succeededCount?: number;
  failedCount?: number;
  invalidUsersDownloadToken?: string | null;
};
export type VoloAbpIdentityImportUsersFromFileOutputRead = {
  allCount?: number;
  succeededCount?: number;
  failedCount?: number;
  invalidUsersDownloadToken?: string | null;
  isAllSucceeded?: boolean;
};
export type VoloAbpAccountLinkUserInput = {
  userId?: string;
  tenantId?: string | null;
  token: string;
};
export type VoloAbpAccountUnLinkUserInput = {
  userId?: string;
  tenantId?: string | null;
};
export type VoloAbpAccountIsLinkedInput = {
  userId?: string;
  tenantId?: string | null;
};
export type VoloAbpAccountVerifyLinkTokenInput = {
  userId: string;
  tenantId?: string | null;
  token: string;
};
export type VoloAbpAccountVerifyLinkLoginTokenInput = {
  userId: string;
  tenantId?: string | null;
  token: string;
};
export type VoloAbpAccountLinkUserDto = {
  targetUserId?: string;
  targetUserName?: string | null;
  targetTenantId?: string | null;
  targetTenantName?: string | null;
  directlyLinked?: boolean;
};
export type VoloAbpApplicationDtosListResultDto601VoloAbpAccountLinkUserDto20VoloAbpAccountProPublicApplicationContracts20Version831020CultureNeutral20PublicKeyTokenNull =
  {
    items?: VoloAbpAccountLinkUserDto[] | null;
  };
export type VoloAbpAccountUserDelegationDto = {
  id?: string;
  userName?: string | null;
  startTime?: string;
  endTime?: string;
};
export type VoloAbpApplicationDtosListResultDto601VoloAbpAccountUserDelegationDto20VoloAbpAccountProPublicApplicationContracts20Version831020CultureNeutral20PublicKeyTokenNull =
  {
    items?: VoloAbpAccountUserDelegationDto[] | null;
  };
export type VoloAbpAccountUserLookupDto = {
  id?: string;
  userName?: string | null;
};
export type VoloAbpApplicationDtosListResultDto601VoloAbpAccountUserLookupDto20VoloAbpAccountProPublicApplicationContracts20Version831020CultureNeutral20PublicKeyTokenNull =
  {
    items?: VoloAbpAccountUserLookupDto[] | null;
  };
export type VoloAbpAccountDelegateNewUserInput = {
  targetUserId?: string;
  startTime: string;
  endTime: string;
};
export type Activ8UsersShareUserWithAnotherPartnerRequest = {
  sourceIdentityUserId?: string;
  sourceTenantId?: string;
  destinationTenantId?: string;
  destinationUserRole?: string | null;
};
export type Activ8UsersUserLinkageInfo = {
  user?: VoloAbpIdentityIdentityUserDto;
  tenant?: VoloSaasHostDtosSaasTenantDto;
};
export type Activ8UsersUserLinkageInfoRead = {
  user?: VoloAbpIdentityIdentityUserDtoRead;
  tenant?: VoloSaasHostDtosSaasTenantDtoRead;
};
export type VoloAbpUsersUserData = {
  id?: string;
  tenantId?: string | null;
  userName?: string | null;
  name?: string | null;
  surname?: string | null;
  isActive?: boolean;
  email?: string | null;
  emailConfirmed?: boolean;
  phoneNumber?: string | null;
  phoneNumberConfirmed?: boolean;
};
export type VoloAbpUsersUserDataRead = {
  id?: string;
  tenantId?: string | null;
  userName?: string | null;
  name?: string | null;
  surname?: string | null;
  isActive?: boolean;
  email?: string | null;
  emailConfirmed?: boolean;
  phoneNumber?: string | null;
  phoneNumberConfirmed?: boolean;
  extraProperties?: {
    [key: string]: any;
  } | null;
};
export type VoloAbpApplicationDtosListResultDto601VoloAbpUsersUserData20VoloAbpUsersAbstractions20Version831020CultureNeutral20PublicKeyTokenNull =
  {
    items?: VoloAbpUsersUserData[] | null;
  };
export type VoloAbpApplicationDtosListResultDto601VoloAbpUsersUserData20VoloAbpUsersAbstractions20Version831020CultureNeutral20PublicKeyTokenNullRead =
  {
    items?: VoloAbpUsersUserDataRead[] | null;
  };
export const {
  useAbpApiDefinitionGetQuery,
  useAbpApplicationConfigurationGetQuery,
  useAbpApplicationLocalizationGetQuery,
  useAbpTenantFindTenantByNameQuery,
  useAbpTenantFindTenantByIdQuery,
  useAccountRegisterMutation,
  useAccountSendPasswordResetCodeMutation,
  useAccountVerifyPasswordResetTokenMutation,
  useAccountResetPasswordMutation,
  useAccountGetConfirmationStateQuery,
  useAccountSendPhoneNumberConfirmationTokenMutation,
  useAccountSendEmailConfirmationTokenMutation,
  useAccountVerifyEmailConfirmationTokenMutation,
  useAccountConfirmPhoneNumberMutation,
  useAccountConfirmEmailMutation,
  useAccountSetProfilePictureMutation,
  useAccountGetProfilePictureQuery,
  useAccountGetTwoFactorProvidersQuery,
  useAccountSendTwoFactorCodeMutation,
  useAccountGetSecurityLogListQuery,
  useAccountVerifyAuthenticatorCodeMutation,
  useAccountResetAuthenticatorMutation,
  useAccountHasAuthenticatorQuery,
  useAccountGetAuthenticatorInfoQuery,
  useAccountGetProfilePictureFileQuery,
  useAccountRecaptchaQuery,
  useAccountExternalProviderGetAllQuery,
  useAccountExternalProviderGetByNameQuery,
  useAccountSettingsGetQuery,
  useAccountSettingsUpdateMutation,
  useAccountSettingsGetTwoFactorQuery,
  useAccountSettingsUpdateTwoFactorMutation,
  useAccountSettingsGetRecaptchaQuery,
  useAccountSettingsUpdateRecaptchaMutation,
  useAccountSettingsGetExternalProviderQuery,
  useAccountSettingsUpdateExternalProviderMutation,
  useActiveCampaignIntegrationGetSettingsQuery,
  useActiveCampaignIntegrationSetSettingsMutation,
  useApiIntegrationCompleteProgramExpressionOfInterestRequestMutation,
  useApiIntegrationGetPartnerInfoQuery,
  useApiIntegrationListActiveProgramsMutation,
  useApiIntegrationGetIntegrationSettingsQuery,
  useApplicationGetQuery,
  useApplicationUpdateMutation,
  useApplicationGetListQuery,
  useApplicationCreateMutation,
  useApplicationDeleteMutation,
  useApplicationGetTokenLifetimeQuery,
  useApplicationSetTokenLifetimeMutation,
  useAuditLogsGetListQuery,
  useAuditLogsGetQuery,
  useAuditLogsGetErrorRateQuery,
  useAuditLogsGetAverageExecutionDurationPerDayQuery,
  useAuditLogsGetEntityChangesQuery,
  useAuditLogsGetEntityChangesWithUsernameQuery,
  useAuditLogsGetEntityChangeWithUsernameQuery,
  useAuditLogsGetEntityChangeQuery,
  useIdentityClaimTypeGetListQuery,
  useIdentityClaimTypeCreateMutation,
  useIdentityClaimTypeGetQuery,
  useIdentityClaimTypeUpdateMutation,
  useIdentityClaimTypeDeleteMutation,
  useDataExportTriggerUserExportEmailMutation,
  useDataExportTriggerFacilitatorExportEmailMutation,
  useDataExportTriggerParticipantExportEmailMutation,
  useDataExportTriggerCourseTemplateExportEmailMutation,
  useDataExportTriggerCourseProgramExportEmailMutation,
  useDataExportTriggerCourseProgramParticipantExportEmailMutation,
  useDataImportTriggerParticipantImportMutation,
  useDataImportPrepareImportFileWriteMutation,
  useDirectoryDescriptorGetQuery,
  useDirectoryDescriptorRenameMutation,
  useDirectoryDescriptorDeleteMutation,
  useDirectoryDescriptorGetListQuery,
  useDirectoryDescriptorCreateMutation,
  useDirectoryDescriptorGetContentQuery,
  useDirectoryDescriptorMoveMutation,
  useDynamicClaimsRefreshMutation,
  useEditionGetQuery,
  useEditionUpdateMutation,
  useEditionDeleteMutation,
  useEditionGetListQuery,
  useEditionCreateMutation,
  useEditionMoveAllTenantsMutation,
  useEditionGetAllListQuery,
  useEditionGetUsageStatisticsQuery,
  useEditionGetPlanLookupQuery,
  useSubscriptionCreateSubscriptionMutation,
  useEmailSettingsGetQuery,
  useEmailSettingsUpdateMutation,
  useEmailSettingsSendTestEmailMutation,
  useIdentityExternalLoginCreateOrUpdateMutation,
  useFacilitatorCreateMutation,
  useFacilitatorGetListQuery,
  useFacilitatorUpdateMutation,
  useFacilitatorGetQuery,
  useFacilitatorDeleteMutation,
  useFeaturesGetQuery,
  useFeaturesUpdateMutation,
  useFeaturesDeleteMutation,
  useFileDescriptorGetQuery,
  useFileDescriptorRenameMutation,
  useFileDescriptorDeleteMutation,
  useFileDescriptorGetListQuery,
  useFileDescriptorCreateMutation,
  useFileDescriptorMoveMutation,
  useFileDescriptorGetPreInfoMutation,
  useFileDescriptorGetContentQuery,
  useFileDescriptorGetDownloadTokenQuery,
  useFileDescriptorDownloadQuery,
  useLanguageGetAllListQuery,
  useLanguageGetListQuery,
  useLanguageCreateMutation,
  useLanguageGetQuery,
  useLanguageUpdateMutation,
  useLanguageDeleteMutation,
  useLanguageSetAsDefaultMutation,
  useLanguageGetResourcesQuery,
  useLanguageGetCulturelistQuery,
  useLanguageTextGetListQuery,
  useLanguageTextGetQuery,
  useLanguageTextUpdateMutation,
  useLanguageTextRestoreToDefaultMutation,
  useLearningCourseProgramUpdateMutation,
  useLearningCourseProgramGetQuery,
  useLearningCourseProgramDeleteMutation,
  useLearningCourseProgramCreateMutation,
  useLearningCourseProgramGetListQuery,
  useLearningCourseProgramSetProgramStatusMutation,
  useLearningCourseProgramCloneCourseProgramMutation,
  useLearningCourseProgramAssignParticipantToProgramMutation,
  useLearningCourseProgramUnassignParticipantToProgramMutation,
  useLearningCourseProgramGenerateUrlForDownloadCourseContentQuery,
  useLearningCourseProgramFacilitatorGetQuery,
  useLearningCourseProgramFacilitatorUpdateMutation,
  useLearningCourseProgramFacilitatorDeleteMutation,
  useLearningCourseProgramFacilitatorGetListQuery,
  useLearningCourseProgramFacilitatorCreateMutation,
  useLearningCourseProgramParticipantGetListQuery,
  useLearningCourseProgramParticipantCreateMutation,
  useLearningCourseProgramParticipantGetQuery,
  useLearningCourseProgramParticipantUpdateMutation,
  useLearningCourseProgramParticipantDeleteMutation,
  useLearningCourseProgramParticipantGetByProgramAndParticipantQuery,
  useLearningCourseProgramSessionPrepareSessionContentQuery,
  useLearningCourseProgramSessionProgressThroughSessionContentMutation,
  useLearningCourseProgramSessionProgressThroughSessionContentBatchMutation,
  useLearningCourseTemplateSetTemplateStatusMutation,
  useLearningCourseTemplateGetRoleNamesApplicableToCourseAssignmentsQuery,
  useLearningCourseTemplateDeleteMutation,
  useLearningCourseTemplateGetQuery,
  useLearningCourseTemplateUpdateMutation,
  useLearningCourseTemplateCloneCourseTemplateMutation,
  useLearningCourseTemplateGetTemplatePartnerLicensingQuery,
  useLearningCourseTemplatePrepareResourceWriteMutation,
  useLearningCourseTemplatePrepareResourceReadQuery,
  useLearningCourseTemplateCreateMutation,
  useLearningCourseTemplateGetListQuery,
  useLearningCourseTemplateGetCountOfLiveProgramsUsingCourseTemplateQuery,
  useLearningCourseTemplateGetTemplateContentQuery,
  useLearningCourseTemplateGenerateUrlForDownloadCourseContentQuery,
  useOrganizationUnitAddRolesMutation,
  useOrganizationUnitGetRolesQuery,
  useOrganizationUnitAddMembersMutation,
  useOrganizationUnitGetMembersQuery,
  useOrganizationUnitCreateMutation,
  useOrganizationUnitDeleteMutation,
  useOrganizationUnitGetListQuery,
  useOrganizationUnitGetQuery,
  useOrganizationUnitUpdateMutation,
  useOrganizationUnitGetListAllQuery,
  useOrganizationUnitMoveMutation,
  useOrganizationUnitGetAvailableUsersQuery,
  useOrganizationUnitGetAvailableRolesQuery,
  useOrganizationUnitMoveAllUsersMutation,
  useOrganizationUnitRemoveMemberMutation,
  useOrganizationUnitRemoveRoleMutation,
  useParticipantCreateMutation,
  useParticipantGetListQuery,
  useParticipantUpdateMutation,
  useParticipantGetQuery,
  useParticipantDeleteMutation,
  useParticipantRemoveIdentityUserLinkMutation,
  useParticipantMaintainIdentityUserLinksMutation,
  usePartnerAppNotificationGetAllQuery,
  usePartnerDashboardGetQuery,
  usePartnerManagementEnsurePartnerSetupMutation,
  usePartnerProfileGetQuery,
  usePartnerProfileSetMutation,
  usePermissionsGetQuery,
  usePermissionsUpdateMutation,
  usePersonAccreditationGetAssignmentsForUserQuery,
  usePersonAccreditationSetAssignmentsForUserMutation,
  usePersonAccreditationGetListQuery,
  usePersonAccreditationCreateMutation,
  usePersonAccreditationUpdateMutation,
  usePersonAccreditationDeleteMutation,
  usePersonAccreditationGetQuery,
  usePersonAttributeGetAssignmentsForUserQuery,
  usePersonAttributeSetAssignmentsForUserMutation,
  usePersonAttributeGetListQuery,
  usePersonAttributeCreateMutation,
  usePersonAttributeUpdateMutation,
  usePersonAttributeDeleteMutation,
  usePersonAttributeGetQuery,
  useProfileGetQuery,
  useProfileUpdateMutation,
  useProfileChangePasswordMutation,
  useProfileGetTwoFactorEnabledQuery,
  useProfileSetTwoFactorEnabledMutation,
  useProfileCanEnableTwoFactorQuery,
  useProfileGetTimezonesQuery,
  useIdentityRoleGetQuery,
  useIdentityRoleUpdateMutation,
  useIdentityRoleDeleteMutation,
  useIdentityRoleCreateMutation,
  useIdentityRoleGetListQuery,
  useIdentityRoleGetAllListQuery,
  useIdentityRoleUpdateClaimsMutation,
  useIdentityRoleGetClaimsQuery,
  useIdentityRoleMoveAllUsersMutation,
  useIdentityRoleGetAllClaimTypesQuery,
  useScopeGetQuery,
  useScopeUpdateMutation,
  useScopeGetListQuery,
  useScopeCreateMutation,
  useScopeDeleteMutation,
  useScopeGetAllScopesQuery,
  useIdentitySecurityLogGetListQuery,
  useIdentitySecurityLogGetQuery,
  useAccountSessionGetListQuery,
  useAccountSessionGetQuery,
  useAccountSessionRevokeMutation,
  useIdentitySessionGetListQuery,
  useIdentitySessionGetQuery,
  useIdentitySessionRevokeMutation,
  useAuditLogSettingsGetQuery,
  useAuditLogSettingsUpdateMutation,
  useAuditLogSettingsGetGlobalQuery,
  useAuditLogSettingsUpdateGlobalMutation,
  useIdentitySettingsGetQuery,
  useIdentitySettingsUpdateMutation,
  useIdentitySettingsGetLdapQuery,
  useIdentitySettingsUpdateLdapMutation,
  useIdentitySettingsGetOAuthQuery,
  useIdentitySettingsUpdateOAuthMutation,
  useIdentitySettingsGetSessionQuery,
  useIdentitySettingsUpdateSessionMutation,
  useTenantGetQuery,
  useTenantUpdateMutation,
  useTenantDeleteMutation,
  useTenantGetListQuery,
  useTenantCreateMutation,
  useTenantGetDatabasesQuery,
  useTenantGetConnectionStringsQuery,
  useTenantUpdateConnectionStringsMutation,
  useTenantApplyDatabaseMigrationsMutation,
  useTenantGetEditionLookupQuery,
  useTenantCheckConnectionStringQuery,
  useTenantSetPasswordMutation,
  useTimezoneLookupGetAllIanaQuery,
  useTimezoneLookupGetAllWindowsQuery,
  useTimeZoneSettingsGetQuery,
  useTimeZoneSettingsUpdateMutation,
  useTimeZoneSettingsGetTimezonesQuery,
  useIdentityUserGetQuery,
  useIdentityUserUpdateMutation,
  useIdentityUserDeleteMutation,
  useIdentityUserGetListQuery,
  useIdentityUserCreateMutation,
  useIdentityUserSetUserTimezoneMutation,
  useIdentityUserFindByIdQuery,
  useIdentityUserGetRolesQuery,
  useIdentityUserUpdateRolesMutation,
  useIdentityUserGetAssignableRolesQuery,
  useIdentityUserGetAvailableOrganizationUnitsQuery,
  useIdentityUserGetAllClaimTypesQuery,
  useIdentityUserGetClaimsQuery,
  useIdentityUserUpdateClaimsMutation,
  useIdentityUserGetOrganizationUnitsQuery,
  useIdentityUserLockMutation,
  useIdentityUserUnlockMutation,
  useIdentityUserFindByUsernameQuery,
  useIdentityUserFindByEmailQuery,
  useIdentityUserGetTwoFactorEnabledQuery,
  useIdentityUserSetTwoFactorEnabledMutation,
  useIdentityUserUpdatePasswordMutation,
  useIdentityUserGetRoleLookupQuery,
  useIdentityUserGetOrganizationUnitLookupQuery,
  useIdentityUserGetExternalLoginProvidersQuery,
  useIdentityUserImportExternalUserMutation,
  useIdentityUserGetListAsExcelFileQuery,
  useIdentityUserGetListAsCsvFileQuery,
  useIdentityUserGetDownloadTokenQuery,
  useIdentityUserGetImportUsersSampleFileQuery,
  useIdentityUserImportUsersFromFileMutation,
  useIdentityUserGetImportInvalidUsersFileQuery,
  useIdentityLinkUserLinkMutation,
  useIdentityLinkUserUnlinkMutation,
  useIdentityLinkUserIsLinkedMutation,
  useIdentityLinkUserGenerateLinkTokenMutation,
  useIdentityLinkUserVerifyLinkTokenMutation,
  useIdentityLinkUserGenerateLinkLoginTokenMutation,
  useIdentityLinkUserVerifyLinkLoginTokenMutation,
  useIdentityLinkUserGetAllListQuery,
  useIdentityUserDelegationGetDelegatedUsersQuery,
  useIdentityUserDelegationGetMyDelegatedUsersQuery,
  useIdentityUserDelegationGetActiveDelegationsQuery,
  useIdentityUserDelegationGetUserLookupQuery,
  useIdentityUserDelegationDelegateNewUserMutation,
  useIdentityUserDelegationDeleteDelegationMutation,
  useUserLinkageShareUserWithAnotherTenantMutation,
  useUserLinkageGetAllUserLinkagesQuery,
  useIdentityUserLookupFindByIdQuery,
  useIdentityUserLookupFindByUserNameQuery,
  useIdentityUserLookupSearchQuery,
  useIdentityUserLookupGetCountQuery,
} = injectedRtkApi;
